import { ComponentType } from 'react';

import MenuIcon from '@material-ui/icons/Menu';
import DraftsIcon from '@material-ui/icons/Drafts';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CallMadeIcon from '@material-ui/icons/CallMade';
import SettingsIcon from '@material-ui/icons/Settings';

type ResourceLink = string;

type ResourceGroup = {
    name: string;
    icon: ComponentType;
    items: ResourceLink[];
};

export type MenuItem = ResourceLink | ResourceGroup;

export const isMenuItemGroup = (item: MenuItem): item is ResourceGroup => typeof item !== 'string';

const createMenuBuilder = () => {
    const menu: MenuItem[] = [];

    return {
        addLink(resource: ResourceLink) {
            menu.push(resource);

            return this;
        },

        addGroup(group: ResourceGroup) {
            menu.push(group);

            return this;
        },

        build: () => {
            return menu;
        },
    };
};

const menu = createMenuBuilder()
    .addGroup({
        name: 'errands',
        icon: MenuIcon,
        items: ['errands', 'reclamations', 'products', 'external_sales'],
    })
    .addLink('user_notifications')
    .addGroup({
        name: 'simple_errands',
        icon: DraftsIcon,
        items: ['errand_drafts'],
    })
    .addGroup({
        name: 'general',
        icon: CalendarViewDayIcon,
        items: ['workshops', 'customers', 'statuses', 'stores', 'locations'],
    })
    .addGroup({
        name: 'related',
        icon: ViewModuleIcon,
        items: ['categories', 'goods_types', 'manufacturers', 'models', 'suppliers', 'resellers'],
    })
    .addGroup({
        name: 'received',
        icon: CallReceivedIcon,
        items: ['defects', 'functionalities', 'states', 'types'],
    })
    .addGroup({
        name: 'outgoing',
        icon: CallMadeIcon,
        items: ['grades', 'depreciations'],
    })
    .addGroup({
        name: 'settings',
        icon: SettingsIcon,
        items: [
            'settings',
            'reports',
            'costs',
            'users',
            'user_groups',
            'notification_events',
            'api_tokens',
            'countries',
            'localization_labels',
            'log_entries',
        ],
    })
    .build();

export default menu;
