import { useEffect, useMemo } from 'react';
import { Identifier, useSafeSetState } from 'react-admin';
import { debounce } from 'lodash';

import { get } from '@js/request/apiRequest';

type Options = {
    enabled?: boolean;
    version?: number;
};

const useIdsBatchRequest = <T>(url: string, id: Identifier | undefined, options: Options = {}) => {
    const { enabled = true, version } = options;

    const [loading, setLoading] = useSafeSetState(enabled);
    const [data, setData] = useSafeSetState<T | null>(null);
    const [error, setError] = useSafeSetState<any>();

    const responseHandler: ResponseHandler = useMemo(
        () => ({
            onSuccess: (response) => {
                setData(response);
                setLoading(false);
            },
            onError: (error) => {
                setError(error);
                setLoading(false);
            },
        }),
        [setData, setError, setLoading],
    );

    useEffect(() => {
        if (!enabled || !id) return;

        if (!queries[url]) queries[url] = {};
        if (!queries[url][id]) queries[url][id] = new Set();

        queries[url][id].add(responseHandler);

        callQueries();
    }, [url, id, enabled, setData, setLoading, setError, responseHandler, version]);

    return {
        loading,
        data,
        error,
    };
};

type ResponseHandler = {
    onSuccess: (response: any) => void;
    onError: (error: any) => void;
};

const queries: Record<string, Record<Identifier, Set<ResponseHandler>>> = {};

const callQueries = debounce(() => {
    const urls = Object.keys(queries);

    urls.forEach((url) => {
        const ids = Object.keys(queries[url]);
        const callbacks = queries[url];

        delete queries[url];

        get<Record<Identifier, any>>(url, {
            ids,
        })
            .then((response) => {
                Object.entries(callbacks).forEach(([id, handlers]) => {
                    handlers.forEach(({ onSuccess }) => onSuccess(response[id]));
                });
            })
            .catch((error) => {
                Object.values(callbacks).forEach((handlers) => {
                    handlers.forEach(({ onError }) => onError(error));
                });
            });
    });
});

export default useIdsBatchRequest;
