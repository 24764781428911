import { useResourceContext } from 'react-admin';
import { Resource } from '@api-platform/api-doc-parser';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';

const useResourceSchema = (props: { resource?: string; schema?: Resource }) => {
    const resource = useResourceContext(props);
    const { resources } = useHydraSchemaContext();

    const schema = props.schema ?? resources.find((schema) => schema.name === resource);

    if (!schema) {
        throw new Error(`Resource ${resource} not present inside API description`);
    }

    return schema;
};

export const usePossibleNotDefinedResourceSchema = (props: { resource?: string }) => {
    const resource = useResourceContext(props);
    const { resources } = useHydraSchemaContext();

    if (!resource) return undefined;

    return resources.find((schema) => schema.name === resource);
};

export default useResourceSchema;
