import { ROLES } from '@js/interfaces/user';
import { Iri } from '@js/interfaces/ApiRecord';
import { UserIdentity } from '@js/auth/types';

export type ListAction =
    | 'add_filter'
    | 'create'
    | 'import'
    | 'export'
    | 'split'
    | 'import_orders'
    | 'import_co2'
    | 'co2'
    | 'purchase_separately'
    | 'manual_sale'
    | 'returned_item';
export type ListActions = ListAction[];

export type EditAction = 'delete';
export type EditActions = Array<EditAction>;

export type FilterDefaultValue = Array<string | number | Iri>;

export interface ResourcePermissions {
    enable: boolean;
    actions: {
        list: ListActions;
        edit: EditActions;
    };
    fields: {
        list: string[];
        edit: string[];
        required: string[];
    };
    listFilters: {
        alwaysOn: string[];
        defaultValues: Record<string, FilterDefaultValue>;
    };
    features: {
        [key: string]: boolean;
    };
}

type ResourceFeatures<T extends string> = T extends 'reclamations' ? ReclamationFeatures : Record<string, boolean>;

export type ReclamationFeatures = {
    disableDefaultLabelPrint?: boolean;
    disableSavingsRawValues?: boolean;
    disableSavingsAnalogies?: boolean;
};

export type DashboardArea = 'count_cards_statistic' | 'per_day_chart' | 'count_total_stats' | 'reclamation_statistic';

export type DashboardSection =
    | 'savings'
    | 'savings_detailed'
    | 'economical'
    | 'economical_detailed'
    | 'purchase_separately_sales'
    | 'manual_sales'
    | 'reports'
    | 'statuses'
    | 'records_total'
    | 'users_detailed';

export type EconomicalDetailedPageSection = 'user_processing_time';
export type DashboardDetailedPageSections = {
    economical_detailed: Record<EconomicalDetailedPageSection, boolean>;
};

export type DashboardPermissions = {
    enable: boolean;
    sections?: Record<DashboardSection, boolean>;
    areas?: Record<DashboardArea, boolean>;
    detailed_page_sections?: DashboardDetailedPageSections;
};

export interface UserPermissions {
    dashboard: DashboardPermissions;
    resources: {
        [key: string]: ResourcePermissions;
    };
}

class UserPermissionsService {
    // User role
    public isAdmin: boolean;
    public isSuperAdmin: boolean;

    private readonly permissions;

    constructor(user: UserIdentity) {
        this.permissions = user.permissions;
        this.isAdmin = user.roles.includes(ROLES.ROLE_ADMIN);
        this.isSuperAdmin = user.roles.includes(ROLES.ROLE_SUPER_ADMIN);
    }

    get dashboard() {
        return {
            enabled: this.isAdmin || Boolean(this.permissions?.dashboard.enable),
            isSectionEnabled: (section: DashboardSection) => {
                if (this.isAdmin) return true;
                return Boolean(this.permissions?.dashboard?.sections?.[section]);
            },
            isAreaEnabled: (area: DashboardArea) => {
                if (this.isAdmin) return true;
                return Boolean(this.permissions?.dashboard?.areas?.[area]);
            },
            isDetailedPageSectionEnabled: <
                TPage extends keyof DashboardDetailedPageSections,
                TSection extends keyof DashboardDetailedPageSections[TPage],
            >(
                page: TPage,
                section: TSection,
            ) => {
                if (this.isAdmin) return true;
                return Boolean(this.permissions?.dashboard?.detailed_page_sections?.[page][section]);
            },
        };
    }

    getFeatureFlags<T extends string>(resource: T): ResourceFeatures<T> {
        return (this.getResourcePermissions(resource)?.features ?? {}) as ResourceFeatures<T>;
    }

    isResourceEnabled(resource: string): boolean {
        if (this.isAdmin) {
            return true;
        }

        // Check if resource is allowed
        return this.getResourcePermissions(resource)?.['enable'] ?? false;
    }

    isFieldEditable(resource: string, field: string): boolean {
        if (!this.isResourceFieldsRestricted(resource)) {
            return true;
        }

        return this.getEditableFields(resource).includes(field);
    }

    isFieldRequired(resource: string, field: string): boolean {
        return (this.getResourcePermissions(resource)?.['fields']['required'] ?? []).includes(field);
    }

    isFieldListable(resource: string, field: string): boolean {
        if (!this.isResourceFieldsRestricted(resource)) {
            return true;
        }

        return this.getListFields(resource).includes(field);
    }

    isFilterAlwaysOn(resource: string, filter: string): boolean {
        const alwaysOnFilters = this.getResourcePermissions(resource)?.['listFilters']['alwaysOn'] ?? [];
        return alwaysOnFilters.includes(filter);
    }

    getFilterDefaultValue(resource: string, filter: string): FilterDefaultValue | null {
        return this.getResourcePermissions(resource)?.['listFilters']['defaultValues'][filter] ?? null;
    }

    getListFields(resource: string): string[] {
        return this.getResourcePermissions(resource)?.['fields']['list'] ?? [];
    }

    getEditableFields(resource: string): string[] {
        return this.getResourcePermissions(resource)?.['fields']['edit'] ?? [];
    }

    getListActions(resource: string): ListActions {
        return this.getResourcePermissions(resource)?.['actions']['list'] ?? [];
    }

    isListActionEnabled(resource: string, action: ListAction): boolean {
        if (!this.isResourceFieldsRestricted(resource)) {
            return true;
        }

        return this.getListActions(resource).includes(action);
    }

    isEditActionEnabled(resource: string, action: EditAction): boolean {
        if (!this.isResourceFieldsRestricted(resource)) {
            return true;
        }

        return this.getEditActions(resource).includes(action);
    }

    getEditActions(resource: string): EditActions {
        return this.getResourcePermissions(resource)?.['actions']['edit'] ?? [];
    }

    getResourcePermissions(resource: string): ResourcePermissions | undefined {
        return this.permissions?.['resources'][resource];
    }

    /**
     * List/form fields, actions,
     * filters etc. access be restricted for non-admin users only for errands and reclamations resources
     */
    isResourceFieldsRestricted(resource: string): boolean {
        if (this.isAdmin) {
            return false;
        }

        return resource === 'errands' || resource === 'reclamations';
    }
}

export default UserPermissionsService;
