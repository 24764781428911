import { FC } from 'react';

import FunctionField from '@components/field/FunctionField';
import PriceField from '@components/field/PriceField';

import { Errand } from '@js/interfaces/errand';

const PriceErrandCurrencyField: FC<{ source: string }> = ({ source, ...props }) => {
    return (
        <FunctionField<Errand>
            {...props}
            render={({ currency }, props) => <PriceField currency={currency} source={source} {...props} />}
        />
    );
};

export default PriceErrandCurrencyField;
