import { FC, memo, useCallback, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import {
    Button as RaButton,
    FormTab,
    Identifier,
    RedirectionSideEffect,
    TabbedForm,
    TabbedFormProps,
    ToolbarProps,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useRedirect,
    useTranslate,
} from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';
import { useForm } from 'react-final-form';
import WarningIcon from '@material-ui/icons/Warning';

import Toolbar from '@components/form/Toolbar';
import GridFormLayout from '@components/form/GridFormLayout';

import ErrandInputGuesser from './input/ErrandInputGuesser';
import ReclamationsEditTab from './ReclamationsEditTab';

import useFormFields from '@js/hooks/useFormFields';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useAutoTrackTimeController, { AutoTrackTimeControllerProps } from '@js/hooks/useAutoTrackTimeController';
import useUnmountEffect from '@js/hooks/useUnmountEffect';

import { extractFields } from './util';
import { useAppConfigContext } from '@js/context/AppConfigContext';

type ErrandFormProps = SafeOmit<TabbedFormProps, 'children' | 'onSubmit'>;

type FormToolbarProps = ToolbarProps & {
    autoTrackTimeController: AutoTrackTimeControllerProps;
    isEdit: boolean;
};

// Memo for better performance. For example when confirm dialog popup, don't re-render form
const ErrandFormFields = memo<{ fields: Field[]; fullWidth: boolean }>(({ fields: allFields, ...props }) => {
    const { extracted: lastFields, rest: fields } = extractFields(allFields, [
        'links',
        'images',
        'comment',
        'internalFaultDescription',
        'internalComment',
    ]);

    return (
        <>
            <GridFormLayout {...props}>
                {fields.map((field) => (
                    <ErrandInputGuesser key={field.name} source={field.name} field={field} fullWidth />
                ))}
            </GridFormLayout>
            {lastFields.map((field) => (
                <ErrandInputGuesser key={field.name} source={field.name} field={field} {...props} />
            ))}
        </>
    );
});
ErrandFormFields.displayName = 'ErrandFormFields';

// This component overrides Toolbar only to catch "handleSubmitWithRedirect" call and save redirect in top level Create/Edit components
// Don't know if there is better way to know where form will redirect after submit, since it can "Save" or "Save & Close".
// And reason we need to have redirect in top component is that we have custom "onSuccess" for tracking time
const FormToolbar: FC<FormToolbarProps> = ({
    autoTrackTimeController: { saveEditSession, saveCreateSession, resetSession },
    isEdit,
    handleSubmitWithRedirect,
    ...props
}) => {
    const redirectRef = useRef<RedirectionSideEffect>();
    const notify = useNotify();
    const redirect = useRedirect();
    const { restart } = useForm();

    return (
        <Toolbar
            {...props}
            onSuccess={({ data: record }) => {
                notify(`ra.notification.${isEdit ? 'updated' : 'created'}`, {
                    type: 'info',
                    messageArgs: { smart_count: 1 },
                });

                // If redirect is set, just save tracked time
                if (redirectRef.current) {
                    (isEdit ? saveEditSession : saveCreateSession)({ errand: record.id });
                    // Have no idea why RedirectionSideEffect is not valid param to redirect first argument
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    redirect(redirectRef.current, props.basePath!, record.id, record);
                } else if (isEdit) {
                    // If no redirect is set and user continue editing, need to reset editing tracking session
                    saveEditSession({ errand: record.id });
                    resetSession();
                    restart();
                } else {
                    // If new errand was created and no redirect was set, save spent time on create and redirect to edit page
                    saveCreateSession({ errand: record.id });
                    redirect('edit', props.basePath!, record.id, record);
                }
            }}
            handleSubmitWithRedirect={(redirect) => {
                redirectRef.current = redirect;

                if (handleSubmitWithRedirect) {
                    handleSubmitWithRedirect(redirect);
                }
            }}
        />
    );
};

const ErrandForm: FC<ErrandFormProps> = ({ save, ...props }) => {
    const translate = useTranslate();
    const translateResourceName = useGetResourceLabel();
    const getFieldLabel = useTranslateResourceField();
    const fields = useFormFields(props, ['statusNotifications']);
    const permissions = useUserPermissions();
    const errand = useRecordContext(props);
    const { defaultCurrency } = useAppConfigContext();

    const reclamationFormsRef = useRef<Set<Identifier>>(new Set());
    const [warningDialog, setWarningDialog] = useState<{ open: boolean; message: string; onConfirm?: () => void }>({
        open: false,
        message: '',
    });

    const autoTrackTimeController = useAutoTrackTimeController();
    useUnmountEffect(() => {
        if (errand.id) {
            autoTrackTimeController.saveCancelSession({ errand: errand.id });
        }
    });

    const registerForm = useCallback((id: Identifier) => reclamationFormsRef.current.add(id), []);
    const unregisterForm = useCallback((id: Identifier) => reclamationFormsRef.current.delete(id), []);

    const handleSave: TabbedFormProps['save'] = (...args) => {
        if (!save) throw new Error('"save" props is required for ErrandForm');

        // Warn user if there are unsaved reclamation forms
        if (reclamationFormsRef.current.size > 0) {
            setWarningDialog({
                open: true,
                message: translate('app.message.errand_save_warning'),
            });

            return;
        }

        // Check if there are changes done to fields that are used to generate composed identifier
        if (errand.id) {
            const fields = ['store', 'identificator', 'location'];
            const [values] = args;
            const changedFields = fields.filter((field) => errand[field] !== values[field]);

            if (changedFields.length > 0) {
                setWarningDialog({
                    open: true,
                    message: translate('app.message.errand_composed_identifier_change_warning', {
                        fields: changedFields.map((field) => getFieldLabel(field)).join(', '),
                    }),
                    onConfirm: () => {
                        setWarningDialog({ ...warningDialog, open: false });
                        save(...args);
                    },
                });

                return;
            }
        }

        save(...args);
    };

    const handleWarningDialogClose = () => setWarningDialog({ ...warningDialog, open: false });

    return (
        <>
            <TabbedForm
                {...props}
                save={handleSave}
                initialValues={{ currency: defaultCurrency }}
                toolbar={<FormToolbar autoTrackTimeController={autoTrackTimeController} isEdit={!!errand.id} />}
                autoComplete="off"
            >
                <FormTab label={translate('app.resources.errands.form.general')}>
                    <ErrandFormFields fields={fields} fullWidth />
                </FormTab>
                {permissions.isResourceEnabled('reclamations') && (
                    <FormTab label={translateResourceName('reclamations')} path="reclamations">
                        <ReclamationsEditTab registerForm={registerForm} unregisterForm={unregisterForm} />
                    </FormTab>
                )}
            </TabbedForm>
            <Dialog open={warningDialog.open} onClose={handleWarningDialogClose}>
                <DialogContent>
                    <DialogContentText>{warningDialog.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWarningDialogClose} color="primary">
                        {translate('ra.action.close')}
                    </Button>
                    {warningDialog.onConfirm && (
                        <RaButton onClick={warningDialog.onConfirm} label="ra.action.confirm" variant="contained">
                            <WarningIcon />
                        </RaButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ErrandForm;
