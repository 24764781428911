import { ComponentType, FC } from 'react';
import {
    DeleteButton,
    EditButton,
    ShowButton as ShowButtonComponent,
    useResourceContext,
    useResourceDefinition,
} from 'react-admin';
import { Box, MenuItem } from '@material-ui/core';

import EditButtonReturnUrl from '@components/button/EditButtonReturnUrl';
import MenuButton from '@components/button/MenuButton';
import LockRecordIcon from '@components/detail/LockRecordIcon';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { ApiRecord } from '@js/interfaces/ApiRecord';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

interface Props {
    record?: ApiRecord;
    resource?: string;
    hasShow?: boolean;
    hasEdit?: boolean;
    hasDelete?: boolean;
    forceShowDelete?: boolean;
    showButton?: ComponentType;
    editButton?: ComponentType;
    deleteButton?: ComponentType;
    subResourceActions?: boolean;
    basePath?: string;
    showLockIcon?: boolean;
}

const RowActions: FC<Props> = ({
    hasDelete,
    forceShowDelete,
    editButton,
    deleteButton,
    subResourceActions,
    showLockIcon,
    showButton: ShowButton = ShowButtonComponent,
    ...props
}) => {
    const { hasShow, hasEdit } = useResourceDefinition(props);
    const isSmall = useIsSmallScreen();
    const resource = useResourceContext(props);
    const permissions = useUserPermissions();

    // Allow deleting only if editing is allowed
    hasDelete = forceShowDelete || (hasDelete && permissions.isEditActionEnabled(resource, 'delete'));

    const renderEditButton = () => {
        const Component = editButton || (subResourceActions ? EditButtonReturnUrl : EditButton);

        return <Component {...sanitizeButtonProps(props)} />;
    };

    const renderDeleteButton = () => {
        const Component = deleteButton || DeleteButton;

        return (
            <Component
                {...sanitizeButtonProps(props)}
                mutationMode="pessimistic"
                redirect={subResourceActions ? false : undefined}
            />
        );
    };

    return (
        <Box display="flex" justifyContent="end">
            {showLockIcon && <LockRecordIcon />}
            {isSmall ? (
                <MenuButton>
                    {hasShow && (
                        <MenuItem disableGutters>
                            <ShowButton {...sanitizeButtonProps(props)} />
                        </MenuItem>
                    )}
                    {hasEdit && <MenuItem disableGutters>{renderEditButton()}</MenuItem>}
                    {hasDelete && <MenuItem disableGutters>{renderDeleteButton()}</MenuItem>}
                </MenuButton>
            ) : (
                <>
                    {hasShow && <ShowButton {...sanitizeButtonProps(props)} />}
                    {hasEdit && renderEditButton()}
                    {hasDelete && renderDeleteButton()}
                </>
            )}
        </Box>
    );
};

const sanitizeButtonProps = ({ basePath, record }: { basePath?: string; record?: ApiRecord }) => ({ basePath, record });

RowActions.defaultProps = {
    subResourceActions: false,
};

export default RowActions;
