import { email, isRequired, maxLength, minValue, required, useResourceContext, Validator } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useResourceSchema from '@js/hooks/useResourceSchema';
import { BackendValidationRule } from '@js/global';
import strNotContains from '@js/validator/strNotContains';
import { uniqueField } from '@js/validator/unique';

interface Props {
    source?: string;
    resource?: string;
    validate?: Validator | Validator[];
    field?: Field;
}

const useInputAutoValidator = ({ source, field, validate, ...props }: Props): Validator[] | undefined => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);
    const schema = useResourceSchema(props);
    const finalValidate = Array.isArray(validate) ? [...validate] : validate ? [validate] : [];
    const hasRequiredValidator = isRequired(finalValidate);

    if (!source && !field) {
        throw new Error('You should provide either a "source" or "field" prop.');
    }

    source ??= field?.name;
    field ??= schema.fields?.find((f) => f.name === source);

    if (!hasRequiredValidator && (field?.required || permissions.isFieldRequired(resource, source!))) {
        finalValidate.push(required());
    }

    const validationRules = schema.validationRules[source!];
    finalValidate.push(...backendValidationToReactAdminValidation(validationRules ?? [], source!, resource));

    return finalValidate.length > 0 ? finalValidate : undefined;
};

const backendValidationToReactAdminValidation = (
    validationRules: BackendValidationRule[],
    field: string,
    resource: string,
) => {
    const validators: Validator[] = [];

    for (const rule of validationRules) {
        switch (rule.type) {
            case 'email':
                validators.push(email());
                break;
            case 'length':
                validators.push(maxLength(rule.arguments?.max ?? 255));
                break;
            case 'positiveOrZero':
                validators.push(minValue(0));
                break;
            case 'positive':
                validators.push(minValue(1));
                break;
            case 'strNotContains':
                validators.push(strNotContains(rule.arguments?.disallowed ?? []));
                break;
            case 'unique':
                validators.push(uniqueField(field, resource));
                break;
        }
    }

    return validators;
};

export default useInputAutoValidator;
