import { useEffect } from 'react';

import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';
import { UserNotification } from '@js/interfaces/usernotification';
import useGetIdentity from '@js/hooks/useGetIdentity';

const useOnNewNotificationSubscription = (onNewNotification: () => void) => {
    const { identity } = useGetIdentity();
    const subscription = useCustomMercureSubscription();

    useEffect(() => {
        if (!identity?.id) return;

        return subscription(`${identity.id}/notifications`, (data) => {
            try {
                const notification = JSON.parse(data) as UserNotification;
                if (!notification.readAt) {
                    onNewNotification();
                }
            } catch (e) {
                console.error('Failed to parse notification data', e);
            }
        });
    }, [subscription, identity?.id, onNewNotification]);
};

export default useOnNewNotificationSubscription;
