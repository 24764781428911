import { FC, MouseEvent, useRef, useState } from 'react';
import {
    Box,
    Button as MuiButton,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core';
import {
    DeleteButton as RaDeleteButton,
    Identifier,
    linkToRecord,
    OnSuccess,
    SaveButton,
    Toolbar as RaToolbar,
    ToolbarProps,
    useNotify,
    useRecordContext,
    useResourceContext,
    useSaveContext,
    useTranslate,
} from 'react-admin';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import CancelButton from '@components/button/CancelButton';
import useReturnUrlPath from '@js/hooks/useReturnUrlPath';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

type ToolbarButtonsCommonProps = {
    onCancel?: () => void;
    onSuccess?: OnSuccess;
};

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

type ButtonProps = { isEdit: boolean } & ToolbarButtonsCommonProps &
    Pick<
        ToolbarProps,
        | 'basePath'
        | 'handleSubmit'
        | 'handleSubmitWithRedirect'
        | 'undoable'
        | 'submitOnEnter'
        | 'saving'
        | 'invalid'
        | 'pristine'
        | 'validating'
    >;

const Buttons: FC<ButtonProps> = ({
    isEdit,
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    validating,
    saving,
    submitOnEnter,
    onCancel,
    onSuccess,
}) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const translate = useTranslate();
    const returnUrl = useReturnUrlPath();
    const { setOnSuccess } = useSaveContext();
    const notify = useNotify();

    const handleMenuOpen = () => {
        setOpen(true);
    };
    const handleMenuClose = (event: MouseEvent<Document>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleSaveAndClose = () => {
        if (onSuccess && setOnSuccess) {
            setOnSuccess(onSuccess);
        }

        if (handleSubmitWithRedirect) {
            if (!saving) {
                if (invalid) {
                    notify('ra.message.invalid_form', { type: 'warning' });
                }
                handleSubmitWithRedirect(returnUrl ?? 'list');
            }
        }
    };

    const getEditRedirectWithReturnUrl = (basePath: string, id: Identifier) => {
        return `${linkToRecord(basePath, id, 'edit')}?returnUrl=${encodeURIComponent(returnUrl!)}`;
    };

    return (
        <Box display="flex" gridGap={8}>
            <ButtonGroup variant="contained" color="primary" size="medium" ref={anchorRef} disabled={pristine}>
                <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
                    invalid={invalid}
                    saving={saving || validating}
                    submitOnEnter={submitOnEnter}
                    redirect={
                        isEdit
                            ? returnUrl
                                ? getEditRedirectWithReturnUrl
                                : false
                            : returnUrl
                            ? getEditRedirectWithReturnUrl
                            : undefined
                    }
                    onSuccess={onSuccess}
                />
                <MuiButton color="primary" size="small" onClick={handleMenuOpen}>
                    <ArrowDropDownIcon />
                </MuiButton>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} transition style={{ zIndex: 2 }} placement="bottom-end">
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center top',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleMenuClose}>
                                <MenuList>
                                    <MenuItem onClick={handleSaveAndClose}>
                                        {translate('app.action.save_close')}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <CancelButton to={(returnUrl ?? basePath)!} onCancel={onCancel} requireConfirm={!pristine} />
        </Box>
    );
};

type DeleteButtonProps = Pick<ToolbarProps, 'record' | 'mutationMode'> & { isEdit: boolean };

const DeleteButton = ({ mutationMode, isEdit, record, ...props }: DeleteButtonProps) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);
    const returnUrl = useReturnUrlPath();

    if (!isEdit || !permissions.isEditActionEnabled(resource, 'delete')) {
        return null;
    }

    return <RaDeleteButton mutationMode={mutationMode} record={record} redirect={returnUrl} />;
};

const Toolbar: FC<ToolbarProps & ToolbarButtonsCommonProps> = ({ onCancel, onSuccess, ...props }) => {
    const record = useRecordContext(props);
    const classes = useStyles();
    const isEdit = Boolean(record?.id);
    const isSmall = useIsSmallScreen();

    return (
        <RaToolbar
            {...props}
            classes={classes}
            // React Admin is using deprecated HOC 'withWidth' and initial value is 'xs',
            // which make toolbar jump on refresh(when it unmounts and mount back user main view), because on mobile it's fixed to the bottom.
            // So if it's not a mobile just pass "md" which works fine
            width={isSmall ? 'xs' : 'md'}
        >
            <Buttons isEdit={isEdit} onCancel={onCancel} onSuccess={onSuccess} />
            <DeleteButton mutationMode={props.mutationMode} record={record} isEdit={isEdit} />
        </RaToolbar>
    );
};

export default Toolbar;
