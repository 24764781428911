import { SimpleFormProps, Validator } from 'react-admin';

import SimpleFormGuesser from '@components/form/SimpleFormGuesser';

import useInputAutoValidator from '@js/hooks/useInputAutoValidator';
import ScrollableSelectInput from '@components/input/ScrollableSelectInput';

const GoodsTypeInput = (props: { source: string; resource?: string; validate?: Validator | Validator[] }) => {
    const validate = useInputAutoValidator(props);

    return (
        <ScrollableSelectInput
            {...props}
            reference="goods_types"
            filter={{ 'exists[parent]': false }}
            sort={{ field: 'name', order: 'ASC' }}
            validate={validate}
        />
    );
};

const GoodsTypeForm = (props: Omit<SimpleFormProps, 'children' | 'onSubmit'>) => {
    return (
        <SimpleFormGuesser {...props}>
            <GoodsTypeInput source="parent" />
        </SimpleFormGuesser>
    );
};

export default GoodsTypeForm;
