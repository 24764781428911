import { FC } from 'react';
import { ChipField, InjectedFieldProps, ReferenceField } from 'react-admin';
import { Alert } from '@material-ui/lab';

import { LinkType, ReferenceArrayField } from '@components/field/FieldGuesser';

import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import useResourceSchema from '@js/hooks/useResourceSchema';

interface Props extends InjectedFieldProps {
    sortable?: boolean;
    addLabel?: boolean;
    label?: string;
    source: string;
    linkType?: LinkType;
}

const ReferenceFieldGuesser: FC<Props> = ({ linkType, ...props }) => {
    const schema = useResourceSchema(props);
    const { schemaAnalyzer } = useHydraSchemaContext();

    const field = schema.fields?.find((field) => field.name === props.source);

    if (!field) {
        throw new Error(
            `Field "${props.source}" not present inside API description for the resource "${props.resource}"`,
        );
    }

    const reference = field.reference;

    if (reference !== null && typeof reference === 'object') {
        const fieldName = schemaAnalyzer.getFieldNameFromSchema(reference);

        if (field.maxCardinality === 1) {
            return (
                <ReferenceField {...props} reference={reference.name}>
                    <ChipField source={fieldName} />
                </ReferenceField>
            );
        }

        return <ReferenceArrayField {...props} reference={reference.name} fieldName={fieldName} linkType={linkType} />;
    }

    return <Alert severity="warning">&ldquo;{field.name}&rdquo; field is not reference</Alert>;
};

ReferenceFieldGuesser.defaultProps = {
    sortable: false,
    addLabel: true,
};

export default ReferenceFieldGuesser;
