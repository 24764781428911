import {
    LinearProgress,
    linkToRecord,
    ReferenceManyField,
    ReferenceManyFieldProps,
    useGetResourceLabel,
    useListContext,
    useReference,
} from 'react-admin';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import AutoHidePagination from '@components/list/AutoHidePagination';
import TimelineLink from '@js/components/mui/TimelineLink';

import DataField from '@components/resource/logEntry/field/DataField';
import TimelineUserTitle from './TimelineUserTitle';

import { LogEntry } from '@js/interfaces/logentry';
import { toLocaleString } from '@js/utility/dateUtility';
import { useActionLabel } from '@components/resource/logEntry/field/ActionField';
import useReferenceFieldLink from '@js/hooks/useReferenceFieldLink';
import { Errand } from '@js/interfaces/errand';
import { Reclamation } from '@js/interfaces/reclamation';

type Props = Omit<ReferenceManyFieldProps, 'children' | 'reference' | 'target'> & {
    hideRecordTitle?: boolean;
};

const useStyles = makeStyles({
    missingOppositeContent: {
        '&:before': {
            display: 'none',
        },
    },
});

const LogsView = ({ hideRecordTitle }: { hideRecordTitle?: boolean }) => {
    const { loaded, data, ids } = useListContext<LogEntry>();
    const classes = useStyles();
    const getActionLabel = useActionLabel();

    if (!loaded) return <LinearProgress />;

    return (
        <Timeline align="left">
            {ids.map((id) => {
                const log = data[id];
                if (!log) return null;

                return (
                    <TimelineItem key={id} classes={classes}>
                        <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant="body2" color="secondary">
                                {toLocaleString(log.loggedAt)}
                                {' | '}
                                {getActionLabel(log.action)}
                                {log.user && (
                                    <>
                                        {' | '}
                                        <TimelineUserTitle id={log.user} />
                                    </>
                                )}
                                {!hideRecordTitle && (
                                    <>
                                        {' | '}
                                        <RecordTitle log={log} />
                                    </>
                                )}
                            </Typography>
                            <Box mt={1}>
                                <DataField record={log} />
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>
    );
};

const RecordTitle = ({ log }: { log: LogEntry }) => {
    const id = `/api/${log.resource}/${log.objectId}`;
    const { referenceRecord, loaded } = useReference({ reference: log.resource, id });
    const link = useReferenceFieldLink(log.resource);
    const getResourceLabel = useGetResourceLabel();

    if (!loaded) return <span>...</span>;
    if (!referenceRecord) return <span>[Not found]</span>;

    const record = referenceRecord as Errand | Reclamation;
    const title =
        get(record, 'name') ||
        get(record, 'label') ||
        `${getResourceLabel(log.resource, 1)} ${record.composedIdentifier ?? ''}`;

    if (!link) return <span>{title}</span>;
    return <TimelineLink to={linkToRecord(`/${log.resource}`, record.id, link)}>{title}</TimelineLink>;
};

const Logs = ({ hideRecordTitle, ...props }: Props) => {
    return (
        <ReferenceManyField
            reference="log_entries"
            target="object"
            perPage={10}
            sort={{ field: 'loggedAt', order: 'desc' }}
            pagination={<AutoHidePagination />}
            {...props}
        >
            <LogsView hideRecordTitle={hideRecordTitle} />
        </ReferenceManyField>
    );
};

export default Logs;
