import { FC } from 'react';
import { CircularProgress, FormControl, InputAdornment, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'react-final-form';
import { useRecordContext } from 'react-admin';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useFetchGet from '@js/hooks/useFetchGet';
import { Errand } from '@js/interfaces/errand';

type Props = {
    alwaysOn?: boolean;
    source?: string;
    record?: Errand;
};

type Response = {
    items: string[];
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: theme.spacing(16),
    },
    selectAdornment: {
        marginRight: theme.spacing(3),
    },
}));

const PalletFilterInput: FC<Props> = ({ source, ...props }) => {
    const classes = useStyles();
    const errand = useRecordContext(props);

    if (!errand) return null;

    return (
        <FormControl className={classes.formControl}>
            <PalletsSelect source={source!} errand={errand} selectAdornmentClassName={classes.selectAdornment} />
        </FormControl>
    );
};

PalletFilterInput.defaultProps = {
    source: 'pallet',
};

const PalletsSelect = ({
    source,
    errand,
    selectAdornmentClassName,
}: {
    source: string;
    errand: Errand;
    selectAdornmentClassName: string;
}) => {
    const translateField = useTranslateResourceField();
    const { input } = useField(source);
    const { data, loading } = useFetchGet<Response>(`${errand.id}/pallets`);
    const items = data?.items || [];

    return (
        <Select
            displayEmpty
            disabled={loading || items.length === 0}
            endAdornment={
                loading && (
                    <InputAdornment className={selectAdornmentClassName} position="end">
                        <CircularProgress size={20} />
                    </InputAdornment>
                )
            }
            {...input}
        >
            <MenuItem value="">
                <em>{translateField('pallet')}</em>
            </MenuItem>
            {items.map((item) => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))}
        </Select>
    );
};
export default PalletFilterInput;
