import DraftsIcon from '@material-ui/icons/Drafts';

import ErrandDraftList from './ErrandDraftList';

export default {
    list: ErrandDraftList,
    show: null,
    create: null,
    edit: null,
    icon: DraftsIcon,
};
