import { FC } from 'react';
import { makeStyles, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useTranslate, Button as RaButton } from 'react-admin';
import { get } from 'lodash';
import DownloadIcon from '@material-ui/icons/GetApp';

import { VichUploadable } from '@js/interfaces/vichUploadable';
import { Alert } from '@material-ui/lab';

type Props = {
    open: boolean;
    onClose: () => void;
    file: VichUploadable;
    path: string;
    title?: string;
};

const useStyles = makeStyles({
    contentElem: {
        maxWidth: '100%',
        display: 'block',
    },
});

const MediaFileViewDialog: FC<Props> = ({ open, onClose, file, path, title }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const src = get(file, path);
    const titleValue = title ? get(file, title) : src;

    const renderContent = () => {
        if (file.mimeType?.startsWith('image')) {
            return <img src={src} alt={titleValue} className={classes.contentElem} />;
        } else if (file.mimeType?.startsWith('video')) {
            return <video title={titleValue} src={src} controls className={classes.contentElem} />;
        }

        return <Alert severity="warning">Unknown type</Alert>;
    };

    return (
        <Dialog maxWidth="lg" open={open} onClose={onClose}>
            <DialogContent>{renderContent()}</DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    {translate('ra.action.close')}
                </Button>
                <RaButton
                    label="app.action.download"
                    component="a"
                    href={src}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    download
                >
                    <DownloadIcon />
                </RaButton>
            </DialogActions>
        </Dialog>
    );
};

export default MediaFileViewDialog;
