import { FC } from 'react';
import {
    LinearProgress,
    ReferenceArrayInput,
    ReferenceArrayInputProps,
    SelectArrayInput,
    SelectArrayInputProps,
    useGetOne,
    useRecordContext,
} from 'react-admin';
import { Box } from '@material-ui/core';

import SettingsButton from './SettingsButton';

import { resellerListFilter } from '@components/resource/resellers/ResellerList';
import { useAppConfigContext } from '@js/context/AppConfigContext';

import { Iri } from '@js/interfaces/ApiRecord';
import { Reseller } from '@js/interfaces/reseller';
import { Reclamation } from '@js/interfaces/reclamation';
import { Errand } from '@js/interfaces/errand';

type Props = Omit<ReferenceArrayInputProps, 'reference' | 'children'> & {
    source: string;
    disableOverride?: boolean;
};

const ResellerSelect: FC<SelectArrayInputProps & { disableOverride?: boolean }> = ({ disableOverride, ...props }) => {
    const {
        input: { value },
        choices,
        loaded,
    } = props;

    return (
        <Box display="flex" alignItems="flex-start">
            <SelectArrayInput optionText="name" {...props} />
            {!disableOverride && (
                <SettingsButton selectedIds={value} choices={(choices ?? []) as Array<Reseller>} loaded={loaded} />
            )}
        </Box>
    );
};

const initialValue: Iri[] = [];

const ResellersInput: FC<Props> = ({ disableOverride, ...props }) => {
    // Fetch Errand record in order to know selected Currency
    const reclamation = useRecordContext<Reclamation>();
    const { loaded, data: errand } = useGetOne<Errand>('errands', reclamation?.errand, {
        enabled: !!reclamation?.errand,
    });
    const { defaultCurrency } = useAppConfigContext();

    if (!loaded) {
        return <LinearProgress />;
    }
    const currency = errand?.currency ?? defaultCurrency;

    return (
        <ReferenceArrayInput
            reference="resellers"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={50}
            filter={{
                ...resellerListFilter,
                currency,
            }}
            initialValue={initialValue}
            {...props}
        >
            <ResellerSelect disableOverride={disableOverride} />
        </ReferenceArrayInput>
    );
};

export default ResellersInput;
