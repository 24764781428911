import { FC } from 'react';
import { ListProps, SelectInput, useGetResourceLabel } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import { ScrollableSelectInputGuesser } from '@components/form/InputGuesser';

import EventField from './EventField';
import { eventChoices } from './EventInput';

const filters = (getResourceLabel: (resource: string) => string) => [
    // eslint-disable-next-line react/jsx-key
    <SelectInput source="event" choices={eventChoices} allowEmpty alwaysOn />,
    // eslint-disable-next-line react/jsx-key
    <ScrollableSelectInputGuesser
        label={getResourceLabel('user_groups')}
        reference="user_groups"
        source="initiator"
        alwaysOn
    />,
    // eslint-disable-next-line react/jsx-key
    <ScrollableSelectInputGuesser
        label={getResourceLabel('users')}
        reference="users"
        source="userRecipients"
        sort={{ field: 'firstName', order: 'ASC' }}
        multiple
    />,
    // eslint-disable-next-line react/jsx-key
    <ScrollableSelectInputGuesser
        label={getResourceLabel('user_groups')}
        reference="user_groups"
        source="userGroupRecipients"
        multiple
    />,
];

const NotificationEventList: FC<ListProps> = (props) => {
    const getResourceLabel = useGetResourceLabel();

    return (
        <ListGuesser {...props} filters={filters(getResourceLabel)}>
            <DatagridGuesser>
                <EventField source="event" />
            </DatagridGuesser>
        </ListGuesser>
    );
};

export default NotificationEventList;
