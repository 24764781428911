import { FC } from 'react';
import { BulkActionProps, BulkDeleteButton, useResourceContext } from 'react-admin';

import ErrandsConfigurableExportButton from '@components/button/ErrandsConfigurableExportButton';
import BulkUpdateStatusButton from '../BulkUpdateStatusButton';

import { useUserPermissions } from '@js/context/UserPermissionsContext';

const BulkActionButtons: FC<BulkActionProps> = (props) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);

    return (
        <>
            {permissions.isListActionEnabled(resource, 'export') && <ErrandsConfigurableExportButton {...props} />}
            {permissions.isFieldEditable(resource, 'lastStatus') && (
                <BulkUpdateStatusButton source="lastStatus" {...props} />
            )}
            {permissions.isEditActionEnabled(resource, 'delete') && <BulkDeleteButton {...props} undoable={false} />}
        </>
    );
};

export default BulkActionButtons;
