import { Identifier } from 'react-admin';
import { useEffect } from 'react';
import { doFetch, post } from '@js/request/apiRequest';

/**
 * Lock and unlock record by ID
 */
const useLockRecord = (id: Identifier | undefined) => {
    useEffect(() => {
        if (!id) return;

        const url = '/api/lock_records';
        const body = JSON.stringify({ iri: id });

        post(url, {
            body,
        });

        return () => {
            doFetch(url, {
                method: 'DELETE',
                body,
            });
        };
    }, [id]);
};

export default useLockRecord;
