/* eslint-disable react/jsx-key */
import { useMemo } from 'react';
import {
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    List,
    ListProps,
    Record as RaRecord,
    SearchInput,
    TextField,
    useGetResourceLabel,
} from 'react-admin';
import { Box } from '@material-ui/core';
import ReferenceFieldGuesser from '@components/field/ReferenceFieldGuesser';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

const ReportList = (props: ListProps) => {
    const getResourceLabel = useGetResourceLabel();
    const filters = useMemo(() => getFilters(getResourceLabel), [getResourceLabel]);

    return (
        <List {...props} exporter={false} filters={filters} sort={{ field: 'createdAt', order: 'desc' }}>
            <Datagrid>
                <DateField source="createdAt" showTime />
                <TextField source="originalName" sortable={false} />
                <ReferenceFieldGuesser source="stores" />
                <RowActions />
            </Datagrid>
        </List>
    );
};

const RowActions = (props: { record?: RaRecord; resource?: string }) => {
    return (
        <Box display="flex" justifyContent="end">
            <DeleteWithConfirmButton {...props} />
        </Box>
    );
};

const getFilters = (getResourceLabel: (resource: string) => string) => {
    return [
        <SearchInput source="q" alwaysOn />,
        <HierarchicalAutocompleteSelectInput
            reference="stores"
            source="stores"
            multiple
            label={getResourceLabel('stores')}
        />,
    ];
};

export default ReportList;
