import { BulkDeleteButton, Datagrid, DatagridProps, useResourceContext } from 'react-admin';
import { FC, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import BulkActionsToolbar from '@components/list/BulkActionsToolbar';

import ReclamationFieldGuesser from './ReclamationFieldGuesser';
import BulkUpdateStatusButton from '../BulkUpdateStatusButton';
import Toolbar from './Toolbar';

import { useListGuesserControllerContext } from '@components/list/ListGuesser';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { isFieldSortable } from '@js/utility/fieldsUtil';

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: 0,
        transition: theme.transitions.create('margin-top'),
        position: 'relative',
        flex: '1 1 auto',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
        },
        overflow: 'inherit',
    },
    bulkActionsDisplayed: {
        marginTop: -theme.spacing(8),
        transition: theme.transitions.create('margin-top'),
    },
}));

const DefaultDatagrid: FC<DatagridProps & { rowActions?: ReactElement }> = ({ rowActions, ...props }) => {
    const { selectedIds } = props;
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);
    const classes = useStyles();
    const { selectedFields, schema } = useListGuesserControllerContext();

    return (
        <>
            <Toolbar />
            <div
                className={classnames(classes.content, {
                    [classes.bulkActionsDisplayed]: selectedIds?.length ?? 0 > 0,
                })}
            >
                <BulkActionsToolbar>
                    {permissions.isFieldEditable(resource, 'status') && (
                        <BulkUpdateStatusButton source="status" resource={resource} />
                    )}
                    {permissions.isEditActionEnabled(resource, 'delete') && (
                        <BulkDeleteButton undoable={false} selectedIds={selectedIds} />
                    )}
                </BulkActionsToolbar>
                <Datagrid {...props} hasBulkActions>
                    {selectedFields.map((field) => (
                        <ReclamationFieldGuesser
                            key={field.name}
                            source={field.name}
                            field={field}
                            sortable={isFieldSortable(field, schema)}
                        />
                    ))}
                    {rowActions}
                </Datagrid>
            </div>
        </>
    );
};

export default DefaultDatagrid;
