import { useEffect } from 'react';
import { Error as RaError, TitleComponent, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

type Props = {
    error: Error;
    componentStack: string;
    resetError(): void;
    title: TitleComponent | undefined;
};

const Error = ({ error, componentStack, title, resetError }: Props) => {
    const history = useHistory();
    const translate = useTranslate();

    useEffect(() => {
        return history.listen(() => {
            resetError();
        });
    }, [history, resetError]);

    return (
        <>
            <RaError error={error} errorInfo={{ componentStack }} title={title as string} />
            <Box justifyContent="center" display="flex" mt={2}>
                <Button variant="contained" startIcon={<RotateLeftIcon />} onClick={resetError}>
                    {translate('app.action.reset')}
                </Button>
            </Box>
        </>
    );
};

export default Error;
