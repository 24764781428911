import { FC, PropsWithChildren } from 'react';
import { useTranslate } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

interface Props {
    onClose: () => void;
    open: boolean;
    title: string;
}

const DialogInfo: FC<PropsWithChildren<Props>> = ({ title, open, onClose, children }) => {
    const fullScreen = useIsSmallScreen();
    const translate = useTranslate();

    return (
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth="lg">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogInfo;
