import { FC } from 'react';
import {
    ArrayField,
    ArrayFieldProps,
    BooleanField,
    BooleanFieldProps,
    ChipField,
    DateField,
    DateFieldProps,
    EmailField,
    EmailFieldProps,
    NumberField,
    NumberFieldProps,
    ReferenceArrayField as RaReferenceArrayField,
    ReferenceArrayFieldProps,
    ReferenceFieldProps,
    SingleFieldList,
    TextField,
    TextFieldProps,
    UrlField,
    UrlFieldProps,
} from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import PriceField, { PriceFieldProps } from '@components/field/PriceField';
import ReferenceField from '@components/field/ReferenceField';

import useReferenceFieldLink from '@js/hooks/useReferenceFieldLink';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import { getFieldType } from '@js/utility/fieldsUtil';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

type FieldProps =
    | TextFieldProps
    | DateFieldProps
    | BooleanFieldProps
    | NumberFieldProps
    | UrlFieldProps
    | EmailFieldProps
    | ArrayFieldProps
    | ReferenceArrayFieldProps
    | ReferenceFieldProps;

interface IntrospectedProps {
    field: Field;
}

type Props = IntrospectedProps & FieldProps;

export type LinkType = 'edit' | 'show' | false;

export const ReferenceArrayField: FC<
    Omit<ReferenceArrayFieldProps, 'children'> & { fieldName: string; linkType?: LinkType }
> = ({ fieldName, linkType, ...props }) => {
    const link = useReferenceFieldLink(props.reference);

    return (
        <RaReferenceArrayField {...props}>
            <SingleFieldList linkType={linkType ?? link}>
                <ChipField source={fieldName} />
            </SingleFieldList>
        </RaReferenceArrayField>
    );
};

const FieldGuesser: FC<Props> = ({ field, ...props }) => {
    const { schemaAnalyzer } = useHydraSchemaContext();

    if (field.reference !== null && typeof field.reference === 'object') {
        return <ReferenceFieldGuesser {...(props as ReferenceFieldProps | ReferenceArrayFieldProps)} field={field} />;
    }
    if (field.embedded && field.maxCardinality !== 1) {
        return <EmbeddedFieldGuesser {...(props as ArrayFieldProps)} field={field} />;
    }

    const fieldType = getFieldType(field, schemaAnalyzer);

    switch (fieldType) {
        case 'email':
            return <EmailField {...(props as EmailFieldProps)} />;

        case 'url':
            return <UrlField {...(props as UrlFieldProps)} />;

        case 'integer':
        case 'integer_id':
        case 'float':
            return <NumberField {...(props as NumberFieldProps)} />;

        case 'boolean':
            return <BooleanField {...(props as BooleanFieldProps)} />;

        case 'date':
        case 'dateTime':
            return <DateField {...(props as DateFieldProps)} />;

        case 'price':
            return <PriceField {...(props as PriceFieldProps)} />;

        default:
            return <TextField {...(props as TextFieldProps)} />;
    }
};

const ReferenceFieldGuesser = ({
    field,
    ...props
}: Omit<ReferenceFieldProps | ReferenceArrayFieldProps, 'children'> & {
    field: Field;
}) => {
    if (field.reference === null || typeof field.reference !== 'object') {
        throw new Error('Invalid reference field');
    }
    const fieldName = useResourceFieldName(field.reference.name);

    if (field.maxCardinality === 1) {
        return (
            <ReferenceField {...(props as ReferenceFieldProps)} reference={field.reference.name}>
                <ChipField source={fieldName} />
            </ReferenceField>
        );
    }

    return (
        <ReferenceArrayField
            {...(props as ReferenceArrayFieldProps)}
            fieldName={fieldName}
            reference={field.reference.name}
        />
    );
};

const EmbeddedFieldGuesser = ({ field, ...props }: Omit<ArrayFieldProps, 'children'> & { field: Field }) => {
    if (field.embedded === null || typeof field.embedded !== 'object') {
        throw new Error('Invalid embedded field');
    }
    const fieldName = useResourceFieldName(field.embedded.name);

    return (
        <ArrayField {...(props as ArrayFieldProps)}>
            <SingleFieldList linkType={false}>
                <ChipField source={fieldName === 'id' ? '@id' : fieldName} />
            </SingleFieldList>
        </ArrayField>
    );
};

FieldGuesser.defaultProps = {
    addLabel: true,
};

export default FieldGuesser;
