import { isPlainObject } from 'lodash';
import { PaginationPayload, SortPayload } from 'react-admin';

/**
 * React admin filters should be normalized before sending as request to API platform.
 * This helper convert filter generated by React Admin to filters object that can be used as URL params in request to API platform.
 *
 * Copied and a bit modified function from api platform.
 * @see @api-platform/admin/src/hydra
 */

type FilterScalarType = string | boolean | number;

const isScalarValue = (value: FilterScalarType | Record<string, FilterScalarType>): value is FilterScalarType =>
    !isPlainObject(value);

interface Params {
    pagination?: PaginationPayload;
    sort: SortPayload;
    filter: Record<string, any>;
}

export const createListURL = (url: URL, params: Params): URL => {
    const {
        pagination,
        sort: { field, order },
        filter,
    } = params;

    if (order && field) {
        url.searchParams.set(`order[${field}]`, order);
    }
    if (pagination?.page) url.searchParams.set('page', pagination.page.toString());
    if (pagination?.perPage) url.searchParams.set('itemsPerPage', pagination.perPage.toString());

    if (filter) {
        const buildFilterParams = (
            key: string,
            nestedFilter: Record<string, FilterScalarType | Record<string, FilterScalarType>>,
            rootKey: string,
        ) => {
            const filterValue = nestedFilter[key];
            if (filterValue === undefined) {
                return;
            }

            if (Array.isArray(filterValue)) {
                filterValue.forEach((arrayFilterValue, index) => {
                    url.searchParams.set(`${rootKey}[${index}]`, arrayFilterValue);
                });
                return;
            }

            if (isScalarValue(filterValue)) {
                url.searchParams.set(rootKey, filterValue.toString());
                return;
            }

            Object.keys(filterValue).forEach((subKey) => {
                if (
                    rootKey === 'exists' ||
                    [
                        'after',
                        'before',
                        'strictly_after',
                        'strictly_before',
                        'lt',
                        'gt',
                        'lte',
                        'gte',
                        'between',
                    ].includes(subKey)
                ) {
                    buildFilterParams(subKey, filterValue, `${rootKey}[${subKey}]`);
                    return;
                }
                buildFilterParams(subKey, filterValue, `${rootKey}.${subKey}`);
            });
        };

        Object.keys(filter).forEach((key) => {
            buildFilterParams(key, filter, key);
        });
    }

    return url;
};
