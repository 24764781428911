import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import {
    required,
    ResourceContextProvider,
    TextInput,
    useGetResourceLabel,
    useTranslate,
    Validator,
} from 'react-admin';

import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import { StatusNotification, Type } from '@js/interfaces/statusNotification';

interface Props {
    onSubmit: (data: StatusNotification) => void;
}

const resource = 'status_notifications';

const validateFutureDate: Validator = (value) => {
    if (new Date() > new Date(value)) {
        return 'app.validation.date_must_be_in_future';
    }

    return undefined;
};

const StatusNotificationForm: FC<Props> = ({ onSubmit }) => {
    const [key, setKey] = useState(0);
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    const handleSubmit = ({ notifyAt, ...data }: StatusNotification) => {
        onSubmit({
            ...data,
            notifyAt: notifyAt && new Date(notifyAt).toISOString(),
        });
        // Fully reset form
        setKey((key) => key + 1);
    };

    return (
        <ResourceContextProvider value={resource}>
            <Form<StatusNotification>
                key={key}
                onSubmit={handleSubmit}
                initialValues={{
                    type: Type.QUARANTINE,
                    users: [],
                    userGroups: [],
                    message: translate(`resources.status_notifications.message.${Type.QUARANTINE}`),
                }}
                validate={(values) => {
                    if (!values.users?.length && !values.userGroups?.length) {
                        const error = translate('app.label.choose_at_least_one', { option: '' });

                        return {
                            users: error,
                            userGroups: error,
                        };
                    }

                    return {};
                }}
                render={({ handleSubmit, pristine, values }) => (
                    <Box display="flex" flexDirection="column">
                        <TextInput
                            source="notifyAt"
                            type="datetime-local"
                            validate={[required(), validateFutureDate]}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            resource={resource}
                        />
                        <TextInput
                            source="message"
                            validate={required()}
                            resource={resource}
                            defaultValue={translate(`resources.status_notifications.message.${values.type}`)}
                        />
                        <Box display="flex" gridGap={10}>
                            <AutoCompleteGuesserInput
                                label={getResourceLabel('users')}
                                reference="users"
                                source="users"
                                optionText="fullName"
                                multiple
                                fullWidth
                            />
                            <AutoCompleteGuesserInput
                                label={getResourceLabel('user_groups')}
                                reference="user_groups"
                                source="userGroups"
                                multiple
                                fullWidth
                            />
                        </Box>

                        <Box mb={2}>
                            <Button onClick={handleSubmit} color="primary" variant="contained" disabled={pristine}>
                                {translate('ra.action.add')}
                            </Button>
                        </Box>
                    </Box>
                )}
            />
        </ResourceContextProvider>
    );
};

export default StatusNotificationForm;
