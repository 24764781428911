/**
 * Copy from material-ui docs
 * https://github.com/mui/material-ui/blob/v5.5.3/docs/data/material/customization/color/ColorTool.js
 */

import { ChangeEvent, useState } from 'react';
import { Box, Color, colors, Grid, Input, Slider, Tooltip, Typography } from '@material-ui/core';
import { Button } from 'react-admin';
import { rgbToHex, useTheme } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { isEqual } from 'lodash';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import ColorDemo from './ColorDemo';
import TooltipRadio from './TooltipRadio';

import useDidMountEffect from '@js/hooks/useDidMountEffect';
import { CustomTheme } from '@js/context/CustomThemeContext';

interface Props {
    onChange: (theme: CustomTheme | null) => void;
    theme: CustomTheme;
}

export interface StateType {
    primary: string;
    secondary: string;
    primaryInput: string;
    secondaryInput: string;
    primaryHue: string;
    secondaryHue: string;
    primaryShade: number;
    secondaryShade: number;
}

type PaletteColorType = 'light' | 'main' | 'dark';

type Palette = keyof CustomTheme['palette'];
type Hue = keyof typeof colors;
type Shade = keyof Color;

const hues: Array<Hue> = [
    'red',
    'pink',
    'purple',
    'deepPurple',
    'indigo',
    'blue',
    'lightBlue',
    'cyan',
    'teal',
    'green',
    'lightGreen',
    'lime',
    'yellow',
    'amber',
    'orange',
    'deepOrange',
];

const shades: Array<Shade> = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 'A700', 'A400', 'A200', 'A100'];
const defaultTheme: CustomTheme = {
    palette: {
        primary: {
            main: '#38a9b4',
        },
        secondary: {
            main: '#288690',
        },
    },
};

const getState = ({ palette }: CustomTheme): StateType => ({
    primary: palette.primary.main,
    secondary: palette.secondary.main,
    primaryInput: palette.primary.main,
    secondaryInput: palette.secondary.main,
    primaryHue: 'blue',
    secondaryHue: 'pink',
    primaryShade: 4,
    secondaryShade: 11,
});

function ColorTool({ onChange, theme: initialTheme }: Props) {
    const theme = useTheme();
    const [state, setState] = useState<StateType>(() => getState(initialTheme ?? defaultTheme));

    useDidMountEffect(() => {
        const theme = {
            palette: {
                primary: {
                    main: state.primary,
                },
                secondary: {
                    main: state.secondary,
                },
            },
        };

        onChange(isEqual(defaultTheme, theme) ? null : theme);
    }, [state.primary, state.secondary]);

    const handleResetClick = () => {
        setState(getState(defaultTheme));
    };

    const handleChangeColor = (name: Palette) => (event: ChangeEvent<HTMLInputElement>) => {
        const isRgb = (string: string) => /rgb\([0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\)/i.test(string);
        const isHex = (string: string) => /^#?([0-9a-f]{3})$|^#?([0-9a-f]){6}$/i.test(string);

        let {
            target: { value: color },
        } = event;

        setState((prevState) => ({
            ...prevState,
            [`${name}Input`]: color,
        }));

        let isValidColor = false;

        if (isRgb(color)) {
            isValidColor = true;
        } else if (isHex(color)) {
            isValidColor = true;
            if (color.indexOf('#') === -1) {
                color = `#${color}`;
            }
        }

        if (isValidColor) {
            setState((prevState) => ({
                ...prevState,
                [name]: color,
            }));
        }
    };

    const handleChangeHue = (name: Palette) => (event: ChangeEvent<HTMLInputElement>) => {
        const hue = event.target.value as Hue;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line import/namespace
        const color = colors[hue][shades[state[`${name}Shade`]] as keyof Color];

        setState({
            ...state,
            [`${name}Hue`]: hue,
            [name]: color,
            [`${name}Input`]: color,
        });
    };

    const handleChangeShade = (name: Palette, shade: number) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line import/namespace
        const color = colors[state[`${name}Hue`] as Hue][shades[shade]];

        setState({
            ...state,
            [`${name}Shade`]: shade,
            [name]: color,
            [`${name}Input`]: color,
        });
    };

    const colorBar = (color: string) => {
        const background = theme.palette.augmentColor({ main: color });
        const paletteColor: PaletteColorType[] = ['dark', 'main', 'light'];

        return (
            <Grid container>
                <Box display="flex" mt={2}>
                    {paletteColor.map((key) => (
                        <Box
                            width={64}
                            height={64}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{ backgroundColor: background[key] }}
                            key={key}
                        >
                            <Typography
                                variant="caption"
                                style={{
                                    color: theme.palette.getContrastText(background[key]),
                                }}
                            >
                                {rgbToHex(background[key])}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Grid>
        );
    };

    const colorPicker = (intent: Palette) => {
        const intentInput = state[`${intent}Input`];
        const intentShade = state[`${intent}Shade`];
        const color = state[`${intent}`];

        return (
            <Grid item xs={12} sm={6} md={4}>
                <Typography component="label" gutterBottom htmlFor={intent} variant="h6">
                    {capitalize(intent)}
                </Typography>
                <Input id={intent} value={intentInput} onChange={handleChangeColor(intent)} fullWidth />
                <Box display="flex" alignItems="center" mt={2} mb={2}>
                    <Typography id={`${intent}ShadeSliderLabel`}>Shade:</Typography>
                    <Slider
                        value={intentShade}
                        min={0}
                        max={13}
                        step={1}
                        onChange={(event, value) => handleChangeShade(intent, value as number)}
                    />
                    <Typography>{shades[intentShade]}</Typography>
                </Box>
                <Box width={192}>
                    {hues.map((hue) => {
                        const shade = intent === 'primary' ? shades[state.primaryShade] : shades[state.secondaryShade];
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line import/namespace
                        const backgroundColor = colors[hue][shade];

                        return (
                            <Tooltip placement="right" title={hue} key={hue}>
                                <TooltipRadio
                                    color="default"
                                    checked={state[intent] === backgroundColor}
                                    onChange={handleChangeHue(intent)}
                                    value={hue}
                                    name={intent}
                                    icon={<Box width={48} height={48} style={{ backgroundColor }} />}
                                    checkedIcon={
                                        <Box
                                            width={48}
                                            height={48}
                                            border={1}
                                            borderColor="white"
                                            color="common.white"
                                            boxSizing="border-box"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ backgroundColor }}
                                        >
                                            <CheckIcon style={{ fontSize: 30 }} />
                                        </Box>
                                    }
                                />
                            </Tooltip>
                        );
                    })}
                </Box>
                {colorBar(color)}
            </Grid>
        );
    };

    return (
        <Grid container spacing={5}>
            {colorPicker('primary')}
            {colorPicker('secondary')}
            <Grid item xs={12} sm={6} md={4}>
                <ColorDemo data={state} />
            </Grid>
            <Grid item xs={12}>
                <Button
                    label="app.action.reset"
                    variant="contained"
                    onClick={handleResetClick}
                    disabled={!initialTheme}
                >
                    <ClearIcon />
                </Button>
            </Grid>
        </Grid>
    );
}

export default ColorTool;
