import { FC, useState } from 'react';
import { AutocompleteInput, ReferenceInput, ReferenceInputProps } from 'react-admin';
import { Box } from '@material-ui/core';

import QuickCreateReferenceButton from '@components/button/QuickCreateReferenceButton';
import InputGuesser from '@components/form/InputGuesser';

import { handleAutocompleteBlur } from '@js/utility/inputsUtility';
import useFormFields from '@js/hooks/useFormFields';

const CreateButton: FC<{ onCreate: () => void }> = ({ onCreate }) => {
    const fields = useFormFields({ resource: 'customers' }, ['serialNumber']);

    return (
        <Box mb="20px">
            <QuickCreateReferenceButton resource="customers" field="customer" onChange={onCreate}>
                {fields.map((field) => (
                    <div key={field.name}>
                        <InputGuesser source={field.name} field={field} fullWidth />
                    </div>
                ))}
            </QuickCreateReferenceButton>
        </Box>
    );
};

const CustomerInput = ({
    helperText,
    ...props
}: Omit<ReferenceInputProps, 'reference' | 'children'> & { source: string; helperText?: string | false }) => {
    const [version, setVersion] = useState(0);

    return (
        <Box display="flex" alignItems="center">
            <ReferenceInput
                key={version}
                reference="customers"
                sort={{
                    field: 'firstName',
                    order: 'ASC',
                }}
                onBlur={handleAutocompleteBlur}
                {...props}
            >
                <AutocompleteInput optionText="optionText" helperText={helperText} />
            </ReferenceInput>
            <CreateButton onCreate={() => setVersion((v) => v + 1)} />
        </Box>
    );
};

export default CustomerInput;
