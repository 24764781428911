import { Children, isValidElement, ReactElement, ReactNode } from 'react';
import { Field, Resource } from '@api-platform/api-doc-parser';
import { SchemaAnalyzer } from '@api-platform/admin';

export const putPriorityFieldsFirst = (fields: Field[], priorityFields: string[] = []): Field[] => {
    return [
        ...priorityFields
            .map((fieldName) => fields.find((field) => field.name === fieldName))
            .filter((field): field is Field => !!field),
        ...fields.filter((field) => !priorityFields.includes(field.name)),
    ];
};

// see config/services.yaml:parameters.corerely.api_platform_helper.order_by_fields
const defaultSortableFields = ['createdAt', 'updatedAt', 'id'];

export const isFieldSortable = (field: Field, schema: Resource) => {
    // Originally api-platform/admin is doing another request to the collection api of resource get the schema.parameters.
    // We extend the schema with the sortableFields from the extendDocs in "js/hydra/dataProvider.tx".
    // This is a workaround to avoid the extra request.
    return [...defaultSortableFields, ...schema.sortableFields].includes(field.name);
};

export const getOverrideFields = (children: ReactNode | undefined) => {
    return Children.toArray(children).reduce<
        Record<
            string,
            ReactElement<{
                source: string;
            }>
        >
    >((fields, field) => {
        if (
            isValidElement<{
                source: string;
            }>(field)
        ) {
            if (!field.props.source) {
                throw new Error(
                    'Each child should be a valid element with "source" prop set in order to override guesser field.',
                );
            }

            fields[field.props.source] = field;
        }

        return fields;
    }, {});
};

export const getFieldType = (
    field: Field,
    schemaAnalyzer: SchemaAnalyzer,
): ReturnType<SchemaAnalyzer['getFieldType']> | 'multiline-text' | 'price' => {
    switch (field.range) {
        case 'http://www.w3.org/2001/XMLSchema#text':
            return 'multiline-text';
        case 'http://www.w3.org/2001/XMLSchema#price':
            return 'price';
    }

    return schemaAnalyzer.getFieldType(field);
};
