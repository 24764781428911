import { Validator } from 'react-admin';

export const minutes = (): Validator => (value) => {
    if (!value || value === 0) return undefined;

    const valueInt = parseInt(value);

    if (
        isNaN(valueInt) ||
        valueInt < 0 ||
        (typeof value === 'string' && value !== valueInt.toString()) ||
        (typeof value === 'number' && value !== valueInt)
    ) {
        return { message: 'ra.validation.regex', args: { pattern: '45' } };
    }
};
