import { Validator } from 'react-admin';

const strNotContains =
    (disallowed: string[]): Validator =>
    (value) => {
        if (typeof value !== 'string' || !value) {
            return;
        }

        // Loop through the disallowed values and check if the value contains any of them
        for (const disallowedValue of disallowed) {
            if (value.toLowerCase().includes(disallowedValue.toLowerCase())) {
                return { message: 'app.validation.strNotContains', args: { value: disallowedValue } };
            }
        }
    };

export default strNotContains;
