import { FC, PropsWithChildren } from 'react';
import { useTranslate } from 'react-admin';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { makeStyles } from '@material-ui/core/styles';

import { secondsToHumanTime } from '@js/utility/numberFormatUtility';

interface Props {
    totalSeconds: number;
    onClick: () => void;
}

const useStyles = makeStyles({
    icon: {
        height: 20,
        width: 20,
    },
    button: {
        height: 24,
        width: 24,
        padding: 5,
        marginLeft: 5,
    },
});

const ProcessingTimeField: FC<PropsWithChildren<Props>> = ({ totalSeconds, onClick }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Typography component="span" variant="body2">
            {`${secondsToHumanTime(totalSeconds)} h`}
            <Tooltip title={translate('ra.action.show')}>
                <IconButton className={classes.button} disableRipple onClick={onClick}>
                    <HistoryIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
        </Typography>
    );
};

export default ProcessingTimeField;
