import { FC } from 'react';
import { SelectField, SelectFieldProps } from 'react-admin';

import { AddressType } from '@js/interfaces/address';

const AddressTypeField: FC<Omit<SelectFieldProps, 'choices'>> = (props) => {
    return (
        <SelectField
            {...props}
            choices={Object.values(AddressType).map((type) => ({
                id: type,
                name: `resources.addresses.types.${type}`,
            }))}
        />
    );
};

AddressTypeField.defaultProps = {
    source: 'type',
};

export default AddressTypeField;
