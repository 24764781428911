import { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
    CardContentInner,
    Identifier,
    NumberInput,
    required,
    SelectInput,
    useGetResourceLabel,
    useTranslate,
} from 'react-admin';
import { Box, Grid, LinearProgress, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Toolbar from '@components/routes/Toolbar';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { ImportConfig } from './Co2Import';

type FormValues = ImportConfig['configure'];

type Props = {
    onGoBack: () => void;
    state: ImportConfig;
    onSubmit: (values: FormValues) => void;
};

type ConfigureImportPayload = {
    categories: Record<string, Identifier | null>;
};

const useStyles = makeStyles<Theme>((theme) => ({
    fieldsBox: {
        '& > div': {
            marginRight: theme.spacing(1),
        },
    },
}));

const ConfigureImportForm: FC<Props> = ({ onGoBack, state, onSubmit }) => {
    const [categories, setCategories] = useState<ConfigureImportPayload['categories'] | null>(null);
    const notifyError = useNotifyHttpError();
    const classes = useStyles();
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();
    const { upload: { importFile } = {}, mapping: { mapping: columnMapping } = {} } = state;

    useEffect(() => {
        if (!importFile || !columnMapping) return;

        const form = new FormData();
        form.append('file', importFile);
        form.append('mapping', JSON.stringify(columnMapping));

        post<ConfigureImportPayload>('/api/savings-import/configure_import', {
            body: form,
        })
            .then((response) => {
                setCategories(response.categories);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [notifyError, importFile, columnMapping]);

    const handleSubmit = (values: FormValues) => onSubmit(values);

    return (
        <Form onSubmit={handleSubmit}>
            {(formProps) => {
                return (
                    <>
                        <CardContentInner>
                            {categories ? (
                                <>
                                    {Object.keys(categories).length > 0 && (
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                {getResourceLabel('categories')}
                                            </Typography>
                                            <Grid container spacing={1}>
                                                {Object.keys(categories).map((category) => (
                                                    <Grid key={category} item xs={12} sm={3}>
                                                        <ReferenceRecursiveSelectInput
                                                            key={category}
                                                            label={category}
                                                            source={`categories[${category}]`}
                                                            reference="categories"
                                                            initialValue={categories[category]}
                                                            validate={required()}
                                                            filter={{ entrypoint: true }}
                                                            searchInFieldName
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </>
                                    )}
                                    <Typography variant="h6" gutterBottom>
                                        {translate('app.label.conversation')}
                                    </Typography>
                                    <Box display="flex" className={classes.fieldsBox}>
                                        <SelectInput
                                            source="weightMeasure"
                                            label={translate('app.input.weightMeasure')}
                                            initialValue="kg"
                                            choices={[
                                                { id: 'kg', name: 'app.label.kg' },
                                                { id: 'g', name: 'app.label.g' },
                                            ]}
                                            helperText={translate('app.form.helperText.weightMeasure')}
                                        />
                                        <NumberInput
                                            source="exchangeRate"
                                            label={translate('app.input.exchangeRate')}
                                            helperText={translate('app.form.helperText.exchangeRate')}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <LinearProgress />
                            )}
                        </CardContentInner>
                        <Toolbar
                            cancel={{ onClick: onGoBack, label: 'ra.action.back' }}
                            submit={{
                                disabled: !categories || formProps.submitting,
                                onSubmit: formProps.handleSubmit,
                                saving: formProps.submitting,
                                label: 'app.action.import',
                            }}
                        />
                    </>
                );
            }}
        </Form>
    );
};

export default ConfigureImportForm;
