import { FC } from 'react';
import { useField } from 'react-final-form';
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@material-ui/core';
import chunk from 'lodash/chunk';

type Choice = {
    id: string;
    name: string;
};

interface Props {
    source: string;
    label?: string;
    choices: Choice[];
    chunkSize?: number;
}

const CheckboxChunkGroupInput: FC<Props> = ({ source, choices, label, chunkSize = 10 }) => {
    return (
        <FormControl component="fieldset" fullWidth>
            <FormGroup>
                {label && <FormLabel>{label}</FormLabel>}
                <Grid container spacing={2}>
                    {chunk(choices, chunkSize).map((chunk) => {
                        const key = chunk.map((field) => field.id).join('-');

                        return (
                            <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                                {chunk.map((choice) => (
                                    <div key={choice.id}>
                                        <Checkbox choice={choice} source={source} />
                                    </div>
                                ))}
                            </Grid>
                        );
                    })}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};

const Checkbox: FC<{ choice: Choice; source: string }> = ({ source, choice }) => {
    const { input } = useField(source, { value: choice.id, type: 'checkbox' });
    const id = `${source}-${choice.id}`;

    return (
        <FormControlLabel
            htmlFor={id}
            control={<MuiCheckbox id={id} color="primary" {...input} />}
            label={choice.name}
        />
    );
};

export default CheckboxChunkGroupInput;
