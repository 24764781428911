import { FC } from 'react';
import { useField } from 'react-final-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props {
    source: string;
    label?: string;
    resource?: string;
    addLabel?: boolean;
    fullWidth?: boolean;
}

const CheckboxInput: FC<Props> = ({ source, resource, label, fullWidth }) => {
    const { input } = useField(source, { type: 'checkbox' });
    const translateField = useTranslateResourceField();

    return (
        <FormControl margin="dense" fullWidth={fullWidth}>
            <FormGroup row>
                <FormControlLabel control={<Checkbox {...input} />} label={label || translateField(source, resource)} />
            </FormGroup>
        </FormControl>
    );
};

CheckboxInput.defaultProps = {
    addLabel: false,
};

export default CheckboxInput;
