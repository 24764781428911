import { SelectInput, SelectInputProps } from 'react-admin';

import { AddressType } from '@js/interfaces/address';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';

const AddressTypeInput = (props: Omit<SelectInputProps, 'choices'>) => {
    const validate = useInputAutoValidator(props);

    return (
        <SelectInput
            {...props}
            validate={validate}
            choices={Object.values(AddressType).map((type) => ({
                id: type,
                name: `resources.addresses.types.${type}`,
            }))}
        />
    );
};

AddressTypeInput.defaultProps = {
    source: 'type',
};

export default AddressTypeInput;
