import { useEffect, useRef } from 'react';

const useUnmountEffect = (func: () => void) => {
    const unmountRef = useRef(false);

    useEffect(() => {
        return () => {
            unmountRef.current = true;
        };
    }, []);

    useEffect(() => {
        return () => {
            if (unmountRef.current) {
                func();
            }
        };
    }, [func]);
};

export default useUnmountEffect;
