import { ReactNode } from 'react';

const PassthroughProps = <P = NonNullable<unknown>,>({
    children,
    ...props
}: P & { children: (props: P) => ReactNode }) => {
    return <>{children(props as P)}</>;
};

export default PassthroughProps;
