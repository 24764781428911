import { BulkUpdateButton, Identifier, useTranslate } from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface Props {
    selectedIds?: Identifier[];
    resource?: string;
}

const BulkMarkAsReadButton = (props: Props) => {
    const translate = useTranslate();

    return (
        <BulkUpdateButton
            {...props}
            data={{ readAt: new Date().toISOString() }}
            icon={<VisibilityIcon />}
            label={translate('app.action.mark_as_read')}
            mutationMode="pessimistic"
        />
    );
};

export default BulkMarkAsReadButton;
