import ShortTextIcon from '@material-ui/icons/ShortText';

import PredefinedTextForm from './PredefinedTextForm';

export default {
    list: null,
    show: null,
    icon: ShortTextIcon,
    options: {
        form: PredefinedTextForm,
        alwaysHideFields: ['userGroup'],
    },
};
