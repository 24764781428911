import { Box, Card, Typography } from '@material-ui/core';
import { Datagrid, ListBase, NumberField, Pagination, ResourceContextProvider, useTranslate } from 'react-admin';

import TranslatableTextField from '@components/field/TranslatableTextField';
import SecondsTimeField from '@components/field/SecondsTimeField';
import PriceField from '@components/field/PriceField';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Locale } from '@js/locale/i18nProvider';
import { Iri } from '@js/interfaces/ApiRecord';

type Props = {
    filter?: {
        store?: string;
        after?: string;
        before?: string;
    };
};

type _CategoryEconomicalStatisticItem = {
    id: number;
    '@id': Iri;
    averageSalesPrice: number;
    totalProcessingTime: number;
    totalErrandProcessingTime: number;
    totalReclamationProcessingTime: number;
    translatableTitle: Record<Locale, string | null>;
    totalReclamationQuantity: number;
};

const CategoryEconomicalStatisticList = ({ filter }: Props) => {
    const getFieldLabel = useTranslateResourceField();
    const translate = useTranslate();
    const getResourceLabel = useTranslateResourceField();

    const resource = 'category_economical_statistic_items';
    const processingTimeLabel = getFieldLabel('processingTimes', 'reclamations');

    return (
        <>
            <Typography variant="h6">{getFieldLabel('category', 'reclamations')}</Typography>
            <Box mt={2}>
                <ResourceContextProvider value={resource}>
                    <ListBase
                        resource={resource}
                        basePath={`/${resource}`}
                        filter={{ ...filter, 'reclamations.errand.store': filter?.store }}
                        sort={{ field: 'averageSalesPrice', order: 'DESC' }}
                    >
                        <Card>
                            <Datagrid>
                                <TranslatableTextField
                                    source="translatableTitle"
                                    sortable={false}
                                    label={getFieldLabel('title', 'categories')}
                                />
                                <NumberField
                                    label={getResourceLabel('reclamations')}
                                    source="totalReclamationQuantity"
                                />
                                <PriceField
                                    source="averageSalesPrice"
                                    label={translate('app.label.average_sales_price')}
                                    formatCents={false}
                                />
                                <SecondsTimeField
                                    source="totalProcessingTime"
                                    label={processingTimeLabel}
                                    sortable={false}
                                />
                                <SecondsTimeField
                                    source="totalErrandProcessingTime"
                                    label={`${getResourceLabel('errands')} ${processingTimeLabel}`}
                                />
                                <SecondsTimeField
                                    source="totalReclamationProcessingTime"
                                    label={`${getResourceLabel('reclamations')} ${processingTimeLabel}`}
                                />
                            </Datagrid>
                            <Pagination />
                        </Card>
                    </ListBase>
                </ResourceContextProvider>
            </Box>
        </>
    );
};

export default CategoryEconomicalStatisticList;
