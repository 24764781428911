import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Button as RaButton,
    Record as RaRecord,
    RecordContextProvider,
    RecordMap,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useRefresh,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Button,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle,
    Grid,
    makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { ApiPlatformAdminState } from '@api-platform/admin';
import AddIcon from '@material-ui/icons/Add';
import ReturnIcon from '@material-ui/icons/FlipCameraAndroid';
import CloseIcon from '@material-ui/icons/Close';

import LoadingButton from '@components/button/LoadingButton';
import IconButton from '@components/button/IconButton';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';
import InputGuesser from '@components/form/InputGuesser';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useFormFields from '@js/hooks/useFormFields';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

import { cloneErrand, cloneReclamation } from '@js/utility/cloneUtil';
import { post } from '@js/request/apiRequest';

import { Errand } from '@js/interfaces/errand';
import { Reclamation } from '@js/interfaces/reclamation';
import { Iri } from '@js/interfaces/ApiRecord';

type Props = {
    record?: Errand;
    resource?: string;
};

const ReturnedItemButton = (props: Props) => {
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const [open, setOpen] = useState(false);
    const isSmallScreen = useIsSmallScreen();
    const classes = useStyles();

    if (resource !== 'errands') throw new Error('ReturnedItemButton can only be used inside ErrandList');
    if (!record) return null;

    const handleClose = () => setOpen(false);

    return (
        <>
            <IconButton label="app.action.returned_item" onClick={() => setOpen(true)}>
                <ReturnIcon />
            </IconButton>
            <MuiDialog
                open={open}
                onClose={handleClose}
                fullScreen={isSmallScreen}
                maxWidth="md"
                fullWidth
                classes={classes}
            >
                <DialogContent errand={record} onClose={handleClose} />
            </MuiDialog>
        </>
    );
};

type CustomerSelectMode = 'select' | 'create';

const DialogContent = ({ errand, onClose }: { errand: Errand; onClose: () => void }) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const [customerMode, setCustomerMode] = useState<CustomerSelectMode>('select');

    const handleSubmit = useHandleSubmit(errand, onClose);

    return (
        <>
            <DialogTitle>{translate('app.action.returned_item')}</DialogTitle>
            <RecordContextProvider value={{}}>
                <Form onSubmit={handleSubmit} destroyOnUnregister>
                    {(formProps) => {
                        return (
                            <>
                                <MuiDialogContent>
                                    <ReferenceArrayInput
                                        reference="reclamations"
                                        source="reclamations"
                                        label={getResourceLabel('reclamations')}
                                        validate={required()}
                                        filter={{
                                            errand: errand.id,
                                            'exists[child]': false,
                                        }}
                                        fullWidth
                                    >
                                        <AutocompleteArrayInput optionText="label" />
                                    </ReferenceArrayInput>
                                    <HierarchicalAutocompleteSelectInput
                                        reference="stores"
                                        source="store"
                                        validate={required()}
                                        fullWidth
                                    />
                                    <CustomerInput
                                        key={customerMode}
                                        source="customer"
                                        mode={customerMode}
                                        onModeChange={setCustomerMode}
                                    />
                                </MuiDialogContent>
                                <DialogActions>
                                    <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
                                    <LoadingButton
                                        label="ra.action.confirm"
                                        loading={formProps.submitting}
                                        icon={<ReturnIcon />}
                                        onClick={formProps.handleSubmit}
                                    />
                                </DialogActions>
                            </>
                        );
                    }}
                </Form>
            </RecordContextProvider>
        </>
    );
};

const CustomerInput = ({
    source,
    mode,
    onModeChange,
}: {
    source: string;
    mode: CustomerSelectMode;
    onModeChange: (mode: CustomerSelectMode) => void;
}) => {
    if (mode === 'create') {
        return <CustomerFormFields source={source} onCancel={() => onModeChange('select')} />;
    }

    return (
        <Box display="flex" alignItems="center">
            <ReferenceInput
                source={source}
                reference="customers"
                validate={required()}
                sort={{
                    field: 'fullName',
                    order: 'ASC',
                }}
                fullWidth
            >
                <AutocompleteInput optionText="optionText" />
            </ReferenceInput>
            <Box mb="20px">
                <RaButton onClick={() => onModeChange('create')} label="ra.action.create">
                    <AddIcon />
                </RaButton>
            </Box>
        </Box>
    );
};

const CustomerFormFields = ({ source, onCancel }: { source: string; onCancel: () => void }) => {
    const fields = useFormFields({ resource: 'customers' }, ['serialNumber']);
    const getFieldLabel = useTranslateResourceField('customers');
    const [first, ...rest] = fields;

    return (
        <>
            <Box display="flex" alignItems="center">
                <InputGuesser
                    source={`${source}.${first.name}`}
                    label={getFieldLabel(first.name)}
                    field={first}
                    fullWidth
                />
                <Box mb="20px">
                    <RaButton onClick={onCancel} label="ra.action.cancel">
                        <CloseIcon />
                    </RaButton>
                </Box>
            </Box>
            <Grid container spacing={1}>
                {rest.map((field) => {
                    return (
                        <Grid key={field.name} item xs={12} sm={4}>
                            <InputGuesser
                                source={`${source}.${field.name}`}
                                label={getFieldLabel(field.name)}
                                field={field}
                                fullWidth
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

const useHandleSubmit = (errand: Errand, closeDialog: () => void) => {
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const refresh = useRefresh();

    const storeOfReclamations = useSelector<ApiPlatformAdminState, RecordMap<RaRecord>>(
        (data) => data.admin.resources['reclamations'].data,
    );

    return async ({ reclamations, customer, store }: { customer: Iri; store: string; reclamations: Iri[] }) => {
        const reclamationClones = reclamations.map((iri) => {
            const reclamation = (storeOfReclamations[iri] ?? null) as Reclamation | null;

            // This should never happen, because the reclamation was selected from the list of available reclamations.
            if (!reclamation) {
                throw new Error(`Reclamation with id ${iri} not found`);
            }

            return {
                ...cloneReclamation(reclamation),
                parent: reclamation.id,
            };
        });
        const errandClone = cloneErrand(errand);

        try {
            await post<Errand>(`${errand.id}/returned_item`, {
                body: JSON.stringify({
                    customer,
                    store,
                    errandClone,
                    reclamationClones,
                }),
            });

            notify('ra.notification.created', {
                type: 'info',
                messageArgs: { smart_count: 1 },
            });

            closeDialog();
            refresh();
        } catch (e) {
            notifyError(e);
        }
    };
};

const useStyles = makeStyles({
    container: {
        alignItems: 'flex-start',
    },
});

export default ReturnedItemButton;
