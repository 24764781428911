import { Person } from '@material-ui/icons';

import UserList from './UserList';
import UserShow from './UserShow';
import UserForm from './UserForm';

export default {
    list: UserList,
    show: UserShow,
    icon: Person,
    options: {
        form: UserForm,
    },
};
