import EmailIcon from '@material-ui/icons/Email';

import UserNotificationList from './UserNotificationList';

export default {
    icon: EmailIcon,
    list: UserNotificationList,
    show: null,
    create: null,
    edit: null,
};
