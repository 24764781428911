import { FC } from 'react';
import { ListProps, SearchInput } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import StoresField from './field/StoresField';

const filters = [<SearchInput key="q" source="q" alwaysOn autoComplete="off" />];

const UserGroupList: FC<ListProps> = (props) => {
    return (
        <ListGuesser {...props} filters={filters} hideFields={['statusChangeRules', 'logo']}>
            <DatagridGuesser>
                <StoresField perPage={10} />
            </DatagridGuesser>
        </ListGuesser>
    );
};

export default UserGroupList;
