import StoreIcon from '@material-ui/icons/Store';

import ResellerList from './ResellerList';
import ResellerShow from './ResellerShow';
import ResellerForm from './ResellerForm';

export default {
    icon: StoreIcon,
    list: ResellerList,
    show: ResellerShow,
    options: {
        form: ResellerForm,
        alwaysHideFields: ['reclamation', 'overrideReseller', 'commissionInPercents'],
    },
};
