import { FC, FormEventHandler, PropsWithChildren } from 'react';
import { Form, FormProps, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

export type FilterChangeHandler = (filters: Record<string, any>) => void;

type Props = { onChange: FilterChangeHandler; initialValues?: Record<string, any> };

const FormSpySubscription = { values: true, pristine: true, touched: true };
const handleFormSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    return false;
};

const useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: 0,
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        minHeight: theme.spacing(10),
    },
}));

const FilterForm: FC<PropsWithChildren<Props>> = ({ onChange, initialValues, children }) => {
    const classes = useStyles();
    const handleSubmit: FormProps['onSubmit'] = (data) => onChange(data);

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={() => (
                <>
                    <FormSpy
                        subscription={FormSpySubscription}
                        onChange={({ touched, pristine, values }) => {
                            if (pristine && (!touched || Object.keys(touched).length === 0)) {
                                return;
                            }

                            onChange(values);
                        }}
                    />
                    <form className={classes.form} onSubmit={handleFormSubmit}>
                        {children}
                    </form>
                </>
            )}
        />
    );
};

export default FilterForm;
