import { useField } from 'react-final-form';
import { BooleanInput } from 'react-admin';

interface Props {
    source: string;
    resource?: string;
    fullWidth?: boolean;
}

const ShippingPaidInput = (props: Props) => {
    const { input } = useField('shippingCost', { subscription: { value: true } });

    return <BooleanInput {...props} disabled={!input.value} />;
};

export default ShippingPaidInput;
