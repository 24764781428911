import { Button, CardContentInner, Title, useGetResourceLabel, useTranslate } from 'react-admin';
import {
    Box,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    LinearProgress,
} from '@material-ui/core';
import useResourceExportFields from '@js/hooks/useResourceExportFields';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { chunk } from 'lodash';
import SimpleToolbar from '@components/form/SimpleToolbar';
import { Link, useParams } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';

const ConfigureErrandsExport = () => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const getFieldName = useTranslateResourceField();
    const params = useParams<{ resource: string }>();

    const errandExportFields = useResourceExportFields('errands');
    const reclamationExportFields = useResourceExportFields('reclamations');
    const loading = errandExportFields.loading || reclamationExportFields.loading;

    return (
        <>
            <Title title={translate('app.action.configure')} />
            <Card>
                <CardContentInner>
                    {loading ? (
                        <LinearProgress />
                    ) : (
                        <Box display="flex" flexDirection="column" gridGap={32}>
                            {(['errands', 'reclamations'] as const).map((resource) => {
                                const {
                                    fields,
                                    selectedFields,
                                    onSelectFieldChange,
                                    isAllFieldsSelected,
                                    toggleSelectAll,
                                } = resource === 'errands' ? errandExportFields : reclamationExportFields;

                                return (
                                    <FormControl key={resource} component="fieldset">
                                        <FormLabel component="legend">
                                            <Box component="span" mr={3}>
                                                {getResourceLabel(resource)}
                                            </Box>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isAllFieldsSelected}
                                                        onChange={toggleSelectAll}
                                                    />
                                                }
                                                label={translate('app.label.select_all')}
                                            />
                                        </FormLabel>
                                        <Box display="flex" flexWrap="wrap">
                                            {chunk(fields, 8).map((fields, index) => (
                                                <FormGroup key={index}>
                                                    {fields.map((field) => (
                                                        <FormControlLabel
                                                            key={field.name}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedFields.includes(field.name)}
                                                                    onChange={() => onSelectFieldChange(field.name)}
                                                                    name={field.name}
                                                                />
                                                            }
                                                            label={getFieldName(
                                                                field.overrideLabelName || field.name,
                                                                resource,
                                                            )}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            ))}
                                        </Box>
                                    </FormControl>
                                );
                            })}
                        </Box>
                    )}
                </CardContentInner>
                <SimpleToolbar>
                    <Button
                        disabled={loading}
                        component={Link}
                        variant="contained"
                        label="ra.action.back"
                        to={`/${params.resource}`}
                    >
                        <SaveIcon />
                    </Button>
                </SimpleToolbar>
            </Card>
        </>
    );
};

export default ConfigureErrandsExport;
