import { createElement, FC } from 'react';
import { Create, CreateProps } from 'react-admin';

import SimpleFormGuesser from '@components/form/SimpleFormGuesser';
import useResourceDefinition from '@js/hooks/useResourceDefinition';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

const CreateGuesser: FC<CreateProps> = (props) => {
    const { options } = useResourceDefinition(props);
    const notifyFailure = useNotifyHttpError();

    return (
        <Create onFailure={notifyFailure} {...props}>
            {createElement(options?.form ?? SimpleFormGuesser)}
        </Create>
    );
};

export default CreateGuesser;
