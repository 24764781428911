import { ReactNode } from 'react';
import {
    linkToRecord,
    Record as RaRecord,
    ReferenceManyField,
    ReferenceManyFieldProps,
    SimpleListProps,
    TopToolbar,
    useListContext,
    useRecordContext,
    useResourceContext,
    useResourceDefinition,
    useTranslate,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import CreateSubResourceButton from '@components/button/CreateSubResourceButton';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import RowActions from '@components/list/RowActions';
import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import { DatagridGuesser, ListGuesserBase } from '@components/list/ListGuesser';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { ApiRecord } from '@js/interfaces/ApiRecord';

const ReferenceManyGrid = <ReferenceRecordType extends ApiRecord, RecordType extends ApiRecord>({
    initialValues,
    children,
    hideFields,
    sort = { field: 'createdAt', order: 'desc' },
    ...props
}: {
    record?: RecordType;
    initialValues?: (record: RecordType) => Partial<ReferenceRecordType>;
    children?: ReactNode;
    hideFields?: string[];
} & Omit<ReferenceManyFieldProps, 'children' | 'record'>) => {
    const record = useRecordContext<RecordType>(props);
    const translate = useTranslate();
    const isSmall = useIsSmallScreen();

    if (!record?.id) {
        return <Alert severity="info">{translate('app.label.first_save')}</Alert>;
    }

    return (
        <>
            <ReferenceManyField addLabel={false} pagination={<AutoHidePagination />} sort={sort} {...props}>
                <ListGuesserBase hideFields={hideFields} resource={props.reference}>
                    {isSmall ? (
                        <SimpleList />
                    ) : (
                        <>
                            <TopToolbar>
                                <VisibleColumnsButton />
                            </TopToolbar>
                            <DatagridGuesser
                                empty={<AlertEmptyResource />}
                                rowActions={<RowActions subResourceActions hasDelete />}
                            >
                                {children}
                            </DatagridGuesser>
                        </>
                    )}
                </ListGuesserBase>
            </ReferenceManyField>
            {initialValues && (
                <CreateSubResourceButton<ReferenceRecordType>
                    initialValues={initialValues(record)}
                    resource={props.reference}
                />
            )}
        </>
    );
};

const SimpleList = (props: SimpleListProps) => {
    const { pathname } = useLocation();
    const resource = useResourceContext(props);
    const { hasEdit } = useResourceDefinition(props);
    const { ids } = useListContext(props);

    if (!ids?.length) {
        return <AlertEmptyResource />;
    }

    const linkType = hasEdit
        ? (record: RaRecord) => `${linkToRecord(`/${resource}`, record.id)}?returnUrl=${encodeURIComponent(pathname)}`
        : false;

    return <SimpleListGuesser {...props} linkType={linkType} />;
};

export default ReferenceManyGrid;
