type ViteEnv = {
    VITE_ICE_CAT_API_URL: string;
    VITE_ICE_CAT_USER_NAME: string;
    VITE_SENTRY_DSN: string;
    MODE: 'production' | 'stage' | 'development';
};

export const loadViteEnv = () => {
    const env = (import.meta as any).env as Partial<ViteEnv>;

    if (
        process.env.NODE_ENV !== 'production' &&
        [env.VITE_ICE_CAT_API_URL, env.VITE_ICE_CAT_USER_NAME, env.VITE_SENTRY_DSN, env.MODE].some(
            (v) => typeof v === 'undefined',
        )
    ) {
        throw new Error('Invalid Vite env variables');
    }

    return env as ViteEnv;
};
