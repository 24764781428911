import { useCallback } from 'react';
import { useLocale, useResourceContext } from 'react-admin';
import { Resource } from '@api-platform/api-doc-parser';

import useResourceSchema from '@js/hooks/useResourceSchema';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';

const useResourceFieldName = (props: string | { resource?: string } = {}) => {
    const resource = useResourceContext(typeof props === 'string' ? { resource: props } : props);
    const schema = useResourceSchema({ resource });
    const getFieldName = useGetFieldName();

    return getFieldName(schema);
};

export const useGetFieldName = () => {
    const { schemaAnalyzer } = useHydraSchemaContext();
    const locale = useLocale();

    return useCallback(
        (schema: Resource) => {
            let fieldName = schemaAnalyzer.getFieldNameFromSchema(schema);

            if (isTranslatableField(fieldName)) {
                fieldName = `${fieldName}.${locale}`;
            }

            return fieldName;
        },
        [schemaAnalyzer, locale],
    );
};

export const isTranslatableField = (fieldName: string) => fieldName.startsWith('translatable');

export default useResourceFieldName;
