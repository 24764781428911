import { FC } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import { UserNotification } from '@js/interfaces/usernotification';

interface Props {
    source?: string;
    record?: UserNotification;
    addLabel?: boolean;
    resource?: string;
}

const TypeField: FC<Props> = ({ source, ...props }) => {
    const translate = useTranslate();
    const record = useRecordContext(props);
    const type = get(record, source!);

    if (!type) {
        return null;
    }

    return (
        <Typography component="span" variant="body2">
            {translate(`resources.user_notifications.type.${type}`)}
        </Typography>
    );
};

TypeField.defaultProps = {
    source: 'type',
    addLabel: true,
};

export default TypeField;
