import { ApiRecord, Iri } from '@js/interfaces/ApiRecord';

// Same as \App\Errand\StatusChangeRule
export enum Rule {
    AutoChange = 'auto_change',
    BlockChange = 'block_change',
}

export interface StatusChangeRule extends ApiRecord {
    rule: Rule;
    sourceStatus: Iri;
    targetStatus: Iri;
}
