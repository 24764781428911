import { useTranslate } from 'react-admin';

const useTranslateListAction = () => {
    const translate = useTranslate();

    return (action: string) =>
        translate(`app.action.${action}`, {
            _: translate(`ra.action.${action}`, {
                _: action,
            }),
        });
};

export default useTranslateListAction;
