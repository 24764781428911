import { Record as RaRecord, useRecordContext, useTranslate } from 'react-admin';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

export interface Props {
    source: string;
    label?: string;
    addLabel?: boolean;
    record?: RaRecord;
    resource?: string;
    sortable?: boolean;
}

const NullableBooleanField = (props: Props) => {
    const record = useRecordContext(props);
    const value = get(record, props.source);
    const translate = useTranslate();

    return (
        <Typography component="span" variant="body2">
            {translate(value === null ? 'app.label.n/a' : value ? 'ra.boolean.true' : 'ra.boolean.false')}
        </Typography>
    );
};

export default NullableBooleanField;
