import { useState } from 'react';
import {
    AutocompleteArrayInput,
    Confirm,
    FileInput,
    FormTab,
    ImageInput,
    minValue,
    NumberInput,
    ReferenceArrayInput,
    required,
    TabbedForm,
    TabbedFormProps,
    useGetList,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { useField } from 'react-final-form';
import { Box, FormControlLabel, FormGroup, Switch } from '@material-ui/core';

import Toolbar from '@components/form/Toolbar';
import InputGuesser from '@components/form/InputGuesser';
import FileInputPreview from '@components/input/FileInputPreview';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

import ContactPeopleDataGrid from './ContactPeopleDataGrid';

import useFormFields from '@js/hooks/useFormFields';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useResourceFieldName from '@js/hooks/useResourceFieldName';
import useDidMountEffect from '@js/hooks/useDidMountEffect';

import { Store } from '@js/interfaces/store';

const StoreForm = ({ save, ...props }: SafeOmit<TabbedFormProps, 'children' | 'onSubmit'>) => {
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();
    const fields = useFormFields(props, ['images', 'files']);
    const permissions = useUserPermissions();

    const record = useRecordContext(props) as Store | undefined;
    const hasErrands = useStoreHasErrands(record);

    const [warningDialog, setWarningDialog] = useState<{ open: boolean; onConfirm?: () => void }>({
        open: false,
    });

    return (
        <>
            <TabbedForm
                {...props}
                toolbar={<Toolbar />}
                save={(...args) => {
                    if (!save) throw new Error('Save function not provided');

                    const [values] = args;
                    // If the parent store of the store is changed and the store has errands, show warning dialog,
                    // because this might affect the errands composed identifier
                    if (hasErrands && record && record['parent'] !== values['parent']) {
                        setWarningDialog({
                            open: true,
                            onConfirm: () => {
                                setWarningDialog({ open: false });
                                save(...args);
                            },
                        });

                        return;
                    }

                    save(...args);
                }}
            >
                <FormTab label={translate('resources.stores.tabs.general')}>
                    {fields.map((field) => {
                        if (field.name === 'parent') {
                            return (
                                <HierarchicalAutocompleteSelectInput
                                    key={field.name}
                                    source="parent"
                                    filter={{ 'exists[parent]': false }}
                                />
                            );
                        }

                        return <InputGuesser key={field.name} source={field.name} field={field} />;
                    })}
                </FormTab>
                <FormTab label={translate('resources.stores.tabs.files')} path="files">
                    <ImageInput source="images" multiple accept="image/*">
                        <FileInputPreview reference="store_files" />
                    </ImageInput>
                    <FileInput source="files" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv" multiple>
                        <FileInputPreview reference="store_files" />
                    </FileInput>
                </FormTab>
                {permissions.isResourceEnabled('contact_people') && (
                    <FormTab label={getResourceLabel('contact_people')} path="contact_people">
                        <ContactPeopleDataGrid />
                    </FormTab>
                )}
                <FormTab label={translate('resources.stores.tabs.sla')} path="sla">
                    <SlaFormTab />
                </FormTab>
            </TabbedForm>
            <Confirm
                isOpen={warningDialog.open}
                onClose={() => setWarningDialog({ open: false })}
                onConfirm={warningDialog.onConfirm || (() => {})}
                title="app.label.warning"
                content={translate('app.message.store_composed_identifier_change_warning', {
                    field: translate('resources.stores.fields.parent'),
                    subject: getResourceLabel('errands'),
                })}
            />
        </>
    );
};

const SlaFormTab = (props: { record?: Store; resource?: string }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    const slaProperty = 'serviceLevelAgreement';
    const { input: slaInput } = useField(slaProperty, {
        isEqual: (s1, s2) => {
            return isEqual(s1, s2);
        },
    });
    const [showSla, setShowSla] = useState(() => !!get(record, slaProperty));

    useDidMountEffect(() => {
        if (!showSla && slaInput.value) {
            slaInput.onChange(null);
        }
    }, [showSla, slaInput]);

    const userOptionText = useResourceFieldName('users');
    const userGroupOptionText = useResourceFieldName('user_groups');

    return (
        <>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showSla}
                            onChange={(event) => setShowSla(event.target.checked)}
                            color="primary"
                        />
                    }
                    label={translate('app.label.enable')}
                />
            </FormGroup>
            {showSla && (
                <Box mt={1}>
                    <NumberInput
                        source={`${slaProperty}.durability`}
                        validate={[minValue(2), required()]}
                        resource={props.resource}
                        helperText={translate(`resources.stores.helperText.${slaProperty}.durability`)}
                    />
                    <ReferenceArrayInput
                        reference="users"
                        source={`${slaProperty}.notifyUsers`}
                        resource={props.resource}
                    >
                        <AutocompleteArrayInput optionText={userOptionText} />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput
                        reference="user_groups"
                        source={`${slaProperty}.notifyUserGroups`}
                        resource={props.resource}
                    >
                        <AutocompleteArrayInput optionText={userGroupOptionText} />
                    </ReferenceArrayInput>
                    {/*<TextInput
                        source={`${slaProperty}.notificationText`}
                        validate={[minValue(2), required()]}
                        resource={props.resource}
                        multiline
                    />*/}
                </Box>
            )}
        </>
    );
};

const useStoreHasErrands = (record: Store | undefined) => {
    const { total } = useGetList(
        'errands',
        { page: 1, perPage: 1 },
        undefined,
        { store: record?.id },
        {
            enabled: !!record?.id,
        },
    );

    return record && (total ?? 0) > 0;
};

export default StoreForm;
