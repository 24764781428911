import { FC, PropsWithChildren } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslate } from 'react-admin';

interface Props {
    title: string;
    error?: string;
    loading?: boolean;
    onClose: () => void;
    open: boolean;
}

const DialogCodeScanner: FC<PropsWithChildren<Props>> = ({ open, title, onClose, loading, error, children }) => {
    const translate = useTranslate();

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby={title}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {loading && <LinearProgress />}
                {error && <Alert severity="warning">{error}</Alert>}
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCodeScanner;
