import { MouseEventHandler } from 'react';
import { linkToRecord, useRecordContext, useResourceContext, Record as RaRecord } from 'react-admin';
import { useHistory } from 'react-router-dom';
import ImageEye from '@material-ui/icons/RemoveRedEye';

import IconButton from '@components/button/IconButton';

interface Props {
    basePath?: string;
    label?: string;
    record?: RaRecord;
    resource?: string;
}

const ShowIconButton = ({ label = 'ra.action.show', basePath, ...props }: Props) => {
    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const history = useHistory();

    const handleClick: MouseEventHandler = (e) => {
        e.stopPropagation();

        history.push({
            pathname: record ? `${linkToRecord(basePath || `/${resource}`, record.id)}/show` : '',
            state: { _scrollToTop: true },
        });
    };

    return (
        <IconButton label={label} onClick={handleClick}>
            <ImageEye />
        </IconButton>
    );
};

export default ShowIconButton;
