import ViewModuleIcon from '@material-ui/icons/ViewModule';

import GoodsTypeForm from './GoodsTypeForm';

export default {
    icon: ViewModuleIcon,
    options: {
        form: GoodsTypeForm,
        alwaysHideFields: ['subGoodsTypes', 'externalIri', 'externalApp'],
    },
};
