import { FC, useState } from 'react';
import { useRecordContext, Button as RaButton } from 'react-admin';
import { get } from 'lodash';
import { Box, Card, CardActions, CardMedia, Typography, makeStyles } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import ImageEye from '@material-ui/icons/RemoveRedEye';

import MediaFileViewDialog from '@components/mui/MediaFileViewDialog';

import { VichUploadable } from '@js/interfaces/vichUploadable';

type Props = {
    source?: string;
    title?: string;
    record?: VichUploadable;
    resource?: string;
    addLabel?: boolean;
};

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const FileField: FC<Props> = (props) => {
    const { source, title } = props;
    const record = useRecordContext(props);
    const classes = useStyles();
    const publicPath = get(record, source!);

    if (!publicPath) return null;

    const mimeType = record.mimeType || '';
    const ContentComponent =
        mimeType.startsWith('image') || mimeType.startsWith('video') ? MediaCardContent : FileCardContent;

    return (
        <Card className={classes.card}>
            <ContentComponent file={record} source={source!} title={title!} />
        </Card>
    );
};

FileField.defaultProps = {
    addLabel: true,
    source: 'paths.publicPath',
    title: 'originalName',
};

const FileCardContent: FC<{ file: VichUploadable; source: string; title: string }> = ({ file, source, title }) => {
    return (
        <>
            <Box height="108px" p={2} display="flex" alignItems="center">
                <Typography>{get(file, title)}</Typography>
            </Box>
            <CardActions>
                <RaButton
                    size="small"
                    color="secondary"
                    label="app.action.download"
                    href={get(file, source)}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    target="_blank"
                >
                    <DownloadIcon />
                </RaButton>
            </CardActions>
        </>
    );
};

const MediaCardContent: FC<{ file: VichUploadable; source: string; title: string }> = ({ file, source, title }) => {
    const [open, setOpen] = useState(false);
    const src = get(file, source);
    const isVideo = file.mimeType?.startsWith('video');

    return (
        <>
            <CardMedia src={src} alt={get(file, title)} component={isVideo ? 'video' : 'img'} height="140" />
            <CardActions>
                <RaButton size="small" color="secondary" label="ra.action.show" onClick={() => setOpen(true)}>
                    <ImageEye />
                </RaButton>
            </CardActions>
            <MediaFileViewDialog open={open} onClose={() => setOpen(false)} file={file} path={source} title={title} />
        </>
    );
};

export default FileField;
