import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, ToolbarProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px',
            width: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            zIndex: 2,
        },
        [theme.breakpoints.up('xs')]: {
            marginTop: theme.spacing(2),
        },
    },
}));

const SimpleToolbar: FC<ToolbarProps> = (props) => {
    const classes = useStyles();

    return <Toolbar className={classes.toolbar} {...props} />;
};

export default SimpleToolbar;
