import { FC } from 'react';
import { Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import SimpleIcon from '@material-ui/icons/Description';
import BundleIcon from '@material-ui/icons/FormatLineSpacing';
import { SplitType, SplitTypes } from '@js/interfaces/product';

type Props = {
    activeList: SplitType;
    onListChange: (list: SplitType) => void;
};

const Aside: FC<Props> = ({ activeList, onListChange }) => {
    const translate = useTranslate();
    const icons = {
        simple: <SimpleIcon />,
        bundle: <BundleIcon />,
    };

    return (
        <Card>
            <CardContent>
                <List component="nav">
                    {SplitTypes.map((list) => (
                        <ListItem key={list} button selected={activeList === list} onClick={() => onListChange(list)}>
                            <ListItemIcon>{icons[list]}</ListItemIcon>
                            <ListItemText primary={translate(`resources.products.splitType.${list}`)} />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default Aside;
