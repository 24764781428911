import { minValue, SimpleFormProps } from 'react-admin';

import InputGuesser from '@components/form/InputGuesser';
import SimpleFormGuesser from '@components/form/SimpleFormGuesser';

import { minutes } from '@js/validator/minutes';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

const PredefinedTextForm = (props: SimpleFormProps) => {
    const getFieldLabel = useTranslateResourceField();

    return (
        <SimpleFormGuesser {...props}>
            <InputGuesser
                source="time"
                validate={[minutes(), minValue(1)]}
                label={getFieldLabel('timePlaceholder', 'processing_times')}
            />
        </SimpleFormGuesser>
    );
};

export default PredefinedTextForm;
