import { Theme, Tooltip } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FC, MouseEventHandler, ReactNode, useState } from 'react';
import classnames from 'classnames';

interface Props {
    text?: string;
    children: ReactNode;
    className?: string;
    onClick?: MouseEventHandler<HTMLSpanElement>;
}

const useStyles = makeStyles<Theme, Pick<Props, 'text'>>({
    root: ({ text }) => ({
        '&:hover': {
            cursor: text ? 'pointer' : 'default',
        },
    }),
});

const ClickToCopyTooltip: FC<Props> = ({ text, className, children, onClick }) => {
    const translate = useTranslate();
    const defaultLabel = translate('app.action.click_to_copy');
    const [title, setTitle] = useState(defaultLabel);
    const classes = useStyles({ text });

    const handleClick: MouseEventHandler<HTMLSpanElement> = (event) => {
        navigator.clipboard
            .writeText(text ?? '')
            .then(() => {
                setTitle(translate('app.message.copied'));
            })
            .catch((error) => {
                setTitle(error.message);
            });
        if (onClick) {
            onClick(event);
        }
    };

    const handleLeave = () => {
        setTitle(defaultLabel);
    };

    const renderContent = () => (
        <span className={classnames(classes.root, className)} onClick={handleClick} onMouseLeave={handleLeave}>
            {children}
        </span>
    );

    if (text) {
        return <Tooltip title={title}>{renderContent()}</Tooltip>;
    }

    return renderContent();
};

export default ClickToCopyTooltip;
