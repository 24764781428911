import { ApiRecord } from '@js/interfaces/ApiRecord';

export enum AddressType {
    HOME = 'home',
    WORK = 'work',
}

export interface Address extends ApiRecord {
    address: string;
    co?: string;
    zipCode: string;
    city: string;
    customer?: string;
    type: AddressType;
    country?: string;
}
