import { useState } from 'react';
import { TextInput, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

import RowCell from './RowCell';

import { LocalizationLabel } from '@js/interfaces/localizationlabel';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props {
    label: LocalizationLabel;
    locales: string[];
    hidden: boolean;
}

const useStyles = makeStyles({
    flex: {
        display: 'flex',
    },
    hidden: {
        display: 'none',
    },
    tooltip: {
        position: 'relative',
        top: '5px',
        marginLeft: '4px',
    },
});

const LabelTabRow = ({ locales, label, hidden }: Props) => {
    const [edit, setEdit] = useState(false);
    const classes = useStyles();

    return (
        <div className={hidden ? classes.hidden : classes.flex}>
            <RowCell variant="body2">{label.key}</RowCell>
            {edit ? (
                <EditFormCells label={label} locales={locales} onQuitEditing={() => setEdit(false)} />
            ) : (
                <>
                    {locales.map((locale) => {
                        const infoLabel = !Array.isArray(label.infoLabels) && label.infoLabels[locale];

                        return (
                            <RowCell key={locale} variant="body2">
                                <Typography variant="body2" component="span">
                                    {label.labels[locale]}
                                    {infoLabel && (
                                        <Tooltip title={infoLabel}>
                                            <InfoIcon fontSize="small" className={classes.tooltip} />
                                        </Tooltip>
                                    )}
                                </Typography>
                            </RowCell>
                        );
                    })}
                    <RowCell type="actions">
                        <IconButton color="primary" onClick={() => setEdit(true)}>
                            <EditIcon />
                        </IconButton>
                    </RowCell>
                </>
            )}
        </div>
    );
};

const EditFormCells = ({
    label,
    onQuitEditing,
    locales,
}: {
    label: LocalizationLabel;
    locales: string[];
    onQuitEditing: () => void;
}) => {
    const resource = 'localization_labels';
    const getFieldLabel = useTranslateResourceField(resource);
    const translate = useTranslate();
    const notifyHttpError = useNotifyHttpError();
    const notify = useNotify();
    const refresh = useRefresh();
    const { labels, infoLabels } = label;

    const [update] = useUpdate(
        resource,
        label.id,
        {}, // set by the caller
        label,
    );

    const handleSubmit = async (data: LocalizationLabel) => {
        return update(
            { payload: { data } },
            {
                onSuccess: () => {
                    notify('ra.notification.updated', 'info', {
                        smart_count: 1,
                    });
                    onQuitEditing();
                },
                onFailure: (error) => {
                    notifyHttpError(error);
                    onQuitEditing();
                    refresh();
                },
                mutationMode: 'pessimistic',
                returnPromise: true,
            },
        );
    };

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={{
                labels,
                ...(label.hasInfoLabelSupport && { infoLabels }),
            }}
            render={({ submitting, pristine, handleSubmit }) => {
                return (
                    <>
                        {locales.map((locale) => {
                            const source = `labels.${locale}`;

                            return (
                                <RowCell key={locale}>
                                    <TextInput
                                        source={source}
                                        label={getFieldLabel(source)}
                                        fullWidth
                                        size="small"
                                        helperText={false}
                                        InputProps={{
                                            ...(label.hasInfoLabelSupport && {
                                                endAdornment: <FieldInfoEndAdornment source={`infoLabels.${locale}`} />,
                                            }),
                                        }}
                                    />
                                </RowCell>
                            );
                        })}
                        <RowCell type="actions">
                            <IconButton
                                color="primary"
                                aria-label={translate('ra.action.save')}
                                component="span"
                                title={translate('ra.action.save')}
                                onClick={handleSubmit}
                                disabled={submitting || pristine}
                                size="small"
                            >
                                {submitting ? <CircularProgress size={20} /> : <SaveIcon />}
                            </IconButton>
                            <IconButton
                                aria-label={translate('ra.action.cancel')}
                                title={translate('ra.action.cancel')}
                                component="span"
                                onClick={onQuitEditing}
                                size="small"
                            >
                                {<CancelIcon />}
                            </IconButton>
                        </RowCell>
                    </>
                );
            }}
        />
    );
};

const FieldInfoEndAdornment = ({ source }: { source: string }) => {
    const [open, setOpen] = useState(false);
    const translate = useTranslate();

    return (
        <>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <InfoIcon fontSize="small" />
            </IconButton>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md" keepMounted>
                <DialogContent>
                    <TextInput
                        autoFocus
                        source={source}
                        label={translate('app.label.field_info')}
                        fullWidth
                        multiline
                        helperText={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{translate('ra.action.close')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LabelTabRow;
