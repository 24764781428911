/**
 * Copy from https://github.com/mui/material-ui/blob/v5.5.3/docs/data/material/customization/color/ColorDemo.js
 */

import { FC } from 'react';
import { AppBar, Box, Button, Fab, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { StateType } from './ColorTool';

interface Props {
    data: StateType;
}

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: 20,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const ColorDemo: FC<Props> = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const primary = theme.palette.augmentColor({
        main: data.primary,
    });
    const secondary = theme.palette.augmentColor({
        main: data.secondary,
    });

    return (
        <Box position="relative" overflow="hidden">
            <Box position="relative" height={390} bgcolor="background.paper">
                <AppBar position="static" style={{ backgroundColor: secondary.main }}>
                    <Toolbar style={{ color: secondary.contrastText }}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                        <Typography component="div" variant="h6" color="inherit">
                            Color
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box display="flex" m={2} flexDirection="column" alignItems="start" gridGap="10px">
                    <Button variant="contained" style={{ backgroundColor: primary.main, color: primary.contrastText }}>
                        Primary button
                    </Button>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: secondary.main, color: secondary.contrastText }}
                    >
                        Secondary button
                    </Button>
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ color: primary.main, textDecoration: 'none' }}
                    >
                        <Typography component="span" variant="body2">
                            Link example
                        </Typography>
                    </a>

                    <Typography>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
                </Box>
                <Fab className={classes.fab} style={{ backgroundColor: secondary.main }} aria-label="add">
                    <AddIcon htmlColor={secondary.contrastText} />
                </Fab>
            </Box>
        </Box>
    );
};

export default ColorDemo;
