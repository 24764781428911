import { Edit, EditProps } from 'react-admin';
import { FC } from 'react';

import LockedFormSafeguard from '@components/form/LockedFormSafeguard';
import ProductForm from './ProductForm';
import useOnSuccess from './useOnSuccess';

const ProductEdit: FC<EditProps> = (props) => {
    const onSuccess = useOnSuccess('updated', props.basePath);

    return (
        <LockedFormSafeguard {...props}>
            <Edit {...props} onSuccess={onSuccess}>
                <ProductForm />
            </Edit>
        </LockedFormSafeguard>
    );
};

export default ProductEdit;
