import { useCallback, useEffect } from 'react';
import { CRUD_GET_ONE_SUCCESS, FETCH_END, GET_ONE, Identifier } from 'react-admin';
import { useDispatch } from 'react-redux';

import { useAppConfigContext } from '@js/context/AppConfigContext';
import { Iri } from '@js/interfaces/ApiRecord';

interface Document {
    '@id': Iri;
    id?: Iri;
    [key: string]: any;
}

class ReactAdminDocument {
    originId;
    id;

    constructor(obj: Document) {
        Object.assign(this, obj);

        if (obj.id) {
            this.originId = obj.id;
        }
        if (!obj['@id']) {
            throw new Error('Document needs to have an @id member.');
        }

        this.id = obj['@id'];
    }
}

const useMercureResourceSubscription = (
    resource: string | undefined,
    idOrIds: Identifier | Identifier[] | undefined,
) => {
    const dispatch = useDispatch();
    const eventListener = useCallback(
        (event: MessageEvent) => {
            const document = new ReactAdminDocument(JSON.parse(event.data));

            dispatch({
                type: CRUD_GET_ONE_SUCCESS,
                payload: {
                    data: document,
                },
                meta: {
                    resource,
                    fetchResponse: GET_ONE,
                    fetchStatus: FETCH_END,
                },
            });
        },
        [dispatch, resource],
    );

    useSubscribeToRecordEvents(idOrIds, eventListener);
};

export const useSubscribeToRecordEvents = (
    idOrIds: Identifier | Identifier[] | undefined,
    eventListener: (event: MessageEvent) => void,
) => {
    const { entrypoint, mercureUrl } = useAppConfigContext();

    useEffect(() => {
        if (!idOrIds) {
            return;
        }

        const ids = Array.isArray(idOrIds) ? idOrIds : [idOrIds];
        const url = new URL(mercureUrl, window.origin);

        // Append multiple ids
        ids.forEach((id) => {
            url.searchParams.append('topic', new URL(id.toString(), entrypoint).toString());
        });

        const eventSource = new EventSource(url.toString(), {
            withCredentials: true,
        });

        eventSource.addEventListener('message', eventListener);

        return () => {
            eventSource.removeEventListener('message', eventListener);
            eventSource.close();
        };
    }, [idOrIds, entrypoint, mercureUrl, eventListener]);
};

export default useMercureResourceSubscription;
