import { useEffect } from 'react';
import { useLocale } from 'react-admin';

import useChangeLocale from '@js/hooks/useChangeLocale';
import { useAppConfigContext } from '@js/context/AppConfigContext';
import { UserIdentity } from '@js/auth/types';

const useLocaleChangeController = (identity: UserIdentity | undefined) => {
    const changeLocale = useChangeLocale();
    const locale = useLocale();
    const { i18n } = useAppConfigContext();

    // Check if locale changed after user login
    useEffect(() => {
        if (!identity) {
            return;
        }
        const userLocale = identity.locale;

        if (locale !== userLocale && i18n.locales.includes(userLocale)) {
            changeLocale(userLocale);
        }
        // We need to check current locale only on first load.
        // Later if locale changed using language menu we should ignore it.
        // eslint-disable-next-line
    }, [identity, changeLocale]);
};

export default useLocaleChangeController;
