import { ReactElement } from 'react';
import { Button, ButtonProps } from 'react-admin';
import { CircularProgress } from '@material-ui/core';

interface Props {
    label: string;
    loading: boolean;
    icon: ReactElement;
    variant?: ButtonProps['variant'];
    onClick: ButtonProps['onClick'];
    color?: ButtonProps['color'];
    disabled?: boolean;
    size?: ButtonProps['size'];
}

const LoadingButton = ({
    label,
    loading,
    onClick,
    disabled,
    icon,
    size,
    variant = 'contained',
    color = 'primary',
}: Props) => {
    const progressSize = !size || size === 'small' ? 18 : size === 'medium' ? 22 : 24;

    return (
        <Button
            label={label}
            variant={variant}
            type="button"
            onClick={onClick}
            color={loading ? 'default' : color}
            disabled={disabled || loading}
            size={size}
        >
            {loading ? <CircularProgress size={progressSize} thickness={2} /> : icon}
        </Button>
    );
};

export default LoadingButton;
