import { Locale } from '@js/context/AppConfigContext';

export interface SavingRecord {
    salesPrice: number | null;
    soldQuantity: number;
    recipe: string | null;
    error: string | null;
    savings: Record<Saving, SavingResult | null>;
    analogies: Record<Saving, Analogy[]>;
}

export type SavingResult = {
    title: string;
    value: string | null;
    formula: string | null;
};

export enum Saving {
    CollectiveValueEnvironment = 'collectiveValueEnvironment',
    CollectiveValueEconomy = 'collectiveValueEconomy',
    QuantitativeWaterConsumption = 'quantitativeWaterConsumption',
    ClimateChange = 'climateChange',
    OzoneDepletion = 'ozoneDepletion',
    IonisingRadiation = 'ionisingRadiation',
    PhotochemicalOzoneFormation = 'photochemicalOzoneFormation',
    ParticulateMatter = 'particulateMatter',
    HumanToxicityNonCancer = 'humanToxicityNonCancer',
    HumanToxicityCancer = 'humanToxicityCancer',
    Acidification = 'acidification',
    EutrophicationFreshwater = 'eutrophicationFreshwater',
    EutrophicationMarine = 'eutrophicationMarine',
    EutrophicationTerrestrial = 'eutrophicationTerrestrial',
    EcotoxicityFreshwater = 'ecotoxicityFreshwater',
    LandUse = 'landUse',
    WaterUse = 'waterUse',
    ResourceUseFossils = 'resourceUseFossils',
    ResourceUseMineralsAndMetals = 'resourceUseMineralsAndMetals',
}

export type Analogy = {
    id: number;
    description: Partial<Record<Locale, string>>;
    logos: { url: string; title: string }[];
};

export const MainSaving = Saving.CollectiveValueEnvironment;
