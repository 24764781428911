import { ListProps, SearchInput } from 'react-admin';

import ListGuesser from '@components/list/ListGuesser';

const filter = [<SearchInput key="q" source="q" alwaysOn />];

const CustomerList = (props: ListProps) => {
    return <ListGuesser {...props} filters={filter} />;
};

export default CustomerList;
