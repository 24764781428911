import { FC } from 'react';
import { ReferenceField, Show, ShowProps, Tab, TabbedShowLayout, useGetResourceLabel, useTranslate } from 'react-admin';

import FileField from '@components/field/FileField';
import ShowLayoutTabs from '@components/detail/ShowLayoutTabs';
import RecordTitle from '@components/detail/RecordTitle';
import TabbedTabGuesser from '@components/detail/TabbedTabGuesser';

import PermissionsField from './field/PermissionsField';
import PredefinedTextDatagrid from './PredefinedTextDatagrid';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

const UserGroupShow: FC<ShowProps> = (props) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const permissions = useUserPermissions();

    return (
        <Show {...props} title={<RecordTitle />}>
            <TabbedShowLayout tabs={<ShowLayoutTabs />}>
                <TabbedTabGuesser
                    label={translate('resources.user_groups.tab.general')}
                    hideFields={['statusChangeRules']}
                >
                    <ReferenceField reference="user_group_logos" source="logo" linkType={false}>
                        <FileField />
                    </ReferenceField>
                </TabbedTabGuesser>
                {permissions.isFieldListable('user_groups', 'permissions') && (
                    <Tab label={translate('resources.user_groups.tab.permissions')} path="permissions">
                        <PermissionsField />
                    </Tab>
                )}
                {permissions.isResourceEnabled('predefined_texts') && (
                    <Tab label={getResourceLabel('predefined_texts')} path="predefined_texts">
                        <PredefinedTextDatagrid />
                    </Tab>
                )}
            </TabbedShowLayout>
        </Show>
    );
};

export default UserGroupShow;
