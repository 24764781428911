import { FC } from 'react';
import {
    ChipField,
    SingleFieldList,
    ReferenceArrayField,
    useTranslate,
    ReferenceArrayFieldProps,
    useResourceDefinition,
} from 'react-admin';
import { Box, Chip } from '@material-ui/core';

const FieldList: FC<{ perPage?: number; total?: number }> = ({ perPage = 0, total = 0, ...props }) => {
    const translate = useTranslate();
    const { hasShow } = useResourceDefinition(props);

    return (
        <>
            <SingleFieldList linkType={hasShow && 'show'} {...props}>
                <ChipField source="name" />
            </SingleFieldList>
            {total > perPage && (
                <Box mt="10px" mb="4px" mx="4px" display="inline-block">
                    <Chip
                        label={translate('ra.navigation.page_range_info', {
                            offsetBegin: 1,
                            offsetEnd: perPage,
                            total,
                        })}
                    />
                </Box>
            )}
        </>
    );
};

const StoresField: FC<Omit<ReferenceArrayFieldProps, 'children' | 'reference'>> = (props) => {
    return (
        <ReferenceArrayField reference="stores" {...props}>
            <FieldList />
        </ReferenceArrayField>
    );
};

StoresField.defaultProps = {
    source: 'stores',
    addLabel: true,
    sortable: false,
};

export default StoresField;
