import { Box, Card, CardContent, Divider, Grid } from '@material-ui/core';
import { Title, useTranslate } from 'react-admin';
import { useState } from 'react';

import FilterForm from '../FilterForm';
import Filters, { FilterValues } from './Filters';
import SavingsCount from './SavingsCount';
import CategorySavingsList from './CategorySavingsList';
import ErrandSavingsList from './ErrandSavingsList';
import SavingsErrorCount from './SavingsErrorCount';

import { getSavingsFilterStartDate } from '../util';
import { App, useAppConfigContext } from '@js/context/AppConfigContext';

const SavingsDetailPage = () => {
    const translate = useTranslate();
    const { currentApp } = useAppConfigContext();
    const [filters, setFilters] = useState<FilterValues | undefined>(() => {
        if (currentApp !== App.Riv) return undefined;
        return {
            after: getSavingsFilterStartDate(),
        };
    });

    return (
        <>
            <Title title={translate('app.action.co2')} />
            <Card>
                <CardContent>
                    <FilterForm initialValues={filters} onChange={setFilters}>
                        <Filters />
                    </FilterForm>
                    <Grid container spacing={4}>
                        <Grid item sm={12} lg={4}>
                            <SavingsCount filters={filters} />
                        </Grid>
                        <Grid item sm={12} lg={8}>
                            <CategorySavingsList filter={filters} />
                            <Box mt={2} mb={1}>
                                <Divider />
                            </Box>
                            <SavingsErrorCount filter={filters} />
                        </Grid>
                        <Grid item sm={12} lg={12}>
                            <ErrandSavingsList filter={filters} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default SavingsDetailPage;
