import { Pagination, PaginationProps, useListContext } from 'react-admin';
import { FC } from 'react';

const AutoHidePagination: FC<PaginationProps> = (props) => {
    const { total, perPage } = useListContext(props);

    if (total <= perPage) {
        return null;
    }

    return <Pagination {...props} />;
};

export default AutoHidePagination;
