import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import { ReactNode } from 'react';

const RowCell = ({
    children,
    variant,
    type = 'cell',
}: {
    variant?: TypographyProps['variant'];
    children?: ReactNode;
    type?: 'cell' | 'actions';
}) => {
    const classes = useStyles();

    return (
        <div className={classes[type]}>
            {typeof children === 'string' ? (
                <Typography variant={variant} component="span">
                    {children}
                </Typography>
            ) : (
                children
            )}
        </div>
    );
};

const useStyles = makeStyles({
    cell: {
        flex: '1 1 0%',
        overflow: 'hidden',
        padding: '6px 24px 6px 0',
        borderBottom: '1px solid #e0e0e0',
        display: 'flex',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        width: '80px',
        borderBottom: '1px solid #e0e0e0',
    },
});

export default RowCell;
