import { MouseEventHandler, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useLocale, useTranslate, useUpdate } from 'react-admin';
import TranslateIcon from '@material-ui/icons/Translate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

import useChangeLocale from '@js/hooks/useChangeLocale';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { useLanguageChoices } from '@js/context/AppConfigContext';
import useGetIdentity from '@js/hooks/useGetIdentity';

const useStyles = makeStyles({
    button: {
        boxShadow: 'none',
    },
    mediumIcon: {
        fontSize: '20px',
    },
});

const LanguageMenu = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const translate = useTranslate();
    const changeLocale = useChangeLocale();
    const locale = useLocale();
    const classes = useStyles();
    const { identity } = useGetIdentity();
    const [update] = useUpdate('users', identity && identity.id);
    const isXSmall = useIsSmallScreen();
    const languageChoices = useLanguageChoices();

    const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (locale: string) => {
        changeLocale(locale);
        update({ payload: { data: { locale } } }); // Update user
        handleClose(); // Close menu
    };

    return (
        <div id="header-language-menu">
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleOpen}
                startIcon={!isXSmall && <TranslateIcon />}
                endIcon={<ArrowDropDownIcon />}
            >
                {isXSmall ? (
                    <TranslateIcon className={classes.mediumIcon} />
                ) : (
                    translate(`app.translation.language.${locale}`)
                )}
            </Button>
            <Menu id="language-menu" open={!!anchorEl} anchorEl={anchorEl} keepMounted onClose={handleClose}>
                {languageChoices.map(({ id, name }) => (
                    <MenuItem key={id} onClick={() => handleLanguageChange(id)}>
                        {translate(name)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default LanguageMenu;
