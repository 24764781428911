import { FC } from 'react';
import { InjectedFieldProps, useRecordContext, useTranslate } from 'react-admin';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

import { Product } from '@js/interfaces/product';

interface Props extends InjectedFieldProps {
    source: string;
    record?: Product;
    addLabel?: boolean;
    sortable?: boolean;
    label?: string;
}

const QuantityField: FC<Props> = (props) => {
    const translate = useTranslate();
    const record = useRecordContext(props);
    const quantity = get(record, props.source) ?? 0;
    const soldQuantity = get(record, 'soldQuantity') ?? 0;

    return (
        <Typography component="span" variant="body2" noWrap>
            {quantity} - ({soldQuantity} {translate('app.label.sold')}) = {`${quantity - soldQuantity}`}
        </Typography>
    );
};

QuantityField.defaultProps = {
    sortable: false,
    addLabel: true,
};

export default QuantityField;
