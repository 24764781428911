import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { useCustomThemeContext } from '@js/context/CustomThemeContext';
import { UserIdentity } from '@js/auth/types';

const useThemeController = (identity: UserIdentity | undefined) => {
    const { theme, setTheme } = useCustomThemeContext();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!identity || ready) {
            return;
        }

        if (!isEqual(theme, identity.theme)) {
            setTheme(identity.theme);
        }
        setReady(true);
    }, [identity, theme, setTheme, ready]);

    return ready;
};

export default useThemeController;
