import { FC } from 'react';
import { Record, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';

import { Currency } from '@js/context/AppConfigContext';
import { useFormatPrice } from '@js/hooks/useFormatPrice';

export interface PriceFieldProps {
    source: string;
    label?: string;
    currency?: Currency;
    addLabel?: boolean;
    record?: Record;
    resource?: string;
    formatCents?: boolean;
    sortable?: boolean;
}

const PriceField: FC<PriceFieldProps> = ({ source, currency, formatCents = true, ...props }) => {
    const record = useRecordContext(props);
    const formatPrice = useFormatPrice();

    if (!record) return null;

    const value = get(record, source);
    if (typeof value !== 'number') return null;

    const finalValue = formatCents ? value / 100 : value;

    return (
        <Typography variant="body2" component="span">
            {formatPrice(finalValue, currency)}
        </Typography>
    );
};

export default PriceField;
