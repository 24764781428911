import { Form } from 'react-final-form';
import { CardContentInner, FileField, FileInput, required, useResourceContext } from 'react-admin';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import ErrandInputGuesser from '@components/resource/errand/input/ErrandInputGuesser';
import Toolbar from '../Toolbar';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useFormFields from '@js/hooks/useFormFields';
import { FormFile, ImportState } from './ErrandImport';

interface Props {
    onSubmit: (values: ImportState['upload']) => void;
    state: ImportState;
}

type SubmitData = {
    importFile: FormFile;
    [key: string]: any;
};

const UploadFileForm = ({ state, onSubmit }: Props) => {
    const notifyFailure = useNotifyHttpError();
    const fields = useFormFields();
    const selectFields = ['identificator', 'store', 'deliveryDate', 'images'];
    const resource = useResourceContext();

    // Read first line of uploaded exec file for further mapping configuration
    const handleSubmit = async ({ importFile, ...values }: SubmitData) => {
        try {
            const formData = new FormData();
            formData.append('file', importFile.rawFile);

            const { columns } = await post<{ columns: Record<string, string> }>('/api/excel/first_line_reader', {
                body: formData,
            });
            if (!columns) {
                throw new Error('Error parsing file');
            }

            onSubmit({
                import: { file: importFile, firstLine: columns },
                errandData: values,
            });
        } catch (error) {
            notifyFailure(error);
        }
    };

    return (
        <Form onSubmit={handleSubmit} initialValues={state.upload?.errandData}>
            {({ handleSubmit, valid, submitting }) => (
                <>
                    <CardContentInner>
                        {selectFields.map((fieldName) => {
                            const field = fields.find(({ name }) => name === fieldName);
                            if (field) {
                                return (
                                    <ErrandInputGuesser
                                        key={fieldName}
                                        source={fieldName}
                                        field={field}
                                        resource={resource}
                                        fullWidth
                                    />
                                );
                            }

                            return null;
                        })}
                        <FileInput
                            source="importFile"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            validate={required()}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    </CardContentInner>
                    <Toolbar
                        cancel={{ to: '/errands' }}
                        submit={{
                            onSubmit: handleSubmit,
                            disabled: !valid,
                            saving: submitting,
                            icon: NavigateNextIcon,
                        }}
                    />
                </>
            )}
        </Form>
    );
};

export default UploadFileForm;
