import { useHistory } from 'react-router-dom';
import { RouterState } from '@components/resource/products/ProductList';
import { Record, useNotify, useRedirect } from 'react-admin';

const useOnSuccess = (action: 'created' | 'updated', basePath?: string) => {
    const list = useHistory<RouterState>().location.state?.list;
    const redirect = useRedirect();
    const notify = useNotify();

    return (data?: Record) => {
        notify(`ra.notification.${action}`, {
            type: 'info',
            messageArgs: { smart_count: 1 },
            undoable: true,
        });
        redirect('list', basePath, data?.id, data, { list });
    };
};

export default useOnSuccess;
