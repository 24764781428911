import { FC } from 'react';
import { Field } from '@api-platform/api-doc-parser';
import { ReferenceArrayField, SingleFieldList } from 'react-admin';

import FieldGuesser from '@components/field/FieldGuesser';
import FileField from '@components/field/FileField';
import CopyableTextField from '@components/field/CopyableTextField';
import ArrayChipField from '@components/field/ArrayChipField';
import NullableBooleanField from '@components/field/NullableBooleanField';

import ReclamationProcessingTimeField from '../field/ReclamationProcessingTimeField';
import PriceReclamationCurrencyField from '../field/PriceReclamationCurrencyField';
import StatusPreviewField from '../field/StatusPreviewField';
import ResellersField from '../field/ResellersField';
import LinksField from '../field/LinksField';
import TotalSpendCostField from '../field/TotalSpendCostField';
import QuantityField from '../../reclamation/field/QuantityField';
import ComposedIdentifierField from '../../reclamation/field/ComposedIdentifierField';
import CountReturnsField from '../../reclamation/field/CountReturnsField';
import StatusesHistory from '../../reclamation/StatusesHistory';

import { ComponentChoices, Reclamation } from '@js/interfaces/reclamation';

export interface ReclamationFieldGuesserProps {
    field: Field;
    source: string;
    addLabel?: boolean;
    sortable?: boolean;
    record?: Reclamation;
    resource?: string;
}

const ReclamationFieldGuesser: FC<ReclamationFieldGuesserProps> = ({ field, ...props }) => {
    switch (field.name) {
        case 'powerAdapter':
            return <NullableBooleanField {...props} />;
        case 'processingTimes':
            return <ReclamationProcessingTimeField {...props} />;
        case 'status':
            return <StatusPreviewField {...props} history={<StatusesHistory record={props.record} />} />;
        case 'cost':
        case 'costRedeemedItem':
        case 'recyclingFee':
        case 'purchasePrice':
        case 'insurancePrice':
        case 'costSparePart':
        case 'outgoingPrice':
            return <PriceReclamationCurrencyField {...props} />;
        case 'resellers':
            return <ResellersField {...props} />;
        case 'composedIdentifier':
            return <ComposedIdentifierField {...props} />;
        case 'label':
            return <CopyableTextField {...props} />;
        case 'images':
            return (
                <ReferenceArrayField reference="reclamation_media_objects" {...props}>
                    <SingleFieldList linkType={false}>
                        <FileField />
                    </SingleFieldList>
                </ReferenceArrayField>
            );
        case 'links':
            return <LinksField {...props} />;
        case 'totalSpendCost':
            return <TotalSpendCostField {...props} />;
        case 'containsComponents':
            return <ArrayChipField {...props} choices={ComponentChoices} />;
        case 'quantity':
            return <QuantityField {...props} />;
        case 'countReturns':
            return <CountReturnsField {...props} />;
        default:
            return <FieldGuesser field={field} {...props} />;
    }
};

ReclamationFieldGuesser.defaultProps = {
    addLabel: true,
};

export default ReclamationFieldGuesser;
