import { useEffect, useState } from 'react';
import {
    DeleteWithConfirmButton,
    DeleteWithConfirmButtonProps,
    LinearProgress,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { DialogContentText } from '@material-ui/core';

import DeleteIconButton from '@components/button/DeleteIconButton';

import { get } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { FlowItem, Reclamation } from '@js/interfaces/reclamation';

type Props = {
    record?: Reclamation;
    resource?: string;
    onlyIcon?: boolean;
    onClick?: () => void;
    deleteWithConfirmButtonProps?: DeleteWithConfirmButtonProps;
};

const DeleteButton = ({ onlyIcon, deleteWithConfirmButtonProps, ...props }: Props) => {
    const record = useRecordContext(props);
    const confirmContent = record?.child ? <ConfirmChildDelete record={record} /> : undefined;

    if (!onlyIcon) {
        return <DeleteWithConfirmButton {...deleteWithConfirmButtonProps} confirmContent={confirmContent} />;
    }

    return <DeleteIconButton {...props} confirmContent={confirmContent} />;
};

const ConfirmChildDelete = ({ record }: { record: Reclamation }) => {
    const [journey, setJourney] = useState<FlowItem[] | null>(null);
    const notifyError = useNotifyHttpError();
    const translate = useTranslate();

    useEffect(() => {
        get<FlowItem[]>(`${record.id}/purchase_separately_journey`)
            .then((journey) => {
                const currentIndex = journey.findIndex((item) => item.isCurrent);
                if (currentIndex !== -1) {
                    journey = journey.slice(currentIndex + 1);
                }

                setJourney(journey);
            })
            .catch(notifyError);
    }, [record.id, notifyError]);

    return (
        <>
            <DialogContentText>{translate('app.message.cascade_delete_warning')}</DialogContentText>
            {journey ? (
                <ul>
                    {journey.map((item) => (
                        <li key={item.id}>
                            {item.label} #{item.composedIdentifier}
                        </li>
                    ))}
                </ul>
            ) : (
                <LinearProgress />
            )}
        </>
    );
};

export default DeleteButton;
