import { FC, useEffect } from 'react';
import {
    BulkDeleteButton,
    CreateButton,
    Datagrid as RaDatagrid,
    DatagridBodyProps,
    DatagridProps,
    DatagridRow as RaDatagridRow,
    DatagridRowProps,
    FilterButton,
    ListProps,
    Pagination,
    TopToolbar,
    useExpanded,
    useListContext,
    useRecordContext,
    useResourceContext,
} from 'react-admin';
import { Box } from '@material-ui/core';

import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import ListGuesser, { useListGuesserControllerContext } from '@components/list/ListGuesser';
import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import LockRecordIcon from '@components/detail/LockRecordIcon';
import Co2CalculatorButton from '@components/button/Co2CalculatorButton';
import ErrandsConfigurableExportButton from '@components/button/ErrandsConfigurableExportButton';
import ListStickyToolbar from '@components/list/ListStickyToolbar';
import EditIconButton from '@components/button/EditIconButton';
import SyncSelloButton from '@components/button/SyncSelloButton';

import { ExpandShowPanel, hideReclamationFields, ReclamationFieldGuesser } from '../errand/ReclamationEditableDatagrid';
import BulkUpdateStatusButton from '../errand/BulkUpdateStatusButton';
import SplitSelloButton from './button/SplitSelloButton';
import PurchaseSeparatelyButton from './button/PurchaseSeparatelyButton';
import DeleteButton from './button/DeleteButton';
import ManualSaleButton from './button/ManualSaleButton';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { isFieldSortable } from '@js/utility/fieldsUtil';
import { useResourceFilterDefaultValues, useResourcePermissions } from '@js/context/UserPermissionsContext';
import useFilters from './useFilters';
import useListFiltersExistsResetFix from '@js/hooks/useListFiltersExistsResetFix';
import { Reclamation } from '@js/interfaces/reclamation';

const RowActions = (props: { record?: Reclamation; resource?: string }) => {
    const { isListActionEnabled, isFieldEditable, isEditActionEnabled } = useResourcePermissions(props);

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon />
            <EditIconButton {...props} />
            {isListActionEnabled('purchase_separately') && <PurchaseSeparatelyButton {...props} />}
            {isListActionEnabled('co2') && <Co2CalculatorButton />}
            {isListActionEnabled('split') && <SplitSelloButton />}
            {isFieldEditable('status') && <SyncSelloButton />}
            {isEditActionEnabled('delete') && <DeleteButton {...props} onlyIcon />}
        </Box>
    );
};

const ListActions = (props: { resource?: string }) => {
    const { isListActionEnabled } = useResourcePermissions(props);
    const { isListActionEnabled: isErrandListActionEnabled } = useResourcePermissions({ resource: 'errands' });

    // Put it here because hook needs access to FilterContext which is wrapped around ListToolbar
    useListFiltersExistsResetFix();

    return (
        <TopToolbar>
            <VisibleColumnsButton />
            {isErrandListActionEnabled('add_filter') && <FilterButton />}
            {isErrandListActionEnabled('create') && <CreateButton basePath="/errands" resource="errands" />}
            {isListActionEnabled('export') && <ErrandsConfigurableExportButton />}
            {isListActionEnabled('manual_sale') && <ManualSaleButton />}
        </TopToolbar>
    );
};

const BulkActions = (props: { record?: Reclamation; resource?: string }) => {
    const { isListActionEnabled, isEditActionEnabled, isFieldEditable } = useResourcePermissions(props);

    return (
        <>
            {isListActionEnabled('export') && <ErrandsConfigurableExportButton />}
            {isFieldEditable('status') && <BulkUpdateStatusButton source="status" resource={props.resource} />}
            {isEditActionEnabled('delete') && <BulkDeleteButton undoable={false} {...props} />}
        </>
    );
};

const DatagridRow: FC<DatagridRowProps> = (props) => {
    const resource = useResourceContext(props);
    const record = useRecordContext(props) as Reclamation;
    const [expanded, toggleExpanded] = useExpanded(resource, props.id!);
    const { filterValues } = useListContext();

    useEffect(() => {
        const values = Object.values(filterValues);
        if (expanded || values.length === 0) {
            return;
        }

        if (values.includes(record?.composedIdentifier)) {
            toggleExpanded();
        }
        // eslint-disable-next-line
    }, [JSON.stringify(filterValues), record?.composedIdentifier]);

    return <RaDatagridRow {...props} />;
};

const DatagridBody: FC<DatagridBodyProps> = (props) => {
    return <DatagridBodyWithMercure {...props} row={<DatagridRow />} />;
};

const Datagrid = (props: DatagridProps) => {
    const { selectedFields, schema } = useListGuesserControllerContext();

    return (
        <RaDatagrid
            {...props}
            body={DatagridBody}
            expand={<ExpandShowPanel hideFields={hideReclamationFields} />}
            hasBulkActions
        >
            {selectedFields.map((field) => (
                <ReclamationFieldGuesser
                    key={field.name}
                    source={field.name}
                    field={field}
                    sortable={isFieldSortable(field, schema)}
                />
            ))}
            <RowActions />
        </RaDatagrid>
    );
};

const ReclamationList = (props: ListProps) => {
    const isSmall = useIsSmallScreen();
    const filters = useFilters(props);
    const filterDefaultValues = useResourceFilterDefaultValues(['status'], props);

    return (
        <ListGuesser
            list={ListStickyToolbar}
            sort={{ field: 'id', order: 'desc' }}
            filters={filters}
            filterDefaultValues={filterDefaultValues}
            actions={<ListActions />}
            bulkActionButtons={<BulkActions />}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} />}
            {...props}
        >
            {isSmall ? (
                <SimpleListGuesser<Reclamation>
                    linkType={(record) => `/errands/${encodeURIComponent(record.errand)}/reclamations`}
                />
            ) : (
                <Datagrid />
            )}
        </ListGuesser>
    );
};

export default ReclamationList;
