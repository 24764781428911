import { useState } from 'react';
import { Box } from '@material-ui/core';
import { AutocompleteInput, ReferenceInput, ReferenceInputProps, required } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import QuickCreateReferenceButton from '@components/button/QuickCreateReferenceButton';
import InputGuesser from '@components/form/InputGuesser';

import { handleAutocompleteBlur } from '@js/utility/inputsUtility';
import useFormFields from '@js/hooks/useFormFields';
import { uniqueField } from '@js/validator/unique';

const getValidate = (field: Field) => {
    if (field.name === 'name') {
        return [required(), uniqueField(field.name, 'goods_types')];
    }

    return undefined;
};

const CreateButton = ({ onCreate }: { onCreate: () => void }) => {
    const fields = useFormFields({ resource: 'goods_types' });

    return (
        <Box mb="20px">
            <QuickCreateReferenceButton resource="goods_types" field="goods_type" onChange={onCreate}>
                {fields.map((field) => (
                    <InputGuesser
                        key={field.name}
                        source={field.name}
                        field={field}
                        validate={getValidate(field)}
                        fullWidth
                    />
                ))}
            </QuickCreateReferenceButton>
        </Box>
    );
};

const GoodsTypeInput = ({
    helperText,
    ...props
}: Omit<ReferenceInputProps, 'children'> & { source: string; helperText?: string | false }) => {
    const [version, setVersion] = useState(0);

    return (
        <Box display="flex" alignItems="center">
            <ReferenceInput
                key={version}
                reference="goods_types"
                sort={{ field: 'name', order: 'ASC' }}
                onBlur={handleAutocompleteBlur}
                {...props}
            >
                <AutocompleteInput optionText="name" helperText={helperText} />
            </ReferenceInput>
            <CreateButton onCreate={() => setVersion((v) => v + 1)} />
        </Box>
    );
};

export default GoodsTypeInput;
