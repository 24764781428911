import { Reclamation } from '@js/interfaces/reclamation';
import { Product } from '@js/interfaces/product';
import { useRecordContext, useTranslate } from 'react-admin';
import { useAppConfigContext } from '@js/context/AppConfigContext';
import useGetIdentity from '@js/hooks/useGetIdentity';

const useSelloDisableReason = (props: { record?: Reclamation | Product }) => {
    const record = useRecordContext(props);
    const { supportedSelloCountries } = useAppConfigContext();
    const { identity } = useGetIdentity();
    const translate = useTranslate();

    if (identity && record) {
        const disableReason =
            'split' in record && record.split
                ? 'resources.reclamations.sello_disable_reason.was_split'
                : 'sellOnPhysicalStore' in record && record.sellOnPhysicalStore
                ? 'resources.products.sello_disable_reason.sell_on_physical_store'
                : !supportedSelloCountries.includes(identity.country)
                ? translate('resources.reclamations.sello_disable_reason.country_not_supported', {
                      country: translate(`app.translation.country.${identity.country}`),
                  })
                : undefined;

        return {
            loading: false,
            disableReason,
        };
    }

    return {
        loading: true,
        disableReason: undefined,
    };
};

export default useSelloDisableReason;
