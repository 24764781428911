import { Dispatch, useEffect } from 'react';
import { useVersion } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { addLockRecordsAction, LockRecordsState } from '@js/redux/lockRecordsReducer';
import { get } from '@js/request/apiRequest';

import { LockRecord } from '@js/interfaces/lockrecord';
import { Iri } from '@js/interfaces/ApiRecord';
import { useAppConfigContext } from '@js/context/AppConfigContext';

const queries: Record<string, Iri[]> = {};

type GetLockReturnType = (LockRecord & { isValid: () => boolean }) | null | undefined;

const useGetLockRecord = (id: Iri | undefined | null): GetLockReturnType => {
    const lockRecord = useSelector<{ lockRecords: LockRecordsState }, LockRecord | null | undefined>(
        ({ lockRecords }) => {
            if (id && id in lockRecords) return lockRecords[id];
            return undefined;
        },
    );
    const dispatch = useDispatch();
    const version = useVersion();
    const { lockRecordsLifetimeHours } = useAppConfigContext();

    useEffect(() => {
        if (!id) return;

        const resource = id.split('/')[2];
        if (!Array.isArray(queries[resource])) {
            queries[resource] = [];
        }
        if (!queries[resource].includes(id)) {
            queries[resource].push(id);
        }

        callQueries(dispatch);
    }, [id, dispatch, version]);

    return lockRecord
        ? {
              ...lockRecord,
              isValid: () => {
                  const currentTime = new Date();
                  const lockRecordCreatedAt = new Date(lockRecord!.createdAt);

                  currentTime.setHours(currentTime.getHours() + lockRecordsLifetimeHours);
                  return currentTime > lockRecordCreatedAt;
              },
          }
        : lockRecord;
};

const callQueries = debounce((dispatch: Dispatch<any>) => {
    const resources = Object.keys(queries);

    resources.forEach((resource) => {
        const iris = queries[resource];
        delete queries[resource];

        if (iris.length === 0) {
            return;
        }

        get<{ 'hydra:member': LockRecord[] }>('/api/lock_records', {
            iri: iris,
        }).then(({ 'hydra:member': member }) => {
            dispatch(addLockRecordsAction(member, iris));
        });
    });
});

export default useGetLockRecord;
