import { useState } from 'react';
import { DateField, ListProps, ReferenceArrayField, SelectField, SingleFieldList } from 'react-admin';
import { useHistory } from 'react-router';

import CopyableTextField from '@js/components/field/CopyableTextField';
import FilePreviewField from '@components/field/FilePreviewField';
import QuantityField from './field/QuantityField';

import Aside from './Aside';
import SimpleProductList from './SimpleProductList';
import BundleProductList from './BundleProductList';

import { SplitType, SplitTypes } from '@js/interfaces/product';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

export type RouterState = {
    list: SplitType;
};

const SPLIT_TYPE_CHOICES = SplitTypes.map((type) => ({ id: type, name: `resources.products.splitType.${type}` }));

export default function ProductList(props: ListProps) {
    const { location } = useHistory<RouterState>();
    const [list, setList] = useState<SplitType>(location.state?.list ?? 'simple');
    const getFieldLabel = useTranslateResourceField('reclamations');
    const lists = {
        simple: SimpleProductList,
        bundle: BundleProductList,
    };
    const List = lists[list];

    return (
        <List {...props} aside={<Aside activeList={list} onListChange={setList} />} syncWithLocation={false}>
            <SelectField choices={SPLIT_TYPE_CHOICES} source="splitType" sortable={false} />
            <DateField source="selloSyncedAt" showTime sortable={false} />
            <QuantityField source="quantity" label={getFieldLabel('quantity')} />
            <CopyableTextField source="composedIdentifier" label={getFieldLabel('composedIdentifier')} />
            <ReferenceArrayField
                reference="reclamation_media_objects"
                source="images"
                label={getFieldLabel('images')}
                sortable={false}
            >
                <SingleFieldList linkType={false}>
                    <FilePreviewField source="paths.publicPath" />
                </SingleFieldList>
            </ReferenceArrayField>
        </List>
    );
}
