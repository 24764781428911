import { ReactElement, useEffect, useRef, useState } from 'react';
import {
    BulkActionsToolbar,
    ListBase,
    ListProps,
    ListToolbar,
    Pagination,
    Title,
    useListContext,
    useVersion,
} from 'react-admin';
import { Card, makeStyles, Theme, useScrollTrigger } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

interface Props extends ListProps {
    children: ReactElement;
}

const ListStickyToolbar = ({ children, ...props }: Props) => {
    return (
        <ListBase {...props}>
            <ListView {...props}>{children}</ListView>
        </ListBase>
    );
};

const ListView = ({ title, bulkActionButtons, actions, filters, children, pagination = <Pagination /> }: Props) => {
    const { selectedIds, defaultTitle } = useListContext();
    const [listToolbarHeight, setListToolbarHeight] = useState(0);
    const listToolbarRef = useRef<HTMLDivElement>(null);
    const scrollTrigger = useScrollTrigger({ disableHysteresis: true });
    const version = useVersion();
    const classes = useStyles({
        listToolbarHeight,
        scrollTrigger,
        bulkActionsDisplayed: selectedIds.length > 0,
    });

    useEffect(() => {
        if (!listToolbarRef.current) return;
        setListToolbarHeight(listToolbarRef.current.getBoundingClientRect().height);
    }, []);

    useEffect(() => {
        if (!listToolbarRef.current) return;

        const resizeObserver = new ResizeObserver((entries) => {
            setListToolbarHeight(entries[0].contentRect.height);
        });
        resizeObserver.observe(listToolbarRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <>
            <Title title={title} defaultTitle={defaultTitle} />
            <div className={classes.listToolbar} ref={listToolbarRef}>
                <ListToolbar filters={filters} actions={actions} />
            </div>
            <Card className={classes.content} key={version}>
                {bulkActionButtons && (
                    <div className={classes.bulkActionsToolbar}>
                        <BulkActionsToolbar>{bulkActionButtons}</BulkActionsToolbar>
                    </div>
                )}
                {children}
                {pagination}
            </Card>
        </>
    );
};

const useStyles = makeStyles<
    Theme,
    { listToolbarHeight: number; bulkActionsDisplayed: boolean; scrollTrigger: boolean }
>((theme) => ({
    content: {
        marginTop: (props) => (props.bulkActionsDisplayed ? -theme.spacing(8) : 0),
        transition: theme.transitions.create('margin-top'),
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
        },
        overflow: 'inherit',
        '& thead > tr > th': {
            top: (props) => props.listToolbarHeight,
        },
    },
    listToolbar: {
        position: 'sticky',
        top: 0,
        zIndex: 3,
        backgroundColor: (props) => (props.scrollTrigger ? lighten(theme.palette.primary.light, 0.85) : 'unset'),
    },
    bulkActionsToolbar: {
        position: 'sticky',
        top: (props) => props.listToolbarHeight - 64,
        zIndex: 3,
    },
}));

export default ListStickyToolbar;
