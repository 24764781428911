import { FlowItem, Reclamation } from '@js/interfaces/reclamation';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { LinearProgress, linkToRecord, useResourceContext, useTranslate } from 'react-admin';
import {
    Box,
    Button,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import useFetchGet from '@js/hooks/useFetchGet';
import useReferenceFieldLink from '@js/hooks/useReferenceFieldLink';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@material-ui/lab';
import { toLocaleString } from '@js/utility/dateUtility';
import TimelineLink from '@components/mui/TimelineLink';

type Props = {
    record: Reclamation;
    open: boolean;
    onClose: () => void;
    resource?: string;
};

const FlowDialog = ({ record, open, onClose, ...props }: Props) => {
    const isSmall = useIsSmallScreen();
    const translate = useTranslate();
    const resource = useResourceContext(props);

    return (
        <MuiDialog fullScreen={isSmall} fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle>{translate('app.label.flow')}</DialogTitle>
            <DialogContent>
                <Content record={record} resource={resource} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{translate('ra.action.close')}</Button>
            </DialogActions>
        </MuiDialog>
    );
};

const Content = ({ record, resource }: { record: Reclamation; resource: string }) => {
    const { loading, data: flow } = useFetchGet<FlowItem[]>(`${record.id}/flow`);
    const link = useReferenceFieldLink(resource);
    const classes = useContentStyles();
    const translate = useTranslate();

    if (!flow || loading) return <LinearProgress />;

    return (
        <Timeline>
            {flow.map((item) => (
                <TimelineItem key={item.id}>
                    <TimelineOppositeContent>
                        <Typography color="textSecondary">{toLocaleString(item.createdAt)}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color={item.isCurrent ? 'primary' : undefined} />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={classes.content}>
                        <Typography variant={item.isCurrent ? 'subtitle2' : 'body2'}>
                            {item.store}
                            {!!item.systemType && ` (${translate(`app.action.${item.systemType}`)})`}
                            {item.isCurrent && ` [${translate('app.label.current')}]`}
                        </Typography>
                        <Box mt={1}>
                            {link ? (
                                <TimelineLink to={linkToRecord(`/${resource}`, item.id, link)}>
                                    {item.composedIdentifier}
                                </TimelineLink>
                            ) : (
                                <Typography>{item.composedIdentifier}</Typography>
                            )}
                        </Box>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

const useContentStyles = makeStyles({
    content: {
        flex: 3,
    },
});

export default FlowDialog;
