import { useNotify, useTranslate } from 'react-admin';
import { useCallback } from 'react';

const parseErrorMessage = (error: unknown) => {
    if (error && typeof error === 'object' && 'response' in error && error['response'] instanceof Response) {
        const response = error['response'];
        const contentType = response.headers.get('content-type');

        if (
            contentType &&
            ['application/json', 'application/ld+json', 'application/problem+json'].some((ct) =>
                contentType.startsWith(ct),
            )
        ) {
            return response
                .json()
                .then(
                    (json) =>
                        json['hydra:description'] ||
                        json['hydra:title'] ||
                        json['message'] ||
                        json['error'] ||
                        response.statusText,
                );
        }
    }

    return typeof error === 'string' ? error : error instanceof Error ? error.message : undefined;
};

export const useParseHttpErrorAsString = () => {
    const translate = useTranslate();

    return useCallback(
        async (error: unknown) => {
            const message = await parseErrorMessage(error);

            return message ?? translate('ra.notification.http_error');
        },
        [translate],
    );
};

const useNotifyHttpError = () => {
    const notify = useNotify();

    return useCallback(
        async (error: unknown) => {
            if (error instanceof Error && error.name === 'AbortError') {
                return;
            }

            const message = await parseErrorMessage(error);

            notify(message ?? 'ra.notification.http_error', {
                type: 'warning',
                messageArgs: {
                    _: message,
                },
            });
        },
        [notify],
    );
};

export default useNotifyHttpError;
