import { FC } from 'react';
import { Box } from '@material-ui/core';
import { FormInput, required, SelectInput, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';

import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import { EVENT } from '@js/interfaces/notificationevent';

export const eventChoices = Object.values(EVENT).map((event) => ({
    id: event,
    name: `resources.notification_events.events.${event}`,
}));

interface Props {
    source: string;
    addLabel?: boolean;
}

const EventInput: FC<Props> = ({ source, ...props }) => {
    const translate = useTranslate();
    const {
        input: { value },
    } = useField(source, { subscription: { value: true } });

    const renderReference = () => {
        switch (value) {
            case EVENT.STATUS_CHANGE:
                return (
                    <FormInput
                        basePath={''}
                        input={
                            <ReferenceRecursiveSelectInput
                                label={translate('resources.notification_events.reference.status_changed_to')}
                                source="reference"
                                reference="statuses"
                            />
                        }
                        {...props}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box display="flex" gridGap={10}>
            <FormInput
                basePath={''}
                input={<SelectInput source="event" choices={eventChoices} allowEmpty validate={required()} />}
                {...props}
            />
            {renderReference()}
        </Box>
    );
};

EventInput.defaultProps = {
    source: 'event',
    addLabel: false,
};

export default EventInput;
