import { FC, ReactElement } from 'react';
import { useGetResourceLabel, useRecordContext, useResourceContext } from 'react-admin';
import { get } from 'lodash';

import { ApiRecord } from '@js/interfaces/ApiRecord';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import useResourceSchema from '@js/hooks/useResourceSchema';

interface Props {
    record?: ApiRecord;
    resource?: string;
    className?: string;
}

const RecordTitle: FC<Props> = ({ className, ...props }: Props): ReactElement | null => {
    const getResourceLabel = useGetResourceLabel();
    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const { schemaAnalyzer } = useHydraSchemaContext();
    const schema = useResourceSchema({ resource });

    if (!record?.id) {
        return null;
    }

    const id = record.id.toString().split('/').pop();
    const fieldName = schemaAnalyzer.getFieldNameFromSchema(schema);
    const title = get(record, fieldName, '');

    return (
        <span className={className}>{`${getResourceLabel(resource, 1)} - ${
            title ? `${title} #${id}` : record.id
        }`}</span>
    );
};

export default RecordTitle;
