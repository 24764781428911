import StoreIcon from '@material-ui/icons/Store';

import StoreShow from './StoreShow';
import StoreForm from './StoreForm';
import StoreList from './StoreList';

export default {
    list: StoreList,
    show: StoreShow,
    icon: StoreIcon,
    options: {
        form: StoreForm,
        alwaysHideFields: ['stores', 'serviceLevelAgreement'],
    },
};
