import { useCallback } from 'react';
import { Resource } from '@api-platform/api-doc-parser';
import useResourceSchema from '@js/hooks/useResourceSchema';

const useGetResourceField = (props: { resource?: string; schema?: Resource }) => {
    const schema = useResourceSchema(props);

    return useCallback(
        (source: string) => {
            const field = schema.fields?.find((field) => field.name === source);

            if (!field) {
                throw new Error(`Field ${source} not present inside ${schema.name} schema`);
            }

            return field;
        },
        [schema],
    );
};

export default useGetResourceField;
