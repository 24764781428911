type MediaOptions = {
    id: string;
    type: 'image' | 'video';
    url: string;
};

export class ExternalMedia {
    private readonly options: MediaOptions;

    constructor(options: MediaOptions) {
        this.options = options;
    }

    get id(): string {
        return this.options.id;
    }

    get type(): 'image' | 'video' {
        return this.options.type;
    }

    get url(): string {
        return this.options.url;
    }

    toJSON(): string {
        return JSON.stringify(this.options);
    }
}
