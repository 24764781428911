import { ProcessingTime } from '@js/interfaces/processingtime';
import { Identifier } from 'react-admin';

export enum AutoProcessingTimeType {
    Create = 'create',
    Edit = 'edit',
    Cancel = 'cancel',
}

export interface AutoProcessingTime extends ProcessingTime {
    errand?: Identifier;
    reclamation?: Identifier;
    type: AutoProcessingTimeType;
}
