import { FC } from 'react';
import { BooleanField, ListProps, SearchInput } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';

import StoresField from '../user_groups/field/StoresField';
import RolesField from './field/RolesField';
import LocaleField from '@components/field/LocaleField';
import CountryField from '@components/field/CountryField';

const filters = [<SearchInput key="q" source="q" alwaysOn autoComplete="off" />];

const UserList: FC<ListProps> = (props) => {
    return (
        <ListGuesser {...props} filters={filters} hideFields={['statusChangeRules']}>
            <DatagridGuesser>
                <RolesField source="roles" />
                <StoresField perPage={10} />
                <BooleanField looseValue source="archivedAt" />
                <LocaleField source="locale" />
                <CountryField source="country" />
            </DatagridGuesser>
        </ListGuesser>
    );
};

export default UserList;
