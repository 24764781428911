import { useState } from 'react';
import { Box } from '@material-ui/core';
import { AutocompleteInput, ReferenceInput, ReferenceInputProps, required } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import QuickCreateReferenceButton from '@components/button/QuickCreateReferenceButton';
import InputGuesser from '@components/form/InputGuesser';

import useFormFields from '@js/hooks/useFormFields';
import { uniqueField } from '@js/validator/unique';
import { handleAutocompleteBlur } from '@js/utility/inputsUtility';

const getValidate = (field: Field) => {
    if (field.name === 'name') {
        return [required(), uniqueField(field.name, 'models')];
    }

    return undefined;
};

const Create = (props: { onChange: () => void; field: string }) => {
    const fields = useFormFields({ resource: 'models' });

    return (
        <Box mb="20px">
            <QuickCreateReferenceButton resource="models" {...props}>
                {fields.map((field) => (
                    <InputGuesser
                        key={field.name}
                        source={field.name}
                        field={field}
                        validate={getValidate(field)}
                        fullWidth
                    />
                ))}
            </QuickCreateReferenceButton>
        </Box>
    );
};

const ModelInput = ({
    helperText,
    ...props
}: Omit<ReferenceInputProps, 'children'> & { source: string; helperText?: string | false }) => {
    const [version, setVersion] = useState(0);

    return (
        <Box display="flex" alignItems="center">
            <ReferenceInput
                key={version}
                reference="models"
                sort={{ field: 'name', order: 'ASC' }}
                allowEmpty
                emptyValue={null}
                onBlur={handleAutocompleteBlur}
                {...props}
            >
                <AutocompleteInput optionText="name" helperText={helperText} />
            </ReferenceInput>
            <Create onChange={() => setVersion((v) => v + 1)} field={props.source} />
        </Box>
    );
};

export default ModelInput;
