import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, useResourceContext, useTranslate, ListActionsProps } from 'react-admin';
import {
    Button as MuiButton,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup,
    FormControlLabel,
    Box,
} from '@material-ui/core';
import { chunk } from 'lodash';

import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useListColumnContext } from '@js/context/ListColumnsContext';
import { useListGuesserControllerContext } from '@components/list/ListGuesser';

type Props = ListActionsProps & {
    fieldTranslationResource?: string;
};

const VisibleColumnsButton: FC<Props> = ({ fieldTranslationResource, ...props }) => {
    const { selectedColumns, fields } = useListGuesserControllerContext();
    const [selected, setSelected] = useState(selectedColumns);
    const [open, setOpen] = useState(false);
    const isSmallScreen = useIsSmallScreen();
    const resource = useResourceContext(props);
    const translate = useTranslate();
    const translateField = useTranslateResourceField(fieldTranslationResource);
    const { saveResourceColumns } = useListColumnContext();

    useEffect(() => {
        if (selected.length === 0 && selectedColumns.length > 0) {
            setSelected(selectedColumns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumns]);

    if (isSmallScreen) {
        return null;
    }

    const handleClose = () => {
        const selectionChangedAndValid =
            selected.length > 0 &&
            (selected.length !== selectedColumns.length ||
                selected.filter((columnName) => !selectedColumns.includes(columnName)).length > 0);

        if (selectionChangedAndValid) {
            saveResourceColumns(resource, [...selected]);
        } else {
            // Reset local selection state if nothing changed or nothing selected
            setSelected(selectedColumns);
        }

        setOpen(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;

        if (event.target.checked) {
            setSelected((selected) => [...selected, name]);
        } else {
            setSelected((selected) => selected.filter((item) => item !== name));
        }
    };

    return (
        <>
            <Button label="app.action.visible_columns" onClick={() => setOpen(true)}>
                <ViewColumnIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="xl">
                <DialogTitle>{translate('app.action.visible_columns')}</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexWrap="wrap">
                        {chunk(fields, 8).map((fields) => (
                            <FormGroup key={fields.map((field) => field.name).join('-')}>
                                {fields.map((field) => {
                                    return (
                                        <FormControlLabel
                                            key={field.name}
                                            control={
                                                <Checkbox
                                                    checked={selected.includes(field.name)}
                                                    onChange={handleChange}
                                                    name={field.name}
                                                    color="primary"
                                                />
                                            }
                                            label={translateField(field.name)}
                                        />
                                    );
                                })}
                            </FormGroup>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <MuiButton onClick={handleClose} color="primary" disabled={selected.length === 0}>
                        {translate('ra.action.close')}
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default VisibleColumnsButton;
