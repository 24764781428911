import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type Column = string;

type ColumnsInStore = Record<string, Column[]>;

interface ListColumnsContextValue {
    columns: ColumnsInStore;
    saveResourceColumns: (resource: string, columns: Column[]) => void;
}

const STORAGE_KEY = 'app/resource_columns';

const getFromStoreOrDefault = (): ColumnsInStore => {
    const serializedState = window.localStorage.getItem(STORAGE_KEY);
    const defaultState = {};

    if (serializedState) {
        try {
            return JSON.parse(serializedState) || defaultState;
        } catch (error) {
            console.error(`Unable to parse resource columns state "${serializedState}"`);
        }
    }

    return defaultState;
};

const saveInStore = (state: ColumnsInStore) => {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
};

const ListColumnsContext = createContext<ListColumnsContextValue>(undefined!);

export const ListColumnContextProvider = ({ children }: { children: ReactNode }) => {
    const [columns, setColumns] = useState(() => getFromStoreOrDefault());

    const value = useMemo<ListColumnsContextValue>(
        () => ({
            columns,
            saveResourceColumns: (resource, resourceColumns) => {
                const newState = {
                    ...columns,
                    [resource]: resourceColumns,
                };

                saveInStore(newState);
                setColumns(newState);
            },
        }),
        [columns],
    );

    return <ListColumnsContext.Provider value={value}>{children}</ListColumnsContext.Provider>;
};

export const useListColumnContext = (): ListColumnsContextValue => {
    return useContext(ListColumnsContext);
};
