import { AutocompleteInput, ReferenceInput, useGetResourceLabel, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { CircularProgress } from '@material-ui/core';

import List from '@components/Dashboard/List';
import DatagridLoading from '@components/list/DatagridLoading';

import useFetchGet from '@js/hooks/useFetchGet';
import { secondsToHumanTime } from '@js/utility/numberFormatUtility';
import { useFormatPrice } from '@js/hooks/useFormatPrice';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

import { Iri } from '@js/interfaces/ApiRecord';
import { EconomicalDetailedPageSection } from '@js/auth/UserPermissionsService';

type EconomicalDetailedPayload = {
    average_sales_price: number;
    total_reclamation_processing_time: number;
    total_errand_processing_time: number;
};

type UserProcessingTimePayload = {
    user_processing_time: number;
};

type Props = {
    filter?: {
        store?: string;
        after?: string;
        before?: string;
    };
};

const EconomicalValueList = ({ filter }: Props) => {
    const { data, loading } = useFetchGet<EconomicalDetailedPayload>('/api/dashboard/economical_detailed', filter);
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const formatPrice = useFormatPrice();
    const showUserProcessingTime = useIsSectionEnabled('user_processing_time');

    if (loading && !data) return <DatagridLoading nbFakeLines={2} />;
    if (!data) return null;

    const items = [
        {
            label: translate('app.label.average_sales_price'),
            value: formatPrice(data.average_sales_price),
        },
        {
            label: getResourceLabel('processing_times'),
            value: `${secondsToHumanTime(
                data.total_reclamation_processing_time + data.total_errand_processing_time,
            )} h`,
        },
    ];

    return (
        <>
            <List items={items} />
            {showUserProcessingTime && <UserProcessingTime filter={{ after: filter?.after, before: filter?.before }} />}
        </>
    );
};

const UserProcessingTime = ({ filter }: { filter: Omit<Props['filter'], 'store'> }) => {
    const fieldName = useResourceFieldName('users');

    return (
        <Form<{ user?: Iri }> onSubmit={() => {}}>
            {({ values: { user } }) => (
                <>
                    <ReferenceInput
                        source="user"
                        reference="users"
                        sort={{
                            field: 'firstName',
                            order: 'ASC',
                        }}
                    >
                        <AutocompleteInput
                            variant="standard"
                            optionText={fieldName}
                            helperText={false}
                            allowEmpty
                            resettable
                            fullWidth
                        />
                    </ReferenceInput>
                    <UserProcessingTimeList filter={{ ...filter, user }} />
                </>
            )}
        </Form>
    );
};

const UserProcessingTimeList = ({
    filter: { user, after, before },
}: {
    filter: { after?: string; before?: string; user?: Iri };
}) => {
    const translate = useTranslate();
    const { data, loading } = useFetchGet<UserProcessingTimePayload>(
        '/api/dashboard/user_processing_time',
        user
            ? {
                  user,
                  ...(after && { after }),
                  ...(before && { before }),
              }
            : undefined,
        { enabled: !!user },
    );
    const processingTime = data?.user_processing_time;

    const renderValue = () => {
        if (loading) return <CircularProgress size={14} />;
        if (typeof processingTime === 'undefined' || !user) return '---';
        return `${secondsToHumanTime(processingTime)} h`;
    };

    return (
        <List
            items={[
                {
                    label: translate('app.dashboard.section.user_processing_time'),
                    value: renderValue(),
                },
            ]}
        />
    );
};

const useIsSectionEnabled = (section: EconomicalDetailedPageSection) => {
    const permissions = useUserPermissions();
    return permissions.dashboard.isDetailedPageSectionEnabled('economical_detailed', section);
};

export default EconomicalValueList;
