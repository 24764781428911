import { FC } from 'react';

import ReferenceManyGrid from '@components/field/ReferenceManyGrid';
import { ContactPerson } from '@js/interfaces/contactperson';
import { Store } from '@js/interfaces/store';

const ContactPeopleDataGrid: FC = (props) => {
    return (
        <ReferenceManyGrid<ContactPerson, Store>
            {...props}
            reference="contact_people"
            target="store"
            initialValues={(record) => ({ store: record.id.toString() })}
        />
    );
};

export default ContactPeopleDataGrid;
