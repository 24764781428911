import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';

import ProductForm from './ProductForm';
import useOnSuccess from './useOnSuccess';

const ProductCreate: FC<CreateProps> = (props) => {
    const onSuccess = useOnSuccess('created', props.basePath);

    return (
        <Create {...props} onSuccess={onSuccess}>
            <ProductForm />
        </Create>
    );
};

export default ProductCreate;
