import { FC } from 'react';
import { useField } from 'react-final-form';
import { Box } from '@material-ui/core';
import { Identifier, Record, useRecordContext, useResourceContext, Validator } from 'react-admin';

import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import StatusNotificationsDialogButton from './StatusNotificationsDialogButton';

import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useFetchGet from '@js/hooks/useFetchGet';

interface Props {
    source: string;
    resource?: string;
    label?: string;
    fullWidth?: boolean;
    record?: Record;
    validate?: Validator | Validator[];
    helperText?: string | false;
}

interface PropsWithNotification extends Props {
    statusNotificationsSource?: string;
}

export const StatusInput = (props: Props) => {
    const { items, loading } = useDisabledStatuses(props);

    const disabledOption = (item: Record) => {
        return items.includes(item.id);
    };

    return (
        <ReferenceRecursiveSelectInput
            {...props}
            disabled={loading}
            loading={loading}
            disabledOption={disabledOption}
        />
    );
};

export const StatusInputWithNotification: FC<PropsWithNotification> = ({
    source,
    statusNotificationsSource = 'statusNotifications',
    ...props
}) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);
    const {
        input: { value },
    } = useField(source, { subscription: { value: true } });

    const statusInput = <StatusInput source={source} {...props} />;

    return (
        <>
            {permissions.isFieldEditable(resource, statusNotificationsSource) ? (
                <Box display="flex" alignItems="baseline">
                    {statusInput}
                    <StatusNotificationsDialogButton source={statusNotificationsSource} disabled={!value} {...props} />
                </Box>
            ) : (
                statusInput
            )}
        </>
    );
};

const useDisabledStatuses = (props: Pick<Props, 'resource' | 'record'>) => {
    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const permissions = useUserPermissions();

    const enableLoading = resource === 'errands' && !!record?.id && !permissions.isAdmin;
    const { data, loading } = useFetchGet<{ items: Identifier[] }>(
        `${record?.id}/disabled_statuses`,
        {},
        { enabled: enableLoading },
    );

    return {
        items: data?.items ?? [],
        loading,
    };
};
