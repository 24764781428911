import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isMenuItemGroup, MenuItem } from '@js/menu/menuBuilder';

interface ExpandedState {
    [key: string]: boolean;
}

type ReturnType = [ExpandedState, (group: string) => void];

const useExpandedState = (menuItems: MenuItem[]): ReturnType => {
    const { pathname } = useLocation();
    const [expanded, setExpanded] = useState<ExpandedState>(() => {
        const activeResource = pathname
            .split('/')
            .filter((v) => !!v)
            .shift();

        return menuItems.filter(isMenuItemGroup).reduce<ExpandedState>((prev, { name, items }) => {
            prev[name] = !!(activeResource && items.some((r) => r === activeResource));

            return prev;
        }, {});
    });

    const toggleExpanded = (key: string) => {
        setExpanded({
            ...expanded,
            [key]: !expanded[key],
        });
    };

    return [expanded, toggleExpanded];
};

export default useExpandedState;
