import { FC, useEffect } from 'react';
import { SelectInput, SelectInputProps, useGetMany } from 'react-admin';
import { useField } from 'react-final-form';
import { UserGroup } from '@js/interfaces/usergroup';
import { Iri } from '@js/interfaces/ApiRecord';

type Props = Omit<SelectInputProps, 'choices'>;

const DefaultProcessingTimeUserGroupInput: FC<Props> = (props) => {
    const {
        input: { value: userGroupsValue },
    } = useField('userGroups', {
        subscription: { value: true },
    });
    const { input } = useField(props.source!);

    // User "userGroups" IRIs and defaultProcessingTimeUserGroup for choices
    const userGroups: Set<Iri> = new Set([...(userGroupsValue || [])]);
    if (input.value) {
        userGroups.add(input.value);
    }

    const { loading, loaded, data } = useGetMany('user_groups', [...userGroups], {
        enabled: userGroups.size > 0,
    }) as {
        data: Omit<UserGroup, '@id'>[];
        loading: boolean;
        loaded: boolean;
    };
    const choices = data.filter(Boolean).map(({ id, name }) => ({
        id,
        name,
    }));

    useEffect(() => {
        if (Array.isArray(userGroupsValue) && input.value && !userGroupsValue.includes(input.value)) {
            input.onChange(null);
        }
    }, [userGroupsValue, input]);

    return <SelectInput allowEmpty emptyValue={null} {...props} choices={choices} loading={loading} loaded={loaded} />;
};

DefaultProcessingTimeUserGroupInput.defaultProps = {
    source: 'defaultProcessingTimeUserGroup',
};

export default DefaultProcessingTimeUserGroupInput;
