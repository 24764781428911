import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps, useRecordContext } from 'react-admin';

import { Status } from '@js/interfaces/status';
import { Theme } from '@material-ui/core';

interface Props {
    size?: ButtonProps['size'];
    record?: Status;
    onClick?: () => void;
    source?: string;
}

const useStyles = makeStyles<Theme, Props>({
    root: ({ onClick }) => ({
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: onClick ? 'pointer' : 'default',
        },
    }),
});

const PreviewField: FC<Props> = ({ onClick, size = 'medium', ...props }) => {
    const classes = useStyles({ onClick });
    const record = useRecordContext(props);

    if (!record) {
        return null;
    }

    const { label, textColor, backgroundColor } = record;

    return (
        <Button
            variant="contained"
            color="secondary"
            style={{ color: textColor, backgroundColor }}
            size={size}
            onClick={onClick}
            className={classes.root}
        >
            {label}
        </Button>
    );
};

export default PreviewField;
