import { FC } from 'react';
import { useLocale, useTranslate } from 'react-admin';

import List from '@components/Dashboard/List';
import { Saving } from '@js/interfaces/Saving';

type Props = {
    savings: Record<Saving, number>;
};

const ListRecordSavings: FC<Props> = ({ savings }) => {
    const locale = useLocale();
    const translate = useTranslate();

    const items = (Object.entries(savings) as Array<[Saving, number]>).map(([saving, value]) => ({
        label: translate(`app.co2.savings.${saving}`),
        value: value.toLocaleString(locale),
    }));

    return <List items={items} />;
};

export default ListRecordSavings;
