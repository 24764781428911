import { FunctionField, Record as RaRecord } from 'react-admin';
import { get } from 'lodash';
import { secondsToHumanTime } from '@js/utility/numberFormatUtility';

type Props = {
    source: string;
    record?: RaRecord;
    resource?: string;
    label?: string;
    addLabel?: boolean;
    sortable?: boolean;
    formatTime?: (value: number) => string;
};

const defaultFormatTime = (value: number) => `${secondsToHumanTime(value)} h`;

const SecondsTimeField = ({ formatTime = defaultFormatTime, ...props }: Props) => {
    return (
        <FunctionField
            {...props}
            render={(record) => {
                const value = get(record, props.source);
                return formatTime(value);
            }}
        />
    );
};

export default SecondsTimeField;
