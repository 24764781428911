import { render } from 'react-dom';
import * as Sentry from '@sentry/react';

import Admin from './js/Admin';
import { loadViteEnv } from '@js/utility/envUtil';

import './styles/app.css';

const viteEnv = loadViteEnv();

// If sentry DSN is not set, disable Sentry. This is useful for development and stage.
const enabledSentry = viteEnv.MODE === 'production' && !!viteEnv.VITE_SENTRY_DSN;

Sentry.init({
    dsn: viteEnv.VITE_SENTRY_DSN,
    integrations: [
        /*
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
        */
    ],
    // Performance Monitoring
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    enabled: enabledSentry,
});

const rootNode = document.getElementById('api-platform-admin');

render(<Admin />, rootNode);
