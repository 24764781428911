import { createElement, FC } from 'react';
import { Edit, EditProps } from 'react-admin';

import SimpleFormGuesser from '@components/form/SimpleFormGuesser';
import RecordTitle from '@components/detail/RecordTitle';

import useResourceDefinition from '@js/hooks/useResourceDefinition';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

const EditGuesser: FC<EditProps> = ({ children, ...props }) => {
    const { options } = useResourceDefinition(props);
    const notifyFailure = useNotifyHttpError();

    return (
        <Edit title={<RecordTitle />} onFailure={notifyFailure} {...props}>
            {createElement(options?.form ?? SimpleFormGuesser, {}, children)}
        </Edit>
    );
};

export default EditGuesser;
