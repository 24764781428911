import { Box } from '@material-ui/core';
import { useGetResourceLabel } from 'react-admin';

import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';
import DateRangeInput from '../input/DateRangeInput';

export type FilterValues = {
    store?: string;
    after?: string;
    before?: string;
};

const Filters = () => {
    const getResourceLabel = useGetResourceLabel();

    return (
        <Box display="flex" alignItems="center" gridGap="20px">
            <HierarchicalAutocompleteSelectInput
                label={getResourceLabel('stores')}
                reference="stores"
                source="store"
                variant="standard"
            />
            <DateRangeInput />
        </Box>
    );
};

export default Filters;
