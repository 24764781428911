import { useState } from 'react';
import {
    Datagrid,
    DateField,
    DateTimeInput,
    maxValue,
    minValue,
    NumberInput,
    Record as RaRecord,
    ReferenceManyField,
    required,
    TextField,
    TextInput,
    useGetOne,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';

import RecordPriceField from '@components/field/RecordPriceField';
import DeleteIconButton from '@components/button/DeleteIconButton';
import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import UpdateDialogForm from '@components/form/UpdateDialogForm';
import IconButton from '@components/button/IconButton';
import PassthroughProps from '@components/PassthroughProps';

import decimal from '@js/validator/decimal';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useSalesContextFilter from '@components/resource/reclamation/useSalesContextFilter';
import { ManualSale } from '@js/interfaces/ManualSale';
import { Reclamation } from '@js/interfaces/reclamation';

type Props = {
    target: string;
    record?: RaRecord;
    resource?: string;
    basePath?: string;
};

const ManualSales = (props: Props) => {
    const { warning, filter } = useSalesContextFilter();

    return (
        <ReferenceManyField
            {...props}
            reference="manual_sales"
            sort={{ field: 'soldAt', order: 'DESC' }}
            pagination={<AutoHidePagination />}
            filter={filter}
        >
            <PassthroughProps>
                {(props) => (
                    <>
                        {warning}
                        <Datagrid {...props} empty={<AlertEmptyResource mb={2} />}>
                            <DateField source="soldAt" showTime />
                            <RecordPriceField source="salesPrice" currencyField="currency" sortable={false} />
                            <TextField source="quantity" sortable={false} />
                            <TextField source="channel" sortable={false} />
                            <RowActions />
                        </Datagrid>
                    </>
                )}
            </PassthroughProps>
        </ReferenceManyField>
    );
};

const RowActions = (props: { record?: ManualSale; resource?: string }) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const getFieldLabel = useTranslateResourceField(resource);
    const translate = useTranslate();
    const { data: reclamation } = useGetOne<Reclamation>('reclamations', record?.reclamation);

    if (!record || !reclamation) return null;

    // Because we are editing existing sale, it's quantity is already subtracted from the stock,
    // add it back to know the stock without this sale
    const inStock = (reclamation.inStockQuantity ?? 0) + record.quantity;

    return (
        <>
            <Box display="flex" justifyContent="end">
                <IconButton label="ra.action.edit" onClick={() => setOpen(true)}>
                    <EditIcon />
                </IconButton>
                <DeleteIconButton {...props} />
            </Box>
            <UpdateDialogForm<ManualSale>
                title="ra.action.edit"
                record={record}
                resource={resource}
                open={open}
                onClose={() => setOpen(false)}
                mutationMode="optimistic"
                initialValues={{
                    salesPricePerItem: record.salesPricePerItem,
                    channel: record.channel,
                    quantity: record.quantity,
                    soldAt: record.soldAt,
                }}
            >
                <NumberInput
                    label={getFieldLabel('salesPrice')}
                    source="salesPricePerItem"
                    step="0.01"
                    validate={[decimal(), required(), minValue(1)]}
                    fullWidth
                    resource={resource}
                    helperText={translate('app.form.helperText.price_per_item_input')}
                />
                <NumberInput
                    source="quantity"
                    step="1"
                    validate={[required(), minValue(1), maxValue(inStock)]}
                    fullWidth
                    resource={resource}
                    helperText={translate('app.label.max_value', { value: inStock })}
                />
                <TextInput source="channel" validate={required()} fullWidth resource={resource} />
                <DateTimeInput source="soldAt" validate={required()} fullWidth resource={resource} />
            </UpdateDialogForm>
        </>
    );
};

export default ManualSales;
