const defaultHeaders = {
    Accept: 'application/ld+json',
};

export type GetParams = Record<string, string | string[] | boolean | number | null | undefined>;

const createUrl = (path: string | URL, params: GetParams = {}) => {
    const url = new URL(path, window.location.origin);

    for (const [name, value] of Object.entries(params)) {
        if (value === null || value === undefined) continue;

        if (Array.isArray(value)) {
            value.forEach((v) => {
                url.searchParams.append(`${name}[]`, v);
            });
        } else {
            url.searchParams.append(name, value.toString());
        }
    }

    return url;
};

export const doFetch = async (url: string | URL, options: RequestInit): Promise<Response> => {
    const { headers, ...restOptions } = options;

    options = {
        credentials: 'same-origin',
        ...restOptions,
        headers: {
            ...defaultHeaders,
            ...(!(options?.body instanceof FormData) && { 'Content-Type': 'application/ld+json' }),
            ...headers,
        },
    };

    return await fetch(url, options);
};

const parseResponse = async (response: Response) => {
    if (response.status === 204) {
        return null;
    }

    const json = await response.json();
    if (!response.ok) {
        const message = json['message'] ?? json['hydra:description'] ?? json['hydra:title'] ?? json['error'];

        throw new Error(message ?? 'Server error');
    }

    return json;
};

export const deleteFetch = async (url: string | URL, options: RequestInit = {}): Promise<null> => {
    const response = await doFetch(url, {
        method: 'DELETE',
        ...options,
    });

    return await parseResponse(response);
};

export const post = async <T>(url: string | URL, options: RequestInit = {}): Promise<T> => {
    const response = await doFetch(url, {
        method: 'POST',
        ...options,
    });

    return await parseResponse(response);
};

export const get = async <T>(path: string | URL, params: GetParams = {}, options: RequestInit = {}): Promise<T> => {
    const url = createUrl(path, params);

    const response = await doFetch(url, {
        method: 'GET',
        ...options,
    });

    return await parseResponse(response);
};

export const downloadCsvStream = async (path: string, params?: GetParams) => {
    const url = createUrl(path, params);

    const link = document.createElement('a');
    link.href = url.toString();
    link.target = '_blank';
    link.hidden = true;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
