import { useLocation } from 'react-router-dom';

const useReturnUrlPath = (defaultPath?: string) => {
    const { search } = useLocation();

    return parseReturnUrl(search) ?? defaultPath;
};

const parseReturnUrl = (search: string) => {
    const searchParams = new URLSearchParams(search);

    if (searchParams.has('returnUrl')) {
        return searchParams.get('returnUrl');
    }

    return null;
};

export default useReturnUrlPath;
