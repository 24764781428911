import {
    FieldTitle,
    minValue,
    Record as RaRecord,
    useInput,
    useTranslate,
    ValidationError,
    Validator,
} from 'react-admin';
import { makeStyles, TextField } from '@material-ui/core';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';

type Props = {
    source?: string;
    record?: RaRecord;
    resource?: string;
    fullWidth?: boolean;
    warningAfter?: number;
    step?: string;
    validate?: Validator | Validator[];
    label?: string | false;
};

const useStyles = makeStyles((theme) => {
    const warningColor = theme.palette.warning.dark;

    return {
        warning: {
            '& .MuiFormLabel-root': {
                color: warningColor,
            },
            '& .MuiFormHelperText-root': {
                color: warningColor,
            },
            '& .MuiFilledInput-underline:after': {
                borderBottomColor: warningColor,
                transform: 'scaleX(1)',
            },
            '& .MuiInputBase-input': {
                color: warningColor,
            },
        },
    };
});

const convertStringToNumber = (value: string) => {
    const float = parseFloat(value);

    return isNaN(float) ? null : float;
};

const WeightInput = ({ label, validate, warningAfter = 1000, step = '0.01', source = 'weight', ...props }: Props) => {
    const min = 0.01;

    const finalValidate = useInputAutoValidator({ source, validate }) ?? [];
    finalValidate.push(minValue(min));

    const {
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        ...props,
        source,
        step,
        validate: finalValidate,
        parse: convertStringToNumber,
        type: 'number',
        min,
    });
    const translate = useTranslate();
    const classes = useStyles();

    const showWarning = input.value > warningAfter;
    const hasError = !!(touched && (error || submitError));
    const { resource } = props;

    return (
        <TextField
            type="number"
            label={
                label !== '' &&
                label !== false && (
                    <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
                )
            }
            className={showWarning ? classes.warning : undefined}
            variant="filled"
            margin="dense"
            error={hasError}
            helperText={
                hasError ? (
                    <ValidationError error={error} />
                ) : (
                    <>
                        {translate('app.form.helperText.weight_in_kg')}
                        {showWarning && (
                            <>
                                <br />
                                {translate('app.form.helperText.too_big_weight')}
                            </>
                        )}
                    </>
                )
            }
            fullWidth={props.fullWidth}
            inputProps={{ step, min }}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            {...input}
        />
    );
};

export default WeightInput;
