import { FC } from 'react';
import { Edit, EditActionsProps, EditProps, ShowButton, TopToolbar } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AlertLockEditRecord from '@components/detail/AlertLockEditRecord';
import ErrandForm from './ErrandForm';

import useLockRecord from '@js/hooks/useLockRecord';
import useMercureResourceSubscription from '@js/hooks/useMercureResourceSubscription';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

const useStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

const ErrandEditActions: FC<EditActionsProps> = ({ basePath, data }) => {
    const classes = useStyles();

    return (
        <>
            <TopToolbar className={classes.toolbar}>
                <div id="errand-filter-form" />
                <span />
                <Box display="flex">
                    <div id="errand-filter-button" />
                    <ShowButton basePath={basePath} record={data} />
                </Box>
            </TopToolbar>
            {data && (
                <Box mb={1}>
                    <AlertLockEditRecord id={data.id} />
                </Box>
            )}
        </>
    );
};

const ErrandEdit: FC<EditProps> = (props) => {
    useMercureResourceSubscription('errands', props.id);
    useLockRecord(props.id);

    const notifyFailure = useNotifyHttpError();

    return (
        <Edit {...props} mutationMode="pessimistic" actions={<ErrandEditActions />} onFailure={notifyFailure}>
            <ErrandForm />
        </Edit>
    );
};

export default ErrandEdit;
