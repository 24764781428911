import { FC, ReactNode, useEffect, useState } from 'react';
import { Layout as RaLayout, LayoutProps, useCheckAuth } from 'react-admin';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import AppBar from './AppBar';
import Menu from './Menu';
import Error from './Error';

import { ZebraPrinterContextProvider } from '@js/context/ZebraPrinterContext';
import { HydraSchemaContextProvider } from '@js/context/HydraSchemaContext';
import { UserPermissionsContextProvider } from '@js/context/UserPermissionsContext';
import { ListColumnContextProvider } from '@js/context/ListColumnsContext';

import useLocaleChangeController from './useLocaleChangeController';
import useThemeController from './useThemeController';
import useUserZebraPrintSubscription from './useUserZebraPrintSubscription';
import useLockRecordMercureSubscription from './useLockRecordMercureSubscription';

import useGetIdentity from '@js/hooks/useGetIdentity';
import { isUserLoggedIn } from '@js/auth/authProvider';

const LayoutWithControllers: FC<{ children: ReactNode }> = ({ children }) => {
    const { identity } = useGetIdentity();

    const themeReady = useThemeController(identity);

    useLocaleChangeController(identity);
    useUserZebraPrintSubscription(identity);
    useLockRecordMercureSubscription(identity);

    useEffect(() => {
        if (!identity) {
            Sentry.setUser(null);
            return;
        }

        Sentry.setUser({
            id: identity.id,
            email: identity.email,
            fullName: identity.fullName,
        });

        return () => {
            Sentry.setUser(null);
        };
    }, [identity]);

    if (!themeReady) {
        return null;
    }

    return <>{children}</>;
};

const Layout: FC<LayoutProps> = ({ children, title, ...props }) => {
    // Default Layout is rendered even if user is not logged in and redirect take place later,
    // this creates issue with "HydraSchemaContextProvider" and "UserPermissionsContextProvider" that depends on login in order to avoid null context values.
    // Do not render layout until user is logged in.
    const [canRender, setCanRender] = useState(isUserLoggedIn());
    const checkAuth = useCheckAuth();
    const history = useHistory();

    useEffect(() => {
        if (canRender) {
            return;
        }

        checkAuth({}, false)
            .then(() => {
                setCanRender(true);
            })
            .catch(() => {
                history.push('/login');
            });
    }, [checkAuth, history, canRender]);

    if (!canRender) {
        return null;
    }

    return (
        <ZebraPrinterContextProvider>
            <HydraSchemaContextProvider>
                <UserPermissionsContextProvider>
                    <ListColumnContextProvider>
                        <LayoutWithControllers>
                            <RaLayout {...props} title={title} appBar={AppBar} menu={Menu}>
                                <Sentry.ErrorBoundary fallback={(props) => <Error {...props} title={title} />}>
                                    {children}
                                </Sentry.ErrorBoundary>
                            </RaLayout>
                        </LayoutWithControllers>
                    </ListColumnContextProvider>
                </UserPermissionsContextProvider>
            </HydraSchemaContextProvider>
        </ZebraPrinterContextProvider>
    );
};

export default Layout;
