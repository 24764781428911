import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';

import ErrandForm from './ErrandForm';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

const ErrandCreate: FC<CreateProps> = (props) => {
    const notifyFailure = useNotifyHttpError();

    return (
        <Create onFailure={notifyFailure} {...props}>
            <ErrandForm />
        </Create>
    );
};

export default ErrandCreate;
