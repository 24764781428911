import { FC } from 'react';
import { SimpleFormProps, useTranslate } from 'react-admin';

import SimpleFormGuesser from '@components/form/SimpleFormGuesser';
import { ScrollableSelectInputGuesser } from '@components/form/InputGuesser';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

import EventInput from './EventInput';

const NotificationEventForm: FC<Omit<SimpleFormProps, 'children' | 'onSubmit'>> = (props) => {
    const translate = useTranslate();

    const validateForm: SimpleFormProps['validate'] = (values: Record<string, any>) => {
        if (!values.userRecipients?.length && !values.userGroupRecipients?.length) {
            const error = translate('app.label.choose_at_least_one', { option: '' });

            return {
                userRecipients: error,
                userGroupRecipients: error,
            };
        }

        return {};
    };

    return (
        <SimpleFormGuesser {...props} validate={validateForm}>
            <ScrollableSelectInputGuesser
                source="initiator"
                reference="user_groups"
                emptyLabel={translate('app.label.any')}
                allowEmpty
                shrink
            />
            <EventInput source="event" />
            <AutoCompleteGuesserInput source="userRecipients" />
            <AutoCompleteGuesserInput source="userGroupRecipients" />
        </SimpleFormGuesser>
    );
};

export default NotificationEventForm;
