import { useCallback, useState } from 'react';
import { SearchInput, SelectInput, useTranslate } from 'react-admin';
import { LocalizationLabel } from '@js/interfaces/localizationlabel';
import { Box } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { Form, FormSpy } from 'react-final-form';

import CheckboxInput from '@components/input/CheckboxInput';

import LabelTabRow from './LabelTabRow';
import RowCell from './RowCell';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useSortedLabeledResources } from '@js/context/HydraSchemaContext';

import { TranslationDomain, Locale } from '@js/context/AppConfigContext';

type Filters = { search?: string; resource?: string; onlyFields?: boolean };

const LocalesTabContent = ({
    labels,
    locales,
    domain,
}: {
    labels: LocalizationLabel[];
    locales: Locale[];
    domain: TranslationDomain;
}) => {
    const [filters, setFilters] = useState<Filters>({});
    const translateField = useTranslateResourceField();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetFilters = useCallback(
        debounce((values: Filters) => {
            setFilters(values);
        }, 300),
        [],
    );

    return (
        <>
            <Box display="flex" alignItems="center" gridGap={16}>
                <SearchFilters domain={domain} onFilterChange={debouncedSetFilters} />
            </Box>
            <Box display="flex" mt={2}>
                <RowCell variant="subtitle2">{translateField('key')}</RowCell>
                {locales.map((locale) => (
                    <RowCell key={locale} variant="subtitle2">
                        {translateField(`labels.${locale}`)}
                    </RowCell>
                ))}
                <RowCell type="actions" />
            </Box>
            {labels.map((label) => {
                return (
                    <LabelTabRow
                        key={label.id}
                        label={label}
                        locales={locales}
                        hidden={!isItemVisible(label, filters)}
                    />
                );
            })}
        </>
    );
};

const SearchFilters = ({
    domain,
    onFilterChange,
}: {
    domain: TranslationDomain;
    onFilterChange: (values: Filters) => void;
}) => {
    const isResourceDomain = domain === RESOURCE_DOMAIN;

    return (
        <Form onSubmit={() => {}}>
            {() => {
                return (
                    <>
                        <FormSpy
                            subscription={{ values: true, visited: true }}
                            onChange={({ values, visited }) => {
                                const formVisited = Object.values(visited ?? {}).filter(Boolean).length > 0;

                                if (!formVisited) {
                                    return;
                                }

                                onFilterChange(values);
                            }}
                        />
                        <SearchInput source="search" />
                        {isResourceDomain && <AdditionalResourceFields />}
                    </>
                );
            }}
        </Form>
    );
};

const AdditionalResourceFields = () => {
    const resources = useSortedLabeledResources();
    const translate = useTranslate();

    return (
        <>
            <SelectInput
                label={translate('resources.user_groups.label.resources')}
                source="resource"
                choices={resources}
                optionValue="name"
                optionText="label"
                helperText={false}
                resettable
            />
            <CheckboxInput source="onlyFields" label={translate('app.label.show_only_fields')} />
        </>
    );
};

const isItemVisible = (label: LocalizationLabel, filters: Filters) => {
    const { search, resource, onlyFields } = filters;

    const visibleBySearch = () => {
        if (!search) return true;

        const searchIn = [label.key, ...Object.values(label.labels)];
        return searchIn.some((item) => item.toLowerCase().includes(search));
    };

    const visibleByResource = () => {
        if (!resource) return true;
        return label.key.startsWith(resource);
    };

    const visibleByOnlyFields = () => {
        if (!onlyFields) return true;
        return label.key.includes('.fields.');
    };

    return visibleBySearch() && visibleByResource() && visibleByOnlyFields();
};

const RESOURCE_DOMAIN = 'resources';

export default LocalesTabContent;
