import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRecordContext, useTranslate } from 'react-admin';
import { Chip } from '@material-ui/core';

import { ROLES, User } from '@js/interfaces/user';

const useStyles = makeStyles({
    chip: {
        marginRight: '5px',
    },
});

const RolesField: FC<{ source?: string; addLabel?: boolean }> = (props) => {
    const record = useRecordContext<User>(props);
    const translate = useTranslate();
    const classes = useStyles();
    let roles: ROLES[] | undefined = record?.roles;

    if (!Array.isArray(roles)) {
        return null;
    }
    if (roles.length > 1) {
        roles = roles.filter((role) => role !== ROLES.ROLE_USER);
    }

    return (
        <>
            {roles.map((role) => (
                <Chip
                    key={role}
                    className={classes.chip}
                    label={translate(`resources.users.fields.roleChoices.${role.toLowerCase()}`)}
                />
            ))}
        </>
    );
};

RolesField.defaultProps = {
    source: 'roles',
    addLabel: true,
};

export default RolesField;
