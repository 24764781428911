import { Box, CardActions, Link, TextField } from '@material-ui/core';
import { Field } from 'react-final-form';
import { useNotify, useSafeSetState, useTranslate } from 'react-admin';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import LoginFormLayout, { ActionButton } from './LoginFormLayout';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';

interface ChangePasswordFormData {
    plainPassword: string;
    plainPasswordConfirm: string;
}

const ChangePasswordPage = () => {
    const [loading, setLoading] = useSafeSetState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const history = useHistory();

    const handleSubmit = async (values: ChangePasswordFormData) => {
        try {
            setLoading(true);

            const data = await post<{
                message: string;
            }>('/api/reset-password/reset', {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            notify(data.message, { type: 'info' });
            history.push('/login');
        } catch (error) {
            notifyError(error);
        } finally {
            setLoading(false);
        }
    };

    const validate = (values: ChangePasswordFormData) => {
        const errors: Partial<ChangePasswordFormData> = { plainPassword: undefined };

        if (!values.plainPassword) {
            errors.plainPassword = translate('ra.validation.required');
        } else if (values.plainPassword.length < 6) {
            errors.plainPassword = translate('ra.validation.minLength', { min: 6 });
        } else if (values.plainPassword !== values.plainPasswordConfirm) {
            errors.plainPasswordConfirm = translate('app.validation.repeat', { compareToField: 'Password' });
        }
        return errors;
    };

    return (
        <LoginFormLayout onSubmit={handleSubmit} validate={validate}>
            <Box paddingBottom={2} paddingX={2}>
                <Field
                    autoFocus
                    id="plainPassword"
                    name="plainPassword"
                    label={translate('resources.users.fields.password')}
                    disabled={loading}
                >
                    {({ meta: { touched, error }, input, ...props }) => (
                        <TextField
                            type="password"
                            required
                            error={!!(touched && error)}
                            helperText={touched && error}
                            {...input}
                            {...props}
                            fullWidth
                        />
                    )}
                </Field>
                <Field
                    id="plainPasswordConfirm"
                    name="plainPasswordConfirm"
                    label={translate('resources.users.fields.passwordRepeat')}
                    disabled={loading}
                >
                    {({ meta: { touched, error }, input, ...props }) => (
                        <TextField
                            type="password"
                            required
                            error={!!(touched && error)}
                            helperText={touched && error}
                            {...input}
                            {...props}
                            fullWidth
                        />
                    )}
                </Field>
            </Box>
            <CardActions>
                <ActionButton loading={loading} label="ra.action.confirm" />
            </CardActions>
            <Box display="flex" justifyContent="center">
                <Link component={RouterLink} variant="overline" to="/login">
                    {translate('ra.action.cancel')}
                </Link>
            </Box>
        </LoginFormLayout>
    );
};

export default ChangePasswordPage;
