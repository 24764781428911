import { FC, useState } from 'react';
import {
    Button as RaButton,
    LinearProgress,
    minValue,
    NumberInput,
    required,
    useLocale,
    useNotify,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton as MuiIconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Form } from 'react-final-form';

import SyncIcon from '@material-ui/icons/Sync';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import ActionCheck from '@material-ui/icons/CheckCircle';

import IconButton from '@components/button/IconButton';
import LoadingButton from '@components/button/LoadingButton';
import CheckboxChunkGroupInput from '@components/input/CheckboxChunkGroupInput';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useSelloDisableReason from '@js/hooks/useSelloDisableReason';
import useFetchGet from '@js/hooks/useFetchGet';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { deleteFetch, post } from '@js/request/apiRequest';
import { Reclamation } from '@js/interfaces/reclamation';
import { Product } from '@js/interfaces/product';
import { createSelloProductSearchUrl } from '@js/utility/sello';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';

type RecordItem = Reclamation | Product;

type SyncSelloButtonProps = {
    record?: RecordItem;
    resource?: string;
    uncheckFields?: SyncField[];
    excludeFields?: SyncField[];
    onClick?: () => void;
};

type SyncField = (typeof SYNC_FIELDS)[number];

const SYNC_FIELDS = [
    'title',
    'composedIdentifier',
    'inStockQuantity',
    'manufacturer',
    'supplierSerialNumber',
    'ean',
    'model',
    'grade',
    'totalPurchasePrice',
    'outgoingPrice',
    'insurancePrice',
    'classificationOutComment',
    'shortDescription',
    'longDescription',
    'images',
] as const;

const SYNC_FIELDS_OVERRIDE_KEY: Partial<Record<SyncField, string>> = {
    totalPurchasePrice: 'purchasePrice',
    inStockQuantity: 'quantity',
};

const SyncSelloButtonView: FC<SyncSelloButtonProps> = ({ excludeFields, uncheckFields, onClick, ...props }) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const { disableReason, loading } = useSelloDisableReason(props);

    if (loading || !record) return null;

    const handleClick = () => {
        setOpen(true);
        onClick?.();
    };

    return (
        <>
            <IconButton label="app.action.sello_sync" disabledReason={disableReason} onClick={handleClick}>
                <SyncIcon />
            </IconButton>
            {open && (
                <SelloDialog
                    record={record}
                    resource={resource}
                    onClose={() => setOpen(false)}
                    uncheckFields={uncheckFields}
                    excludeFields={excludeFields}
                />
            )}
        </>
    );
};

const Dialogs = ['sync_new', 'sync_existing_product', 'unsync'] as const;

type PossibleDialog = (typeof Dialogs)[number];

const SelloDialog = ({
    onClose,
    record,
    resource,
    uncheckFields,
    excludeFields,
}: { resource: string; record: RecordItem; onClose: () => void } & Pick<
    SyncSelloButtonProps,
    'uncheckFields' | 'excludeFields'
>) => {
    const [open, setOpen] = useState(true);
    const [dialog, setDialog] = useState<PossibleDialog>(
        record.selloExitingProductSync ? 'sync_existing_product' : 'sync_new',
    );
    const fullScreen = useIsSmallScreen();

    const handleClose = () => setOpen(false);

    return (
        <MuiDialog
            open={open}
            fullScreen={fullScreen}
            maxWidth="md"
            fullWidth
            onClose={handleClose}
            TransitionProps={{ onExited: onClose }}
        >
            <DialogTitle>
                <Box position="absolute" right={1} top={1}>
                    <MuiIconButton onClick={handleClose} disableRipple size="small">
                        <CloseIcon />
                    </MuiIconButton>
                </Box>
            </DialogTitle>
            {dialog === 'sync_new' ? (
                <SyncNewDialog
                    resource={resource}
                    record={record}
                    onClose={onClose}
                    onDialogChange={setDialog}
                    dialog={dialog}
                    unchecked={uncheckFields}
                    exclude={excludeFields}
                />
            ) : dialog === 'sync_existing_product' ? (
                <SyncExistingProductDialog
                    record={record}
                    dialog={dialog}
                    onDialogChange={setDialog}
                    onClose={handleClose}
                />
            ) : dialog === 'unsync' ? (
                <UnsyncDialog record={record} onClose={handleClose} onDialogChange={setDialog} />
            ) : null}
        </MuiDialog>
    );
};

type DialogSelectProps = {
    dialog: PossibleDialog;
    onDialogChange: (value: PossibleDialog) => void;
};

const DialogSelect = ({ dialog, onDialogChange }: DialogSelectProps) => {
    const translate = useTranslate();

    const items: Partial<Record<PossibleDialog, string>> = {
        sync_new: translate('app.label.sello_new_sync'),
        sync_existing_product: translate('app.label.sello_sync_existing_product'),
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="select-sello-sync-action">{translate('app.action.sello_sync')}</InputLabel>
            <Select
                labelId="select-sello-sync-action"
                value={dialog}
                onChange={(e) => onDialogChange(e.target.value as PossibleDialog)}
            >
                {Object.entries(items).map(([key, label]) => (
                    <MenuItem key={key} value={key}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const SyncNewDialog = ({
    onClose,
    resource,
    record,
    dialog,
    onDialogChange,
    unchecked = [],
    exclude = [],
}: {
    resource: string;
    record: RecordItem;
    onClose: () => void;
    unchecked?: SyncField[];
    exclude?: SyncField[];
} & DialogSelectProps) => {
    const permissions = useUserPermissions();
    const translate = useTranslate();
    const translateResourceField = useTranslateResourceField('reclamations');
    const notifyError = useNotifyHttpError();

    const fields = SYNC_FIELDS.filter((field) => {
        const overrideField = SYNC_FIELDS_OVERRIDE_KEY[field] || field;
        return permissions.isFieldEditable(resource, overrideField) && !exclude.includes(field);
    });
    const defaultChecked = fields.filter((field) => !unchecked.includes(field));

    const handleSubmit = async (data: { fields: SyncField[] }) => {
        try {
            await post(`${record.id}/sello_sync`, { body: JSON.stringify({ fields: data.fields }) });
            onClose();
        } catch (error) {
            notifyError(error);
        }
    };

    const getFieldLabel = (field: SyncField) => {
        return translateResourceField(SYNC_FIELDS_OVERRIDE_KEY[field] || field);
    };

    /**
     * Allow selecting if we should sync to new or existing product only if the record is not already synced
     */
    const allowToSelectAction = !record.selloProductId;

    return (
        <Form onSubmit={handleSubmit} initialValues={{ fields: defaultChecked }}>
            {({ submitting, handleSubmit, values }) => (
                <>
                    <DialogContent>
                        {allowToSelectAction && <DialogSelect dialog={dialog} onDialogChange={onDialogChange} />}
                        <Box mb={2} mt={allowToSelectAction ? 2 : 0}>
                            <AlertSyncStatus record={record} />
                        </Box>
                        <CheckboxChunkGroupInput
                            label={translate('app.message.select_fields')}
                            chunkSize={6}
                            source="fields"
                            choices={fields.map((field) => ({ id: field, name: getFieldLabel(field) }))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <RaButton
                            size="small"
                            color="default"
                            label="app.action.unsync"
                            disabled={allowToSelectAction}
                            onClick={() => onDialogChange('unsync')}
                        >
                            <CancelIcon />
                        </RaButton>
                        <LoadingButton
                            disabled={values.fields.length === 0}
                            loading={submitting}
                            onClick={handleSubmit}
                            label="app.action.sello_sync"
                            icon={<SyncIcon />}
                        />
                    </DialogActions>
                </>
            )}
        </Form>
    );
};

const SyncExistingProductDialog = ({
    onClose,
    record,
    dialog,
    onDialogChange,
}: {
    record: RecordItem;
    onClose: () => void;
} & DialogSelectProps) => {
    const notifyError = useNotifyHttpError();

    const handleSubmit = async (data: { productId: number }) => {
        try {
            await post(`${record.id}/sello_sync`, { body: JSON.stringify({ existingSelloProductId: data.productId }) });
            onClose();
        } catch (error) {
            notifyError(error);
        }
    };

    if (record.selloProductId) {
        return (
            <>
                <DialogContent>
                    <AlertSyncStatus record={record} />
                </DialogContent>
                <DialogActions>
                    <RaButton
                        size="small"
                        color="default"
                        label="app.action.unsync"
                        disabled={!record.selloProductId}
                        onClick={() => onDialogChange('unsync')}
                    >
                        <CancelIcon />
                    </RaButton>
                </DialogActions>
            </>
        );
    }

    return (
        <Form onSubmit={handleSubmit}>
            {({ submitting, handleSubmit, values }) => (
                <>
                    <DialogContent>
                        <DialogSelect dialog={dialog} onDialogChange={onDialogChange} />
                        <Box my={1}>
                            <NumberInput
                                source="productId"
                                label="ID"
                                helperText="app.form.helperText.existingProductId"
                                step={1}
                                min={1}
                                validate={[required(), minValue(1)]}
                                fullWidth
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <RaButton
                            size="small"
                            color="default"
                            label="app.action.unsync"
                            disabled={!record.selloProductId}
                            onClick={() => onDialogChange('unsync')}
                        >
                            <CancelIcon />
                        </RaButton>
                        <LoadingButton
                            disabled={!values.productId}
                            loading={submitting}
                            onClick={handleSubmit}
                            label="app.action.sello_sync"
                            icon={<SyncIcon />}
                        />
                    </DialogActions>
                </>
            )}
        </Form>
    );
};

const AlertSyncStatus = ({ record }: { record: RecordItem }) => {
    const translate = useTranslate();
    const locale = useLocale();

    return (
        <Alert severity={record.selloSyncedAt ? 'success' : 'info'}>
            {record.selloSyncedAt ? (
                <>
                    <div>
                        {translate('app.label.sent_to_sello_at', {
                            date: new Date(record.selloSyncedAt).toLocaleString(locale),
                        })}
                    </div>
                    {record.selloProductId && (
                        <Link
                            href={createSelloProductSearchUrl(record.selloProductId)}
                            target="_blank"
                            rel="noreferrer"
                            color="textSecondary"
                        >
                            {translate('app.label.link')}
                        </Link>
                    )}
                </>
            ) : (
                <div>{translate('app.label.not_sello_product')}</div>
            )}
        </Alert>
    );
};

const UnsyncDialog: FC<{ onClose: () => void; record: RecordItem } & Pick<DialogSelectProps, 'onDialogChange'>> = ({
    onClose,
    record,
    onDialogChange,
}) => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const { data: validateResult, loading: validateLoading } = useFetchGet<{ errors: string[] }>(
        `${record.id}/sello_unsync_validate`,
    );

    const handleDelete = async () => {
        setLoading(true);

        try {
            await deleteFetch(`${record.id}/sello_sync`);
            notify('ra.notification.deleted', { type: 'info', messageArgs: { smart_count: 1 } });
            onClose();
        } catch (error) {
            notifyError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <DialogContent>
                {validateLoading ? (
                    <LinearProgress />
                ) : validateResult?.errors.length === 0 ? (
                    <DialogContentText>
                        {`${translate(
                            `app.message.${
                                record.selloExitingProductSync
                                    ? 'unsync_existing_sello_product'
                                    : 'unsync_new_sello_product'
                            }`,
                        )}. ${translate('ra.message.are_you_sure')}`}
                    </DialogContentText>
                ) : (
                    validateResult?.errors.map((error) => (
                        <Alert key={error} severity="error">
                            {error}
                        </Alert>
                    ))
                )}
            </DialogContent>
            <DialogActions>
                <RaButton
                    size="small"
                    color="default"
                    label="ra.action.cancel"
                    disabled={loading || validateLoading}
                    onClick={() =>
                        onDialogChange(record.selloExitingProductSync ? 'sync_existing_product' : 'sync_new')
                    }
                >
                    <CancelIcon />
                </RaButton>
                <LoadingButton
                    onClick={handleDelete}
                    label="ra.action.confirm"
                    loading={loading || validateLoading}
                    disabled={(validateResult?.errors.length ?? 0) > 0}
                    icon={<ActionCheck />}
                />
            </DialogActions>
        </>
    );
};

export default function SyncSelloButton(props: SyncSelloButtonProps) {
    const isFeatureDisabled = useIsFeatureDisabled();

    if (isFeatureDisabled('Sello')) return null;

    return <SyncSelloButtonView {...props} />;
}
