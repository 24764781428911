import { SelectField, SelectFieldProps } from 'react-admin';

import { useCountryChoices } from '@js/context/AppConfigContext';

type Props = Omit<SelectFieldProps, 'choices'>;

const CountryField = (props: Props) => {
    const choices = useCountryChoices();

    return <SelectField {...props} choices={choices} />;
};

CountryField.defaultProps = {
    addLabel: true,
};

export default CountryField;
