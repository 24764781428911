import { useEffect, useMemo, useRef, useState } from 'react';
import { useCreate } from 'react-admin';

import { AutoProcessingTime, AutoProcessingTimeType } from '@js/interfaces/autoprocessingtime';

type SaveActionArgs = Pick<AutoProcessingTime, 'errand' | 'reclamation'>;

export type AutoTrackTimeControllerProps = ReturnType<typeof useAutoTrackTimeController>;

const useAutoTrackTimeController = () => {
    const [create] = useCreate<Omit<AutoProcessingTime, '@id'>>();
    const [sessionId, setSessionId] = useState(0);

    const timeRef = useRef(0);
    const createdAtRef = useRef(new Date());
    const sessionSavedRef = useRef(false);

    useEffect(() => {
        timeRef.current = 0;
        createdAtRef.current = new Date();
        sessionSavedRef.current = false;

        const intervalId = setInterval(() => {
            timeRef.current++;
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [sessionId]);

    return useMemo(() => {
        const saveSession = (data: SaveActionArgs, type: AutoProcessingTimeType) => {
            if (!data.errand && !data.reclamation) {
                console.error('"errand" or "reclamation" id is required in order to save time');
                return;
            }

            create('auto_processing_times', {
                ...data,
                type,
                time: timeRef.current,
                createdAt: createdAtRef.current.toISOString(),
            });
        };

        return {
            saveEditSession: (data: SaveActionArgs) => {
                saveSession(data, AutoProcessingTimeType.Edit);
                sessionSavedRef.current = true;
            },
            saveCreateSession: (data: SaveActionArgs) => {
                saveSession(data, AutoProcessingTimeType.Create);
                sessionSavedRef.current = true;
            },
            saveCancelSession: (data: SaveActionArgs) => {
                // Execute cancel only if session wasn't saved already
                if (!sessionSavedRef.current) {
                    saveSession(data, AutoProcessingTimeType.Cancel);
                }
            },
            resetSession: () => {
                setSessionId((id) => id + 1);
            },
        };
    }, [create]);
};

export default useAutoTrackTimeController;
