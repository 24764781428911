import { useRecordContext } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { VichUploadable } from '@js/interfaces/vichUploadable';

type Props = {
    record?: VichUploadable;
    source: string;
    addLabel?: boolean;
    sortable?: boolean;
};

const useStyles = makeStyles((theme) => ({
    root: {
        height: 40,
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    verticalBox: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
}));

const FilePreviewField = ({ source, ...props }: Props) => {
    const record = useRecordContext(props);
    const classes = useStyles();
    const publicPath = get(record, source, '');

    if (!publicPath) return null;

    const mimeType = record.mimeType;

    if (!mimeType || ['video', 'image'].every((type) => !mimeType.startsWith(type))) {
        return (
            <div className={classes.verticalBox}>
                <Typography>{record.originalName || publicPath}</Typography>
            </div>
        );
    }

    const Component = record.mimeType.startsWith('video') ? 'video' : 'img';

    return <Component alt={record.id.toString()} className={classes.root} src={publicPath} />;
};

FilePreviewField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default FilePreviewField;
