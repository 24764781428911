import { FC } from 'react';
import { TabbedShowLayoutTabsProps, TabbedShowLayoutTabs } from 'react-admin';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

const ShowLayoutTabs: FC<TabbedShowLayoutTabsProps> = (props) => {
    const isSmall = useIsSmallScreen();

    if (!isSmall) {
        return <TabbedShowLayoutTabs {...props} />;
    }

    // Set max-width of tabs in order to make it scrollable. Without container, it doesn't work for some reason.
    // Maybe because main content wrapper has display: flex and min-width: fit-content
    const maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    return (
        <div style={{ maxWidth }}>
            <TabbedShowLayoutTabs scrollButtons="auto" variant="scrollable" {...props} />
        </div>
    );
};

export default ShowLayoutTabs;
