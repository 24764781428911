import { Children, FC, isValidElement, PropsWithChildren, ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import { chunk } from 'lodash';
import { FormInput, Record, SimpleFormViewProps } from 'react-admin';

type InputViewProps = Pick<SimpleFormViewProps, 'margin' | 'variant'>;

type Input = ReactElement<
    {
        source: string;
        label?: string;
        fullWidth?: boolean;
    } & InputViewProps
>;

type Props = { chunkSize?: number; basePath?: string; record?: Record; resource?: string };

const GridFormLayout: FC<PropsWithChildren<Props>> = ({ chunkSize, record, resource, basePath, children }) => {
    const inputs = Children.toArray(children).filter((input) => {
        if (input && isValidElement(input)) {
            if (!input.props.source) {
                throw new Error('Each input should have "source" prop set');
            }

            return true;
        }

        return false;
    }) as Array<Input>;
    const createKey = (inputs: Array<Input>) => inputs.map((input) => input.props.source).join('_');

    return (
        <Grid container spacing={3}>
            {chunk(inputs, chunkSize).map((inputs) => (
                <Grid key={createKey(inputs)} item xs={12} md={6} lg={4} xl={3}>
                    {inputs.map((input) => (
                        <FormInput
                            key={input.props.source}
                            basePath={basePath!}
                            input={input}
                            record={record}
                            resource={resource}
                            variant={input.props.variant}
                            margin={input.props.margin}
                        />
                    ))}
                </Grid>
            ))}
        </Grid>
    );
};

export default GridFormLayout;
