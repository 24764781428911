import { FC } from 'react';
import { BooleanInput, BooleanInputProps } from 'react-admin';

const ArchivedAtInput: FC<BooleanInputProps> = (props) => {
    const parse = (value: unknown) => {
        if (value) {
            return new Date().toISOString();
        }

        return null;
    };
    const format = (value: unknown) => {
        return !!value;
    };

    return <BooleanInput parse={parse} format={format} {...props} />;
};

export default ArchivedAtInput;
