import { useTranslate } from 'react-admin';
import { CircularProgress, IconButton, InputAdornment, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const InputEndAdornment = (props: { onClear?: () => void; disabled?: boolean; loading?: boolean }) => {
    const { loading, onClear, disabled } = props;
    const translate = useTranslate();
    const label = translate('ra.action.clear_input_value');
    const classes = useStyles(props);

    return (
        <InputAdornment position="end">
            {loading && <CircularProgress size={20} />}
            <IconButton
                className={classes.clearButton}
                aria-label={label}
                title={label}
                disableRipple
                onClick={onClear}
                onMouseDown={(event) => event.stopPropagation()}
                disabled={disabled}
            >
                <ClearIcon className={classes.clearIcon} />
            </IconButton>
        </InputAdornment>
    );
};

const useStyles = makeStyles<Theme, Pick<{ disabled?: boolean }, 'disabled'>>({
    clearIcon: ({ disabled }) => ({
        height: 16,
        width: disabled ? 0 : 16,
    }),
    clearButton: {
        height: 24,
        width: 24,
        padding: 0,
    },
});

export default InputEndAdornment;
