import CategoryIcon from '@material-ui/icons/Category';

import CategoryList from './CategoryList';

export default {
    icon: CategoryIcon,
    list: CategoryList,
    create: null,
    edit: null,
    show: null,
};
