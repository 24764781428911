import { useTranslate } from 'react-admin';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type Props = {
    onClose: () => void;
    error: string;
};

const DialogErrorContent = ({ onClose, error }: Props) => {
    const translate = useTranslate();

    return (
        <>
            <DialogContent dividers>
                <Alert severity="error">{error}</Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.close')}
                </Button>
            </DialogActions>
        </>
    );
};

export default DialogErrorContent;
