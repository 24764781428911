import { FC, useContext } from 'react';
import {
    CreateButton,
    FilterButton,
    FilterContext,
    ListActionsProps as ListActionsProps,
    TopToolbar,
    useResourceContext,
    useResourceDefinition,
} from 'react-admin';

import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

const ListActions: FC<ListActionsProps> = (props) => {
    const resource = useResourceContext(props);
    const { hasCreate } = useResourceDefinition(props);
    const permissions = useUserPermissions();
    const filters = useContext(FilterContext);

    return (
        <TopToolbar>
            <VisibleColumnsButton {...props} />
            {filters && permissions.isListActionEnabled(resource, 'add_filter') && <FilterButton />}
            {hasCreate && permissions.isListActionEnabled(resource, 'create') && <CreateButton />}
        </TopToolbar>
    );
};

export default ListActions;
