import { FC } from 'react';
import {
    ChipField,
    FileField as RaFileField,
    Labeled,
    NumberField,
    ReferenceArrayField,
    Show,
    ShowProps,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';

import RecordTitle from '@js/components/detail/RecordTitle';
import FieldGuesser from '@components/field/FieldGuesser';
import ShowLayoutTabs from '@components/detail/ShowLayoutTabs';
import ErrandsDataGrid from '@components/resource/customer/ErrandsDataGrid';
import FileField from '@js/components/field/FileField';
import FunctionField from '@components/field/FunctionField';

import ContactPeopleDataGrid from './ContactPeopleDataGrid';

import useShowFields from '@js/hooks/useShowFields';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

import { Store } from '@js/interfaces/store';

const StoreShow: FC<ShowProps> = (props) => {
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();
    const fields = useShowFields(props, ['images', 'files', 'fullId']);
    const permissions = useUserPermissions();

    return (
        <Show {...props} title={<RecordTitle />}>
            <TabbedShowLayout tabs={<ShowLayoutTabs />}>
                <Tab label={translate('resources.stores.tabs.general')}>
                    <TextField source="fullId" />
                    {fields.map((field) => (
                        <FieldGuesser key={field.name} field={field} source={field.name} />
                    ))}
                </Tab>
                <Tab label={translate('resources.stores.tabs.files')} path="files">
                    <ReferenceArrayField reference="store_files" source="images">
                        <SingleFieldList linkType={false}>
                            <FileField />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField reference="store_files" source="files">
                        <SingleFieldList linkType={false}>
                            <FunctionField
                                render={(record, props) => (
                                    <Box mr={1} my={1}>
                                        <RaFileField
                                            source="paths.publicPath"
                                            title="originalName"
                                            target="_blank"
                                            record={record}
                                            {...props}
                                        />
                                    </Box>
                                )}
                            />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </Tab>
                {permissions.isResourceEnabled('contact_people') && (
                    <Tab label={getResourceLabel('contact_people')} path="contact_people">
                        <ContactPeopleDataGrid />
                    </Tab>
                )}
                {permissions.isResourceEnabled('errands') && (
                    <Tab label={getResourceLabel('errands')} path="errands">
                        <ErrandsDataGrid initialValues={(record) => ({ store: record.id.toString() })} target="store" />
                    </Tab>
                )}
                <Tab label={translate('resources.stores.tabs.sla')} path="sla">
                    <ServiceLevelAgreementField />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const ServiceLevelAgreementField = (props: { record?: Store }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    const userOptionText = useResourceFieldName('users');
    const userGroupOptionText = useResourceFieldName('user_groups');

    if (!record?.serviceLevelAgreement) {
        return (
            <Box my={2}>
                <Typography variant="h6">{translate('app.label.disabled')}</Typography>
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" gridGap={8} mb={1}>
            <Labeled {...props} source="serviceLevelAgreement.durability">
                <NumberField source="serviceLevelAgreement.durability" />
            </Labeled>
            <Labeled {...props} source="serviceLevelAgreement.durability">
                <ReferenceArrayField source="serviceLevelAgreement.notifyUsers" reference="users">
                    <SingleFieldList linkType={false}>
                        <ChipField source={userOptionText} />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Labeled>
            <Labeled {...props} source="serviceLevelAgreement.durability">
                <ReferenceArrayField source="serviceLevelAgreement.notifyUserGroups" reference="user_groups">
                    <SingleFieldList linkType={false}>
                        <ChipField source={userGroupOptionText} />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Labeled>
            {/*<TextField source="serviceLevelAgreement.notificationText" />*/}
        </Box>
    );
};

export default StoreShow;
