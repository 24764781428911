import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { addLockRecordsAction, deleteLockRecordAction } from '@js/redux/lockRecordsReducer';
import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';

import { LockRecord } from '@js/interfaces/lockrecord';
import { Iri } from '@js/interfaces/ApiRecord';
import { UserIdentity } from '@js/auth/types';

type LockRecordDeletePayload = { '@id': Iri; '@type'?: string };

type EventPayload = LockRecord | LockRecordDeletePayload;

const isDeletePayload = (payload: EventPayload): payload is LockRecordDeletePayload => {
    const keys = Object.keys(payload);

    if (keys.length === 1 && keys[0] === '@id') {
        return true;
    }

    return keys.length === 2 && keys.includes('@id') && keys.includes('@type');
};

const useLockRecordMercureSubscription = (identity: UserIdentity | undefined) => {
    const dispatch = useDispatch();
    const subscribe = useCustomMercureSubscription();

    useEffect(() => {
        return subscribe('/api/lock_records', (data) => {
            const lockRecord = JSON.parse(data) as EventPayload;

            if (isDeletePayload(lockRecord)) {
                dispatch(deleteLockRecordAction(lockRecord['@id']));
            } else if (identity?.id !== lockRecord.user) {
                dispatch(addLockRecordsAction([lockRecord]));
            }
        });
    }, [dispatch, identity?.id, subscribe]);
};

export default useLockRecordMercureSubscription;
