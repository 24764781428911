import { FC } from 'react';
import { Box } from '@material-ui/core';
import { useInput } from 'react-admin';

import ColorTool from './ColorTool';

interface Props {
    resource?: string;
    source: string;
}

const ThemePaletteInput: FC<Props> = (props) => {
    const {
        input: { onChange, value },
    } = useInput(props);

    return (
        <Box p={4} borderRadius={10} bgcolor="grey.100">
            <ColorTool theme={value || null} onChange={onChange} />
        </Box>
    );
};

ThemePaletteInput.defaultProps = {
    resource: 'user_groups',
};

export default ThemePaletteInput;
