import { forwardRef, ForwardRefRenderFunction, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { Button, useLocale, useTranslate } from 'react-admin';
import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    List,
    ListItem as MuiListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import IconButton from '@components/button/IconButton';

import { useParseHttpErrorAsString } from '@js/hooks/useNotifyHttpError';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

import { iceCatClient, IcecatProduct } from '@js/utility/iceCatUtility';
import { ExternalMedia } from '@js/utility/ExternalMedia';

export type IceCatDialogIconButtonHandle = {
    open: () => void;
    close: () => void;
};

type Props = {
    gtinSource: string;
};

type CopyableData = {
    shortDescription?: string;
    longDescription?: string;
    videos?: IcecatProduct['Multimedia'];
    images?: IcecatProduct['Gallery'];
    weight?: number;
};

type IcecatOption = keyof CopyableData;

const FeaturesGroupsWeightId = '14';
const FeaturesWeightId = '94';

const IceCatDialogIconButton: ForwardRefRenderFunction<IceCatDialogIconButtonHandle, Props> = (
    { gtinSource },
    forwardedRef,
) => {
    const [open, setOpen] = useState<boolean>(false);
    const { iceCatProduct, loading, error } = useIceCatProductController(gtinSource);
    const form = useForm();

    const handleClose = () => setOpen(false);
    const handleOpen = () => {
        if (error || !iceCatProduct || loading) return;
        setOpen(true);
    };

    useImperativeHandle(forwardedRef, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    const handleCopy = ({ images, videos, ...data }: CopyableData) => {
        const formData = {
            ...data,
            images: mediaItemsToExternalMedia({ images, videos }),
        };

        form.batch(() => {
            Object.entries(formData).forEach(([key, value]) => {
                if (key === 'images' && Array.isArray(value)) {
                    const currentImagesValue = form.getFieldState('images')?.value ?? [];
                    form.change('images', [...currentImagesValue, ...value]);
                } else {
                    form.change(key, value);
                }
            });
        });
        handleClose();
    };

    return (
        <>
            <IconButton
                size="medium"
                label="app.action.icecat"
                onClick={handleOpen}
                loading={loading}
                disabled={!iceCatProduct}
                disabledReason={error}
            >
                <InfoIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <IceCatInfo product={iceCatProduct} onClose={handleClose} onCopy={handleCopy} />
            </Dialog>
        </>
    );
};

const IceCatInfo = ({
    product,
    onClose,
    onCopy,
}: {
    product?: IcecatProduct;
    onClose: () => void;
    onCopy: (data: CopyableData) => void;
}) => {
    const data = parseIceCatProduct(product);

    const getFieldLabel = useTranslateResourceField('reclamations');
    const translate = useTranslate();
    const [checked, setChecked] = useState(() =>
        (Object.keys(data) as IcecatOption[]).filter((option) => {
            const value = data[option];
            if (Array.isArray(value)) return value.length > 0;
            return !!value;
        }),
    );

    const getItemSecondary = (option: IcecatOption) => {
        const value = data[option];
        if (!value || (Array.isArray(value) && value.length === 0)) return null;

        switch (option) {
            case 'shortDescription':
            case 'longDescription':
            case 'weight':
                return value;
            case 'images':
                return <ListMedia items={mediaItemsToExternalMedia({ images: data.images, asThumb: true })} />;
            case 'videos':
                return <ListMedia items={mediaItemsToExternalMedia({ videos: data.videos })} />;
        }
    };

    const handleCopy = () => {
        onCopy(checked.reduce((acc, option) => ({ ...acc, [option]: data[option] }), {} as CopyableData));
    };

    const handleToggle = (option: IcecatOption) => () => {
        if (checked.includes(option)) {
            setChecked(checked.filter((v) => v !== option));
        } else {
            setChecked([...checked, option]);
        }
    };

    return (
        <>
            <DialogContent>
                <List>
                    {(Object.keys(data) as IcecatOption[]).map((option) => {
                        const secondary = getItemSecondary(option);
                        if (!secondary) return null;

                        return (
                            <ListItem
                                key={option}
                                primary={getFieldLabel(option)}
                                secondary={secondary}
                                checked={checked.includes(option)}
                                onClick={handleToggle(option)}
                            />
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} label={translate('ra.action.cancel')} />
                <Button
                    onClick={handleCopy}
                    label={translate('app.action.copy')}
                    variant="contained"
                    disabled={checked.length === 0}
                />
            </DialogActions>
        </>
    );
};

const ListItem = ({
    primary,
    secondary,
    checked,
    onClick,
}: {
    primary: string;
    secondary: ReactNode;
    checked: boolean;
    onClick: () => void;
}) => (
    <>
        <MuiListItem disableGutters onClick={onClick}>
            <ListItemIcon>
                <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText primary={primary} secondary={secondary} />
        </MuiListItem>
        <Divider />
    </>
);

const ListMedia = ({ items }: { items: ExternalMedia[] }) => {
    return (
        <Box display="flex" flexWrap="wrap" component="span">
            {items.map((item) => (
                <Box key={item.id} p={1} component="span">
                    {item.type === 'video' ? (
                        <video src={item.url} controls width="150" />
                    ) : (
                        <img src={item.url} alt={item.id} width="75" />
                    )}
                </Box>
            ))}
        </Box>
    );
};

const mediaItemsToExternalMedia = ({
    videos = [],
    images = [],
    asThumb = false,
}: {
    videos?: IcecatProduct['Multimedia'];
    images?: IcecatProduct['Gallery'];
    asThumb?: boolean;
}): ExternalMedia[] => {
    return [
        ...videos.map(
            (video) =>
                new ExternalMedia({
                    id: video.ID,
                    type: 'video',
                    url: video.URL,
                }),
        ),
        ...images.map(
            (image) =>
                new ExternalMedia({
                    id: image.ID,
                    type: 'image',
                    url: asThumb ? image.ThumbPic : image.Pic,
                }),
        ),
    ];
};

const parseIceCatProduct = (product: IcecatProduct | undefined): CopyableData => {
    const { ShortSummaryDescription: shortDescription, LongSummaryDescription: longDescription } =
        product?.GeneralInfo?.SummaryDescription ?? {};
    const images = product?.Gallery ?? [];
    const videos = (product?.Multimedia ?? []).filter((m) => m.IsVideo);
    const weightInGrams = product?.FeaturesGroups?.find(
        ({ FeatureGroup }) => FeatureGroup.ID === FeaturesGroupsWeightId,
    )?.Features?.find(({ Feature }) => Feature.ID === FeaturesWeightId);
    const weight = weightInGrams ? +weightInGrams.Value / 1000 : undefined;

    return {
        weight,
        shortDescription,
        longDescription,
        images,
        videos,
    };
};

const useIceCatProductController = (gtinSource: string) => {
    const {
        input: { value: gtinValue },
    } = useField(gtinSource, { subscription: { value: true } });
    const [product, setProduct] = useState<IcecatProduct>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const translate = useTranslate();
    const parseError = useParseHttpErrorAsString();
    const locale = useLocale();

    let gtinValidationError: string | undefined;
    if (typeof gtinValue !== 'string' || gtinValue.length < 12) {
        gtinValidationError = translate('ra.validation.minLength', { min: 12 });
    }

    useEffect(() => {
        // Reset state if EAN changes
        setProduct(undefined);
        setError(undefined);

        if (gtinValidationError) return;

        const abortController = new AbortController();
        const timeout = setTimeout(async () => {
            setLoading(true);

            try {
                const product = await iceCatClient.getProduct(
                    { GTIN: gtinValue, lang: locale },
                    { signal: abortController.signal },
                );
                setProduct(product);
            } catch (e) {
                setError(await parseError(e));
            } finally {
                setLoading(false);
            }
        }, 300);

        return () => {
            clearTimeout(timeout);
            abortController.abort();
        };
    }, [gtinValue, parseError, gtinValidationError, locale]);

    return {
        iceCatProduct: product,
        loading,
        error: error || gtinValidationError,
    };
};

export default forwardRef(IceCatDialogIconButton);
