import { FC } from 'react';
import {
    CircularProgress,
    IconButton as MuiIconButton,
    IconButtonProps,
    Theme,
    Tooltip,
    TooltipProps,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

interface Props extends IconButtonProps {
    label: string;
    loading?: boolean;
    disabledReason?: string | null | false;
    tooltipPlacement?: TooltipProps['placement'];
}

const getFontSize = ({ size }: IconButtonProps): number => (size === 'small' ? 20 : size === 'medium' ? 22 : 24);

const useStyles = makeStyles<Theme, IconButtonProps>({
    button: (props) => ({
        '& .MuiSvgIcon-root': {
            fontSize: () => getFontSize(props),
        },
    }),
});

const IconButton: FC<Props> = ({ label, children, loading, disabled, disabledReason, tooltipPlacement, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles(props);

    const button = (
        <div>
            <MuiIconButton className={classes.button} disabled={disabled || !!disabledReason || loading} {...props}>
                {loading ? <CircularProgress size={getFontSize(props)} /> : children}
            </MuiIconButton>
        </div>
    );

    if (loading || (disabled && !disabledReason)) return button;

    const translateKey = disabledReason || label;
    const title = translate(translateKey, { _: translateKey });

    return (
        <Tooltip title={title} placement={tooltipPlacement}>
            {button}
        </Tooltip>
    );
};

IconButton.defaultProps = {
    size: 'small',
    color: 'primary',
};

export default IconButton;
