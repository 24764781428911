import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ColorChangeHandler, SketchPicker } from 'react-color';
import { FieldTitle, InputHelperText, ResettableTextField, useInput, Validator } from 'react-admin';

import hexColor from '@js/validator/hexColor';

const useStyle = makeStyles({
    popover: {
        position: 'absolute',
        zIndex: 2,
        top: '-74px',
        left: '120px',
    },
    cover: {
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
    },
});

interface ColorPickerProps {
    value: string | undefined;
    onClose: () => void;
    onChange: ColorChangeHandler;
}

const ColorPicker: FC<ColorPickerProps> = ({ value, onClose, onChange }) => {
    const [color, setColor] = useState(value || '#000000');
    const classes = useStyle();

    const handleChange: ColorChangeHandler = ({ hex }) => {
        setColor(hex);
    };

    return (
        <div className={classes.popover}>
            <div onClick={onClose} className={classes.cover} />
            <SketchPicker color={color} onChange={handleChange} onChangeComplete={onChange} />
        </div>
    );
};

interface InputProps {
    source: string;
    resource?: string;
    className?: string;
    validate?: Validator[];
}

const HexInputColorPickerInput: FC<InputProps> = ({ validate = [], className, ...props }) => {
    const {
        input,
        isRequired,
        meta: { touched, error, submitError },
    } = useInput({
        ...props,
        validate: [hexColor(), ...validate],
    });
    const [showPicker, setShowPicker] = useState(false);
    const { source, resource } = props;

    const handleChangeComplete: ColorChangeHandler = ({ hex }) => {
        input.onChange(hex);
    };

    const handleFocus = () => {
        input.onFocus();
        setShowPicker(true);
    };

    return (
        <div style={{ position: 'relative' }}>
            <ResettableTextField
                source={source}
                onChange={input.onChange}
                onBlur={() => input.onBlur()}
                onFocus={handleFocus}
                value={input.value}
                label={<FieldTitle source={source} resource={resource} isRequired={isRequired} />}
                error={!!(touched && (error || submitError))}
                helperText={<InputHelperText touched={!!touched} error={error || submitError} helperText={undefined} />}
                className={className}
            />
            {showPicker && (
                <ColorPicker value={input.value} onClose={() => setShowPicker(false)} onChange={handleChangeComplete} />
            )}
        </div>
    );
};

export default HexInputColorPickerInput;
