import { get } from '@js/request/apiRequest';
import pDebounce from 'p-debounce';
import lodashGet from 'lodash/get';
import { Validator } from 'react-admin';

import { customValidatorPath } from '@js/routes/apiRouter';

/**
 * Generate ID path to get from all values. Useful for validation of embedded resources.
 * Convert "reclamations[0].caseNumber" to "reclamations[0].@id"
 */
const getIdPathFromName = (name: string): string => {
    const parts = name.split('.');

    // Remove last part
    parts.pop();
    // And replace it with ID
    // Use '@id' for embedded records
    parts.push(parts.length === 0 ? 'id' : '@id');

    return parts.join('.');
};

export const uniqueField = (field: string, resource: string, message = 'app.validation.unique'): Validator =>
    pDebounce(async (value, allValues, props) => {
        // Do not validate on empty values
        if (value === undefined || value === '' || value === null) {
            return;
        }

        const { name } = props;
        const idPath = getIdPathFromName(name);
        const id = lodashGet(allValues, idPath, '');
        const path = customValidatorPath('unique');

        try {
            const { valid } = await get(path, {
                value,
                resource: `/api/${resource}`,
                field,
                id,
            });

            if (valid === false) {
                return message;
            }
        } catch (error) {
            console.error(error);

            return 'ra.notification.http_error';
        }

        return undefined;
    }, 200);
