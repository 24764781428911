import { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from '@material-ui/core';
import { useField } from 'react-final-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PermissionSettings from './PermissionSettings';
import { ShowMode } from './PermissionsFilter';
import { LabeledResource } from '@js/context/HydraSchemaContext';

interface Props {
    source: string;
    showMode: ShowMode;
    resource: LabeledResource;
    hidden: boolean;
}

export type SettingsTab = 'listFields' | 'actions' | 'allowFields' | 'listFilters' | 'filterDefaultValues' | 'features';

const settingsTabsConfig: Record<string, Array<SettingsTab>> = {
    errands: ['listFields', 'listFilters', 'filterDefaultValues', 'actions', 'allowFields'],
    reclamations: ['listFields', 'listFilters', 'filterDefaultValues', 'actions', 'allowFields', 'features'],
};

const ResourcePermissionsAccordion: FC<Props> = ({ source, resource, showMode, hidden }) => {
    const { label, name } = resource;
    const { input } = useField(`${source}.${name}.enable`, { type: 'checkbox' });
    const settingsTabs = settingsTabsConfig[name] ?? null;

    hidden =
        hidden ||
        (showMode === ShowMode.DISABLED && input.checked) ||
        (showMode === ShowMode.ENABLED && !input.checked);

    return (
        <Accordion expanded={settingsTabs ? undefined : false} hidden={hidden}>
            <AccordionSummary expandIcon={settingsTabs && <ExpandMoreIcon />} id={`resource-${name}-permission-header`}>
                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={<Checkbox {...input} />}
                    label={label}
                />
            </AccordionSummary>
            {settingsTabs && (
                <AccordionDetails>
                    <PermissionSettings source={source} resource={resource} tabs={settingsTabs} />
                </AccordionDetails>
            )}
        </Accordion>
    );
};

export default ResourcePermissionsAccordion;
