import { FC } from 'react';
import { Datagrid, DatagridProps, FunctionField } from 'react-admin';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from './Toolbar';

import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import { Reclamation } from '@js/interfaces/reclamation';
import useResourceSchema from '@js/hooks/useResourceSchema';

const useStyles = makeStyles({
    cell: {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

const SmallDatagrid: FC<DatagridProps> = (props) => {
    const classes = useStyles();
    const schema = useResourceSchema(props);
    const { schemaAnalyzer } = useHydraSchemaContext();
    const fieldName = schemaAnalyzer.getFieldNameFromSchema(schema);

    return (
        <>
            <Toolbar />
            <Datagrid {...props}>
                <FunctionField<Reclamation>
                    cellClassName={classes.cell}
                    source={fieldName}
                    render={(record) => get(record, fieldName)}
                />
            </Datagrid>
        </>
    );
};

export default SmallDatagrid;
