import { BooleanField, InjectedFieldProps, PublicFieldProps, Record as RaRecord, useRecordContext } from 'react-admin';

interface Props<T extends RaRecord> extends PublicFieldProps, InjectedFieldProps {
    source: string;
    sortable?: boolean;
    addLabel?: boolean;
    valueLabelTrue?: (record: T) => string | undefined;
    valueLabelFalse?: (record: T) => string | undefined;
}

const BooleanTooltipField = <T extends RaRecord>({ valueLabelTrue, valueLabelFalse, ...props }: Props<T>) => {
    const record = useRecordContext(props) as T;

    return (
        <BooleanField
            {...props}
            looseValue
            valueLabelTrue={valueLabelTrue ? valueLabelTrue(record) : undefined}
            valueLabelFalse={valueLabelFalse ? valueLabelFalse(record) : undefined}
        />
    );
};

BooleanTooltipField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default BooleanTooltipField;
