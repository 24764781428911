import { Box, CardActions, Link, TextField } from '@material-ui/core';
import { Field } from 'react-final-form';
import { useNotify, useSafeSetState, useTranslate } from 'react-admin';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import LoginFormLayout, { ActionButton } from './LoginFormLayout';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';

interface ResetPasswordFormData {
    email: string;
}

type ResetPasswordRequestResponse = {
    message: string;
};

const ResetPasswordPage = () => {
    const [loading, setLoading] = useSafeSetState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const history = useHistory();

    const handleSubmit = async (values: ResetPasswordFormData) => {
        try {
            setLoading(true);

            const data = await post<ResetPasswordRequestResponse>('/api/reset-password/request', {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            notify(data.message, { type: 'info' });
            history.push('/login');
        } catch (error) {
            notifyError(error);
        } finally {
            setLoading(false);
        }
    };

    const validate = (values: ResetPasswordFormData) => {
        const errors: Partial<ResetPasswordFormData> = { email: undefined };

        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <LoginFormLayout onSubmit={handleSubmit} validate={validate}>
            <Box paddingBottom={2} paddingX={2}>
                <Field autoFocus id="email" name="email" label={translate('app.label.email')} disabled={loading}>
                    {({ meta: { touched, error }, input, ...props }) => (
                        <TextField
                            type="email"
                            required
                            error={!!(touched && error)}
                            helperText={touched && error ? error : translate('app.label.reset_password_helper')}
                            {...input}
                            {...props}
                            fullWidth
                        />
                    )}
                </Field>
            </Box>
            <CardActions>
                <ActionButton loading={loading} label="app.action.reset" />
            </CardActions>
            <Box display="flex" justifyContent="center">
                <Link component={RouterLink} variant="overline" to="/login">
                    {translate('ra.action.cancel')}
                </Link>
            </Box>
        </LoginFormLayout>
    );
};

export default ResetPasswordPage;
