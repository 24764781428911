import { FC } from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useRecordContext, useUpdate } from 'react-admin';
import { get } from 'lodash';

import AnchorText from '@components/field/AnchorText';
import { UserNotification } from '@js/interfaces/usernotification';

interface Props {
    record?: UserNotification;
    resource?: string;
    source?: string;
    addLabel?: boolean;
    sortable?: boolean;
}

const StarredField: FC<Props> = ({ source, ...props }) => {
    const record = useRecordContext(props);
    const starred = get(record, source!);
    const [toggleStarred, { loading }] = useUpdate('user_notifications', record?.id, { starred: !starred }, record);

    const handleClick = () => {
        if (!loading) {
            toggleStarred();
        }
    };

    return <AnchorText onClick={handleClick}>{starred ? <StarIcon /> : <StarBorderIcon />}</AnchorText>;
};

StarredField.defaultProps = {
    source: 'starred',
    addLabel: true,
};

export default StarredField;
