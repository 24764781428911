import { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import { Title, useTranslate } from 'react-admin';

import DatagridLoading from '@components/list/DatagridLoading';

import { useUserPermissions } from '@js/context/UserPermissionsContext';

const DashboardLazy = lazy(() => import('./Dashboard'));

const Dashboard = () => {
    const permissions = useUserPermissions();
    const translate = useTranslate();

    if (!permissions.dashboard.enabled) {
        return <Redirect to="/errands" />;
    }

    return (
        <Suspense
            fallback={
                <Card>
                    <CardContent>
                        <DatagridLoading />
                    </CardContent>
                </Card>
            }
        >
            <>
                <Title title={translate('app.dashboard.title')} />
                <DashboardLazy />
            </>
        </Suspense>
    );
};

export default Dashboard;
