import { FC } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Record as RaRecord, useRecordContext } from 'react-admin';
import { get } from 'lodash';

import { useFormatPrice } from '@js/hooks/useFormatPrice';
import { useAppConfigContext } from '@js/context/AppConfigContext';

type Props = {
    source: string;
    currencyField: string;
    label?: string;
    addLabel?: boolean;
    sortable?: boolean;
    record?: RaRecord;
    resource?: string;
    color?: 'primary' | 'secondary' | 'disabled';
};

const RecordPriceField: FC<Props> = ({ source, currencyField, color, ...props }) => {
    const { defaultCurrency } = useAppConfigContext();
    const record = useRecordContext(props);
    const value = get(record, source);
    const currency = get(record, currencyField) ?? defaultCurrency;
    const formatPrice = useFormatPrice();
    const classes = useStyles({ color });

    if (typeof value !== 'number') return null;

    return (
        <Typography component="span" variant="body2" className={classes.root}>
            {formatPrice(value, currency)}
        </Typography>
    );
};

const useStyles = makeStyles<Theme, { color: Props['color'] }>((theme) => ({
    root: (props) => ({
        color: theme.palette.text[props.color ?? 'primary'],
    }),
}));

RecordPriceField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default RecordPriceField;
