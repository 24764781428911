import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import IconButton from '@components/button/IconButton';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useIdsBatchRequest from '@js/hooks/useIdsBatchRequest';
import { post } from '@js/request/apiRequest';

import { LogEntry } from '@js/interfaces/logentry';

const RestoreButton = ({ record }: { record: LogEntry }) => {
    const [isRestoring, setIsRestoring] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const notifyError = useNotifyHttpError();
    const { data } = useIdsBatchRequest<{ deleted: boolean }>('/api/soft-delete-check', record.resourceIri);

    const handleClick = async () => {
        setIsRestoring(true);

        try {
            await post('/api/restore', {
                body: JSON.stringify({ iri: record.resourceIri }),
            });
            notify('app.label.successfully_restored');
            setIsRestoring(false);
            refresh();
        } catch (error) {
            notifyError(error);
            setIsRestoring(false);
        }
    };

    return (
        <IconButton label="app.action.restore" onClick={handleClick} loading={isRestoring} disabled={!data?.deleted}>
            <RestoreIcon />
        </IconButton>
    );
};

export default RestoreButton;
