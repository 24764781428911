import { useCallback } from 'react';
import { useAppConfigContext } from '@js/context/AppConfigContext';

const useCustomMercureSubscription = () => {
    const { entrypoint, mercureUrl } = useAppConfigContext();

    return useCallback(
        (topic: string, dataListener: (data: string) => void) => {
            const url = new URL(mercureUrl, window.origin);
            url.searchParams.append('topic', new URL(topic, entrypoint).toString());

            const eventSource = new EventSource(url.toString(), {
                withCredentials: true,
            });

            const eventListener = (event: MessageEvent<string>) => {
                dataListener(event.data);
            };
            eventSource.addEventListener('message', eventListener);

            return () => {
                eventSource.removeEventListener('message', eventListener);
                eventSource.close();
            };
        },
        [entrypoint, mercureUrl],
    );
};

export default useCustomMercureSubscription;
