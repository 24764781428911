import { ReactNode } from 'react';
import { Login, TitleComponent, useTranslate } from 'react-admin';
import { Box, Button, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { Form, FormProps } from 'react-final-form';

import { useSettings } from '@js/context/AppConfigContext';

const LoginFormLayout = <FormValues extends Record<string, any>>({
    children,
    onSubmit,
    validate,
    ...props
}: {
    title?: TitleComponent;
    theme?: object;
    children: ReactNode;
    onSubmit: FormProps<FormValues>['onSubmit'];
    validate: FormProps<FormValues>['validate'];
}) => {
    const { logoFile, loginPageBackgroundColor } = useSettings();
    const classes = useStyles({
        backgroundColor: loginPageBackgroundColor,
    });

    return (
        <Login {...props} className={classes.main}>
            {logoFile && <img src={logoFile} alt="Logo" className={classes.logo} />}
            <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
            />
        </Login>
    );
};

const useStyles = makeStyles<Theme, { backgroundColor: string | null }>({
    main: (props) => ({
        backgroundColor: props.backgroundColor || undefined,
        ...(props.backgroundColor && { backgroundImage: 'unset' }),
    }),
    logo: {
        maxWidth: '320px',
    },
});

export const ActionButton = ({ loading, label }: { loading: boolean; label: string }) => {
    const translate = useTranslate();

    return (
        <Button fullWidth variant="contained" type="submit" color="primary" disabled={loading}>
            {loading && <CircularProgress size={18} thickness={2} />}
            <Box ml={loading ? 1 : 0} component="span">
                {translate(label)}
            </Box>
        </Button>
    );
};

export default LoginFormLayout;
