import { useCallback, useState } from 'react';

type RefCallback<T> = (node: T) => void;

const useRefCallback = <T = any>(): [T | null, RefCallback<T>] => {
    const [node, setNode] = useState<T | null>(null);

    const ref = useCallback<RefCallback<T>>((node) => {
        setNode(node);
    }, []);

    return [node, ref];
};

export default useRefCallback;
