import { FC } from 'react';
import { Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useListFilterContext, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import InboxIcon from '@material-ui/icons/Inbox';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles({
    card: {
        minWidth: '200px',
    },
});

const Aside: FC = () => {
    const translate = useTranslate();
    const { filterValues, setFilters } = useListFilterContext();
    const classes = useStyles();

    const handleFilterClick = (value: Record<string, any>) => {
        setFilters(value, {});
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <List component="nav">
                    <ListItem
                        button
                        selected={Object.keys(filterValues).length === 0}
                        onClick={() => handleFilterClick({})}
                    >
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={translate('resources.user_notifications.nav.inbox')} />
                    </ListItem>
                    <ListItem
                        button
                        selected={Boolean(filterValues['starred'])}
                        onClick={() => handleFilterClick({ starred: true })}
                    >
                        <ListItemIcon>
                            <StarIcon />
                        </ListItemIcon>
                        <ListItemText primary={translate('resources.user_notifications.nav.starred')} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default Aside;
