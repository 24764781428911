import { useMemo } from 'react';
import { useResourceContext } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import useResourceSchema from '@js/hooks/useResourceSchema';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useResourceDefinition from '@js/hooks/useResourceDefinition';

interface Props {
    resource?: string;
}

const useFormFields = (props: Props = {}, hideInputs: string[] = []) => {
    const resource = useResourceContext(props);
    const schema = useResourceSchema(props);
    const permissions = useUserPermissions();
    const { options } = useResourceDefinition(props);

    return useMemo(() => {
        if (!schema.writableFields) {
            throw new Error(`Resource ${schema.name} "writableFields" not present inside API description`);
        }
        const hiddenInputs: Set<string> = new Set([...hideInputs, ...(options?.alwaysHideFields ?? [])]);
        const { writableFields } = schema;

        const fields = permissions.isResourceFieldsRestricted(schema.name)
            ? permissions
                  .getEditableFields(resource)
                  .map((fieldName) => writableFields.find((field) => field.name === fieldName))
                  .filter((field): field is Field => !!field)
            : [...writableFields];

        return fields.filter((field) => !hiddenInputs.has(field.name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options?.alwaysHideFields, permissions, resource, schema, hideInputs.join('_')]);
};

export default useFormFields;
