import { Field, Resource } from '@api-platform/api-doc-parser';
import { LinearProgress, useRecordContext, useReference } from 'react-admin';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

import ClickToCopyTooltip from '@components/button/ClickToCopyTooltip';

import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import { Errand } from '@js/interfaces/errand';

type Props = {
    field: Field;
    source: string;
    resource?: string;
    record?: Errand;
};

const AddressField = ({ field, ...props }: Props) => {
    const { schemaAnalyzer } = useHydraSchemaContext();
    const reference = field.reference as Resource;
    const fieldName = schemaAnalyzer.getFieldNameFromSchema(reference);
    const record = useRecordContext(props);

    const { referenceRecord: address, loaded: addressLoaded } = useReference({
        reference: reference.name,
        id: get(record, props.source),
    });
    const { referenceRecord: customer, loaded: customerLoaded } = useReference({
        reference: 'customers',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: record?.customer,
    });

    if (!addressLoaded || !customerLoaded) {
        return <LinearProgress />;
    }

    if (!address) {
        return null;
    }

    const addressLabel = get(address, fieldName);
    const customerLabel = [get(customer, 'email'), get(customer, 'phoneNumber')].filter(Boolean).join(', ');
    const value = `${addressLabel} ${customerLabel}`.trim();

    return (
        <ClickToCopyTooltip text={value}>
            <Typography component="span" variant="body2">
                {addressLabel}
            </Typography>
            {customerLabel && (
                <>
                    <br />
                    <Typography component="span" variant="body2">
                        {customerLabel}
                    </Typography>
                </>
            )}
        </ClickToCopyTooltip>
    );
};

export default AddressField;
