import { useTranslate } from 'react-admin';
import { getMinutesDiff } from '@js/utility/dateUtility';

const useFormatTimeAgo = () => {
    const translate = useTranslate();

    return (from: string) => {
        const minutes = getMinutesDiff(from);

        const hours = Math.floor(minutes / 60);
        let diff = '';

        if (hours > 0) {
            diff += translate('app.label.hours', { smart_count: hours });
        }
        if (0 === minutes || minutes % 60 > 0) {
            if (hours > 0) diff += ` ${translate('app.label.and')} `;

            diff += translate('app.label.minutes', { smart_count: minutes % 60 });
        }
        diff += ` ${translate('app.label.ago')}`;

        return diff;
    };
};

export default useFormatTimeAgo;
