import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetList, useGetMany, Identifier, UseInputValue, SortPayload, FilterPayload } from 'react-admin';
import { get } from 'lodash';
import { ApiRecord } from '@js/interfaces/ApiRecord';

export type Choice<T> = T & { _level: number };
export type Choices<T extends ApiRecord> = Record<Identifier, Choice<T>>;

interface Props {
    input: UseInputValue['input'];
    reference: string;
    sort?: SortPayload;
    filter?: FilterPayload;
    subItems?: string;
    perPage: number;
}

const flatItemsRecursive = <T extends ApiRecord>(items: Record<Identifier, T>, subItemsPath?: string) => {
    const flattened: Record<Identifier, Choice<T>> = {};
    const recursive = (items: T[], level = 0) => {
        items.forEach((item) => {
            const { '@id': id } = item;
            const subItems = subItemsPath && (get(item, subItemsPath) as T[] | undefined);

            flattened[id] = { ...item, _level: level };
            if (subItems) {
                recursive(subItems, level + 1);
            }
        });
    };

    recursive(Object.values(items));

    return flattened;
};

const normalizeInputValue = (value: Identifier | Identifier[]) => {
    if (Array.isArray(value)) {
        return value;
    }
    if (value) {
        return [value];
    }

    return [];
};

const useScrollableSelectInputController = <RecordType extends ApiRecord>({
    input,
    reference,
    sort,
    filter,
    subItems,
    perPage,
}: Props) => {
    // Local state of component
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [choices, setChoices] = useState<Choices<RecordType> | undefined>();
    // Load records by increasing page
    const {
        ids: rootIds,
        data: list,
        loaded: listLoaded,
        total,
        loading: listLoading,
    } = useGetList<RecordType>(reference, { page, perPage }, sort, filter);
    // Load initial input records
    const inputValueRef = useRef(normalizeInputValue(input.value));
    const { data: valueItems, loaded: valueItemsLoaded } = useGetMany(reference, inputValueRef.current);
    // Track root records if there are sub-items
    const rootLevelChoiceIdsRef = useRef<Set<Identifier>>(new Set());

    // When page changes, we first change local loading state, after list loading is done sync it with local state
    useEffect(() => {
        setLoading(listLoading);
    }, [listLoading]);

    useEffect(() => {
        if (!listLoaded || !valueItemsLoaded) {
            return;
        }

        rootIds.filter(Boolean).forEach((id) => {
            rootLevelChoiceIdsRef.current.add(id);
        });
        const flatList = flatItemsRecursive(list, subItems);
        const prependValueItems = valueItems
            .filter((item) => item?.id && !(item.id in flatList))
            .reduce((prev, item) => ({ ...prev, [item.id]: item }), {});

        setChoices((choices) => ({
            ...choices,
            ...prependValueItems,
            ...flatList,
        }));
    }, [list, rootIds, valueItems, listLoaded, valueItemsLoaded, subItems]);

    const loadNextPage = useCallback(() => {
        setLoading(true);
        setPage((page) => page + 1);
    }, []);
    const totalChoices = rootLevelChoiceIdsRef.current.size;

    return {
        loaded: listLoaded && valueItemsLoaded,
        page,
        loadNextPage,
        loading,
        choices,
        totalChoices,
        reachedLastPage: totalChoices >= (total ?? 0),
    };
};

export default useScrollableSelectInputController;
