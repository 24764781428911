import { Identifier } from 'react-admin';
import { isObject, isPlainObject } from 'lodash';
import { post } from '@js/request/apiRequest';
import { ExternalMedia } from '@js/utility/ExternalMedia';

export const normalizeMediaObjectField = async (
    url: string | undefined,
    data: any,
): Promise<Identifier | Identifier[] | null> => {
    if (!url) {
        throw new Error('Field should have valid reference url.');
    }

    let isArray = true;
    if (!Array.isArray(data)) {
        isArray = false;
        data = [data];
    }

    const result: Array<Identifier | null> = await Promise.all(
        data.map(async (item: any) => {
            // If is uploaded file
            if (isObject(item)) {
                const body = fileObjectToRequestBody(item);
                if (!body) return null;

                try {
                    const response = await post<{ '@id': Identifier }>(url, {
                        method: 'POST',
                        body,
                    });

                    return response['@id'];
                } catch (error) {
                    console.log(error);
                    return null;
                }
            }

            // If is IRI
            if (typeof item === 'string' && item) {
                return item;
            }

            return null;
        }),
    );

    // If this is OneToMany exclude null values from array
    // If OneToOne to ManyToOne shift a first element even if it's null
    return isArray ? result.filter<Identifier>((v): v is Identifier => v !== null) : result.shift() ?? null;
};

const fileObjectToRequestBody = (file: ExternalMedia | Record<string, any>) => {
    // If is external media, added, for example, from Icecat. @see IceCatDialogIconButton
    if (file instanceof ExternalMedia) {
        return file.toJSON();
    }
    // If is uploaded file
    if (isPlainObject(file)) {
        const rawFile = Object.values<File>(file).find((v) => v instanceof File);
        if (rawFile) {
            const formData = new FormData();
            formData.append('file', rawFile);

            return formData;
        }
    }

    return null;
};
