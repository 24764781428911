import { FC, PropsWithChildren, useState } from 'react';
import {
    Datagrid,
    DateField,
    FunctionField,
    ListBase,
    Pagination,
    ReferenceManyField,
    ResourceContextProvider,
    SearchInput,
    TextField,
    Title,
    useGetResourceLabel,
    useListContext,
    useLocale,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import {
    Box,
    BoxProps,
    Card,
    CardContent,
    CircularProgress,
    Tab as MuiTab,
    TableCell,
    TableRow,
    Tabs,
} from '@material-ui/core';
import { pick } from 'lodash';

import DateRangeInput from '@components/Dashboard/input/DateRangeInput';
import CopyableTextField from '@components/field/CopyableTextField';
import PriceField from '@components/field/PriceField';
import AutoHidePagination from '@components/list/AutoHidePagination';
import DatagridBodyAdditionalRows from '@components/list/DatagridBodyAdditionalRows';
import CheckboxInput from '@components/input/CheckboxInput';
import AlertEmptyResource from '@components/list/AlertEmptyResource';

import FilterForm from '../FilterForm';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { secondsToHumanTime } from '@js/utility/numberFormatUtility';

import { Currency, useAppConfigContext } from '@js/context/AppConfigContext';
import { useFormatPrice } from '@js/hooks/useFormatPrice';
import useFetchGet from '@js/hooks/useFetchGet';

import { Iri } from '@js/interfaces/ApiRecord';

type SalesStatistic = {
    [key in Currency]: number;
};

type UserDetailedStatistic = {
    id: Iri;
    user: Iri;
    fullName: string;
    totalProcessingTime: number;
    totalHandledReclamation: number;
} & SalesStatistic;

type ErrandUserStatistic = {
    id: Iri;
    errand: Iri;
    createdAt: string;
    name: string;
    composedIdentifier: string;
    totalProcessingTime: number;
} & SalesStatistic;

type ReclamationUserStatistic = {
    id: Iri;
    reclamation: Iri;
    createdAt: string;
    label: string;
    composedIdentifier: string;
    totalProcessingTime: number;
} & SalesStatistic;

type Filters = {
    q?: string;
    after?: string;
    before?: string;
    hasTotalProcessingTime?: boolean;
};

const SHOW_CURRENCIES = [Currency.SEK, Currency.NOK, Currency.DKK];

const UsersDetailed = () => {
    const [filters, setFilters] = useState<Filters>();

    const resource = 'user_detailed_statistics';
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();
    const getResourceLabel = useGetResourceLabel();
    const locale = useLocale();

    return (
        <Card>
            <CardContent>
                <Title title={translate('app.dashboard.section.users_detailed')} />
                <ResourceContextProvider value={resource}>
                    <ListBase
                        resource={resource}
                        basePath={`/${resource}`}
                        filter={filters}
                        sort={{ field: 'fullName', order: 'ASC' }}
                    >
                        <FilterForm onChange={setFilters}>
                            <Filters />
                        </FilterForm>
                        <Datagrid
                            expand={<ExpandUserStatistic filters={filters} />}
                            body={
                                <DatagridBodyAdditionalRows rows={<TotalRow resource={resource} filters={filters} />} />
                            }
                        >
                            <TextField source="fullName" label={getFieldLabel('fullName', 'users')} />
                            <FunctionField<UserDetailedStatistic>
                                source="totalHandledReclamation"
                                label={getResourceLabel('reclamations')}
                                render={(record) => {
                                    return (record?.totalHandledReclamation ?? 0).toLocaleString(locale);
                                }}
                            />
                            <FunctionField<UserDetailedStatistic>
                                source="totalProcessingTime"
                                label={getResourceLabel('processing_times')}
                                render={(record) => {
                                    if (!record) return null;
                                    return `${secondsToHumanTime(record.totalProcessingTime)} h`;
                                }}
                            />
                            {SHOW_CURRENCIES.map((currency) => (
                                <PriceField
                                    key={currency}
                                    label={`${translate('app.label.total_sales')} (${currency})`}
                                    source={`totalSales${currency}`}
                                    currency={currency}
                                    formatCents={false}
                                />
                            ))}
                        </Datagrid>
                        <Pagination />
                    </ListBase>
                </ResourceContextProvider>
            </CardContent>
        </Card>
    );
};

const ExpandUserStatistic = ({
    filters: rootFilter,
    ...props
}: {
    record?: UserDetailedStatistic;
    filters?: Filters;
}) => {
    const [tab, setTab] = useState<'errands' | 'categories'>('errands');
    const record = useRecordContext(props);
    const getFieldLabel = useTranslateResourceField('errands');
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const filters = pick(rootFilter, ['after', 'before', 'hasTotalProcessingTime']);

    // Copy sorting from the parent list
    const { currentSort } = useListContext();
    const sort = currentSort.field !== 'totalHandledReclamation' ? currentSort : { field: 'createdAt', order: 'DESC' };

    return (
        <>
            <Tabs value={tab} onChange={(_, activeTab) => setTab(activeTab)}>
                <MuiTab value="errands" label={getResourceLabel('errands')} />
                {/*<MuiTab value="categories" label={getResourceLabel('categories')} />*/}
            </Tabs>
            <TabPanel activeTab={tab} tab="errands" pl={1} pb={1}>
                <ReferenceManyField
                    reference="errand_user_statistics"
                    target="reclamations.handlers.user"
                    record={{ id: record.user }}
                    sort={sort}
                    perPage={10}
                    pagination={<AutoHidePagination />}
                    filter={filters}
                >
                    <Datagrid
                        expand={<ExpandErrandStatistic user={record.user} filters={filters} />}
                        empty={<AlertEmptyResource />}
                    >
                        <DateField source="createdAt" label={getFieldLabel('createdAt')} />
                        <TextField source="name" label={getFieldLabel('name')} sortable={false} />
                        <CopyableTextField
                            source="composedIdentifier"
                            label={getFieldLabel('composedIdentifier')}
                            sortable={false}
                        />
                        <FunctionField<ErrandUserStatistic>
                            source="totalProcessingTime"
                            label={getResourceLabel('processing_times')}
                            render={(record) => {
                                if (!record) return null;
                                return `${secondsToHumanTime(record.totalProcessingTime)} h`;
                            }}
                        />
                        {SHOW_CURRENCIES.map((currency) => (
                            <PriceField
                                key={currency}
                                label={`${translate('app.label.total_sales')} (${currency})`}
                                source={`totalSales${currency}`}
                                currency={currency}
                                formatCents={false}
                            />
                        ))}
                    </Datagrid>
                </ReferenceManyField>
            </TabPanel>
        </>
    );
};

const ExpandErrandStatistic = ({
    user,
    filters,
    ...props
}: {
    record?: ErrandUserStatistic;
    user: Iri;
    filters?: Record<string, any>;
}) => {
    const record = useRecordContext(props);
    const getFieldLabel = useTranslateResourceField('reclamations');
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    // Copy sorting from the parent list
    const { currentSort } = useListContext();

    return (
        <ReferenceManyField
            reference="reclamation_user_statistics"
            target="handlers.user"
            record={{ id: user }}
            sort={currentSort}
            perPage={10}
            pagination={<AutoHidePagination />}
            filter={{ ...filters, errand: record.errand }}
        >
            <Datagrid empty={<AlertEmptyResource />}>
                <DateField source="createdAt" label={getFieldLabel('createdAt')} />
                <TextField source="label" label={getFieldLabel('label')} sortable={false} />
                <CopyableTextField
                    source="composedIdentifier"
                    label={getFieldLabel('composedIdentifier')}
                    sortable={false}
                />
                <FunctionField<ReclamationUserStatistic>
                    source="totalProcessingTime"
                    label={getResourceLabel('processing_times')}
                    render={(record) => {
                        if (!record) return null;
                        return `${secondsToHumanTime(record.totalProcessingTime)} h`;
                    }}
                />
                {SHOW_CURRENCIES.map((currency) => (
                    <PriceField
                        key={currency}
                        label={`${translate('app.label.total_sales')} (${currency})`}
                        source={`totalSales${currency}`}
                        currency={currency}
                        formatCents={false}
                    />
                ))}
            </Datagrid>
        </ReferenceManyField>
    );
};

const TabPanel: FC<PropsWithChildren<{ activeTab: string; tab: string } & BoxProps>> = ({
    children,
    activeTab,
    tab,
    ...rest
}) => {
    const isActive = activeTab === tab;

    return (
        <Box hidden={!isActive} {...rest}>
            {isActive && children}
        </Box>
    );
};

const Filters = () => {
    const getFieldLabel = useTranslateResourceField('reclamations');
    const translate = useTranslate();

    return (
        <Box display="flex" alignItems="center" gridGap="20px">
            <SearchInput source="q" variant="outlined" size="small" />
            <DateRangeInput />
            <CheckboxInput
                source="hasTotalProcessingTime"
                label={translate('app.label.has_value', { value: getFieldLabel('processingTimes') })}
            />
        </Box>
    );
};

const TotalRow = ({ resource, filters: rootFilter }: { resource: string; filters?: Filters }) => {
    const translate = useTranslate();
    const { entrypoint } = useAppConfigContext();
    const formatPrice = useFormatPrice();

    const { data: total, loading } = useFetchGet<{
        totalProcessingTime: number;
        totalSalesDKK: number;
        totalSalesNOK: number;
        totalSalesSEK: number;
    }>(`${entrypoint}/${resource}/total`, pick(rootFilter, ['after', 'before']));
    const colSpan = 3;

    return (
        <TableRow>
            <TableCell colSpan={colSpan} align="right">
                <b>{translate('app.label.total')}:</b>
            </TableCell>
            <TableCellLoading loading={loading}>
                {`${secondsToHumanTime(total?.totalProcessingTime ?? 0)} h`}
            </TableCellLoading>
            <TableCellLoading loading={loading}>{formatPrice(total?.totalSalesSEK, Currency.SEK)}</TableCellLoading>
            <TableCellLoading loading={loading}>{formatPrice(total?.totalSalesNOK, Currency.NOK)}</TableCellLoading>
            <TableCellLoading loading={loading}>{formatPrice(total?.totalSalesDKK, Currency.DKK)}</TableCellLoading>
        </TableRow>
    );
};

const TableCellLoading = ({ children, loading }: { children?: string; loading: boolean }) => {
    return <TableCell>{loading ? <CircularProgress size={20} color="inherit" /> : children}</TableCell>;
};

export default UsersDetailed;
