import { useGetIdentity as useRaGetIdentity } from 'react-admin';
import { UserIdentity } from '@js/auth/types';

type State = {
    loading: boolean;
    loaded: boolean;
    identity?: UserIdentity;
    error?: any;
};

const useGetIdentity = () => {
    const state = useRaGetIdentity();

    return state as State;
};

export default useGetIdentity;
