import { Form, FormSpy } from 'react-final-form';
import { SearchInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

export enum ShowMode {
    ALL = 'all',
    ENABLED = 'allowed',
    DISABLED = 'disallowed',
}

export interface FilterValue {
    search: string;
    showMode: ShowMode;
}

interface Props {
    onFilter: (filter: FilterValue) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleSubmit = () => {};
const FormSpySubscription = { values: true };
const initialValues = { search: '', showMode: ShowMode.ALL };

const useStyles = makeStyles((theme) => ({
    searchInput: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const PermissionsFilter: FC<Props> = ({ onFilter: setFilter }) => {
    const classes = useStyles();

    return (
        <Form<FilterValue>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            render={() => (
                <>
                    <FormSpy<FilterValue>
                        subscription={FormSpySubscription}
                        onChange={({ values }) => {
                            setFilter(values);
                        }}
                    />

                    <SearchInput source="search" autoComplete="off" className={classes.searchInput} />
                    <SelectInput
                        source="showMode"
                        hiddenLabel
                        label=""
                        choices={Object.values(ShowMode).map((mode) => ({
                            id: mode,
                            name: `resources.user_groups.label.${mode}`,
                        }))}
                    />
                </>
            )}
        />
    );
};

export default PermissionsFilter;
