import { Field } from '@api-platform/api-doc-parser';
import { FC } from 'react';
import { ChipField, useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ReferenceField from '@js/components/field/ReferenceField';
import ClickToCopyTooltip from '@components/button/ClickToCopyTooltip';

import { Errand } from '@js/interfaces/errand';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';

type Props = {
    field: Field;
    source: string;
    addLabel?: boolean;
    sortable?: boolean;
    fullWidth?: boolean;
    resource?: string;
    record?: Errand;
};

const CustomerField: FC<Props> = ({ field, ...props }) => {
    const { schemaAnalyzer } = useHydraSchemaContext();

    if (null === field.reference || typeof field.reference !== 'object') {
        return null;
    }
    const fieldName = schemaAnalyzer.getFieldNameFromSchema(field.reference);

    return (
        <ReferenceField reference={field.reference.name} {...props}>
            <View source={fieldName} />
        </ReferenceField>
    );
};

const View = (props: { source: string; record?: Errand }) => {
    const record = useRecordContext(props);
    const value = get(record, props.source);

    return (
        <>
            <ChipField {...props} />
            <ClickToCopyTooltip
                text={value}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <IconButton disabled={!value} size="small">
                    <FileCopyIcon fontSize="small" />
                </IconButton>
            </ClickToCopyTooltip>
        </>
    );
};

CustomerField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default CustomerField;
