import { FC } from 'react';
import { Show, ShowProps } from 'react-admin';

import ResellerShowLayout from './ResellerShowLayout';
import RecordTitle from '@components/detail/RecordTitle';

const ResellerShow: FC<ShowProps> = (props) => {
    return (
        <Show {...props} title={<RecordTitle />}>
            <ResellerShowLayout />
        </Show>
    );
};

export default ResellerShow;
