import { FormTab, ImageInput, TabbedForm, TabbedFormProps, useGetResourceLabel, useTranslate } from 'react-admin';

import Toolbar from '@components/form/Toolbar';
import FileInputPreview from '@components/input/FileInputPreview';
import InputGuesser from '@components/form/InputGuesser';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

import PermissionsInput from './input/PermissionsInput';
import ThemePaletteInput from './input/ThemePaletteInput';
import PredefinedTextDatagrid from './PredefinedTextDatagrid';
import StatusChangeRulesFormTab from './StatusChangeRulesFormTab';

import useFormFields from '@js/hooks/useFormFields';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

const UserGroupForm = (props: Omit<TabbedFormProps, 'children'>) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const translateField = useTranslateResourceField();
    const fields = useFormFields(props, ['logo', 'permissions', 'theme', 'statusChangeRules']);
    const permissions = useUserPermissions();

    return (
        <TabbedForm {...props} toolbar={<Toolbar />}>
            <FormTab label={translate('resources.user_groups.tab.general')}>
                {fields.map((field) => {
                    if (field.name === 'statuses') {
                        return (
                            <AutoCompleteGuesserInput
                                key={field.name}
                                field={field}
                                source={field.name}
                                filter={{ 'exists[parent]': false }}
                                perPage={50}
                            />
                        );
                    }

                    if (field.name === 'viewableCategories') {
                        return (
                            <AutoCompleteGuesserInput
                                key={field.name}
                                field={field}
                                source={field.name}
                                filter={{ private: true }}
                                perPage={50}
                            />
                        );
                    }

                    return <InputGuesser key={field.name} field={field} source={field.name} />;
                })}
            </FormTab>
            {permissions.isFieldEditable('user_groups', 'permissions') && (
                <FormTab label={translate('resources.user_groups.tab.permissions')} path="permissions">
                    <PermissionsInput />
                </FormTab>
            )}
            {permissions.isFieldEditable('user_groups', 'theme') && (
                <FormTab label={translate('resources.user_groups.tab.theme')} path="theme">
                    <ImageInput source="logo" accept="image/*">
                        <FileInputPreview reference="user_group_logos" />
                    </ImageInput>
                    <ThemePaletteInput source="theme" />
                </FormTab>
            )}
            {permissions.isResourceEnabled('predefined_texts') && (
                <FormTab label={getResourceLabel('predefined_texts')} path="predefined_texts">
                    <PredefinedTextDatagrid />
                </FormTab>
            )}
            {permissions.isFieldEditable('user_groups', 'statusChangeRules') && (
                <FormTab label={translateField('statusChangeRules')} path="status_change_rules">
                    <StatusChangeRulesFormTab fullWidth />
                </FormTab>
            )}
        </TabbedForm>
    );
};
export default UserGroupForm;
