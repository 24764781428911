import { FC } from 'react';
import { ListProps } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import TokenField from './field/TokenField';

const ApiTokenList: FC<ListProps> = (props) => {
    return (
        <ListGuesser {...props}>
            <DatagridGuesser>
                <TokenField />
            </DatagridGuesser>
        </ListGuesser>
    );
};

export default ApiTokenList;
