import { FC } from 'react';
import { useGetResourceLabel, useResourceContext, useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Box, BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
    label?: string;
    resource?: string;
}

const AlertEmptyResource: FC<Props> = ({ label, resource: resourceOverride, ...props }) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const resource = useResourceContext({ resource: resourceOverride });

    return (
        <Box mt={2} {...props}>
            <Alert severity="info">{label || translate('ra.page.empty', { name: getResourceLabel(resource) })}</Alert>
        </Box>
    );
};

export default AlertEmptyResource;
