import { FC } from 'react';
import { CardContentInner, FileField, FileInput, required } from 'react-admin';
import { Form } from 'react-final-form';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Toolbar from '@components/routes/Toolbar';
import { ImportConfig } from './Co2Import';

type Props = {
    onSubmit: (values: ImportConfig['upload']) => void;
};

type FormValues = {
    file: { rawFile: File };
};

const UploadFileForm: FC<Props> = ({ onSubmit }) => {
    const handleSubmit = (values: FormValues) => {
        onSubmit({
            importFile: values.file.rawFile,
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            {({ handleSubmit, valid, submitting }) => (
                <>
                    <CardContentInner>
                        <FileInput
                            source="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            validate={required()}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    </CardContentInner>
                    <Toolbar
                        cancel={{ to: '/errands' }}
                        submit={{
                            onSubmit: handleSubmit,
                            disabled: !valid,
                            saving: submitting,
                            icon: NavigateNextIcon,
                        }}
                    />
                </>
            )}
        </Form>
    );
};

export default UploadFileForm;
