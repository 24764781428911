import PeopleIcon from '@material-ui/icons/People';

import CustomerList from './CustomerList';
import CustomerShow from './CustomerShow';
import CustomerForm from './CustomerForm';

export default {
    list: CustomerList,
    show: CustomerShow,
    icon: PeopleIcon,
    options: {
        form: CustomerForm,
        alwaysHideFields: ['fullNameOrEmail', 'optionText'],
    },
};
