import { FC, lazy, ReactNode, Suspense, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { Field } from '@api-platform/api-doc-parser';
import { LinearProgress } from 'react-admin';

import ActionCheckboxes from './ActionCheckboxes';
import FilterSettings from './FilterSettings';
import FilterDefaultValues from './FilterDefaultValues';
import FeatureResourceSettings from './FeatureResourceSettings';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useResourceDefinition from '@js/hooks/useResourceDefinition';
import ListFieldsTransferList from './ListFieldsTransferList';

import { LabeledResource } from '@js/context/HydraSchemaContext';
import { SettingsTab } from './ResourcePermissionsAccordion';

const FieldsTransferList = lazy(() => import('./FieldsTransferList'));

interface PermissionSettingsProps {
    source: string;
    resource: LabeledResource;
    tabs: SettingsTab[];
}

interface TabPanelProps {
    tab: SettingsTab;
    activeTab: SettingsTab;
}

const PermissionSettings: FC<PermissionSettingsProps> = ({
    source,
    tabs,
    resource: { readableFields, writableFields, name },
}) => {
    const translateField = useTranslateResourceField('user_groups');
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const { options } = useResourceDefinition({ resource: name });

    const isFieldHidden = (field: Field) => !options?.alwaysHideFields?.includes(field.name);

    const renderTabContent = (tab: SettingsTab) => {
        switch (tab) {
            case 'actions':
                return <ActionCheckboxes source={source} resource={name} />;
            case 'listFields':
                return (
                    <Suspense fallback={<LinearProgress />}>
                        <FieldsTransferList
                            source={`${source}.${name}.fields.list`}
                            fields={readableFields?.filter(isFieldHidden) ?? []}
                            resource={name}
                        />
                    </Suspense>
                );
            case 'allowFields':
                return (
                    <ListFieldsTransferList
                        source={`${source}.${name}.fields.edit`}
                        requireableSource={`${source}.${name}.fields.required`}
                        fields={writableFields?.filter(isFieldHidden) ?? []}
                        resource={name}
                    />
                );
            case 'listFilters':
                return <FilterSettings source={source} resource={name} />;
            case 'filterDefaultValues':
                return <FilterDefaultValues source={source} resource={name} />;
            case 'features':
                return <FeatureResourceSettings source={source} resource={name} />;
            default:
                throw new Error(`Unknown tab "${tab}"`);
        }
    };

    return (
        <Box width="100%">
            <Tabs value={activeTab} onChange={(_1, tab) => setActiveTab(tab)}>
                {tabs.map((tab) => (
                    <Tab key={tab} value={tab} label={translateField(tab)} />
                ))}
            </Tabs>
            {tabs.map((tab) => (
                <TabPanel key={tab} activeTab={activeTab} tab={tab}>
                    {renderTabContent(tab)}
                </TabPanel>
            ))}
        </Box>
    );
};

const TabPanel: FC<TabPanelProps & { children: ReactNode }> = ({ children, activeTab, tab }) => {
    const isActive = activeTab === tab;

    return (
        <Box my={2} hidden={!isActive}>
            {children}
        </Box>
    );
};

export default PermissionSettings;
