import { Field, Resource } from '@api-platform/api-doc-parser';
import { useResourceContext } from 'react-admin';

import useResourceSchema from '@js/hooks/useResourceSchema';
import useResourceDefinition from '@js/hooks/useResourceDefinition';
import { putPriorityFieldsFirst } from '@js/utility/fieldsUtil';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { useMemo } from 'react';

interface Props {
    resource?: string;
    schema?: Resource;
}

const useListFields = (props: Props = {}, hideFields: string[] = []): Field[] => {
    const schema = useResourceSchema(props);
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);
    const { options } = useResourceDefinition(props);

    return useMemo(() => {
        if (!schema.readableFields) {
            throw new Error(`Resource ${schema.name} "readableFields" not present inside API description`);
        }

        const { readableFields } = schema;
        const excludeFields = new Set([
            ...hideFields, // Hide fields passed as argument
            ...(options?.alwaysHideFields ?? []), // Hide fields defined in resource definition
        ]);

        let fields: Field[];
        // If this is not admin and resource is errands or reclamations, use only fields defined by permissions
        if (permissions.isResourceFieldsRestricted(schema.name)) {
            fields = permissions
                .getListFields(resource)
                .map((fieldName) => readableFields.find((field) => field.name === fieldName))
                .filter((field): field is Field => !!field);
        } else {
            fields = putPriorityFieldsFirst(readableFields, ['createdAt', 'updatedAt', 'createdBy']);
        }

        return fields.filter((field) => !excludeFields.has(field.name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, permissions, resource, options, hideFields.join(',')]);
};

export default useListFields;
