import { FC } from 'react';
import { useTranslate, FunctionField } from 'react-admin';

import { NotificationEvent } from '@js/interfaces/notificationevent';

interface Props {
    source: string;
    addLabel?: boolean;
}

const EventField: FC<Props> = (props) => {
    const translate = useTranslate();

    return (
        <FunctionField<NotificationEvent>
            {...props}
            render={(record) => translate(`resources.notification_events.events.${record?.event}`)}
        />
    );
};

EventField.defaultProps = {
    source: 'event',
    addLabel: true,
};

export default EventField;
