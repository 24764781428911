import { FC } from 'react';
import { LinearProgress, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import useIdsBatchRequest from '@js/hooks/useIdsBatchRequest';
import { secondsToHumanTimeWithSeconds } from '@js/utility/numberFormatUtility';

interface Props {
    source: string;
    addLabel?: boolean;
    sortable?: boolean;
}

const AutoProcessingTimeField: FC<Props> = (props) => {
    const record = useRecordContext(props);
    const {
        loading,
        data: total,
        error,
    } = useIdsBatchRequest<number>('/api/errands/auto_processing_time', record?.id, {
        enabled: !!record,
    });

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <ErrorIcon role="presentation" color="error" fontSize="small" />;
    }

    return (
        <Typography component="span" variant="body2">
            {secondsToHumanTimeWithSeconds(total!)}
        </Typography>
    );
};

AutoProcessingTimeField.defaultProps = {
    addLabel: true,
    source: 'totalAutoProcessingTimeInSeconds',
    sortable: false,
};

export default AutoProcessingTimeField;
