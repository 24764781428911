import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';

import { useSettings } from '@js/context/AppConfigContext';
import useGetIdentity from '@js/hooks/useGetIdentity';

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),

        '& img': {
            maxWidth: '175px',
        },
    },
}));

const MenuLogo: FC<{ sidebarOpen?: boolean }> = ({ sidebarOpen }) => {
    const { identity } = useGetIdentity();
    const classes = useStyles();
    const settings = useSettings();
    const [error, setError] = useState(false);
    const logoPath = identity?.logoPath || settings.logoFile;

    if (!sidebarOpen || !logoPath) {
        return null;
    }

    return (
        <span className={classes.root}>
            {error ? <BrokenImageIcon /> : <img src={logoPath} alt={logoPath} onError={() => setError(true)} />}
        </span>
    );
};

export default MenuLogo;
