import { FC, forwardRef } from 'react';
import {
    linkToRecord,
    MenuItemLink,
    MenuItemLinkProps,
    UserMenu as RaUserMenu,
    UserMenuProps,
    useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useGetIdentity from '@js/hooks/useGetIdentity';

const Menu = forwardRef<HTMLAnchorElement, Pick<MenuItemLinkProps, 'onClick'>>(({ onClick }, ref) => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    const permissions = useUserPermissions();

    if (!permissions.isResourceEnabled('users')) {
        return null;
    }

    return (
        <MenuItemLink
            ref={ref}
            to={linkToRecord('/users', identity?.id)}
            primaryText={translate('app.menu.userProfile')}
            leftIcon={<SettingsIcon />}
            onClick={onClick}
        />
    );
});

Menu.displayName = 'Menu';

const UserMenu: FC<UserMenuProps> = (props) => (
    <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        <RaUserMenu {...props}>
            <Menu />
        </RaUserMenu>
    </Box>
);

export default UserMenu;
