import { createContext, ReactNode, useState } from 'react';
import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export type TreeViewProps = {
    children: ReactNode;
    subTree?: boolean;
    defaultExpanded?: string[];
};

type TreeViewContextValue = {
    expanded: string[];
    onExpand: (id: string) => void;
};

const TreeView = ({ subTree, children, defaultExpanded }: TreeViewProps) => {
    const [expanded, setExpanded] = useState<string[]>(defaultExpanded ?? []);

    const context = {
        expanded,
        onExpand: (id: string) => {
            setExpanded((prev) => {
                if (prev.includes(id)) {
                    return prev.filter((item) => item !== id);
                }
                return [...prev, id];
            });
        },
    };

    return (
        <TreeViewContext.Provider value={context}>
            <TreeViewRoot subTree={subTree}>{children}</TreeViewRoot>
        </TreeViewContext.Provider>
    );
};

export const TreeViewContext = createContext<TreeViewContextValue>(undefined!);

export const TreeViewRoot = ({ subTree, children }: Pick<TreeViewProps, 'subTree'> & { children: ReactNode }) => {
    const classes = useStyles({ subTree });

    return <ul className={classes.root}>{children}</ul>;
};

const useStyles = makeStyles<Theme, Pick<TreeViewProps, 'subTree'>>((theme) => ({
    root: (props) => ({
        padding: 0,
        margin: 0,
        listStyle: 'none',
        outline: 0,
        ...(props.subTree && {
            marginLeft: '7px',
            paddingLeft: '18px',
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        }),
    }),
}));

export default TreeView;
