import { FC } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

const AnchorText: FC<TypographyProps> = (props) => {
    const classes = useStyles();

    return <Typography color="primary" variant="body2" component="span" className={classes.root} {...props} />;
};

export default AnchorText;
