import NotificationsIcon from '@material-ui/icons/Notifications';

import NotificationEventList from './NotificationEventList';
import NotificationEventForm from './NotificationEventForm';

export default {
    list: NotificationEventList,
    icon: NotificationsIcon,
    show: null,
    options: {
        form: NotificationEventForm,
        alwaysHideFields: ['reference'],
    },
};
