import { FC } from 'react';
import { BulkActionsToolbarProps, TopToolbar, useTranslate, useListContext } from 'react-admin';
import { Toolbar, Typography } from '@material-ui/core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        zIndex: 3,
        color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary,
        justifyContent: 'space-between',
        backgroundColor:
            theme.palette.type === 'light' ? lighten(theme.palette.primary.light, 0.85) : theme.palette.primary.dark,
        minHeight: theme.spacing(8),
        height: theme.spacing(8),
        transition: `${theme.transitions.create('height')}, ${theme.transitions.create('min-height')}`,
    },
    topToolbar: {
        paddingTop: theme.spacing(2),
    },
    collapsed: {
        minHeight: 0,
        height: 0,
        overflowY: 'hidden',
    },
    title: {
        display: 'flex',
        flex: '0 0 auto',
    },
    icon: {
        marginLeft: '-0.5em',
        marginRight: '0.5em',
    },
}));

const BulkActionsToolbar: FC<BulkActionsToolbarProps> = (props) => {
    const { label = 'ra.action.bulk_actions', children } = props;
    const { selectedIds, onUnselectItems } = useListContext(props);
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Toolbar
            className={classnames(classes.toolbar, {
                [classes.collapsed]: selectedIds.length === 0,
            })}
        >
            <div className={classes.title}>
                <IconButton
                    className={classes.icon}
                    aria-label={translate('ra.action.unselect')}
                    title={translate('ra.action.unselect')}
                    onClick={onUnselectItems}
                    size="small"
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                <Typography color="inherit" variant="subtitle1">
                    {translate(label, {
                        _: label,
                        smart_count: selectedIds.length,
                    })}
                </Typography>
            </div>
            <TopToolbar className={classes.topToolbar}>{children}</TopToolbar>
        </Toolbar>
    );
};

export default BulkActionsToolbar;
