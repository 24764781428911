import { useCallback } from 'react';
import { getFieldLabelTranslationArgs, useResourceContext, useTranslate } from 'react-admin';

const useTranslateResourceField = (resource?: string) => {
    const translate = useTranslate();
    const contextResource = useResourceContext({ resource });

    return useCallback(
        (field: string, resource?: string): string =>
            translate(
                ...getFieldLabelTranslationArgs({
                    resource: resource || contextResource,
                    source: field,
                }),
            ),
        [contextResource, translate],
    );
};

export default useTranslateResourceField;
