import { FC, memo } from 'react';
import { Record, useRecordContext } from 'react-admin';
import { Box, Tooltip } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import useLockRecordWarningMessage from '@js/hooks/useLockRecordWarningMessage';

interface Props {
    record?: Record;
}

const LockRecordIcon: FC<Props> = (props) => {
    const record = useRecordContext(props);
    const warningMessage = useLockRecordWarningMessage(record?.id);

    return (
        <Box width="24px" display="flex" alignItems="center">
            {warningMessage && (
                <Tooltip title={warningMessage}>
                    <LockOpenIcon />
                </Tooltip>
            )}
        </Box>
    );
};

const PureLockRecordIcon = memo(LockRecordIcon, (prevProps, nextProps) => {
    return prevProps.record === nextProps.record;
});

export default PureLockRecordIcon;
