import { Iri } from '@js/interfaces/ApiRecord';
import { LockRecord } from '@js/interfaces/lockrecord';

export type LockRecordsState = Record<Iri, LockRecord | null>;

export enum ActionType {
    ADD_LOCK_RECORD = 'LOCK_RECORDS/ADD_LOCK_RECORD',
    DELETE_LOCK_RECORD = 'LOCK_RECORDS/DELETE_LOCK_RECORD',
}

type AddAction = {
    type: ActionType.ADD_LOCK_RECORD;
    payload: { lockRecords: LockRecord[]; iris?: Iri[] };
};

type DeleteAction = {
    type: ActionType.DELETE_LOCK_RECORD;
    payload: Iri;
};

type Action = AddAction | DeleteAction;

export const addLockRecordsAction = (lockRecords: LockRecord[], iris?: Iri[]): AddAction => ({
    type: ActionType.ADD_LOCK_RECORD,
    payload: { lockRecords, iris },
});

export const deleteLockRecordAction = (id: Iri): DeleteAction => ({
    type: ActionType.DELETE_LOCK_RECORD,
    payload: id,
});

/**
 * Convert payload array to object where each key is the IRI of locked record
 */
const mapLockRecords = (lockRecords: LockRecord[]) => {
    return lockRecords.reduce((acc, lockRecord) => ({ ...acc, [lockRecord.iri]: lockRecord }), {} as LockRecordsState);
};

const lockRecordsReducer = (state: LockRecordsState = {}, { type, payload }: Action) => {
    switch (type) {
        case ActionType.ADD_LOCK_RECORD: {
            const newLockRecords = {
                // If iris is provided, we know for each records locks where requested, set null for each if is not locked
                ...Object.fromEntries(payload.iris?.map((iri) => [iri, null]) || []),
                ...mapLockRecords(payload.lockRecords),
            };

            return {
                ...state,
                ...newLockRecords,
            };
        }
        case ActionType.DELETE_LOCK_RECORD: {
            const lockRecords = { ...state };
            for (const iri in lockRecords) {
                if (lockRecords[iri]?.['@id'] === payload) {
                    lockRecords[iri] = null;
                }
            }

            return lockRecords;
        }
        default:
            return state;
    }
};

export default lockRecordsReducer;
