import { Children, ReactNode, useContext } from 'react';
import { CircularProgress, IconButton, SvgIcon as MuiSvgIcon, SvgIconProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TreeViewContext, TreeViewRoot } from './TreeView';

type Props = {
    id: string;
    label: ReactNode;
    loading?: boolean;
    expandable?: boolean;
    disabled?: boolean;
    children?: ReactNode;
};

const TreeItem = ({ id, label, children, expandable, loading, disabled }: Props) => {
    const { isExpanded, onExpand } = useTreeItem(id);
    const classes = useStyles();

    const childrenCount = Children.count(children);
    const canExpand = Boolean((expandable || childrenCount > 0) && !disabled);

    const renderIcon = () => {
        if (loading) {
            return <CircularProgress size={14} color="inherit" />;
        }
        if (canExpand) {
            return isExpanded ? <MinusSquare /> : <PlusSquare />;
        }
        return <CloseSquare />;
    };

    return (
        <TreeItemRoot disabled={disabled}>
            <IconButton onClick={onExpand} disabled={!canExpand} className={classes.iconButton}>
                {renderIcon()}
            </IconButton>
            {label}
            {canExpand && isExpanded && children && <TreeViewRoot subTree>{children}</TreeViewRoot>}
        </TreeItemRoot>
    );
};

const useStyles = makeStyles({
    iconButton: {
        padding: 0,
        marginRight: 3,
    },
});

const TreeItemRoot = ({ disabled, children }: { disabled?: boolean; children: ReactNode }) => {
    const classes = useTreeItemStyles({ disabled });

    return <li className={classes.root}>{children}</li>;
};

export const useTreeItem = (id: string) => {
    const context = useContext(TreeViewContext);

    return {
        isExpanded: context.expanded.includes(id),
        onExpand: () => context.onExpand(id),
    };
};

const useTreeItemStyles = makeStyles<Theme, { disabled?: boolean }>((theme) => ({
    root: (props) => ({
        listStyle: 'none',
        margin: 0,
        padding: 0,
        outline: 0,
        ...(props.disabled && {
            opacity: theme.palette.action.disabledOpacity,
            backgroundColor: 'transparent',
            '& button, label, input': {
                cursor: 'default',
            },
        }),
    }),
}));

const SvgIcon = (props: SvgIconProps) => {
    const classes = useSvgIconStyles();
    return <MuiSvgIcon className={classes.root} {...props} />;
};

const useSvgIconStyles = makeStyles({
    root: {
        fontSize: 'inherit',
        width: 14,
        height: 14,
    },
});

export const PlusSquare = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
);

export const MinusSquare = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
);

export const CloseSquare = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
);

export default TreeItem;
