import { FC } from 'react';
import { ArrayField, ArrayFieldProps, SingleFieldList, UrlField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

type Props = Omit<ArrayFieldProps, 'children' | 'source'> & { source?: string };

const useStyles = makeStyles({
    root: {
        flexDirection: 'column',
        margin: 0,
    },
});

const LinksField: FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <ArrayField {...props}>
            <SingleFieldList linkType={false} className={classes.root}>
                <UrlField target="_blank" source="link" />
            </SingleFieldList>
        </ArrayField>
    );
};

LinksField.defaultProps = {
    source: 'links',
    addLabel: true,
    sortable: false,
};

export default LinksField;
