import { useState, lazy, Suspense, FC } from 'react';
import { LinearProgress, useListContext, useTranslate } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';

import DialogCodeScanner from '@components/camera/DialogCodeScanner';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props {
    filterName?: string;
    resource?: string;
    source: string;
    alwaysOn?: boolean;
}

const useStyles = makeStyles({
    button: {
        marginBottom: '12px',
    },
});

const BarcodeScanner = lazy(() => import('@components/camera/BarcodeScanner'));

const BarcodeScannerFilter: FC<Props> = ({ resource, source, filterName = 'q' }) => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const { filterValues, setFilters } = useListContext();
    const translate = useTranslate();
    const translateField = useTranslateResourceField();
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccess = (result: string) => {
        setFilters(
            {
                ...filterValues,
                [filterName]: result,
            },
            {},
        );
        setOpen(false);
    };
    const handleError = (error: unknown) => {
        setError(
            error instanceof Error && error.message
                ? error.message
                : typeof error === 'string'
                ? error
                : 'Unknown error',
        );
    };

    return (
        <>
            <Button
                variant="contained"
                color="default"
                size="small"
                className={classes.button}
                onClick={handleClickOpen}
                startIcon={<HorizontalSplitIcon />}
            >
                {translateField(source, resource)}
            </Button>
            <DialogCodeScanner
                onClose={handleClose}
                open={open}
                title={translate('app.filter.fields.scanBarcode.label')}
                error={error}
                loading={false}
            >
                <Suspense fallback={<LinearProgress />}>
                    <BarcodeScanner onSuccess={handleSuccess} onError={handleError} />
                </Suspense>
            </DialogCodeScanner>
        </>
    );
};

export default BarcodeScannerFilter;
