import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import { useField } from 'react-final-form';
import { Alert } from '@material-ui/lab';
import { useTranslate } from 'react-admin';
import { ReclamationFeatures } from '@js/auth/UserPermissionsService';

type Props = {
    source: string;
    resource: string;
};

const FeatureResourceSettings = ({ source, resource }: Props) => {
    const featureSource = `${source}.${resource}.features`;

    const render = () => {
        switch (resource) {
            case 'reclamations':
                return <ReclamationSettings source={featureSource} />;
            default:
                return <Alert severity="error">Not supported {resource}</Alert>;
        }
    };

    return (
        <Box m={3} ml={5}>
            {render()}
        </Box>
    );
};

const ReclamationSettings = ({ source }: { source: string }) => {
    const translate = useTranslate();

    const getSource = (feature: keyof ReclamationFeatures) => `${source}.${feature}`;
    const getLabel = (feature: keyof ReclamationFeatures) => translate(`resources.reclamations.features.${feature}`);

    return (
        <>
            <InverseCheckbox
                source={getSource('disableDefaultLabelPrint')}
                label={getLabel('disableDefaultLabelPrint')}
            />
            <InverseCheckbox
                source={getSource('disableSavingsRawValues')}
                label={getLabel('disableSavingsRawValues')}
            />
            <InverseCheckbox
                source={getSource('disableSavingsAnalogies')}
                label={getLabel('disableSavingsAnalogies')}
            />
        </>
    );
};

const InverseCheckbox = ({ label, source }: { label: string; source: string }) => {
    const { input } = useField(source, { type: 'checkbox' });

    return (
        <FormControl margin="dense">
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={!input.checked} onChange={(_e, checked) => input.onChange(!checked)} />}
                    label={label}
                />
            </FormGroup>
        </FormControl>
    );
};

export default FeatureResourceSettings;
