import { useEffect, useRef, useState } from 'react';

const useSecondsTimer = (time: number) => {
    const [seconds, setSeconds] = useState(time);
    const [expired, setExpired] = useState(false);
    const intervalRef = useRef<number | null>(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setSeconds((time) => {
                if (time > 0) {
                    return time - 1;
                }
                return 0;
            });
        }, 1000);
    }, []);

    useEffect(() => {
        if (seconds <= 0) {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            setExpired(true);
        }
    }, [seconds]);

    useEffect(() => {
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return { seconds, expired };
};

export default useSecondsTimer;
