import { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useFieldArray } from 'react-final-form-arrays';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import keyBy from 'lodash/keyBy';

import ResellersModalCheckboxGroup from './ResellersModalCheckboxGroup';
import ResellerOverrideForm from './ResellerOverrideForm';

import { Reseller, ResellerEmbedded } from '@js/interfaces/reseller';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

interface Props {
    open: boolean;
    source: string;
    resellers: Reseller[];
    onClose: () => void;
}

const useStyles = makeStyles({
    dialogContentWrapper: {
        minWidth: '370px',
    },
});

const OverrideResellerModal: FC<Props> = ({ open, source, resellers, onClose }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const fullScreen = useIsSmallScreen();
    const { fields } = useFieldArray<Partial<ResellerEmbedded>>(source);
    const value = Array.isArray(fields.value) ? fields.value : [];

    const mappedResellers = keyBy(resellers, 'id');
    const selectedResellers = value
        .map(({ overrideReseller }) => overrideReseller)
        .filter((iri): iri is string => !!iri);

    const handleChange = (checked: boolean, reseller: Reseller) => {
        if (checked) {
            fields.push(extractResellerOverrideProps(reseller));
        } else {
            let findIndex;

            fields.forEach((_, index) => {
                const { overrideReseller } = value[index];

                if (overrideReseller === reseller.id) {
                    findIndex = index;
                }
            });

            if (findIndex !== undefined) {
                fields.remove(findIndex);
            }
        }
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
            <DialogContent classes={{ root: classes.dialogContentWrapper }}>
                <div>
                    <ResellersModalCheckboxGroup
                        onChange={handleChange}
                        selected={selectedResellers}
                        resellers={resellers}
                    />
                </div>
                <Box my={1}>
                    {fields.map((name, index) => {
                        const { overrideReseller } = value[index];

                        if (overrideReseller && overrideReseller in mappedResellers) {
                            return (
                                <ResellerOverrideForm
                                    key={name}
                                    name={name}
                                    title={mappedResellers[overrideReseller].name || 'Undefined'}
                                />
                            );
                        }
                    })}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={onClose}>
                    {translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const extractResellerOverrideProps = ({
    id,
    name,
    commission,
    commissionDescription,
    commissionInPercents,
    pack,
    shipping,
    staffCost,
}: Reseller): Partial<ResellerEmbedded> => ({
    overrideReseller: id.toString(),
    name: `${name} (override)`,
    commission,
    commissionDescription,
    commissionInPercents,
    pack,
    shipping,
    staffCost,
});

export default OverrideResellerModal;
