import { FC, MouseEventHandler, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Identifier,
    linkToRecord,
    Record,
    useGetResourceLabel,
    useRecordContext,
    useResourceDefinition,
    useTranslate,
} from 'react-admin';
import QueueIcon from '@material-ui/icons/Queue';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

interface Props {
    record?: Record;
}

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    icon: {
        fontSize: 18,
    },
}));

const CreateErrandFromInitialValuesButton: FC<Props> = (props) => {
    const record = useRecordContext(props);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const { push } = useHistory();
    const notifyError = useNotifyHttpError();
    const { hasCreate } = useResourceDefinition({ resource: 'errands' });
    const draftId = record?.id;

    if (!draftId || !hasCreate) {
        return null;
    }

    const handleClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.stopPropagation();
        setLoading(true);

        try {
            const { '@id': id } = await post<{ '@id': Identifier }>(`${draftId}/create_errand`, {
                body: '{}',
            });

            push(linkToRecord('/errands', id, 'edit'));
        } catch (error) {
            notifyError(error);
            setLoading(false);
        }
    };

    return (
        <Button
            label={translate('ra.page.create', { name: getResourceLabel('errands') })}
            onClick={handleClick}
            disabled={loading}
            color={loading ? 'default' : 'primary'}
        >
            {loading ? (
                <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
            ) : (
                <QueueIcon className={classnames(classes.leftIcon, classes.icon)} />
            )}
        </Button>
    );
};

export default CreateErrandFromInitialValuesButton;
