import { useState } from 'react';
import { Title, useNotify, useRedirect, useRefresh, useTranslate } from 'react-admin';
import { Card } from '@material-ui/core';

import UploadStep from './UploadStep';
import MappingStep from './MappingStep';
import ConfirmStep from './ConfirmStep';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

export type State = {
    upload?: {
        file: {
            rawFile: File;
        };
    };
    mapping?: Record<string, string>;
};

const STEPS = ['upload', 'mapping', 'confirm'] as const;
type Step = (typeof STEPS)[number];

const ExternalOrdersImport = () => {
    const [step, setStep] = useState<Step>(STEPS[0]);
    const [state, setState] = useState<State>({});
    const currentStepIndex = STEPS.indexOf(step) ?? 0;

    const translate = useTranslate();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleStepSubmit =
        <T extends Step>(step: T) =>
        async (values?: T extends 'upload' | 'mapping' ? State[T] : undefined) => {
            const nextStep = STEPS[currentStepIndex + 1];
            const newState = { ...state, [step]: values };

            if (nextStep) {
                setStep(nextStep);
                setState(newState);
            } else {
                const formData = new FormData();

                const file = state.upload?.file.rawFile;
                formData.append('file', file!);
                formData.append('mapping', JSON.stringify(state.mapping));

                try {
                    const response = await post<{ count: number }>('/api/errands/external_sales_import', {
                        body: formData,
                    });

                    notify('app.message.bulk_import', {
                        type: 'info',
                        messageArgs: { smart_count: response.count },
                    });
                    redirect('/errands');
                    refresh();
                } catch (e) {
                    notifyError(e);
                }
            }
        };

    const handleGoBack = () => {
        if (step === 'upload') {
            redirect('/errands');
        } else {
            const prevStep = currentStepIndex - 1 < 0 ? 0 : currentStepIndex - 1;
            setStep(STEPS[prevStep]);
        }
    };

    const StepComponent = {
        upload: UploadStep,
        mapping: MappingStep,
        confirm: ConfirmStep,
    }[step];

    return (
        <>
            <Title title={`${translate('app.action.import')} ${translate('app.label.orders').toLowerCase()}`} />
            <Card>
                <StepComponent onSubmit={handleStepSubmit(step)} onGoBack={handleGoBack} state={state} />
            </Card>
        </>
    );
};

export default ExternalOrdersImport;
