import { FC } from 'react';
import { useResourceContext } from 'react-admin';
import { Box } from '@material-ui/core';

import LockRecordIcon from '@components/detail/LockRecordIcon';
import SplitSelloButton from '@components/resource/reclamation/button/SplitSelloButton';

import { Reclamation } from '@js/interfaces/reclamation';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

interface Props {
    resource?: string;
    record?: Reclamation;
}

const ViewRowActions: FC<Props> = ({ record, ...props }) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon record={record} />
            {permissions.isListActionEnabled(resource, 'split') && (
                <SplitSelloButton record={record} resource={resource} />
            )}
        </Box>
    );
};

export default ViewRowActions;
