import { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { debounce, get as lodashGet } from 'lodash';
import { FieldTitle, useInput, useRecordContext, useTranslate, Validator, InputHelperText } from 'react-admin';

import { get } from '@js/request/apiRequest';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';

interface Props {
    source: string;
    resource?: string;
    helperText?: string | false;
    validate?: Validator | Validator[];
}

const SerialNumberInput = ({ helperText: helperTextProp, ...props }: Props) => {
    const { source } = props;
    const record = useRecordContext(props);
    const validate = useInputAutoValidator(props);
    const {
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({ source, validate });
    const [helperText, setHelperText] = useState<string>();
    const abortControllerRef = useRef<AbortController | null>(null);
    const translate = useTranslate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadBySerialNumber = useCallback(
        debounce(async (serialNumber) => {
            // eslint-disable-line
            // Reset
            setHelperText(undefined);

            if (!serialNumber || serialNumber === lodashGet(record, source)) {
                return;
            }
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            const abortController = new AbortController();

            abortControllerRef.current = abortController;
            try {
                const { totalItems } = await get<{ totalItems: number }>(
                    '/api/reclamations/count',
                    { serialNumber },
                    { signal: abortController.signal },
                );

                if (totalItems > 0) {
                    setHelperText(
                        translate('resources.reclamations.helperText.serialNumber', { smart_count: totalItems }),
                    );
                }
            } catch (error) {
                // ignore errors
            }
        }, 300),
        [],
    );

    useEffect(() => {
        loadBySerialNumber(input.value);
    }, [input.value]); // eslint-disable-line

    const hasError = !!(touched && (error || submitError));

    return (
        <TextField
            label={<FieldTitle source={source} resource={props.resource} isRequired={isRequired} />}
            {...input}
            variant="filled"
            margin="dense"
            error={!!(touched && (error || submitError))}
            helperText={
                hasError ? (
                    <InputHelperText touched={!!touched} error={error || submitError} />
                ) : (
                    (helperTextProp || helperText) && (
                        <>
                            {helperTextProp}
                            {helperText && (
                                <>
                                    {helperTextProp && <br />}
                                    {helperText}
                                </>
                            )}
                        </>
                    )
                )
            }
            fullWidth
        />
    );
};

export default SerialNumberInput;
