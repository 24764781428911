import { useState } from 'react';
import {
    CardContentInner,
    ResourceContextProvider,
    Title,
    useGetResourceLabel,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { Card, Step, StepLabel, Stepper } from '@material-ui/core';

import UploadFileForm from './UploadFileForm';
import MappingForm from './MappingForm';
import ConfirmationForm from './ConfirmationForm';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';

export interface FormFile {
    src: string;
    title: string;
    rawFile: File;
}

const STEPS = ['upload', 'mapping', 'confirmation'] as const;
type Step = (typeof STEPS)[number];

export type ImportState = {
    upload?: {
        import?: { file?: FormFile; firstLine?: Record<string, string> };
        errandData?: {
            images?: FormFile[];
            [key: string]: any;
        };
    };
    mapping?: {
        mapping: Record<string, string>;
        weightMeasure: 'kg' | 'g';
        exchangeRate?: number;
    };
};

const ErrandImport = () => {
    const [state, setState] = useState<ImportState>({});
    const [activeStep, setActiveStep] = useState<Step>('upload');
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const currentStepIndex = STEPS.indexOf(activeStep) ?? 0;

    const handleSubmit =
        <T extends Step>(step: T) =>
        async (values?: T extends 'upload' | 'mapping' ? ImportState[T] : undefined) => {
            const nextStep = STEPS[currentStepIndex + 1];
            const finalConfig = {
                ...state,
                [step]: values,
            };

            if (nextStep) {
                setActiveStep(nextStep);
                setState(finalConfig);
            } else {
                const formData = stateToFormData(finalConfig);

                try {
                    const { createdCount } = await post<{ createdCount: number }>('/api/errands/import', {
                        body: formData,
                    });

                    notify('app.message.bulk_import', {
                        type: 'info',
                        messageArgs: { smart_count: createdCount },
                    });
                    redirect('/errands');
                    refresh();
                } catch (error) {
                    notifyFailure(error);
                }
            }
        };

    const handleGoBack = () => {
        const prevStep = currentStepIndex - 1 < 0 ? 0 : currentStepIndex - 1;
        setActiveStep(STEPS[prevStep]);
    };

    const Form = {
        upload: UploadFileForm,
        mapping: MappingForm,
        confirmation: ConfirmationForm,
    }[activeStep];

    return (
        <div>
            <Title title={`${translate('app.action.import')} ${getResourceLabel('errands')}`} />
            <Card>
                <CardContentInner>
                    <Stepper activeStep={currentStepIndex}>
                        {STEPS.map((step) => (
                            <Step key={step}>
                                <StepLabel>{translate(`resources.errands.import.steps.${step}`)}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </CardContentInner>
                <ResourceContextProvider value="errands">
                    <Form onSubmit={handleSubmit(activeStep)} onGoBack={handleGoBack} state={state} />
                </ResourceContextProvider>
            </Card>
        </div>
    );
};

export const stateToFormData = (state: ImportState) => {
    const errandData = state.upload?.errandData;
    const images = state.upload?.errandData?.images;
    const importFile = state.upload?.import?.file;
    const mapping = state.mapping?.mapping;
    const weightMeasure = state.mapping?.weightMeasure;
    const exchangeRate = state.mapping?.exchangeRate;

    const formData = new FormData();

    // Append Errand data
    formData.append('errandData', JSON.stringify(errandData));
    images?.forEach((image) => {
        formData.append('images[]', image.rawFile);
    });

    // Append mapping form data
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('weightMeasure', weightMeasure!);
    if (exchangeRate) formData.append('exchangeRate', exchangeRate.toString());
    // Append import file
    formData.append('importFile', importFile!.rawFile);

    return formData;
};
export default ErrandImport;
