import { useRef, useState } from 'react';
import { Box, Toolbar as MuiToolbar } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { useListContext, useNotify, useRecordContext, useTranslate } from 'react-admin';
import OpacityIcon from '@material-ui/icons/Opacity';
import { makeStyles } from '@material-ui/core/styles';

import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import IconButton from '@components/button/IconButton';
import PalletFilterInput from './PalletFilterInput';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        alignItems: 'center',
        paddingRight: 0,
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 0,
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(2),
            justifyContent: 'flex-start',
        },
    },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleFinalFormSubmit = () => {};
const FormSpySubscription = { values: true, pristine: true };

const Co2Button = () => {
    const [loading, setLoading] = useState(false);
    const notifyFailure = useNotifyHttpError();
    const errand = useRecordContext();
    const notify = useNotify();
    const isFeatureDisabled = useIsFeatureDisabled();
    const translate = useTranslate();

    if (isFeatureDisabled('Co2')) return null;

    const handleClick = async () => {
        setLoading(true);

        try {
            await post<{ message: string }>(`${errand.id}/clear_savings`, {
                body: '{}',
            });
            notify(translate('app.label.done'));
        } catch (error) {
            notifyFailure(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box ml={1} display="flex" alignItems="end">
            <IconButton label="app.label.recalculate_savings" loading={loading} onClick={handleClick}>
                <OpacityIcon />
            </IconButton>
        </Box>
    );
};

const FilterForm = () => {
    const { setFilters, displayedFilters } = useListContext();
    const filterChangedFlag = useRef(false);

    return (
        <Form
            onSubmit={handleFinalFormSubmit}
            render={() => (
                <>
                    <FormSpy
                        subscription={FormSpySubscription}
                        onChange={({ pristine, values }) => {
                            if (pristine && !filterChangedFlag.current) {
                                return;
                            }

                            filterChangedFlag.current = true;
                            setFilters(values, displayedFilters);
                        }}
                    />
                    <Box display="flex" mr={2}>
                        <PalletFilterInput />
                        <Co2Button />
                    </Box>
                </>
            )}
        />
    );
};

const Toolbar = () => {
    const classes = useStyles();

    return (
        <MuiToolbar className={classes.toolbar}>
            <FilterForm />
            <VisibleColumnsButton />
        </MuiToolbar>
    );
};

export default Toolbar;
