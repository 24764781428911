import { lazy, MouseEvent, Suspense } from 'react';
import { LinearProgress } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { useField } from 'react-final-form';

import AcUnitIcon from '@material-ui/icons/AcUnit';

import IconButton from '@components/button/IconButton';

const FieldsTransferList = lazy(() => import('./FieldsTransferList'));

type Props = {
    source: string;
    requireableSource: string;
    resource: string;
    fields: Field[];
};

const ListFieldsTransferList = ({ requireableSource, ...props }: Props) => {
    return (
        <Suspense fallback={<LinearProgress />}>
            <FieldsTransferList
                {...props}
                renderFieldButtons={(field) => <RequiredFieldButton source={requireableSource} field={field} />}
            />
        </Suspense>
    );
};

const RequiredFieldButton = ({ source, field }: { source: string; field: string }) => {
    const { input } = useField(source);
    const value = Array.isArray(input.value) ? input.value : [];

    const isChecked = value.includes(field);
    const classes = useStyles({ checked: isChecked });

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();

        if (isChecked) {
            input.onChange(value.filter((v) => v !== field));
        } else {
            input.onChange([...value, field]);
        }
    };

    return (
        <IconButton label="app.label.is_required" onClick={handleClick}>
            <AcUnitIcon className={classes.icon} />
        </IconButton>
    );
};

const useStyles = makeStyles<Theme, { checked?: boolean }>((theme) => ({
    icon: ({ checked }) => ({
        fontSize: theme.typography.pxToRem(18),
        color: checked ? theme.palette.error.main : theme.palette.grey['500'],
    }),
}));

export default ListFieldsTransferList;
