import { FC } from 'react';
import { Labeled, useGetResourceLabel, useRecordContext } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';

import { ErrandDraft } from '@js/interfaces/erranddraft';

type Props = { record?: ErrandDraft };

const ErrandDraftExpandPanel: FC<Props> = (props) => {
    const record = useRecordContext(props);
    const getResourceLabel = useGetResourceLabel();

    if (!record) return null;
    const { data } = record;

    const renderFields = (resource: string, data: Record<string, any> | undefined) => {
        if (!data) return null;

        return (
            <Box display="flex" flexDirection="column" mb={1}>
                {Object.entries(data).map(([field, value]) => {
                    return (
                        <Labeled key={`${resource}-${field}`} resource={resource} source={field}>
                            <Typography component="span" variant="body2">
                                {value}
                            </Typography>
                        </Labeled>
                    );
                })}
            </Box>
        );
    };

    return (
        <Box mt={2}>
            <Grid container>
                <Grid item lg={4} md={12}>
                    <Typography variant="h6">{getResourceLabel('errands')}</Typography>
                    {renderFields('errands', data.errand)}
                </Grid>
                <Grid item lg={4} md={12}>
                    <Typography variant="h6">{getResourceLabel('reclamations')}</Typography>
                    {renderFields('reclamations', data.reclamation)}
                </Grid>
                <Grid item lg={4} md={12}>
                    <Typography variant="h6">{getResourceLabel('customers')}</Typography>
                    {renderFields('customers', data.customer)}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ErrandDraftExpandPanel;
