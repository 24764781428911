import { FC } from 'react';
import { useField } from 'react-final-form';

import PriceInput from '@components/input/PriceInput';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props {
    label?: string;
    resource?: string;
    disabled?: boolean;
    source: string;
}

const PriceCurrencySpyInput: FC<Props> = ({ label, source, resource, ...props }) => {
    const {
        input: { value: currency },
    } = useField('currency', { subscription: { value: true } });
    const translateField = useTranslateResourceField(resource);

    return (
        <PriceInput
            {...props}
            source={source}
            resource={resource}
            label={`${label || translateField(source)} (${currency})`}
            fullWidth
        />
    );
};

export default PriceCurrencySpyInput;
