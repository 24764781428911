/* eslint-disable react/jsx-key */
import { FC, useMemo } from 'react';
import {
    AutocompleteInput,
    ChipField,
    Datagrid,
    DateField,
    List,
    ListProps,
    Record as RaRecord,
    ReferenceInput,
    SelectInput,
    SimpleList,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';

import ReferenceField from '@components/field/ReferenceField';
import DateRangeFilter from '@components/filter/DateRangeFilter';
import { ScrollableSelectInputGuesser } from '@components/form/InputGuesser';

import LogEntryExpandPanel from './LogEntryExpandPanel';
import ActionField from './field/ActionField';
import ResourceField from './field/ResourceField';
import RestoreButton from './RestoreButton';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { ACTION, LogEntry, LogLevel } from '@js/interfaces/logentry';

const sort = { field: 'loggedAt', order: 'DESC' };

const rowStyle = (record: RaRecord) => ({
    ...((record as LogEntry)?.level === LogLevel.ERROR && { backgroundColor: 'rgb(253, 236, 234)' }),
});

const LogEntryList: FC<ListProps> = (props) => {
    const isSmall = useIsSmallScreen();
    const translate = useTranslate();
    const filters = useFilters();

    return (
        <List {...props} exporter={false} sort={sort} filters={filters} bulkActionButtons={false}>
            {isSmall ? (
                <SimpleList<LogEntry>
                    primaryText={(record) => translate(`resources.log_entries.actions.${record.action}`)}
                    secondaryText={(record) => record.username}
                    rowStyle={rowStyle}
                />
            ) : (
                <Datagrid
                    expand={<LogEntryExpandPanel />}
                    isRowExpandable={(logEntry) => logEntry && logEntry.action !== ACTION.VIEW}
                    rowStyle={rowStyle}
                >
                    <DateField source="loggedAt" showTime sortable />
                    <ActionField />
                    <ReferenceField reference="users" source="user">
                        <ChipField source="fullName" />
                    </ReferenceField>
                    <ResourceField />
                    <RowActions />
                </Datagrid>
            )}
        </List>
    );
};

const RowActions = (props: { record?: LogEntry }) => {
    const record = useRecordContext(props);

    if (!record) return null;
    // Support only restores action for certain resources
    if (record.action !== ACTION.REMOVE || !['errands', 'reclamations'].includes(record.resource)) return null;

    return (
        <Box display="flex" justifyContent="end">
            <RestoreButton record={record} />
        </Box>
    );
};

const useFilters = () => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    return useMemo(
        () => [
            <SelectInput
                choices={Object.values(ACTION).map((action) => ({
                    id: action,
                    name: translate(`resources.log_entries.actions.${action}`, {
                        _: translate(`app.action.${action}`, { _: action }),
                    }),
                }))}
                source="action"
                translateChoice={false}
                alwaysOn
            />,
            <DateRangeFilter source="loggedAt" />,
            <ScrollableSelectInputGuesser
                reference="users"
                source="user"
                sort={{ field: 'firstName', order: 'ASC' }}
                label={getResourceLabel('users')}
            />,
            <ReferenceInput
                reference="errands"
                source="object"
                sort={{ field: 'createdAt', order: 'DESC' }}
                label={getResourceLabel('errands')}
            >
                <AutocompleteInput
                    optionText={(record) => (record?.name ?? record?.caseNumber) || '[No title]'}
                    resettable
                />
            </ReferenceInput>,
        ],
        [getResourceLabel, translate],
    );
};

export default LogEntryList;
