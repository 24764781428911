import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Loading, useDataProvider, useGetResourceLabel, useLogoutIfAccessDenied } from 'react-admin';
import { Resource } from '@api-platform/api-doc-parser';
import {
    ApiPlatformAdminDataProvider,
    ApiPlatformAdminState,
    SchemaAnalyzer,
    SchemaAnalyzerContext,
} from '@api-platform/admin';
import { useSelector } from 'react-redux';
import { useIsResourceDisabled } from '@js/context/AppConfigContext';
import menu, { isMenuItemGroup } from '@js/menu/menuBuilder';

interface HydraSchemaContextValue {
    resources: Resource[];
    schemaAnalyzer: SchemaAnalyzer;
}

export interface LabeledResource extends Resource {
    label: string;
}

const HydraSchemaContext = createContext<HydraSchemaContextValue>(undefined!);

const useShowLoadingPage = (delay: number): boolean => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    return show;
};

export const HydraSchemaContextProvider = ({ children }: { children: ReactNode }) => {
    const logoutIfAccessDenied = useLogoutIfAccessDenied();
    const schemaAnalyzer = useContext<SchemaAnalyzer | null>(SchemaAnalyzerContext);
    // Show loading page with delay
    const showLoadingPage = useShowLoadingPage(50);

    const { resources } = useSelector<ApiPlatformAdminState, { resources?: Resource[] | null }>(
        (state) => state.introspect.introspect?.data ?? { resources: null },
    );
    const dataProvider = useDataProvider<ApiPlatformAdminDataProvider>();

    const schemaAnalyzerProxy = useMemo(() => {
        if (!schemaAnalyzer) {
            return null;
        }

        return new Proxy(schemaAnalyzer, {
            get: (target, key: keyof SchemaAnalyzer) => {
                if (typeof target[key] !== 'function') {
                    return target[key];
                }

                return (...args: never[]) => {
                    // eslint-disable-next-line prefer-spread,@typescript-eslint/ban-types
                    const result = (target[key] as Function).apply(target, args);

                    if (result && typeof result.then === 'function') {
                        return result.catch((e: Error) => {
                            logoutIfAccessDenied(e).then((loggedOut) => {
                                if (loggedOut) {
                                    return;
                                }

                                throw e;
                            });
                        });
                    }

                    return result;
                };
            },
        });
    }, [schemaAnalyzer, logoutIfAccessDenied]);

    useEffect(() => {
        if (resources) {
            return;
        }

        dataProvider.introspect(undefined, {}, { action: 'INTROSPECT' }).catch((introspectError) => {
            console.error(introspectError);

            throw new Error('API schema is not readable');
        });
    }, [dataProvider, resources]);

    const context = useMemo<HydraSchemaContextValue | null>(() => {
        if (!resources || !schemaAnalyzerProxy) {
            return null;
        }

        return {
            resources,
            schemaAnalyzer: schemaAnalyzerProxy,
        };
    }, [resources, schemaAnalyzerProxy]);

    // This is rendered as child of Layout. Layout now render redirect to login page until JWT token is obtained,
    // and because app/assets/js/hydra/dataProvider.tsx doesn't call introspect anymore after successful login we need to show loading page,
    // because introspect will be called in use useEffect above
    if (!context) {
        return showLoadingPage ? <Loading /> : null;
    }

    return <HydraSchemaContext.Provider value={context}>{children}</HydraSchemaContext.Provider>;
};

export const useHydraSchemaContext = (): HydraSchemaContextValue => {
    return useContext(HydraSchemaContext);
};

export const useSortedLabeledResources = (): LabeledResource[] => {
    const { resources } = useHydraSchemaContext();
    const getResourceLabel = useGetResourceLabel();
    const isResourceDisabledBySettings = useIsResourceDisabled();

    const visibleResources = [
        ...menu.map((item) => (isMenuItemGroup(item) ? item.items : item)).flat(),
        'addresses',
        'contact_people',
        'reclamations',
        'predefined_texts',
        'sello_sales',
        'comments',
    ].filter((resource) => !isResourceDisabledBySettings(resource));

    const showFirst = ['errands', 'reclamations'].reverse();
    return [...resources]
        .filter((resource) => visibleResources.includes(resource.name))
        .map((resource) => ({ ...resource, label: getResourceLabel(resource.name) }))
        .sort((r1, r2) => {
            const r1Index = showFirst.indexOf(r1.name);
            const r2Index = showFirst.indexOf(r2.name);
            if (r1Index !== r2Index) return r1Index < r2Index ? 1 : -1;

            return r1.label > r2.label ? 1 : -1;
        });
};
