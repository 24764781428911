import { FC, forwardRef, MouseEvent, useEffect, useRef, useState } from 'react';
import {
    FieldTitle,
    InputHelperText,
    LinearProgress,
    Record as RaRecord,
    SortPayload,
    useGetList,
    useGetMany,
    useGetManyReference,
    useInput,
    useLocale,
    useResourceContext,
    useTranslate,
    Validator,
} from 'react-admin';
import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    Menu,
    MenuItem as MuiMenuItem,
    Select,
    SelectProps,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import clsx from 'classnames';

import SearchIcon from '@material-ui/icons/Search';

import { MinusSquare, PlusSquare } from '@components/mui/TreeView';
import InputEndAdornment from '@components/input/InputEndAdornment';

import useInputReferenceField from '@js/hooks/useInputReferenceField';
import useRefCallback from '@js/hooks/useRefCallback';

import { Iri } from '@js/interfaces/ApiRecord';
import { Locale } from '@js/context/AppConfigContext';
import { GetManyReferenceResult } from '@components/types';

type ReferenceSelectInputProps = {
    resource?: string;
    reference?: string;
    fullWidth?: boolean;
    label?: string;
    source: string;
    sort?: SortPayload;
    parentField?: string;
    childrenField?: string;
    validate?: Validator | Validator[];
    alwaysOn?: boolean;
    helperText?: string | false;
    disabledOption?: (option: RaRecord) => boolean;
    loading?: boolean;
    disabled?: boolean;
    initialValue?: any;
    searchInFieldName?: boolean;
    multiple?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    margin?: 'dense' | 'none' | 'normal';
    filter?: Record<string, any>;
};

interface Item extends RaRecord {
    parent: Iri | null;
    children: Iri[];
    breadcrumbs: string[] | Record<Locale, string>[];
}

const ReferenceRecursiveSelectInputView: FC<ReferenceSelectInputProps & { fieldName: string; reference: string }> = ({
    disabled: forceDisabled,
    loading: forceLoading,
    disabledOption,
    helperText,
    fullWidth,
    fieldName,
    reference,
    margin = 'dense',
    variant = 'filled',
    filter,
    searchInFieldName,
    ...props
}) => {
    const {
        label,
        source,
        multiple,
        sort = { field: fieldName, order: 'ASC' },
        parentField = 'parent',
        childrenField = 'children',
    } = props;
    const pagination = { page: 1, perPage: 1000 };

    const [query, setQuery] = useState<string>('');
    const [menuExited, setMenuExited] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const [menuMinWidth, setMenuMinWidth] = useState<number>();
    const resource = useResourceContext(props);
    const locale = useLocale() as Locale;
    const classes = useInputStyles();

    const {
        data: searchData = {},
        loading: searchDataLoading,
        loaded: searchDataLoaded,
        total: searchDataTotal,
    } = useGetList<Item>(
        reference,
        pagination,
        sort,
        {
            [searchInFieldName ? fieldName : 'q']: query,
        },
        { enabled: !!query },
    );

    const {
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput(props);
    const value = multiple
        ? Array.isArray(input.value)
            ? (input.value as string[])
            : []
        : typeof input.value === 'string'
        ? input.value
        : null;

    const {
        data: rootData = {},
        loading: rootDataLoading,
        loaded: rootDataLoaded,
    } = useGetList<Item>(reference, pagination, sort, {
        [`exists[${parentField}]`]: false,
        ...filter,
    });

    const { loaded: isLoadedValue, data: valueData = [] } = useGetMany(
        reference,
        Array.isArray(value) ? value : value ? [value] : [],
    );
    const valueRecords = valueData.filter(Boolean) as Item[];

    const breadcrumbs = (() => {
        if (multiple) return [];

        const breadcrumbs = valueRecords[0]?.breadcrumbs ?? [];
        return breadcrumbs.map((breadcrumb) =>
            typeof breadcrumb === 'object' ? breadcrumb[locale as Locale] : breadcrumb,
        );
    })();
    const previousBreadcrumbs = useRef<string[]>();

    useEffect(() => {
        if (menuExited) {
            previousBreadcrumbs.current = breadcrumbs;
        }
    }, [breadcrumbs, menuExited]);

    const loading = forceLoading || rootDataLoading || !isLoadedValue;
    const disabled = forceDisabled || !rootDataLoaded || !isLoadedValue;
    const recordsMapToArray = (record: Record<Iri, Item>) => Object.values(record).filter(Boolean);

    const handleOpenMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const target = event.currentTarget;

        setMenuMinWidth(target.clientWidth);
        setAnchorEl(target);
        setMenuExited(false);
    };

    const handleCloseMenu = () => {
        setAnchorEl(undefined);
    };

    const handleSelect = (item: RaRecord) => {
        handleCloseMenu();

        if (Array.isArray(value)) {
            const id = item.id.toString();
            input.onChange(value.includes(id) ? value.filter((value) => value !== id) : [...value, id]);
        } else {
            input.onChange(item.id);
        }
    };

    const handleMenuExited = () => {
        setMenuExited(true);
        setQuery('');
    };

    const handleClear = (id?: Iri) => {
        if (id && Array.isArray(value)) {
            input.onChange(value.filter((value) => value !== id));
        } else {
            input.onChange(null);
        }
    };

    const fieldLabel = <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />;
    const touchedError = !!(touched && (error || submitError));
    const endAdornment = <InputEndAdornment loading={loading} disabled={multiple || !value} onClear={handleClear} />;

    return (
        <>
            {multiple ? (
                <FormControl variant={variant} margin={margin} error={touchedError}>
                    <InputLabel error={touchedError}>{fieldLabel}</InputLabel>
                    <Select
                        disabled={disabled}
                        fullWidth={fullWidth}
                        value={valueRecords}
                        autoWidth
                        inputProps={{
                            endAdornment,
                        }}
                        onClick={handleOpenMenu}
                        inputComponent={SelectInput}
                        renderValue={(choices) =>
                            (choices as typeof valueRecords).filter(Boolean).map((item) => (
                                <Chip
                                    key={item.id}
                                    label={get(item, fieldName)}
                                    size="small"
                                    className={classes.chip}
                                    onDelete={(e) => {
                                        e.preventDefault();
                                        handleClear(item.id.toString());
                                    }}
                                />
                            ))
                        }
                    />
                </FormControl>
            ) : (
                <TextField
                    disabled={disabled}
                    fullWidth={fullWidth}
                    value={get(valueRecords[0], fieldName, '')}
                    label={fieldLabel}
                    variant={variant}
                    margin={margin}
                    onMouseDown={handleOpenMenu}
                    InputProps={{
                        endAdornment,
                    }}
                    helperText={
                        <InputHelperText
                            touched={!!touched}
                            error={error || submitError}
                            helperText={helperText || breadcrumbs.slice(0, -1).join(' > ')}
                        />
                    }
                    error={touchedError}
                />
            )}
            <Menu
                id={`menu-${source}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                MenuListProps={{
                    role: 'listbox',
                    disableListWrap: true,
                }}
                PaperProps={{
                    style: {
                        minWidth: menuMinWidth,
                    },
                }}
                TransitionProps={{ onExited: handleMenuExited }}
            >
                {(previousBreadcrumbs.current ?? breadcrumbs).length > 0 && (
                    <Box mb={1}>
                        <MuiMenuItem>
                            {!isLoadedValue ? (
                                <LinearProgress />
                            ) : (
                                stringifyItemBreadcrumbs(previousBreadcrumbs.current ?? breadcrumbs, locale)
                            )}
                        </MuiMenuItem>
                        <Divider />
                    </Box>
                )}
                <MuiMenuItem>
                    <SearchInput loading={searchDataLoading} onSearch={setQuery} />
                </MuiMenuItem>
                {query ? (
                    <SearchResults
                        items={recordsMapToArray(searchData)}
                        loaded={searchDataLoaded}
                        total={searchDataTotal}
                        onSelect={handleSelect}
                        disabledOption={disabledOption}
                        locale={locale}
                    />
                ) : (
                    <MenuItems
                        items={recordsMapToArray(rootData)}
                        fieldName={fieldName}
                        reference={reference}
                        onSelect={handleSelect}
                        level={1}
                        childrenField={childrenField}
                        disabledOption={disabledOption}
                        sort={sort}
                        parentField={parentField}
                    />
                )}
            </Menu>
        </>
    );
};

const SelectInput = ({
    renderValue,
    variant,
    value,
    disabled,
    className,
    endAdornment,
    classes = {},
}: Pick<SelectProps, 'value' | 'renderValue' | 'classes' | 'className' | 'disabled' | 'variant' | 'endAdornment'>) => {
    return (
        <>
            <Box
                display="flex"
                flexWrap="wrap"
                minWidth="130px !important"
                className={clsx(
                    classes.root,
                    classes.select,
                    classes.selectMenu,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    classes[variant],
                    {
                        [classes.disabled as string]: disabled,
                    },
                    className,
                )}
                role="button"
            >
                {renderValue?.(value)}
            </Box>
            {endAdornment}
        </>
    );
};

type SearchResultsProps = {
    onSelect: MenuListProps['onSelect'];
    items: MenuListProps['items'];
    loaded: boolean;
    total?: number;
    disabledOption: ReferenceSelectInputProps['disabledOption'];
    locale: Locale;
};

const SearchResults = forwardRef<HTMLLIElement, SearchResultsProps>(
    ({ onSelect, loaded, total, items, disabledOption, locale }, ref) => {
        const translate = useTranslate();

        if (!loaded || !total) {
            return (
                <MuiMenuItem ref={ref} disabled>
                    {!loaded ? <LinearProgress /> : translate('ra.page.not_found')}
                </MuiMenuItem>
            );
        }

        return (
            <>
                {items.map((item) => (
                    <MuiMenuItem
                        key={item.id}
                        onClick={() => onSelect(item)}
                        ref={ref}
                        disabled={disabledOption?.(item)}
                    >
                        {stringifyItemBreadcrumbs(item.breadcrumbs ?? [], locale)}
                    </MuiMenuItem>
                ))}
            </>
        );
    },
);
SearchResults.displayName = 'SearchResults';

type SearchInputProps = { onSearch: (query: string) => void; loading: boolean };

const SearchInput = ({ onSearch, loading }: SearchInputProps) => {
    const [query, setQuery] = useState('');
    const translate = useTranslate();
    const [ref, setRef] = useRefCallback<HTMLDivElement>();

    useEffect(() => {
        const timeout = setTimeout(() => {
            onSearch(query);
        }, 300);

        return () => clearTimeout(timeout);
    }, [onSearch, query]);

    useEffect(() => {
        ref?.querySelector('input')?.focus();
    }, [ref]);

    return (
        <TextField
            variant="filled"
            margin="dense"
            hiddenLabel
            label=""
            placeholder={translate('ra.action.search')}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {loading ? <CircularProgress thickness={2} size={18} /> : <SearchIcon color="disabled" />}
                    </InputAdornment>
                ),
            }}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={(event) => event.stopPropagation()}
            fullWidth
            ref={setRef}
        />
    );
};

interface MenuListProps {
    items: Item[];
    fieldName: string;
    reference: string;
    onSelect: (item: Item) => void;
    level: number;
    childrenField: string;
    parentField: string;
    disabledOption: ReferenceSelectInputProps['disabledOption'];
    sort: SortPayload;
}

const MenuItems = forwardRef<HTMLLIElement, MenuListProps>(({ items, ...props }, ref) => {
    const classes = useMenuStyles(props);

    return (
        <>
            {items.map((item) => {
                return <MenuItem key={item.id} item={item} ref={ref} listItemClassName={classes.listItem} {...props} />;
            })}
        </>
    );
});
MenuItems.displayName = 'MenuItems';

interface MenuItemProps extends Omit<MenuListProps, 'items'> {
    item: Item;
    listItemClassName: string;
}

const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(({ item, level, listItemClassName, ...props }, ref) => {
    const { reference, fieldName, childrenField, onSelect, disabledOption, sort, parentField } = props;
    const children = get(item, childrenField, []) as Iri[];
    const hasChildren = children.length > 0;
    const disabled = item && disabledOption?.(item);

    const [expand, setExpand] = useState(false);
    const enabled = hasChildren && expand;

    const { data, ids, loaded } = useGetManyReference(
        reference,
        parentField,
        item?.id ?? 0,
        { page: 1, perPage: 99 },
        sort,
        {},
        reference,
        {
            enabled,
        },
    ) as GetManyReferenceResult<Item>;
    const subItems = ids.filter((id) => id && data[id]).map((id) => data[id]);

    const handleSelect = () => {
        if (hasChildren) {
            setExpand(!expand);
            return;
        }

        if (!disabled) {
            onSelect(item);
        }
    };

    return (
        <>
            <MuiMenuItem onClick={handleSelect} ref={ref} disabled={disabled}>
                <ListItemIcon className={listItemClassName}>
                    {hasChildren ? (
                        expand ? (
                            loaded ? (
                                <MinusSquare />
                            ) : (
                                <CircularProgress size={16} thickness={2} />
                            )
                        ) : (
                            <PlusSquare />
                        )
                    ) : null}
                </ListItemIcon>
                <Typography variant="inherit">{get(item, fieldName)}</Typography>
            </MuiMenuItem>
            {enabled && loaded && <MenuItems {...props} items={subItems} level={level + 1} />}
        </>
    );
});
MenuItem.displayName = 'MenuItem';

const useMenuStyles = makeStyles<Theme, Pick<MenuListProps, 'level'>>({
    listItem: ({ level }) => ({
        minWidth: 22,
        marginLeft: (level - 1) * 20,
    }),
});

const useInputStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(1 / 4),
    },
}));

const stringifyItemBreadcrumbs = (breadcrumbs: Item['breadcrumbs'], locale: Locale) => {
    return breadcrumbs
        .map((breadcrumb) => (typeof breadcrumb === 'object' ? breadcrumb[locale as Locale] : breadcrumb))
        .join(' > ');
};

const ReferenceRecursiveSelectInput = (props: ReferenceSelectInputProps) => {
    const { reference, fieldName, multiple } = useInputReferenceField(props);

    return (
        <ReferenceRecursiveSelectInputView {...props} reference={reference} multiple={multiple} fieldName={fieldName} />
    );
};

export default ReferenceRecursiveSelectInput;
