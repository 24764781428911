import { useEffect, useState } from 'react';
import { get } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { Country } from '@js/context/AppConfigContext';

export type SelloIntegration = {
    id: number;
    display_name: string;
};

type ReturnType = {
    isLoading: boolean;
    integration: SelloIntegration | null;
};

const cache: Record<string, SelloIntegration> = {};

const useSelloIntegration = (id: number | undefined, country: Country | undefined): ReturnType => {
    const cacheKey = id && country ? `${id}-${country}` : null;

    const [integration, setIntegration] = useState(cacheKey ? cache[cacheKey] ?? null : null);
    const [isLoading, setIsLoading] = useState(!!cacheKey && !(cacheKey in cache));
    const notifyError = useNotifyHttpError();

    useEffect(() => {
        if (integration || !id || !country || !cacheKey) {
            return;
        }

        const abortController = new AbortController();
        setIsLoading(true);

        get<SelloIntegration>(`/api/sello_integrations/${id}/${country}`, {}, { signal: abortController.signal })
            .then((integration) => {
                setIntegration(integration);
                setIsLoading(false);
                cache[cacheKey] = integration;
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    notifyError(error);
                    setIsLoading(false);
                }
            });

        return () => abortController.abort();
    }, [cacheKey, country, id, integration, notifyError]);

    return {
        isLoading,
        integration,
    };
};

export default useSelloIntegration;
