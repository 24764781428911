import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ExternalOrderList from './ExternalSaleList';

export default {
    icon: MonetizationOnIcon,
    list: ExternalOrderList,
    show: null,
    create: null,
    edit: null,
};
