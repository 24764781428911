import { useField } from 'react-final-form';
import { useTranslate } from 'react-admin';
import { Box, TextField } from '@material-ui/core';

type Props = {
    startQueryParam?: string;
    endQueryParam?: string;
};

const DateRangeInput = ({ startQueryParam = 'after', endQueryParam = 'before' }: Props) => {
    const { input: startInput } = useField(startQueryParam);
    const { input: endInput } = useField(endQueryParam);
    const translate = useTranslate();

    return (
        <Box display="flex" gridGap={8}>
            <TextField
                {...startInput}
                type="date"
                variant="outlined"
                label={translate('app.label.start')}
                size="small"
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                {...endInput}
                type="date"
                variant="outlined"
                label={translate('app.label.end')}
                size="small"
                InputLabelProps={{ shrink: true }}
            />
        </Box>
    );
};

export default DateRangeInput;
