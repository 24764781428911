import { FC } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { ACTION, LogEntry } from '@js/interfaces/logentry';

interface Props {
    source?: string;
    record?: LogEntry;
    addLabel?: boolean;
}

const ActionField: FC<Props> = (props) => {
    const actionLabel = useActionLabel();
    const record = useRecordContext(props);

    return (
        <Typography component="span" variant="body2">
            {actionLabel(record?.action)}
        </Typography>
    );
};

export const useActionLabel = () => {
    const translate = useTranslate();

    return (action?: ACTION) => {
        if (!action) return '';

        const prefix = action === ACTION.PURCHASE_SEPARATELY ? 'app.action.' : 'resources.log_entries.actions.';
        return translate(`${prefix}${action}`);
    };
};

ActionField.defaultProps = {
    addLabel: true,
    source: 'action',
};

export default ActionField;
