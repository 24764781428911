import { useListContext, useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';

/**
 * Common hook to get the sales soldAt filter from the context of Reclamations list context and use it for sales tabs.
 *
 * When "sales.soldAt" filter is set, only Reclamations that has sales in a given period will be shown.
 * Also, when a user does export, only sales from a given period will be exported.
 * We need also to apply this filter when show sales for Reclamation.
 */

const useSalesContextFilter = () => {
    const context = useListContext();
    const translate = useTranslate();

    if (
        context?.resource !== 'reclamations' ||
        !context?.filterValues?.sales ||
        typeof context?.filterValues?.sales !== 'object'
    ) {
        return {
            filter: {},
            warning: null,
        };
    }

    return {
        filter: { ...context.filterValues.sales },
        warning: (
            <Box mt={2} mb={1}>
                <Alert severity="warning">{translate('app.label.sales_filter_warning')}</Alert>
            </Box>
        ),
    };
};

export default useSalesContextFilter;
