import { ReactNode, useState } from 'react';
import { ListBase, ListProps, Title, useListContext, useTranslate } from 'react-admin';
import { Box, Card, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import DatagridLoading from '@components/list/DatagridLoading';
import LocalesTabContent from './LocalesTabContent';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { LocalizationLabel } from '@js/interfaces/localizationlabel';
import { TranslationDomain, useAppConfigContext, Locale } from '@js/context/AppConfigContext';

const LocalizationLabelListView = (props: { children: ReactNode }) => {
    const translate = useTranslate();
    const isSmall = useIsSmallScreen();

    return (
        <Card>
            <Title title={translate('app.localizationManager.header')} />
            <Box mt={2}>
                {isSmall ? (
                    <Alert severity="warning">
                        <AlertTitle>Small screen</AlertTitle>
                        This view is not suitable for <strong>small screen</strong>!
                    </Alert>
                ) : (
                    props.children
                )}
            </Box>
        </Card>
    );
};

const Content = ({
    activeDomain,
    domains,
    locales,
    onDomainChange,
}: {
    activeDomain: TranslationDomain;
    domains: TranslationDomain[];
    locales: Locale[];
    onDomainChange: (domain: TranslationDomain) => void;
}) => {
    const translate = useTranslate();
    const { data, loaded, ids } = useListContext<LocalizationLabel>();

    const activeTab = domains.indexOf(activeDomain);
    const classes = useStyles();

    return (
        <div className={classes.tabWrapper}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={activeTab}
                onChange={(_e, index) => {
                    onDomainChange(domains[index]);
                }}
                className={classes.tabs}
            >
                {domains.map((domain) => (
                    <Tab key={domain} label={translate(`app.translation.domains.${domain}`)} />
                ))}
            </Tabs>
            {domains.map((domain) => {
                const isActive = activeDomain === domain;
                return (
                    <Box key={domain} role="tabpanel" hidden={!isActive} width="100%">
                        <Box px={3}>
                            {isActive ? (
                                loaded ? (
                                    <LocalesTabContent
                                        labels={ids.map((id) => data[id]).filter(Boolean)}
                                        locales={locales}
                                        domain={domain}
                                    />
                                ) : (
                                    <DatagridLoading nbChildren={5} />
                                )
                            ) : null}
                        </Box>
                    </Box>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    tabWrapper: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function LocalizationLabelList(props: ListProps) {
    const {
        i18n: { domains, locales },
    } = useAppConfigContext();
    const [activeDomain, setActiveDomain] = useState(domains[0]);

    return (
        <ListBase {...props} filter={{ domain: activeDomain }}>
            <LocalizationLabelListView>
                <Content
                    activeDomain={activeDomain}
                    domains={domains}
                    locales={locales}
                    onDomainChange={setActiveDomain}
                />
            </LocalizationLabelListView>
        </ListBase>
    );
}
