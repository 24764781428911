import { Box } from '@material-ui/core';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import { ComponentType, FC, ReactNode } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import SimpleToolbar from '@components/form/SimpleToolbar';
import LoadingButton from '@components/button/LoadingButton';

type Props = {
    cancel: {
        label?: string;
        onClick?: () => void;
        to?: string;
    };
    submit: {
        label?: string;
        saving?: boolean;
        disabled?: boolean;
        icon?: ComponentType;
        onSubmit: () => void;
    };
};

const Toolbar: FC<Props> = ({ cancel, submit }) => {
    const { icon: Icon = SaveIcon, onSubmit: onClick, saving: loading, ...rest } = submit;

    return (
        <SimpleToolbar>
            <Box display="flex" gridGap={10}>
                <Button
                    component={cancel.onClick ? undefined : (Link as unknown as ReactNode)}
                    size="medium"
                    color="default"
                    label="ra.action.cancel"
                    {...cancel}
                >
                    <CancelIcon />
                </Button>
                <LoadingButton
                    icon={<Icon />}
                    onClick={onClick}
                    loading={!!loading}
                    label="ra.navigation.next"
                    size="medium"
                    {...rest}
                />
            </Box>
        </SimpleToolbar>
    );
};

export default Toolbar;
