import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import TreeInput from '@components/input/TreeInput';
import { Status } from '@js/interfaces/status';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

type Props = {
    source: string;
    resource: string;
};

const FilterDefaultValues = (props: Props) => {
    const renderContent = () => {
        switch (props.resource) {
            case 'errands':
                return <ErrandReclamationDefaultValues {...props} statusSource="lastStatus" />;
            case 'reclamations':
                return <ErrandReclamationDefaultValues {...props} statusSource="status" />;
            default:
                return <Alert severity="error">Not supported {props.resource}</Alert>;
        }
    };

    return (
        <Box ml={5} mt={3}>
            {renderContent()}
        </Box>
    );
};

const ErrandReclamationDefaultValues = ({ source, resource, statusSource }: Props & { statusSource: string }) => {
    const getFieldLabel = useTranslateResourceField(resource);

    return (
        <TreeInput<Status>
            source={`${source}.${resource}.listFilters.defaultValues.${statusSource}`}
            label={getFieldLabel(statusSource)}
            reference="statuses"
            parentField="parent"
            parentsField="parents"
            childrenField="children"
            selectable={(record) => record.children.length === 0}
            multiple
        />
    );
};

export default FilterDefaultValues;
