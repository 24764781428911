import { FC, useState } from 'react';
import {
    BooleanInput,
    maxValue,
    minValue,
    NumberInput,
    RadioButtonGroupInput,
    required,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { Box, Button, Dialog, DialogActions, DialogContent as MuiDialogContent, DialogTitle } from '@material-ui/core';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import SaveIcon from '@material-ui/icons/Save';

import IconButton from '@components/button/IconButton';
import LoadingButton from '@components/button/LoadingButton';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';

import { Reclamation } from '@js/interfaces/reclamation';
import { SplitType, SplitTypes } from '@js/interfaces/product';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';
import { minutes } from '@js/validator/minutes';

type Props = {
    record?: Reclamation;
    resource?: string;
};

type FormValues = {
    numberOfProducts: number;
    splitType: SplitType;
    timeInMinutes: number | null;
    redirect: boolean;
};

const SplitSelloButtonView: FC<Props> = (props) => {
    const [open, setOpen] = useState(false);
    const fullScreen = useIsSmallScreen();
    const record = useRecordContext(props);

    if (!record) return null;

    const disabledReason = record.selloSynced
        ? 'resources.reclamations.split_disable_reason.sello_synced'
        : record.splitQuantityLeft <= 0
        ? 'app.label.out_of_stock'
        : undefined;
    const handleClose = () => setOpen(false);

    return (
        <>
            <IconButton label="app.action.split" disabledReason={disabledReason} onClick={() => setOpen(true)}>
                <FormatLineSpacingIcon />
            </IconButton>
            <Dialog fullScreen={fullScreen} fullWidth maxWidth="sm" open={open} onClose={handleClose}>
                <DialogContent record={record} onClose={handleClose} />
            </Dialog>
        </>
    );
};

const DialogContent = ({ record, onClose }: { record: Reclamation; onClose: () => void }) => {
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();
    const getResourceLabel = useGetResourceLabel();
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const { push } = useHistory();
    const refresh = useRefresh();

    const handleSubmit = async ({ redirect, ...values }: FormValues) => {
        try {
            const submitData = { ...values, reclamation: record.id };
            await post(`/api/reclamations/split`, { body: JSON.stringify(submitData) });

            if (redirect) {
                push({
                    pathname: '/products',
                    state: { _scrollToTop: true, _refresh: true },
                });
            } else {
                onClose();
                refresh();
            }
            notify('ra.notification.created', 'info');
        } catch (e) {
            notifyFailure(e);
        }
    };

    return (
        <>
            <DialogTitle>{translate('app.action.split')}</DialogTitle>
            <Form<FormValues>
                onSubmit={handleSubmit}
                initialValues={{
                    numberOfProducts: record.splitQuantityLeft,
                    splitType: SplitTypes[1],
                    timeInMinutes: null,
                    redirect: false,
                }}
                render={({ handleSubmit, submitting }) => (
                    <>
                        <MuiDialogContent>
                            <Box display="flex" flexDirection="column">
                                <NumberInput
                                    source="numberOfProducts"
                                    validate={[minValue(1), maxValue(record.splitQuantityLeft), required()]}
                                    min="1"
                                    max={record.splitQuantityLeft}
                                    label={getFieldLabel('quantity', 'reclamations')}
                                    helperText={false}
                                />
                                <NumberInput
                                    source="timeInMinutes"
                                    label={translate('resources.processing_times.fields.timePlaceholder')}
                                    fullWidth
                                    validate={[minutes(), minValue(1)]}
                                    helperText={translate('app.form.helperText.per_item')}
                                />
                                <RadioButtonGroupInput
                                    validate={required()}
                                    source="splitType"
                                    choices={SplitTypes.map((type) => ({
                                        id: type,
                                        name: `resources.products.type_choices.${type}`,
                                    }))}
                                    helperText={false}
                                />
                                <BooleanInput
                                    source="redirect"
                                    label={translate('app.message.redirect', {
                                        target: getResourceLabel('products'),
                                    })}
                                />
                            </Box>
                        </MuiDialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={onClose} color="primary">
                                {translate('ra.action.close')}
                            </Button>
                            <LoadingButton
                                onClick={handleSubmit}
                                loading={submitting}
                                label="ra.action.save"
                                icon={<SaveIcon />}
                            />
                        </DialogActions>
                    </>
                )}
            />
        </>
    );
};

const SplitSelloButton: FC<Props> = (props) => {
    const isFeatureDisabled = useIsFeatureDisabled();

    if (isFeatureDisabled('SplitProducts')) return null;

    return <SplitSelloButtonView {...props} />;
};

export default SplitSelloButton;
