import { useState } from 'react';
import { Title, useGetResourceLabel, useTranslate } from 'react-admin';
import { Box, Card, CardContent, Grid } from '@material-ui/core';

import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

import FilterForm from '../FilterForm';
import DateRangeInput from '../input/DateRangeInput';
import EconomicalValueList from './EconomicalValueList';
import CategoryEconomicalStatisticList from './CategoryEconomicalStatisticList';

type Filter = {
    store?: string;
    after?: string;
    before?: string;
};

const EconomicalDetailed = () => {
    const translate = useTranslate();
    const [filter, setFilter] = useState<Filter>();

    return (
        <>
            <Title title={translate('app.dashboard.section.economical_detailed')} />
            <Card>
                <CardContent>
                    <FilterForm initialValues={filter} onChange={setFilter}>
                        <Filters />
                    </FilterForm>
                    <Grid container spacing={4}>
                        <Grid item sm={12} md={4} lg={3}>
                            <EconomicalValueList filter={filter} />
                        </Grid>
                        <Grid item sm={12} md={8} lg={9}>
                            <CategoryEconomicalStatisticList filter={filter} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

const Filters = () => {
    const getResourceLabel = useGetResourceLabel();

    return (
        <Box display="flex" alignItems="center" gridGap="20px">
            <HierarchicalAutocompleteSelectInput
                label={getResourceLabel('stores')}
                reference="stores"
                source="store"
                variant="standard"
            />
            <DateRangeInput />
        </Box>
    );
};

export default EconomicalDetailed;
