import { FC } from 'react';
import { BooleanInput, useGetResourceLabel, useInput, useTranslate } from 'react-admin';
import {
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    makeStyles,
    Switch as MuiSwitch,
} from '@material-ui/core';
import {
    DashboardArea,
    DashboardDetailedPageSections,
    DashboardPermissions,
    DashboardSection,
} from '@js/auth/UserPermissionsService';

interface Props {
    source: string;
}

const DashboardSettings: FC<Props> = ({ source }) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const classes = useStyles();

    const getPermissionSource = (permission: keyof DashboardPermissions) => `${source}.${permission}`;
    const getSectionSource = (section: DashboardSection) => `${getPermissionSource('sections')}.${section}`;
    const getAreaSource = (area: DashboardArea) => `${getPermissionSource('areas')}.${area}`;
    const getDetailedPageSectionSource = <
        T extends keyof Required<DashboardDetailedPageSections>,
        K extends keyof Required<DashboardDetailedPageSections[T]>,
    >(
        page: T,
        section: K,
    ) => {
        return `${getPermissionSource('detailed_page_sections')}.${page}.${section as string}`;
    };

    return (
        <>
            <BooleanInput
                label={translate('resources.user_groups.label.allow')}
                source={getPermissionSource('enable')}
                helperText={false}
            />
            <div className={classes.sectionsWrapper}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{translate('app.dashboard.areas')}</FormLabel>
                    <FormGroup row>
                        <Switch
                            source={getAreaSource('count_cards_statistic')}
                            label={translate('app.dashboard.area.count_cards_statistic')}
                        />
                        <Switch
                            source={getAreaSource('per_day_chart')}
                            label={translate('app.dashboard.area.per_day_chart')}
                        />
                        <Switch
                            source={getAreaSource('count_total_stats')}
                            label={translate('app.dashboard.area.count_total_stats')}
                        />
                        <Switch
                            source={getAreaSource('reclamation_statistic')}
                            label={translate('app.dashboard.area.reclamation_statistic')}
                        />
                    </FormGroup>
                </FormControl>
            </div>
            <div className={classes.sectionsWrapper}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{translate('app.dashboard.area.count_total_stats')}</FormLabel>
                    <FormGroup>
                        <Switch source={getSectionSource('savings')} label={translate('app.label.co2')} />
                        <Switch
                            source={getSectionSource('savings_detailed')}
                            label={translate('app.dashboard.section.savings_detailed')}
                        />
                        <Divider />
                        <Switch
                            source={getSectionSource('records_total')}
                            label={`${getResourceLabel('errands')}/${getResourceLabel('reclamations')}`}
                        />
                        <Switch
                            source={getSectionSource('users_detailed')}
                            label={translate('app.dashboard.section.users_detailed')}
                        />
                        <Divider />
                        <Switch
                            source={getSectionSource('economical')}
                            label={translate('app.label.economical_overview')}
                        />
                        <Switch
                            label={translate('app.label.purchase_separately_sales')}
                            source={getSectionSource('purchase_separately_sales')}
                        />
                        <Switch label={getResourceLabel('manual_sales')} source={getSectionSource('manual_sales')} />
                        <Switch
                            source={getSectionSource('economical_detailed')}
                            label={translate('app.dashboard.section.economical_detailed')}
                        />
                        <Divider />
                        <Switch source={getSectionSource('statuses')} label={getResourceLabel('statuses')} />
                        <Divider />
                        <Switch source={getSectionSource('reports')} label={getResourceLabel('reports')} />
                    </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{translate('app.dashboard.section.economical_detailed')}</FormLabel>
                    <FormGroup>
                        <Switch
                            source={getDetailedPageSectionSource('economical_detailed', 'user_processing_time')}
                            label={translate('app.dashboard.section.user_processing_time')}
                        />
                    </FormGroup>
                </FormControl>
            </div>
        </>
    );
};

const Switch = ({ label, ...props }: { source: string; label: string }) => {
    const { input } = useInput({
        ...props,
        type: 'checkbox',
    });

    return <FormControlLabel control={<MuiSwitch {...input} color="primary" />} label={label} />;
};

const useStyles = makeStyles((theme) => ({
    sectionsWrapper: {
        display: 'flex',
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        '& .MuiDivider-root': {
            margin: theme.spacing(1, 0),
        },
    },
}));

export default DashboardSettings;
