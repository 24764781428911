import { Box, Chip } from '@material-ui/core';
import { InjectedFieldProps, useRecordContext, useTranslate } from 'react-admin';
import { FC } from 'react';
import { get } from 'lodash';

type Props = {
    source: string;
    choices: { id: string; name: string }[];
    fullWidth?: boolean;
    sortable?: boolean;
} & InjectedFieldProps;

const ArrayChipField: FC<Props> = ({ source, choices, ...rest }) => {
    const record = useRecordContext(rest);
    const translate = useTranslate();
    const values = get(record, source);

    if (!Array.isArray(values) || values.length === 0) {
        return null;
    }

    const labels = choices.filter((choice) => values.includes(choice.id)).map((choice) => choice.name);
    return (
        <>
            {labels.map((label) => (
                <Box key={label} m="4px">
                    <Chip label={translate(label, { _: label })} />
                </Box>
            ))}
        </>
    );
};

export default ArrayChipField;
