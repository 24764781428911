import { useCreate, useNotify, useRefresh } from 'react-admin';

import ReclamationForm from '@components/resource/reclamation/ReclamationForm';
import FormController, { FormControllerProps } from './FormController';
import { ReclamationFormCommonProps } from './ReclamationsEditTab';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useAutoTrackTimeController from '@js/hooks/useAutoTrackTimeController';

import { Reclamation } from '@js/interfaces/reclamation';

type Props = ReclamationFormCommonProps & {
    initialValues?: Partial<Reclamation>;
    onCancel: () => void;
    onSuccess?: (reclamation: Reclamation) => void;
} & Pick<FormControllerProps, 'validate' | 'disablePristine'>;

const CreateForm = ({ onSuccess, ...props }: Props) => {
    const [create] = useCreate<Reclamation>();
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const refresh = useRefresh();
    const { saveCreateSession } = useAutoTrackTimeController();

    const handleSubmit: FormControllerProps['onSubmit'] = async (data) =>
        create('reclamations', data, {
            onSuccess: ({ data }) => {
                notify('ra.notification.created', {
                    type: 'info',
                    messageArgs: { smart_count: 1 },
                });
                // Track time spent on creation
                saveCreateSession({
                    reclamation: data.id,
                });

                onSuccess?.(data);
                refresh();
            },
            onFailure: notifyFailure,
            returnPromise: true,
        });

    return (
        <FormController onSubmit={handleSubmit} {...props}>
            <ReclamationForm />
        </FormController>
    );
};

export default CreateForm;
