import { FC } from 'react';
import { linkToRecord, ListProps, useGetList } from 'react-admin';

import DatagridLoading from '@components/list/DatagridLoading';
import { SettingsRecord } from '@js/interfaces/settings';
import { Redirect } from 'react-router-dom';

const SettingsList: FC<ListProps> = (props) => {
    const { loaded, ids } = useGetList<SettingsRecord>('settings');

    if (!loaded || ids.filter(Boolean).length === 0) {
        return <DatagridLoading />;
    }

    // Get first. There is always only one settings record
    const id = ids[0];

    return <Redirect to={linkToRecord(props.basePath, id, 'edit')} />;
};

export default SettingsList;
