import { FC, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { useFieldArray } from 'react-final-form-arrays';
import { useTranslate } from 'react-admin';
import NotificationsIcon from '@material-ui/icons/Notifications';

import StatusNotificationsTable from './StatusNotificationsTable';
import StatusNotificationForm from './StatusNotificationForm';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { StatusNotification } from '@js/interfaces/statusNotification';

interface Props {
    source: string;
    resource?: string;
    disabled?: boolean;
}

const StatusNotificationsDialogButton: FC<Props> = ({ source, resource, disabled }) => {
    const [open, setOpen] = useState(false);
    const fullScreen = useIsSmallScreen();
    const translate = useTranslate();
    const translateField = useTranslateResourceField(resource);
    const title = translateField(source);
    const { fields: notifications } = useFieldArray<StatusNotification>(source);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleDelete = (index: number) => {
        notifications.remove(index);
    };

    const handleAdd = (data: StatusNotification) => {
        notifications.push({
            ...data,
            createdAt: new Date().toISOString(),
            notifiedAt: undefined,
        });
    };

    return (
        <>
            <IconButton size="medium" title={title} onClick={handleOpen} disabled={disabled}>
                <NotificationsIcon fontSize="inherit" />
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="lg">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box>
                        <StatusNotificationForm onSubmit={handleAdd} />
                        {Array.isArray(notifications.value) && (
                            <StatusNotificationsTable
                                notifications={notifications.value}
                                showActions
                                onDelete={handleDelete}
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StatusNotificationsDialogButton;
