import {
    DeleteButton,
    LinearProgress,
    ReferenceManyField,
    ReferenceManyFieldProps,
    useGetResourceLabel,
    useListContext,
    useTranslate,
} from 'react-admin';
import {
    Alert,
    Timeline as MuiTimeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AutoHidePagination from '@components/list/AutoHidePagination';
import TimelineUserTitle from './ErrandList/ExpandPanel/TimelineUserTitle';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { toLocaleString } from '@js/utility/dateUtility';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { FC } from 'react';
import { Comment } from '@js/interfaces/comment';

const useContentStyle = makeStyles((theme) => ({
    deleteButton: {
        padding: 0,
        paddingTop: theme.spacing(1),
    },
}));

const Content: FC<{ comment: Comment; resource?: string }> = ({ comment, resource }) => {
    const { createdBy, createdAt, text, field } = comment;
    const permissions = useUserPermissions();
    const translateField = useTranslateResourceField();
    const classes = useContentStyle();

    return (
        <>
            <Typography variant="body2" color="secondary">
                {toLocaleString(createdAt)}
                {createdBy && (
                    <>
                        {' | '}
                        <TimelineUserTitle id={createdBy} />
                    </>
                )}
                {field && ` | ${translateField(field, 'reclamations')}`}
            </Typography>
            <Typography>{text}</Typography>
            {permissions.isAdmin && (
                <DeleteButton record={comment} resource={resource} redirect={false} className={classes.deleteButton} />
            )}
        </>
    );
};

const useTimelineStyles = makeStyles({
    missingOppositeContent: {
        '&:before': {
            display: 'none',
        },
    },
});

const Timeline: FC = (props) => {
    const { loaded, data, ids, resource } = useListContext<Comment>(props);
    const translateResource = useGetResourceLabel();
    const translate = useTranslate();
    const classes = useTimelineStyles();

    if (!loaded) {
        return (
            <Box marginY={3}>
                <LinearProgress />
            </Box>
        );
    }

    if (ids.length === 0) {
        return (
            <Box marginY={3}>
                <Alert severity="info">{translate('ra.page.empty', { name: translateResource(resource) })}</Alert>
            </Box>
        );
    }

    return (
        <MuiTimeline align="left">
            {ids.map((id) => (
                <TimelineItem key={id} classes={classes}>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Content comment={data[id]} resource={resource} />
                    </TimelineContent>
                </TimelineItem>
            ))}
        </MuiTimeline>
    );
};

const CommentsTimeline: FC<Omit<ReferenceManyFieldProps, 'children' | 'reference' | 'target'>> = (props) => (
    <ReferenceManyField
        {...props}
        perPage={10}
        reference="comments"
        target="errand"
        pagination={<AutoHidePagination />}
    >
        <Timeline />
    </ReferenceManyField>
);

export default CommentsTimeline;
