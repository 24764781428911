import { FC } from 'react';
import { SimpleFormProps } from 'react-admin';

import SimpleFormGuesser from '@components/form/SimpleFormGuesser';
import AddressTypeInput from './AddressTypeInput';

const AddressForm: FC<Omit<SimpleFormProps, 'children' | 'onSubmit'>> = (props) => {
    return (
        <SimpleFormGuesser {...props}>
            <AddressTypeInput />
        </SimpleFormGuesser>
    );
};

export default AddressForm;
