import { FC } from 'react';
import { Identifier, useTranslate } from 'react-admin';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';

import { Reseller } from '@js/interfaces/reseller';

interface Props {
    resellers: Reseller[];
    selected: Identifier[];
    onChange: (checked: boolean, reseller: Reseller) => void;
}

const ResellersModalCheckboxGroup: FC<Props> = ({ resellers, selected, onChange }) => {
    const translate = useTranslate();

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">
                <span>{translate('resources.resellers.labels.chooseOverride')}</span>
            </FormLabel>
            <FormGroup row>
                {resellers.map((reseller) => (
                    <FormControlLabel
                        key={reseller.id}
                        control={
                            <Checkbox
                                name={reseller.id.toString()}
                                checked={selected.includes(reseller.id)}
                                onChange={(e, checked) => onChange(checked, reseller)}
                            />
                        }
                        label={reseller.name}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default ResellersModalCheckboxGroup;
