import { FC } from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

import ClickToCopyTooltip from '@components/button/ClickToCopyTooltip';
import FunctionField from './FunctionField';

interface Props {
    source: string;
    label?: string;
    addLabel?: boolean;
    sortable?: boolean;
}

const CopyableTextField: FC<Props> = ({ source, ...props }) => {
    return (
        <FunctionField
            {...props}
            render={(record) => {
                const value = get(record, source);

                return (
                    <ClickToCopyTooltip text={value}>
                        <Typography component="span" variant="body2">
                            {value}
                        </Typography>
                    </ClickToCopyTooltip>
                );
            }}
        />
    );
};

CopyableTextField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default CopyableTextField;
