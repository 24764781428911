import { FC, useState } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    LinearProgress,
    ReferenceManyField,
    ReferenceManyFieldProps,
    TextField,
    useLocale,
    useNotify,
    useRecordContext,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { get } from 'lodash';
import SyncIcon from '@material-ui/icons/Sync';
import VisibilityIcon from '@material-ui/icons/Visibility';

import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import RecordPriceField from '@components/field/RecordPriceField';
import BooleanTooltipField from '@components/field/BooleanTooltipField';
import IconButton from '@components/button/IconButton';
import SelloSalesPriceField from '@components/resource/sello_sales/field/SelloSalesPriceField';
import PassthroughProps from '@components/PassthroughProps';

import useSelloIntegration from '@js/hooks/useSelloIntegration';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { post } from '@js/request/apiRequest';
import { createSelloProductSearchUrl } from '@js/utility/sello';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useSalesContextFilter from '@components/resource/reclamation/useSalesContextFilter';

import { SelloSale } from '@js/interfaces/selloSale';
import { SelloOrderRow } from '@js/interfaces/selloOrderRow';

type Props = Omit<ReferenceManyFieldProps, 'children' | 'reference'>;

const SelloSalesShowTab: FC<Props> = (props) => {
    const locale = useLocale();
    const { filter, warning } = useSalesContextFilter();

    return (
        <ReferenceManyField
            {...props}
            perPage={10}
            reference="sello_sales"
            pagination={<AutoHidePagination />}
            sort={{ field: 'soldAt', order: 'desc' }}
            filter={filter}
        >
            <PassthroughProps>
                {(props) => (
                    <>
                        {warning}
                        <Datagrid {...props} empty={<AlertEmptyResource mb={2} />} expand={<ExpandSaleRow />}>
                            <DateField source="soldAt" showTime />
                            <TextField source="selloOrderId" sortable={false} />
                            <TextField source="selloRowId" sortable={false} />
                            <TextField source="customerEmail" sortable={false} />
                            <TextField source="customerName" sortable={false} />
                            <IntegrationField source="channel" sortable={false} />
                            <BooleanField source="paid" sortable={false} looseValue />
                            <BooleanField source="delivered" sortable={false} looseValue />
                            <BooleanTooltipField<SelloSale>
                                source="canceledAt"
                                valueLabelTrue={(r) =>
                                    r.canceledAt ? new Date(r.canceledAt).toLocaleString(locale) : undefined
                                }
                            />
                            <TextField source="rowQuantity" sortable={false} />
                            <SelloSalesPriceField sortable={false} />
                            <RecordPriceField source="totalVat" currencyField="currency" />
                            <ActionField />
                        </Datagrid>
                    </>
                )}
            </PassthroughProps>
        </ReferenceManyField>
    );
};

export const IntegrationField: FC<{
    source: string;
    record?: SelloSale | SelloOrderRow;
    sortable?: boolean;
    label?: string;
}> = (props) => {
    const record = useRecordContext(props);
    const value = get(record, props.source);

    const { integration, isLoading } = useSelloIntegration(value, record?.country);

    if (isLoading) return <LinearProgress />;
    if (!integration?.display_name) return null;

    return (
        <Typography component="span" variant="body2">
            {integration.display_name}
        </Typography>
    );
};

export const ActionField: FC<{ record?: SelloSale | SelloOrderRow; addLabel?: boolean; sortable?: boolean }> = (
    props,
) => {
    const record = useRecordContext(props);

    if (!record) return null;

    const handleOpenSelloClick = () => {
        const link = createSelloProductSearchUrl(record.productId);
        window.open(link, '_blank', 'noreferrer');
    };

    return (
        <Box display="flex" justifyContent="end">
            <IconButton label="app.action.open_in_sello" onClick={handleOpenSelloClick}>
                <VisibilityIcon />
            </IconButton>
            <ResyncSelloOrderButton record={record} />
        </Box>
    );
};

ActionField.defaultProps = {
    addLabel: false,
    sortable: false,
};

const ResyncSelloOrderButton = ({ record: { selloOrderId, country } }: { record: SelloSale | SelloOrderRow }) => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const notifyError = useNotifyHttpError();

    const handleClick = () => {
        if (loading) return;
        setLoading(true);

        post<{ message: 'ok' }>('/api/sello/resync_order', {
            body: JSON.stringify({ selloOrderId, country }),
        })
            .then(() => {
                notify('app.label.done', { type: 'info' });
            })
            .catch(notifyError)
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <IconButton label="app.action.resync" onClick={handleClick} loading={loading}>
            <SyncIcon />
        </IconButton>
    );
};

export const ExpandSaleRow = (props: { record?: SelloSale | SelloOrderRow }) => {
    const record = useRecordContext(props);
    const getFieldLabel = useTranslateResourceField('sello_sales');

    return (
        <Box m={1}>
            <b>{getFieldLabel('orderNotes')}</b>
            <pre>{record?.orderNotes || ' '}</pre>
        </Box>
    );
};

export default SelloSalesShowTab;
