import { FC } from 'react';
import { useGetMany, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';

import PriceField from '@components/field/PriceField';
import { Reclamation } from '@js/interfaces/reclamation';
import { Errand } from '@js/interfaces/errand';

interface Props {
    source: string;
    record?: Reclamation;
    resource?: string;
}

const PriceReclamationCurrencyField: FC<Props> = ({ source, ...props }) => {
    const reclamation = useRecordContext(props);
    const { data } = useGetMany('errands', [reclamation?.errand], { enabled: !!reclamation?.errand });
    const errand = data?.[0] as Errand | undefined;

    return (
        <Typography component="span" variant="body2">
            <PriceField currency={errand?.currency} source={source} {...props} />
        </Typography>
    );
};

export default PriceReclamationCurrencyField;
