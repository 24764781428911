import { useRecordContext } from 'react-admin';
import { ApiRecord } from '@js/interfaces/ApiRecord';
import { ReactElement, ReactNode } from 'react';

interface Props<T> {
    label?: string;
    addLabel?: boolean;
    source?: string;
    render: (record: T, props: Omit<Props<T>, 'render'>) => ReactNode;
}

const FunctionField = <T extends ApiRecord = ApiRecord>({ render, ...props }: Props<T>): ReactElement => {
    const record = useRecordContext<T>(props);

    return <>{record ? render(record, props) : null}</>;
};

FunctionField.defaultProps = {
    addLabel: true,
};

export default FunctionField;
