import { FC, ReactNode } from 'react';
import { Show, ShowProps, SimpleShowLayout, SimpleShowLayoutProps } from 'react-admin';

import FieldGuesser from '@components/field/FieldGuesser';
import RecordTitle from '@components/detail/RecordTitle';

import { getOverrideFields } from '@js/utility/fieldsUtil';
import useShowFields from '@js/hooks/useShowFields';

export interface ShowGuesserProps extends ShowProps {
    hideFields?: string[];
    children?: ReactNode;
}

const ShowGuesser: FC<ShowGuesserProps> = ({ hideFields, children, ...props }) => {
    return (
        <Show title={<RecordTitle />} {...props}>
            <SimpleShowLayoutGuesser hideFields={hideFields}>{children}</SimpleShowLayoutGuesser>
        </Show>
    );
};

export const SimpleShowLayoutGuesser = ({
    children,
    hideFields,
    ...props
}: SimpleShowLayoutProps & { hideFields?: string[] }) => {
    const fields = useShowFields(props, hideFields);
    const overrideFields = getOverrideFields(children);

    return (
        <SimpleShowLayout {...props}>
            {fields.map((field) => {
                return (
                    overrideFields[field.name] ?? <FieldGuesser key={field.name} source={field.name} field={field} />
                );
            })}
        </SimpleShowLayout>
    );
};

export default ShowGuesser;
