import { FC } from 'react';
import { useGetResourceLabel, useRecordContext, useTranslate } from 'react-admin';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useSortedLabeledResources } from '@js/context/HydraSchemaContext';
import useTranslateListAction from '../input/PermissionsInput/useTranslateListAction';
import { UserGroup } from '@js/interfaces/usergroup';

interface AllowedLabelProps {
    allowed: boolean | undefined;
}

interface FieldsListProps {
    label: string;
    translate: (key: string) => string;
    fields: string[] | undefined;
}

interface PermissionsFieldProps {
    source?: string;
    addLabel?: boolean;
}

const useStyles = makeStyles<Theme, AllowedLabelProps>((theme) => ({
    root: ({ allowed }) => ({
        color: allowed ? theme.palette.success.light : theme.palette.error.light,
    }),
}));

const AllowedLabel: FC<AllowedLabelProps> = ({ allowed }) => {
    const translate = useTranslate();
    const classes = useStyles({ allowed });

    return (
        <Typography className={classes.root} variant="body2" component="span">
            {translate(`resources.user_groups.label.${allowed ? 'allowed' : 'disallowed'}`)}
        </Typography>
    );
};

const FieldsList: FC<FieldsListProps> = ({ fields, label, translate }) => {
    if (!fields?.length) {
        return null;
    }

    return (
        <Typography component="div" variant="body2">
            {label}: {fields.map((field) => translate(field)).join(', ')}
        </Typography>
    );
};

const PermissionsField: FC<PermissionsFieldProps> = (props) => {
    const record = useRecordContext<UserGroup>(props);
    const getResourceLabel = useGetResourceLabel();
    const translateField = useTranslateResourceField();
    const translate = useTranslate();
    const resources = useSortedLabeledResources();
    const translateAction = useTranslateListAction();

    const permissions = record?.permissions;
    if (!permissions) {
        return null;
    }

    return (
        <>
            <Box display="flex">
                <Typography component="span" variant="body2">
                    {translate('app.dashboard.title')}: <AllowedLabel allowed={!!permissions.dashboard?.enable} />
                </Typography>
            </Box>
            <Box my={1}>
                <hr />
            </Box>
            {resources.map(({ name: resource }) => {
                const resourcePermissions = permissions['resources'][resource] ?? {};
                const { enable } = resourcePermissions;

                return (
                    <Box key={resource} mb={1}>
                        <Typography component="div" variant="body2">
                            {getResourceLabel(resource)}: <AllowedLabel allowed={!!enable} />
                        </Typography>
                        <FieldsList
                            label={translateField('listFields')}
                            fields={resourcePermissions.fields?.list}
                            translate={translateField}
                        />
                        <FieldsList
                            label={translateField('allowFields')}
                            fields={resourcePermissions.fields?.edit}
                            translate={translateField}
                        />
                        <FieldsList
                            label={translateField('requiredFields')}
                            fields={resourcePermissions.fields?.required}
                            translate={translateField}
                        />
                        <FieldsList
                            label={translateAction('list')}
                            fields={resourcePermissions.actions?.list}
                            translate={translateAction}
                        />
                        <FieldsList
                            label={translateAction('edit')}
                            fields={resourcePermissions.actions?.edit}
                            translate={translateAction}
                        />
                        <hr />
                    </Box>
                );
            })}
        </>
    );
};

PermissionsField.defaultProps = {
    addLabel: false,
    source: 'permissions',
};

export default PermissionsField;
