import { TextField, TextFieldProps, useLocale } from 'react-admin';

const TranslatableTextField = ({ source, ...props }: TextFieldProps) => {
    const locale = useLocale();

    return <TextField {...props} source={`${source}.${locale}`} />;
};

TranslatableTextField.defaultProps = {
    addLabel: true,
};

export default TranslatableTextField;
