import { FC } from 'react';
import { ReferenceField, ReferenceFieldProps, TextField } from 'react-admin';

import EditableField from '@components/field/EditableField';
import ScrollableSelectInput from '@components/input/ScrollableSelectInput';

const LocationField: FC<Omit<ReferenceFieldProps, 'children' | 'reference'>> = (props) => {
    return (
        <EditableField
            source={props.source}
            form={
                <ScrollableSelectInput
                    source="location"
                    reference="locations"
                    sort={{ field: 'name', order: 'ASC' }}
                    allowEmpty
                    fullWidth
                />
            }
        >
            <ReferenceField reference="locations" link={false} {...props}>
                <TextField source="name" />
            </ReferenceField>
        </EditableField>
    );
};

LocationField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default LocationField;
