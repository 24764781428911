import { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { Confirm, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { useCheckForApplicationUpdate } from '@js/hooks/useCheckForApplicationUpdate';

const CheckForApplicationUpdate = () => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const translate = useTranslate();
    const applicationUpdateAvailable = useCheckForApplicationUpdate();
    const classes = useStyles();

    if (!applicationUpdateAvailable) {
        return null;
    }

    const handleConfirm = () => {
        window.location.reload();
    };

    return (
        <>
            <Alert
                className={classes.alert}
                severity="info"
                action={
                    <Button color="inherit" size="small" onClick={() => setOpenConfirm(true)}>
                        {translate('app.action.update_application')}
                    </Button>
                }
            >
                {translate('app.label.application_update_available')}
            </Alert>
            <Confirm
                isOpen={openConfirm}
                title="ra.message.are_you_sure"
                content="app.message.page_reload_confirm"
                onClose={() => setOpenConfirm(false)}
                onConfirm={handleConfirm}
            />
        </>
    );
};

const useStyles = makeStyles({
    alert: {
        padding: '0 8px',
    },
});

export default CheckForApplicationUpdate;
