/* eslint-disable react/jsx-key */
import { Route } from 'react-router-dom';
import { RouteWithoutLayout } from 'react-admin';

import DashboardRouter from '@components/Dashboard/Router';
import ErrandImport from './ErrandImport';
import ExternalOrdersImport from './ExternalOrdersImport';
import Co2Import from './Co2Import';
import SentryEntryTest from './SentryEntryTest';
import ResetPasswordPage from './ResetPasswordPage';
import ChangePasswordPage from './ChangePasswordPage';
import ConfigureErrandsExport from './ConfigureErrandsExport';

export default [
    <Route exact path="/errands/import" component={ErrandImport} />,
    <Route exact path="/errands/orders_import" component={ExternalOrdersImport} />,
    <Route exact path="/errands/import_co2" component={Co2Import} />,
    <Route exact path="/:resource(errands|reclamations)/configure_export" component={ConfigureErrandsExport} />,

    // Register additional dashboard routes here
    <Route exact path="/dashboard/*" component={DashboardRouter} />,

    <RouteWithoutLayout exact path="/reset-password" component={ResetPasswordPage} />,
    <RouteWithoutLayout exact path="/change-password" component={ChangePasswordPage} />,

    // Test sentry error boundary
    <Route exact path="/_sentry-test" component={SentryEntryTest} />,
];
