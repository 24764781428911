import {
    Datagrid,
    InjectedFieldProps,
    List,
    ListProps,
    ReferenceManyField,
    TextField,
    TopToolbar,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { FC, ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router';

import { DatagridGuesser, ListGuesserBase } from '@components/list/ListGuesser';
import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import AutoHidePagination from '@components/list/AutoHidePagination';
import CopyableTextField from '@components/field/CopyableTextField';

import RowActions from './RowActions';
import ProductExpand from './ProductExpand';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useFilters from './useFilters';
import { Product, SimpleProductsGroup } from '@js/interfaces/product';

// Just empty toolbar
const Actions = () => <TopToolbar />;

const SimpleProductList: FC<ListProps> = ({ resource: _1, children, ...props }) => {
    const filters = useFilters('products');
    const getFieldLabel = useTranslateResourceField('reclamations');

    return (
        <List
            {...props}
            resource="simple_products_groups"
            exporter={false}
            bulkActionButtons={false}
            actions={<Actions />}
            filters={filters}
        >
            <Datagrid expand={<ExpandPanel>{children}</ExpandPanel>}>
                <TextField label={getFieldLabel('title')} source="title" sortable={false} />
                <CopyableTextField label={getFieldLabel('composedIdentifier')} source="composedIdentifier" />
            </Datagrid>
        </List>
    );
};

const ExpandPanel = ({ children, ...props }: InjectedFieldProps<SimpleProductsGroup> & { children: ReactNode }) => {
    const record = useRecordContext(props);
    const { location } = useHistory<{
        _refresh?: boolean;
    }>();
    const refresh = useRefresh();

    useEffect(() => {
        if (location.state?._refresh) {
            refresh();
        }
    }, [refresh, location.state?._refresh]);

    if (!record) {
        return null;
    }

    return (
        <Box margin={1}>
            <ReferenceManyField
                reference="products"
                target="reclamation"
                pagination={<AutoHidePagination />}
                perPage={10}
                filter={{ 'exists[bundle]': false }}
                sort={{ field: 'createdAt', order: 'DESC' }}
                {...props}
                record={{ id: record.reclamation }}
            >
                <SimpleProductsDatagrid>{children}</SimpleProductsDatagrid>
            </ReferenceManyField>
        </Box>
    );
};

const SimpleProductsDatagrid: FC<InjectedFieldProps<Product>> = ({ children, ...props }) => {
    return (
        <ListGuesserBase resource={props.resource}>
            <TopToolbar>
                <VisibleColumnsButton />
            </TopToolbar>
            <DatagridGuesser
                body={DatagridBodyWithMercure}
                empty={<AlertEmptyResource />}
                expand={<ProductExpand />}
                rowActions={<RowActions list="simple" />}
                overrideFieldTranslateResource="reclamations"
                {...props}
            >
                {children}
            </DatagridGuesser>
        </ListGuesserBase>
    );
};

export default SimpleProductList;
