import { FC, useEffect, useRef, useState } from 'react';
import {
    FieldTitle,
    InputHelperText,
    ResettableTextField,
    sanitizeInputRestProps,
    useGetResourceLabel,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { useFieldArray } from 'react-final-form-arrays';
import { get } from 'lodash';

import ProcessingTimeTable from '../ProcessingTimeTable';
import DialogInfo from '../DialogInfo';
import ProcessingTimeForm, { ProcessingTimeFormData, SubmitHandler } from '../ProcessingTimeForm';

import { minutesStringToSeconds, secondsToHumanTime, secondsToMinutesSting } from '@js/utility/numberFormatUtility';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { ProcessingTimeEmbedded } from '@js/interfaces/processingtime';
import useGetIdentity from '@js/hooks/useGetIdentity';

interface Props {
    source: string;
    label?: string;
    resource?: string;
    className?: string;
    helperText?: string | false;
}

type FieldArrayItem = PartialBy<ProcessingTimeEmbedded, '@id'>;

const ProcessingTimeInput: FC<Props> = ({ source, label, helperText, ...rest }) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(rest);
    const isRequired = permissions.isFieldRequired(resource, source);
    const translate = useTranslate();
    const record = useRecordContext(rest);
    const initialValueLengthRef = useRef<number>(get(record, source)?.length ?? 0);

    useEffect(() => {
        initialValueLengthRef.current = get(record, source)?.length ?? 0;
    }, [record, source]);

    const {
        fields,
        meta: { error, submitError, touched },
    } = useFieldArray<FieldArrayItem>(source, {
        validate: (value) => {
            if (isRequired && (!Array.isArray(value) || value.length === initialValueLengthRef.current)) {
                return translate('ra.validation.required');
            }
        },
    });
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [openDialog, setOpenDialog] = useState(false);
    const getResourceLabel = useGetResourceLabel();
    const { addItem, editItem } = useProcessingTimeUtil();

    let editingItem: ProcessingTimeFormData | undefined = undefined;
    let totalTime = 0;
    const items: Record<string, FieldArrayItem> = {};

    for (const [index, item] of (fields.value ?? []).entries()) {
        totalTime += item.time;
        items[index] = item;

        if (editIndex === index) {
            editingItem = { ...item, time: secondsToMinutesSting(item.time) };
        }
    }

    const handleSubmit: SubmitHandler = (data, { restart }) => {
        // If edit item
        if (editIndex !== undefined) {
            fields.update(editIndex, editItem(data));
        } else {
            fields.unshift(addItem(data));
        }

        setEditIndex(undefined);
        restart();
    };

    return (
        <>
            <ResettableTextField
                value={secondsToHumanTime(totalTime)}
                onChange={(e) => e.preventDefault()}
                onClick={() => setOpenDialog(true)}
                label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
                error={!!(touched && (error || submitError))}
                helperText={
                    <InputHelperText touched={!!touched} error={error || submitError} helperText={helperText} />
                }
                {...sanitizeInputRestProps(rest)}
            />
            <DialogInfo
                onClose={() => setOpenDialog(false)}
                open={openDialog}
                title={getResourceLabel('processing_times')}
            >
                <ProcessingTimeForm
                    initialValues={editingItem}
                    onSubmit={handleSubmit}
                    onReset={() => setEditIndex(undefined)}
                />
                <ProcessingTimeTable
                    items={items}
                    onEdit={(index) => setEditIndex(parseInt(index))}
                    onDelete={(index) => fields.remove(parseInt(index))}
                />
            </DialogInfo>
        </>
    );
};

export const useProcessingTimeUtil = () => {
    const { identity } = useGetIdentity();

    const addItem = ({ time, ...item }: ProcessingTimeFormData): Omit<ProcessingTimeEmbedded, '@id'> => ({
        ...item,
        time: minutesStringToSeconds(time),
        createdAt: new Date().toISOString(),
        createdBy: identity?.id.toString(),
    });

    const editItem = ({ time, ...item }: ProcessingTimeFormData): Omit<ProcessingTimeEmbedded, '@id'> => ({
        ...item,
        time: minutesStringToSeconds(time),
    });

    return {
        addItem,
        editItem,
    };
};

export default ProcessingTimeInput;
