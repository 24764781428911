import { useState } from 'react';
import { LinearProgress, useRecordContext, useTranslate } from 'react-admin';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';

import FlowDialog from './FlowDialog';

import useIdsBatchRequest from '@js/hooks/useIdsBatchRequest';
import { Reclamation } from '@js/interfaces/reclamation';

type Props = {
    record?: Reclamation;
    resource?: string;
    label?: string;
    fullWidth?: boolean;
};

const CountReturnsField = (props: Props) => {
    const [open, setOpen] = useState(false);
    const translate = useTranslate();
    const record = useRecordContext(props);
    const {
        loading,
        data: countReturns,
        error,
    } = useIdsBatchRequest<number>('/api/reclamations/count_returns', record?.id);

    if (!record) return null;
    if (loading) return <LinearProgress />;
    if (error) return <ErrorIcon role="presentation" color="error" fontSize="small" />;

    const hasFlow = record.parent || record.child;

    return (
        <>
            <Box display="flex" alignItems="center">
                <Typography component="span" variant="body2">
                    {countReturns}
                </Typography>
                {hasFlow && (
                    <Tooltip title={translate('app.label.flow')}>
                        <IconButton size="small" onClick={() => setOpen(true)}>
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <FlowDialog onClose={() => setOpen(false)} open={open} record={record} resource={props.resource} />
        </>
    );
};

export default CountReturnsField;
