import { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ResellerInputGuesser from '@components/resource/resellers/ResellerInputGuesser';

import useFormFields from '@js/hooks/useFormFields';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props {
    title: string;
    name: string;
}

const ResellerOverrideForm: FC<Props> = ({ title, name }) => {
    const fields = useFormFields({ resource: 'resellers' }, ['name', 'currency']);
    const translateField = useTranslateResourceField('resellers');

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="span" noWrap>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box flexGrow={1}>
                    {fields.map((field) => (
                        <div key={field.name}>
                            <ResellerInputGuesser
                                label={translateField(field.name)}
                                source={`${name}.${field.name}`}
                                field={field}
                                fullWidth
                            />
                        </div>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ResellerOverrideForm;
