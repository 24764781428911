import {
    CardContentInner,
    Identifier,
    ResourceContextProvider,
    Title,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { Card, Step, StepLabel, Stepper } from '@material-ui/core';
import { useState } from 'react';

import UploadFileForm from './UploadFileForm';
import MappingForm from './MappingForm';
import ConfigureImportForm from './ConfigureImportForm';

import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

export type ImportConfig = {
    upload?: {
        importFile: File;
    };
    mapping?: {
        mapping: Record<string, string>;
        store: Identifier;
    };
    configure?: {
        categories?: Record<string, Identifier>;
        exchangeRate?: number;
        weightMeasure: 'kg' | 'g';
    };
};

type Step = 'upload' | 'mapping' | 'configure';

const STEPS: Step[] = ['upload', 'mapping', 'configure'];

function isValidateConfig(importConfig: ImportConfig): asserts importConfig is Required<ImportConfig> {
    if (!importConfig.upload || !importConfig.mapping || !importConfig.configure) {
        throw new Error('Missing required values');
    }
}

const Co2ImportPage = () => {
    const [step, setStep] = useState<Step>('upload');
    const [importConfigState, setImportConfigState] = useState<ImportConfig>({});
    const currentStepIndex = STEPS.indexOf(step) ?? 0;

    const translate = useTranslate();
    const notify = useNotify();
    const notifyError = useNotifyHttpError();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleStepSubmit =
        <T extends Step>(step: T) =>
        async (values: ImportConfig[T]) => {
            const nextStep = STEPS[currentStepIndex + 1];
            const config = { ...importConfigState, [step]: values };

            if (nextStep) {
                setStep(nextStep);
                setImportConfigState(config);
            } else {
                // Submit without updating state
                // Take current state and submit latest values
                isValidateConfig(config);

                const form = new FormData();
                form.append('file', config.upload.importFile);
                form.append('mapping', JSON.stringify(config.mapping.mapping));
                form.append('store', config.mapping.store.toString());
                form.append('categories', JSON.stringify(config.configure.categories ?? {}));
                form.append('weightMeasure', config.configure.weightMeasure);
                if (config.configure.exchangeRate) {
                    form.append('exchangeRate', config.configure.exchangeRate.toString());
                }

                try {
                    const { importedCount } = await post<{ importedCount: number }>('/api/savings-import/import', {
                        body: form,
                    });

                    notify('app.message.bulk_import', {
                        type: 'info',
                        messageArgs: { smart_count: importedCount },
                    });
                    redirect('/errands');
                    refresh();
                } catch (error) {
                    notifyError(error);
                    console.error(error);
                }
            }
        };

    const handleGoBack = () => {
        const prevStep = currentStepIndex - 1 < 0 ? 0 : currentStepIndex - 1;
        setStep(STEPS[prevStep]);
    };

    const ActiveForm = {
        upload: UploadFileForm,
        mapping: MappingForm,
        configure: ConfigureImportForm,
    }[step];

    return (
        <>
            <Title title={translate('app.action.import_co2')} />
            <Card>
                <CardContentInner>
                    <Stepper activeStep={currentStepIndex}>
                        {STEPS.map((label) => (
                            <Step key={label}>
                                <StepLabel>{translate(`resources.errands.import_co2.steps.${label}`)}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </CardContentInner>
                <ResourceContextProvider value="errands">
                    <ActiveForm state={importConfigState} onSubmit={handleStepSubmit(step)} onGoBack={handleGoBack} />
                </ResourceContextProvider>
            </Card>
        </>
    );
};

export default function Co2Import() {
    const permissions = useUserPermissions();

    if (!permissions.isListActionEnabled('errands', 'import_co2')) return null;

    return <Co2ImportPage />;
}
