import { Field } from '@api-platform/api-doc-parser';

interface ExtractFieldsReturnType {
    extracted: Field[];
    rest: Field[];
}

export const extractFields = (fields: Field[], extract: Field['name'][]): ExtractFieldsReturnType => {
    return fields.reduce<ExtractFieldsReturnType>(
        (chunks, field) => {
            const chunk = extract.includes(field.name) ? 'extracted' : 'rest';
            chunks[chunk].push(field);

            return chunks;
        },
        { extracted: [], rest: [] },
    );
};
