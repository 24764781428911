import { FC } from 'react';
import { Field, Resource } from '@api-platform/api-doc-parser';
import { Alert } from '@material-ui/lab';
import {
    ArrayInputProps,
    BooleanInput,
    BooleanInputProps,
    DateInput,
    DateInputProps,
    DateTimeInput,
    DateTimeInputProps,
    email,
    NumberInput,
    NumberInputProps,
    ReferenceArrayInputProps,
    ReferenceInputProps,
    TextInput,
    TextInputProps,
} from 'react-admin';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';

import PriceInput, { PriceInputProps } from '@components/input/PriceInput';
import ScrollableSelectInput, { ScrollableSelectInputProps } from '@components/input/ScrollableSelectInput';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

import { getFieldType } from '@js/utility/fieldsUtil';
import useResourceFieldName from '@js/hooks/useResourceFieldName';
import useInputInfoHelperText from '@js/hooks/useInputInfoHelperText';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';
import useResourceSchema from '@js/hooks/useResourceSchema';

type InputProps =
    | TextInputProps
    | DateTimeInputProps
    | DateInputProps
    | BooleanInputProps
    | NumberInputProps
    | ArrayInputProps
    | ReferenceArrayInputProps
    | ReferenceInputProps;

interface IntrospectedProps {
    field: Field;
    source: string;
}

export type Props = Partial<InputProps> & IntrospectedProps;

const getAdditionalScrollableSelectInputProps = (
    resource: Resource,
): Partial<ScrollableSelectInputProps> | undefined => {
    switch (resource.name) {
        case 'resellers':
            return {
                filter: {
                    'exists[overrideReseller]': false,
                },
            };
        case 'goods_types':
            return {
                filter: {
                    'exists[parent]': false,
                },
                subItems: 'subGoodsTypes',
            };
        default:
            return undefined;
    }
};

export const ScrollableSelectInputGuesser: FC<
    { schema?: Resource; reference?: string } & Omit<ScrollableSelectInputProps, 'reference'>
> = ({ schema, reference, ...props }) => {
    const { resources } = useHydraSchemaContext();

    if (!schema && !reference) {
        throw new Error('You should provide either a "schema" or "reference" prop.');
    }

    if (!schema) {
        schema = resources.find(({ name }) => reference === name);
    }
    if (!schema) {
        throw new Error(`Resource ${reference} not present inside API description`);
    }

    const fieldName = useResourceFieldName(schema.name);

    return (
        <ScrollableSelectInput
            reference={schema.name}
            sort={{ field: fieldName, order: 'ASC' }}
            optionText={fieldName}
            {...getAdditionalScrollableSelectInputProps(schema)}
            {...props}
        />
    );
};

const InputGuesserField = ({ field, ...props }: Props) => {
    const { schemaAnalyzer } = useHydraSchemaContext();
    const helperText = useInputInfoHelperText(props);
    const validate = useInputAutoValidator({ ...props, field });

    const finalProps = {
        ...props,
        validate,
        helperText,
    };

    if (field.reference !== null && typeof field.reference === 'object') {
        const multiple = field.maxCardinality !== 1;

        if (!multiple && ['statuses'].includes(field.reference.name)) {
            return <ReferenceRecursiveSelectInput {...finalProps} />;
        }

        if (field.reference.name === 'stores') {
            return <HierarchicalAutocompleteSelectInput {...finalProps} multiple={multiple} />;
        }

        return <ScrollableSelectInputGuesser {...finalProps} schema={field.reference} multiple={multiple} allowEmpty />;
    }

    const fieldType = getFieldType(field, schemaAnalyzer);

    switch (fieldType) {
        case 'array':
            return <Alert severity="warning">{`InputGuesser doesn't support embedded field "${field.name}"`}</Alert>;

        case 'integer':
        case 'integer_id':
            return (
                <NumberInput
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    {...(finalProps as NumberInputProps)}
                />
            );

        case 'float':
            return (
                <NumberInput
                    step="0.1"
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    {...(finalProps as NumberInputProps)}
                />
            );

        case 'boolean':
            return <BooleanInput {...(finalProps as BooleanInputProps)} />;

        case 'date':
            return <DateInput key={field.name} {...(finalProps as DateInputProps)} />;

        case 'dateTime':
            return <DateTimeInput {...(finalProps as DateTimeInputProps)} />;

        case 'email':
            return (
                <TextInput
                    {...(finalProps as TextInputProps)}
                    type="email"
                    validate={[...(finalProps.validate || []), email()]}
                />
            );
        case 'price':
            return <PriceInput {...(finalProps as PriceInputProps)} />;

        default:
            return (
                <TextInput
                    {...(finalProps as TextInputProps)}
                    {...(fieldType === 'multiline-text' && { multiline: true, minRows: 2 })}
                />
            );
    }
};

const InputGuesser = ({ field, ...props }: Partial<InputProps> & { source: string; field?: Field }) => {
    const schema = useResourceSchema(props);

    field ??= schema?.fields?.find(({ name }) => name === props.source);

    if (!field) {
        throw new Error(`Field "${props.source}" not found`);
    }

    return <InputGuesserField field={field} {...props} />;
};

export default InputGuesser;
