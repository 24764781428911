import { FC, ReactNode } from 'react';
import { Identifier } from 'react-admin';

import TakeOverEditing from './TakeOverEditing';
import HandOverEditing from './HandOverEditing';

type Props = {
    id?: Identifier;
    resource?: string;
    onCancelTakeOver?: () => void;
    onAcceptHandOver?: () => void;
    children: ReactNode;
};

const LockedFormSafeguard: FC<Props> = ({ children, id, resource, onCancelTakeOver, onAcceptHandOver }) => {
    if (!id) throw new Error('Id is required');
    id = id.toString();

    return (
        <>
            {children}
            <TakeOverEditing id={id} resource={resource} onCancel={onCancelTakeOver} />
            <HandOverEditing id={id} resource={resource} onAccept={onAcceptHandOver} />
        </>
    );
};

export default LockedFormSafeguard;
