import { BooleanField, BooleanInput, ListProps } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';

const GradeList = (props: ListProps) => {
    return (
        <ListGuesser {...props} filters={filters}>
            <DatagridGuesser>
                <BooleanField source="selloNewCondition" looseValue />
            </DatagridGuesser>
        </ListGuesser>
    );
};

// eslint-disable-next-line react/jsx-key
const filters = [<BooleanInput source="hidden" alwaysOn />];

export default GradeList;
