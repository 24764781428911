import { FC } from 'react';
import { Typography } from '@material-ui/core';
import { useRecordContext } from 'react-admin';

import { useFormatCentsPrice } from '@js/hooks/useFormatPrice';

import { hasNumberFormat, numberOptions } from '@js/utility/numberFormatUtility';
import { Reseller } from '@js/interfaces/reseller';

interface Props {
    source?: string;
    addLabel?: boolean;
    record?: Reseller;
}

const CommissionField: FC<Props> = (props) => {
    const reseller = useRecordContext(props);
    const formatPrice = useFormatCentsPrice(reseller.currency);

    if (!reseller) {
        return null;
    }

    const { commissionInPercents, commission, commissionDescription } = reseller;
    let value: number | string | undefined = commission;

    if (hasNumberFormat && value) {
        value = commissionInPercents
            ? // Commission is in float percents
              `${value.toLocaleString(undefined, numberOptions)}%`
            : // Commission is fixed price, multiply it so it can be used as integer for formatting price
              formatPrice(value * 100);
    }

    return (
        <Typography variant="body2" component="span">
            {value}
            {commissionDescription && ` (${commissionDescription})`}
        </Typography>
    );
};

CommissionField.defaultProps = {
    source: 'commission',
    addLabel: true,
};

export default CommissionField;
