import { FC } from 'react';
import { NumberInput, NumberInputProps } from 'react-admin';
import { Box, MenuItem, Select } from '@material-ui/core';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import decimal from '@js/validator/decimal';

type Choice = 'kr' | '%';

const choices: Array<Choice> = ['kr', '%'];
const validateCommission = [decimal()];

const useStyles = makeStyles({
    select: {
        marginLeft: '1rem',
        marginTop: '12px',
        minWidth: 'unset',
    },
});

const SelectPercent: FC<Pick<NumberInputProps, 'source'>> = ({ source }) => {
    const classes = useStyles();
    const { input } = useField<boolean, HTMLElement, Choice>(source.replace('commission', 'commissionInPercents'), {
        initialValue: true,
        format: (value) => (value ? '%' : 'kr'),
        parse: (value) => value === '%',
    });

    return (
        <Select className={classes.select} variant="outlined" margin="dense" {...input}>
            {choices.map((choice) => (
                <MenuItem value={choice} key={choice}>
                    {choice}
                </MenuItem>
            ))}
        </Select>
    );
};

const CommissionInput: FC<NumberInputProps> = (props) => (
    <Box display="flex" alignItems="start">
        <NumberInput validate={validateCommission} formClassName="inline-input" {...props} />
        <SelectPercent source={props.source} />
    </Box>
);

export default CommissionInput;
