import { AutocompleteInput, ReferenceInput, ReferenceInputProps } from 'react-admin';

import { handleAutocompleteBlur } from '@js/utility/inputsUtility';
import useGetIdentity from '@js/hooks/useGetIdentity';

const AssignedUserInput = ({
    helperText,
    ...props
}: Omit<ReferenceInputProps, 'reference' | 'children'> & { source: string; helperText?: string | false }) => {
    const { identity } = useGetIdentity();

    if (!identity) {
        return null;
    }

    return (
        <ReferenceInput
            reference="users"
            sort={{
                field: 'firstName',
                order: 'ASC',
            }}
            initialValue={identity.id}
            onBlur={handleAutocompleteBlur}
            {...props}
        >
            <AutocompleteInput optionText="fullName" helperText={helperText} matchSuggestion={() => true} />
        </ReferenceInput>
    );
};

export default AssignedUserInput;
