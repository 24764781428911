import { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import {
    Button,
    FieldTitle,
    InputHelperText,
    RadioButtonGroupInput,
    ReferenceInput,
    required,
    SelectInput,
    useInput,
    useReference,
    useTranslate,
    Validator,
} from 'react-admin';
import { Box, Dialog, DialogActions, DialogContent as MuiDialogContent, TextField } from '@material-ui/core';
import { useField, useForm } from 'react-final-form';

import CancelIcon from '@material-ui/icons/Cancel';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import InputEndAdornment from '@components/input/InputEndAdornment';
import TreeInput from '@components/input/TreeInput';

import useResourceFieldName from '@js/hooks/useResourceFieldName';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

import { Category } from '@js/interfaces/category';
import { Iri } from '@js/interfaces/ApiRecord';

interface Props {
    source: string;
    materialSource: string;
    label?: string;
    resource?: string;
    fullWidth?: boolean;
    validate?: Validator | Validator[];
    helperText?: string | false;
}

const reference = 'categories';

const CategoryDialogInput = (props: Props) => {
    const { source, resource, label, fullWidth, materialSource, helperText } = props;

    const [open, setOpen] = useState(false);
    const form = useForm();
    const isSmallScreen = useIsSmallScreen();

    const {
        input: { value: categoryValue },
        meta: { error, submitError, touched },
        isRequired,
    } = useInput(props);

    const {
        input: { value: materialValue },
    } = useField(materialSource, { subscription: { value: true } });

    // Load category record
    const {
        referenceRecord: categoryRecord,
        loading: categoryLoading,
        loaded: categoryLoaded,
    } = useReference({
        reference,
        id: categoryValue,
    });

    const categoryFieldName = useResourceFieldName(reference);
    const tagFieldName = useResourceFieldName('tags');

    const handleClear = () => {
        form.batch(() => {
            form.change(source, null);
            form.change(materialSource, null);
        });
    };
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexGrow={2} pr={1}>
                    <TextField
                        variant="filled"
                        margin="dense"
                        disabled={!categoryLoaded}
                        fullWidth={fullWidth}
                        value={get(categoryRecord, categoryFieldName, '')}
                        label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
                        onMouseDown={handleOpen}
                        InputProps={{
                            endAdornment: (
                                <InputEndAdornment
                                    disabled={!categoryLoaded}
                                    loading={categoryLoading}
                                    onClear={handleClear}
                                />
                            ),
                        }}
                        error={!!(touched && (error || submitError))}
                        helperText={
                            <InputHelperText touched={!!touched} error={error || submitError} helperText={helperText} />
                        }
                    />
                </Box>
                {materialValue && (
                    <Box display="flex" flexGrow={1}>
                        <ReferenceInput
                            reference="tags"
                            source={materialSource}
                            filter={{ categories: categoryValue }}
                            fullWidth
                        >
                            <SelectInput optionText={tagFieldName} />
                        </ReferenceInput>
                    </Box>
                )}
            </Box>
            <Dialog open={open} fullWidth maxWidth="lg" fullScreen={isSmallScreen}>
                <DialogContent
                    onClose={handleClose}
                    inputProps={props}
                    selectedCategory={categoryRecord as Category | undefined}
                    selectedMaterialId={materialValue}
                    tagFieldName={tagFieldName}
                />
            </Dialog>
        </>
    );
};

const DialogContent = ({
    onClose,
    inputProps,
    tagFieldName,
    selectedCategory,
    selectedMaterialId,
}: {
    onClose: () => void;
    inputProps: Props;
    tagFieldName: string;
    selectedCategory?: Category;
    selectedMaterialId?: Iri;
}) => {
    const selectedCategoryId = selectedCategory?.id;
    const hasMaterials = !!selectedCategory?.materials?.length;
    const { materialSource } = inputProps;

    const [step, setStep] = useState<'category' | 'material'>('category');
    const prevSelectedCategoryIdRef = useRef(selectedCategoryId);
    const form = useForm();
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();
    const notifyError = useNotifyHttpError();
    const materialFieldName = useResourceFieldName('tags');

    useEffect(() => {
        if (selectedCategoryId !== prevSelectedCategoryIdRef.current) {
            prevSelectedCategoryIdRef.current = selectedCategoryId;
            // Reset material when category changes
            form.resetFieldState(materialSource);
            form.change(materialSource, null);
        }
    }, [selectedCategoryId, materialSource, form]);

    const handleClose = () => {
        if (hasMaterials && !selectedMaterialId) {
            notifyError(translate('app.validation.field_is_required', { field: getFieldLabel(materialSource) }));
            return;
        }

        onClose();
    };

    return (
        <>
            <MuiDialogContent>
                {step === 'category' && (
                    <TreeInput<Category>
                        {...inputProps}
                        reference="categories"
                        parentsField="parents"
                        parentField="parent"
                        childrenField="children"
                        filter={{ entrypoint: true }}
                        selectable={(category) => category.children.length === 0}
                    />
                )}
                {step === 'material' && (
                    <ReferenceInput
                        reference="tags"
                        source={inputProps.materialSource}
                        validate={required()}
                        filter={{ categories: selectedCategoryId }}
                        sort={{ field: materialFieldName, order: 'ASC' }}
                        fullWidth
                    >
                        <RadioButtonGroupInput optionText={tagFieldName} />
                    </ReferenceInput>
                )}
            </MuiDialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    label="ra.action.close"
                    disabled={!selectedMaterialId && hasMaterials && step === 'category'}
                >
                    <CancelIcon />
                </Button>
                {step === 'category' && (
                    <Button
                        onClick={() => setStep('material')}
                        label="resources.reclamations.fields.material"
                        disabled={!hasMaterials}
                    >
                        <NavigateNextIcon />
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default CategoryDialogInput;
