import { ImageInput, ImageInputProps } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const VideoImageInput = (props: ImageInputProps) => {
    const classes = useStyles();
    return <ImageInput {...props} className={classes.root} />;
};

const useStyles = makeStyles({
    root: {
        '& .previews > div[class^="RaFileInput-removeButton"] > button': {
            zIndex: 1,
        },
    },
});

export default VideoImageInput;
