import { FC } from 'react';
import pDebounce from 'p-debounce';

import ScrollableSelectInput, { ScrollableSelectInputProps } from '@components/input/ScrollableSelectInput';
import { customValidatorPath } from '@js/routes/apiRouter';
import { get } from '@js/request/apiRequest';
import { UserGroup } from '@js/interfaces/usergroup';

const validate = pDebounce(async (ids) => {
    if (!Array.isArray(ids) || ids.length < 2) {
        return undefined;
    }

    try {
        const path = customValidatorPath('user_groups_default_store');
        const { valid } = await get(path, { ids });

        if (!valid) {
            return 'app.validation.multipleUserGroupDefaultStore';
        }
    } catch (error) {
        console.error(error);

        return 'ra.notification.http_error';
    }

    return undefined;
}, 200);

const UserGroupsInput: FC<
    Omit<ScrollableSelectInputProps<UserGroup>, 'reference' | 'source' | 'sort'> & { source?: string }
> = (props) => {
    return (
        <ScrollableSelectInput
            {...props}
            reference="user_groups"
            source="userGroups"
            multiple
            validate={validate}
            optionText="name"
            sort={{ field: 'name', order: 'ASC' }}
        />
    );
};

UserGroupsInput.defaultProps = {
    source: 'userGroups',
};

export default UserGroupsInput;
