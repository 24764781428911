import { FC } from 'react';
import { Redirect } from 'react-router-dom';

import AddressForm from './AddressForm';

const List: FC = () => <Redirect to="/customers" />;

export default {
    list: List,
    show: null,
    options: {
        form: AddressForm,
        alwaysHideFields: ['customer'],
    },
};
