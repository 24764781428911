import { Currency } from '@js/context/AppConfigContext';

export const numberOptions = { maximumFractionDigits: 2 };

export const priceOptions = (currency: Currency) => ({ style: 'currency', currency });

export const hasNumberFormat = typeof Intl === 'object' && Intl && typeof Intl.NumberFormat === 'function';

export const secondsToHumanTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

export const secondsToHumanTimeWithSeconds = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor((time % 3600) % 60);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
};

export const humanTimeToSeconds = (time: string): number => {
    let [hours, minutes] = time.split(':') as [string | number, string | number | undefined];

    // Hours required
    hours = parseInt(hours.toString());

    if (minutes === '' || minutes === undefined) {
        minutes = 0;
    } else if (typeof minutes === 'string') {
        // If time passes in format "1:3" - treat as "1:30"
        minutes = parseInt(minutes) * (minutes.length === 1 ? 10 : 1);
    }

    return hours * 60 * 60 + minutes * 60;
};

export const minutesStringToSeconds = (minutes: string): number => parseInt(minutes) * 60;

export const secondsToMinutesSting = (seconds: number): string => Math.floor(seconds / 60).toString();
