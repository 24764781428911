import { useState } from 'react';
import { Identifier, useRecordContext, useTranslate } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import ReclamationEditableDatagrid from '../ReclamationEditableDatagrid';
import CreateForm from './CreateForm';
import EditRowButtons from './EditRowButtons';
import EditForm from './EditForm';
import CloneForm from './CloneForm';

import { Reclamation } from '@js/interfaces/reclamation';
import { Errand } from '@js/interfaces/errand';
import { Iri } from '@js/interfaces/ApiRecord';

export type ReclamationFormCommonProps = {
    registerForm: (id: Identifier) => void;
    unregisterForm: (id: Identifier) => void;
};

type Props = ReclamationFormCommonProps & {
    record?: Errand;
    resource?: string;
};

type CreateFormState =
    | {
          type: 'create';
          errandId: Iri;
      }
    | {
          type: 'clone';
          cloneId: Iri;
      };

const ReclamationsEditTab = ({ registerForm, unregisterForm, ...props }: Props) => {
    const translate = useTranslate();
    const errand = useRecordContext(props);
    const [createFormState, setCreateFormState] = useState<CreateFormState>();
    const isCreateFormOpen = !!createFormState;

    const handleCreateClick = () => {
        setCreateFormState({ type: 'create', errandId: errand.id.toString() });
    };

    const handleClone = (record: Reclamation) => {
        setCreateFormState({ type: 'clone', cloneId: record.id.toString() });
    };

    const resetCreateForm = () => setCreateFormState(undefined);

    return (
        <Box my={2}>
            <Box mb={1}>
                {errand?.id ? (
                    <Button size="small" color="primary" onClick={handleCreateClick} disabled={isCreateFormOpen}>
                        <AddIcon />
                        <Box paddingLeft="0.5em" paddingTop="1px" component="span">
                            {translate('ra.action.add')}
                        </Box>
                    </Button>
                ) : (
                    <Alert severity="info">{translate('app.label.first_save')}</Alert>
                )}
            </Box>
            {createFormState?.type === 'create' && (
                <CreateForm
                    initialValues={{ errand: createFormState.errandId }}
                    onSuccess={resetCreateForm}
                    onCancel={resetCreateForm}
                    registerForm={registerForm}
                    unregisterForm={unregisterForm}
                />
            )}
            {createFormState?.type === 'clone' && (
                <CloneForm
                    cloneId={createFormState.cloneId}
                    onSuccess={resetCreateForm}
                    onCancel={resetCreateForm}
                    registerForm={registerForm}
                    unregisterForm={unregisterForm}
                />
            )}
            {errand?.id && (
                <ReclamationEditableDatagrid
                    {...props}
                    expand={<EditForm registerForm={registerForm} unregisterForm={unregisterForm} />}
                    rowActions={<EditRowButtons disableClone={isCreateFormOpen} onClone={handleClone} />}
                    editMode
                />
            )}
        </Box>
    );
};

export default ReclamationsEditTab;
