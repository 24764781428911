import { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { AutocompleteInput, CardContentInner, Identifier, required, useTranslate, Validator } from 'react-admin';
import { Box, LinearProgress, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';
import Toolbar from '../Toolbar';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useFormFields from '@js/hooks/useFormFields';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { makeStyles } from '@material-ui/styles';

import { ImportConfig } from './Co2Import';

type Props = {
    onSubmit: (values: ImportConfig['mapping']) => void;
    onGoBack: () => void;
    state: Partial<ImportConfig>;
};

type ReadColumnsPayload = {
    columns: Record<string, string>;
};

type FormValues = {
    mapping: Record<string, string>;
    store: Identifier;
};

const validateMapping = (): Validator => (value: string | null, allValues: FormValues) => {
    if (value) {
        const mappedFields = Object.values(allValues.mapping ?? {});
        const repeated = mappedFields.filter((field) => field === value).length;

        if (repeated > 1) {
            return 'app.validation.unique';
        }
    }

    return undefined;
};

const useStyles = makeStyles<Theme>((theme) => ({
    fieldsBox: {
        '& > div': {
            marginRight: theme.spacing(1),
            flexGrow: 'unset',
        },
    },
}));

const MappingForm: FC<Props> = ({ onGoBack, onSubmit, state }) => {
    const [columns, setColumns] = useState<ReadColumnsPayload['columns'] | null>(null);
    const notifyError = useNotifyHttpError();
    const fields = useFormFields({ resource: 'reclamations' });
    const getFieldLabel = useTranslateResourceField('reclamations');
    const translate = useTranslate();
    const classes = useStyles();
    const importFile = state.upload?.importFile;

    useEffect(() => {
        if (!importFile) return;

        const form = new FormData();
        form.append('file', importFile);

        post<ReadColumnsPayload>('/api/excel/first_line_reader', {
            body: form,
        })
            .then((response) => {
                setColumns(response.columns);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [notifyError, importFile]);

    const handleSubmit = (values: FormValues) => {
        const missingAtLEastOneFieldMapping = !values.mapping || Object.keys(values.mapping).length <= 0;

        if (missingAtLEastOneFieldMapping) {
            notifyError(
                translate('app.label.choose_at_least_one', { option: translate('app.label.field').toLowerCase() }),
            );
            return;
        }

        const requireMapping = ['salesPrice'];
        const mappedFields = Object.values(values.mapping);
        const missingFields = requireMapping.filter((field) => !mappedFields.includes(field));

        if (missingFields.length) {
            notifyError(
                `${translate('app.error.missing_required_fields')}: ${getFieldLabel('salesPrice', 'sello_sales')}`,
            );
            return;
        }

        onSubmit(values);
    };

    const choices = [
        { id: 'salesPrice', name: getFieldLabel('salesPrice', 'sello_sales') },
        ...fields.map((field) => ({
            id: field.name,
            name: getFieldLabel(field.name),
        })),
    ];

    return (
        <Form<FormValues> onSubmit={handleSubmit}>
            {(formProps) => {
                return (
                    <>
                        <CardContentInner>
                            {!columns && <LinearProgress />}
                            {!importFile && <Alert severity="error">This form require import file to be chosen</Alert>}
                            {columns && (
                                <>
                                    <HierarchicalAutocompleteSelectInput
                                        source="store"
                                        reference="stores"
                                        validate={required()}
                                        fullWidth
                                    />
                                    <Alert severity="info">
                                        {translate('app.message.required_fields_to_map', {
                                            fields: getFieldLabel('salesPrice', 'sello_sales'),
                                        })}
                                    </Alert>
                                    <Box display="flex" flexWrap="wrap" mt={1} className={classes.fieldsBox}>
                                        {Object.keys(columns).map((column) => (
                                            <AutocompleteInput
                                                key={column}
                                                label={columns[column]}
                                                source={`mapping[${column}]`}
                                                choices={choices}
                                                validate={validateMapping()}
                                                emptyValue={null}
                                                allowEmpty
                                            />
                                        ))}
                                    </Box>
                                </>
                            )}
                        </CardContentInner>
                        <Toolbar
                            cancel={{ onClick: onGoBack, label: 'ra.action.back' }}
                            submit={{
                                disabled: !columns || !importFile,
                                onSubmit: formProps.handleSubmit,
                                icon: NavigateNextIcon,
                            }}
                        />
                    </>
                );
            }}
        </Form>
    );
};

export default MappingForm;
