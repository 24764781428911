import { Box, Divider, List as MuiList, ListItem as MuiListItem, ListItemText, ListProps } from '@material-ui/core';
import { FC, ReactNode } from 'react';

type Props = {
    items: Array<{
        label: ReactNode;
        value: ReactNode;
    }>;
} & ListProps;

const List: FC<Props> = ({ items, ...props }) => {
    return (
        <MuiList {...props}>
            {items.map(({ label, value }, index) => (
                <ListItem key={index} noDivider={index === items.length - 1}>
                    <span>{label}</span>
                    <span>{value}</span>
                </ListItem>
            ))}
        </MuiList>
    );
};

const ListItem: FC<{ noDivider?: boolean }> = ({ noDivider, children }) => {
    return (
        <>
            <MuiListItem dense disableGutters>
                <ListItemText
                    primary={
                        <Box component="span" display="flex" justifyContent="space-between">
                            {children}
                        </Box>
                    }
                />
            </MuiListItem>
            {!noDivider && <Divider component="li" />}
        </>
    );
};

export default List;
