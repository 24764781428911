import { FC } from 'react';
import { DateField, ShowProps } from 'react-admin';
import StoresField from '@components/resource/user_groups/field/StoresField';

import ShowGuesser from '@components/detail/ShowGuesser';
import LocaleField from '@components/field/LocaleField';
import CountryField from '@components/field/CountryField';

import RolesField from './field/RolesField';

const UserShow: FC<ShowProps> = (props) => {
    return (
        <ShowGuesser {...props} hideFields={['statusChangeRules']}>
            <RolesField />
            <StoresField />
            <DateField source="archivedAt" showTime />
            <LocaleField source="locale" />
            <CountryField source="country" />
        </ShowGuesser>
    );
};

export default UserShow;
