import { isValidElement, useContext, useEffect } from 'react';
import { FilterContext, useListContext } from 'react-admin';

/**
 * We have filters like `exists[...]` that are not reset when they are not displayed.
 * This hook will reset the filter values when they are not displayed.
 */
const useListFiltersExistsResetFix = () => {
    const filters = useContext(FilterContext);
    const { displayedFilters, filterValues, setFilters } = useListContext();
    const alwaysOnFilters = Array.isArray(filters)
        ? filters
              .filter((filter) => isValidElement(filter) && filter.props.alwaysOn)
              .map((filter) => (filter as any).props.source as string)
        : [];

    useEffect(() => {
        const existsFilterValues = { ...filterValues?.exists };
        const shouldResetFilterValues = Object.keys(existsFilterValues).filter((key) => {
            const filterName = `exists[${key}]`;

            return !alwaysOnFilters.includes(filterName) && displayedFilters[filterName] !== true;
        });

        if (shouldResetFilterValues.length === 0) return;

        for (const key of shouldResetFilterValues) {
            delete existsFilterValues[key];
        }

        setFilters({ ...filterValues, exists: existsFilterValues }, displayedFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(displayedFilters), alwaysOnFilters.join(',')]);
};

export default useListFiltersExistsResetFix;
