import { useCallback } from 'react';
import { useLocale } from 'react-admin';

import { hasNumberFormat, priceOptions } from '@js/utility/numberFormatUtility';
import { Currency, useAppConfigContext } from '@js/context/AppConfigContext';

export const useFormatCentsPrice = (currency?: Currency) => {
    const formatPrice = useFormatPrice();

    return useCallback(
        (price: number, overrideCurrency?: Currency): string => formatPrice(price / 100, overrideCurrency ?? currency),
        [currency, formatPrice],
    );
};

export const useFormatPrice = (currency?: Currency) => {
    const locale = useLocale();
    const { defaultCurrency } = useAppConfigContext();

    return useCallback(
        (price: number | null | undefined, overrideCurrency?: Currency): string => {
            if (price === null || price === undefined) return '0';

            return hasNumberFormat
                ? price.toLocaleString(locale, priceOptions(overrideCurrency ?? currency ?? defaultCurrency))
                : price.toString();
        },
        [currency, defaultCurrency, locale],
    );
};
