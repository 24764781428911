import { MouseEvent } from 'react';
import { InjectedFieldProps, linkToRecord, Record, useRecordContext } from 'react-admin';
import { Product } from '@js/interfaces/product';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ContentCreate from '@material-ui/icons/Create';

import PrintItemLabelButton from '@components/button/PrintItemLabelButton';
import LockRecordIcon from '@components/detail/LockRecordIcon';
import SyncSelloButton from '@components/button/SyncSelloButton';
import DeleteIconButton from '@components/button/DeleteIconButton';
import IconButton from '@components/button/IconButton';
import ShowIconButton from '@components/button/ShowIconButton';

import { RouterState } from './ProductList';

type Props = InjectedFieldProps<Product> & RouterState;

const RowActions = ({ list, ...props }: Props) => {
    const record = useRecordContext(props);
    if (!record) return null;

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon />
            <SyncSelloButton {...props} />
            <PrintItemLabelButton {...props} />
            <ShowIconButton {...props} />
            <EditButton {...props} list={list} />
            <DeleteIconButton
                confirmContent={record.syncedWithSello ? 'resources.products.message.was_synced_with_sello' : undefined}
                disabledReason={record.deleteDisableReason}
            />
        </Box>
    );
};

const EditButton = ({ list, ...props }: RouterState & { record?: Record }) => {
    const record = useRecordContext(props);
    const history = useHistory();

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();

        history.push({
            pathname: record ? linkToRecord('/products', record.id) : '',
            state: { _scrollToTop: true, list },
        });
    };

    return (
        <IconButton label="ra.action.edit" onClick={handleClick}>
            <ContentCreate />
        </IconButton>
    );
};

export default RowActions;
