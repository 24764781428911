import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { SimplePaletteColorOptions } from '@material-ui/core/styles';

export interface CustomTheme {
    palette: {
        primary: SimplePaletteColorOptions;
        secondary: SimplePaletteColorOptions;
    };
}

type ThemeContextStateValue = CustomTheme | null;

export interface CustomThemeContextValue {
    theme: ThemeContextStateValue;
    setTheme: (theme: ThemeContextStateValue) => void;
}

const DEFAULT_THEME = window.ADMIN_APP.settings.defaultTheme;
const THEME_STORAGE_KEY = 'custom_theme';

const saveTheme = (theme: ThemeContextStateValue) => {
    window.localStorage.setItem(THEME_STORAGE_KEY, JSON.stringify(theme));
};

const getInitialTheme = () => {
    const themeJson = window.localStorage.getItem(THEME_STORAGE_KEY);

    if (themeJson) {
        try {
            return JSON.parse(themeJson) as CustomTheme;
        } catch (error) {
            console.error(`Unable to parse theme "${themeJson}"`);
            resetTheme();
        }
    }

    return DEFAULT_THEME;
};

const resetTheme = () => window.localStorage.removeItem(THEME_STORAGE_KEY);

export const CustomThemeContext = createContext<CustomThemeContextValue>(undefined!);

export const CustomThemeContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [theme, setTheme] = useState(() => getInitialTheme());

    const handleSetTheme = (theme: ThemeContextStateValue) => {
        if (theme) {
            setTheme(theme);
            saveTheme(theme);
        } else {
            setTheme(DEFAULT_THEME);
            resetTheme();
        }
    };

    return (
        <CustomThemeContext.Provider value={{ theme, setTheme: handleSetTheme }}>
            {children}
        </CustomThemeContext.Provider>
    );
};

export const useCustomThemeContext = (): CustomThemeContextValue => {
    return useContext(CustomThemeContext);
};
