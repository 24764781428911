import { FC, ReactNode, useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import { debounce } from 'lodash';

import ResourcePermissionsAccordion from './ResourcePermissionsAccordion';
import PermissionsFilter, { FilterValue, ShowMode } from './PermissionsFilter';
import DashboardSettings from './DashboardSettings';
import { LabeledResource, useSortedLabeledResources } from '@js/context/HydraSchemaContext';

interface Props {
    source?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tab: {
        width: '100%',
    },
}));

const PermissionsInput: FC<Props> = ({ source }) => {
    const [tabValue, setTabValue] = useState(0);
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root}>
                <Tabs
                    value={tabValue}
                    onChange={(e, newValue) => setTabValue(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    orientation="vertical"
                >
                    <Tab label={translate('app.dashboard.title')} />
                    <Tab label={translate('resources.user_groups.label.resources')} />
                </Tabs>
                <TabPanel value={tabValue} index={0} className={classes.tab}>
                    <DashboardSettings source={`${source}.dashboard`} />
                </TabPanel>
                <TabPanel value={tabValue} index={1} className={classes.tab}>
                    <ResourceTabContent source={source!} />
                </TabPanel>
            </div>
        </div>
    );
};

PermissionsInput.defaultProps = {
    source: 'permissions',
};

const ResourceTabContent = ({ source }: { source: string }) => {
    const [filter, setFilter] = useState<FilterValue>({
        search: '',
        showMode: ShowMode.ALL,
    });
    const translate = useTranslate();
    const resources = useSortedLabeledResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSetFilter = useCallback(
        debounce((data: FilterValue) => {
            setFilter(data);
        }, 200),
        [],
    );

    const isResourceVisible = ({ label }: LabeledResource) => {
        const { search } = filter;

        return !search || label.toLowerCase().includes(search.toLowerCase());
    };

    return (
        <>
            <Alert severity="info">{translate('resources.user_groups.label.permissions_input')}</Alert>
            <Box>
                <PermissionsFilter onFilter={handleSetFilter} />
            </Box>
            {resources.map((resource) => {
                const { name } = resource;

                return (
                    <ResourcePermissionsAccordion
                        key={name}
                        source={`${source}.resources`}
                        resource={resource}
                        showMode={filter.showMode}
                        hidden={!isResourceVisible(resource)}
                    />
                );
            })}
        </>
    );
};

const TabPanel = ({
    children,
    value,
    index,
    className,
}: {
    children: ReactNode;
    value: number;
    index: number;
    className: string;
}) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} className={className}>
            <Box p={3}>{children}</Box>
        </div>
    );
};

export default PermissionsInput;
