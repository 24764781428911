import { ChangeEvent, FC, useState } from 'react';
import { Confirm, InputProps, useInput } from 'react-admin';
import { MenuItem, TextField } from '@material-ui/core';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Currency, useCurrencyChoices } from '@js/context/AppConfigContext';

const CurrencyInput: FC<InputProps> = ({ source, resource, helperText, ...props }) => {
    const [currency, setCurrency] = useState<Currency | null>(null);
    const currencyChoices = useCurrencyChoices();
    const translateField = useTranslateResourceField();
    const {
        input: { onChange, ...restInput },
        isRequired,
    } = useInput({ source, ...props });

    const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        setCurrency(event.target.value as Currency);
    };
    const handleConfirm = () => {
        onChange(currency);
        setCurrency(null);
    };
    const handleClose = () => {
        setCurrency(null);
    };

    return (
        <>
            <TextField
                select
                label={translateField('currency', resource) + (isRequired ? ' *' : '')}
                variant="filled"
                margin="dense"
                className="inline-input"
                style={{ minWidth: '160px' }}
                fullWidth
                onChange={handleChange}
                helperText={helperText}
                {...restInput}
            >
                {currencyChoices.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                        {name}
                    </MenuItem>
                ))}
            </TextField>
            <Confirm
                isOpen={!!currency}
                title="ra.action.confirm"
                content="resources.errands.message.confirm_currency_change"
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default CurrencyInput;
