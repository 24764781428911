import { FC } from 'react';
import { Show, ShowProps, Tab, TabbedShowLayout, useGetResourceLabel, useTranslate } from 'react-admin';

import RecordTitle from '@components/detail/RecordTitle';
import ShowLayoutTabs from '@components/detail/ShowLayoutTabs';
import TabbedTabGuesser from '@components/detail/TabbedTabGuesser';

import AddressDataGrid from './AddressDataGrid';
import ErrandsDataGrid from './ErrandsDataGrid';

import { useUserPermissions } from '@js/context/UserPermissionsContext';

const CustomerShow: FC<ShowProps> = (props) => {
    const translate = useTranslate();
    const translateResource = useGetResourceLabel();
    const permissions = useUserPermissions();

    return (
        <Show {...props} title={<RecordTitle />}>
            <TabbedShowLayout tabs={<ShowLayoutTabs />}>
                <TabbedTabGuesser label={translate('app.resources.customers.form.summary')} />
                {permissions.isResourceEnabled('addresses') && (
                    <Tab label={translateResource('addresses')} path="addresses">
                        <AddressDataGrid />
                    </Tab>
                )}
                {permissions.isResourceEnabled('errands') && (
                    <Tab label={translateResource('errands')} path="errands">
                        <ErrandsDataGrid
                            target="customer"
                            initialValues={(record) => ({ customer: record.id.toString() })}
                        />
                    </Tab>
                )}
            </TabbedShowLayout>
        </Show>
    );
};

export default CustomerShow;
