import { FC, ReactNode, useState } from 'react';
import {
    ReferenceField,
    ReferenceFieldProps,
    required,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { get } from 'lodash';
import NotificationsIcon from '@material-ui/icons/Notifications';

import PreviewField from '@components/resource/status/field/PreviewField';
import EditableField from '@components/field/EditableField';

import { StatusInput, StatusNotificationsTable } from '../input/StatusInput';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { StatusNotification } from '@js/interfaces/statusNotification';
import { ApiRecord } from '@js/interfaces/ApiRecord';

interface NotificationsDialogInfoProps {
    notifications: StatusNotification[];
}

interface StatusPreviewFieldProps
    extends Omit<ReferenceFieldProps, 'reference' | 'linkType' | 'label' | 'record' | 'children'> {
    statusNotificationsSource?: string;
    label?: string;
    record?: ApiRecord;
    history?: ReactNode;
}

const NotificationsDialogInfo: FC<NotificationsDialogInfoProps> = ({ notifications }) => {
    const [open, setOpen] = useState(false);
    const fullScreen = useIsSmallScreen();
    const translate = useTranslate();
    const title = translate('resources.errands.fields.statusNotifications');

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton size="small" title={title} onClick={() => setOpen(true)} disabled={!notifications.length}>
                <NotificationsIcon fontSize="inherit" />
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="lg">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {notifications.length && <StatusNotificationsTable notifications={notifications} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const StatusPreviewField: FC<StatusPreviewFieldProps> = ({
    statusNotificationsSource = 'statusNotifications',
    history,
    ...props
}) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);
    const record = useRecordContext(props);

    if (!record) return null;

    return (
        <Box display="flex">
            <EditableField
                {...props}
                form={
                    <>
                        <StatusInput {...props} fullWidth validate={required()} />
                        {history}
                    </>
                }
            >
                <ReferenceField reference="statuses" link={false} {...props}>
                    <PreviewField size="small" />
                </ReferenceField>
            </EditableField>
            {permissions.isFieldEditable(resource, statusNotificationsSource) && (
                <NotificationsDialogInfo notifications={get(record, statusNotificationsSource, [])} />
            )}
        </Box>
    );
};

StatusPreviewField.defaultProps = {
    addLabel: true,
    sortable: false,
};

export default StatusPreviewField;
