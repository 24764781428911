import { FC } from 'react';
import { ArrayInput, required, SimpleFormIterator, TextInput } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import InputGuesser from '@components/form/InputGuesser';
import FileInputPreview from '@components/input/FileInputPreview';
import VideoImageInput from '@components/input/VideoImageInput';

import AssignedUserInput from '../input/AssignedUserInput';
import CustomerInput from '../input/CustomerInput';
import AddressInput from '../input/AddressInput';
import ProcessingTimeInput from '../input/ProcessingTimeInput';
import { StatusInputWithNotification } from '../input/StatusInput';
import CurrencyInput from '../input/CurrencyInput';
import PriceCurrencySpyInput from '../input/PriceCurrencySpyInput';
import ShippingCostInput from '../input/ShippingCostInput';
import ShippingPaidInput from '../input/ShippingPaidInput';

import useInputAutoValidator from '@js/hooks/useInputAutoValidator';
import useInputInfoHelperText from '@js/hooks/useInputInfoHelperText';

interface Props {
    field: Field;
    source: string;
    resource?: string;
    fullWidth?: boolean;
}

const ErrandInputGuesser: FC<Props> = ({ field, ...rest }) => {
    const validate = useInputAutoValidator(rest);
    const helperText = useInputInfoHelperText(rest);

    const props = {
        ...rest,
        validate,
        helperText,
    };

    switch (field.name) {
        case 'assignedUser':
            return <AssignedUserInput {...props} />;
        case 'customer':
            return <CustomerInput {...props} />;
        case 'address':
            return <AddressInput {...props} />;
        case 'processingTimes':
            return <ProcessingTimeInput {...props} />;
        case 'currency':
            return <CurrencyInput {...props} />;
        case 'lastStatus':
            return <StatusInputWithNotification {...props} />;
        case 'cost':
        case 'costRedeemedItem':
            return <PriceCurrencySpyInput {...props} />;
        case 'shippingCost':
            return <ShippingCostInput {...props} />;
        case 'shippingPaid':
            return <ShippingPaidInput {...props} />;
        case 'images':
            return (
                // Accept only images and pdf files
                <VideoImageInput {...props} multiple accept="image/*, application/pdf, video/*">
                    <FileInputPreview reference="errand_upload_images" />
                </VideoImageInput>
            );
        case 'links':
            return (
                <ArrayInput {...props}>
                    <SimpleFormIterator disableReordering TransitionProps={{ timeout: 0 }}>
                        <TextInput label="Url" source="link" validate={required()} fullWidth />
                    </SimpleFormIterator>
                </ArrayInput>
            );
        default:
            return <InputGuesser field={field} {...props} />;
    }
};

export default ErrandInputGuesser;
