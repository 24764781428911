import { BulkActionProps, BulkDeleteButton } from 'react-admin';

import BulkMarkAsReadButton from './BulkMarkAsReadButton';

const BulkActionButtons = (props: BulkActionProps) => {
    return (
        <>
            <BulkMarkAsReadButton {...props} />
            <BulkDeleteButton {...props} />
        </>
    );
};

export default BulkActionButtons;
