import {
    Button,
    ListActionsProps,
    ListProps,
    TopToolbar,
    useResourceContext,
    useResourceDefinition,
} from 'react-admin';
import { FC } from 'react';
import ContentAdd from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import VisibleColumnsButton from '@components/list/VisibleColumnsButton';

import { useUserPermissions } from '@js/context/UserPermissionsContext';

import useFilters from './useFilters';
import ProductExpand from './ProductExpand';
import RowActions from './RowActions';
import { SplitType } from '@js/interfaces/product';

const LIST: SplitType = 'bundle';

const BundleProductList: FC<ListProps> = ({ children, ...props }) => {
    const filters = useFilters();

    return (
        <ListGuesser
            {...props}
            filter={{
                // Fetch only bundles
                'exists[bundleItems]': true,
            }}
            filters={filters}
            sort={{ field: 'id', order: 'desc' }}
            actions={<ListActions />}
        >
            <DatagridGuesser
                body={DatagridBodyWithMercure}
                rowActions={<RowActions list={LIST} />}
                expand={<ProductExpand />}
                overrideFieldTranslateResource="reclamations"
            >
                {children}
            </DatagridGuesser>
        </ListGuesser>
    );
};

const ListActions: FC<ListActionsProps> = (props) => {
    const resource = useResourceContext(props);
    const { hasCreate } = useResourceDefinition(props);
    const permissions = useUserPermissions();

    return (
        <TopToolbar>
            <VisibleColumnsButton {...props} />
            {hasCreate && permissions.isListActionEnabled(resource, 'create') && <CreateButton />}
        </TopToolbar>
    );
};

const CreateButton = () => {
    const location = {
        pathname: '/products/create',
        state: { _scrollToTop: true, list: LIST },
    };

    return (
        <Button component={Link} to={location} label="ra.action.create">
            <ContentAdd />
        </Button>
    );
};

export default BundleProductList;
