import { FC } from 'react';
import { Box } from '@material-ui/core';
import { Button, NumberInputProps, useGetOne, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import FunctionsIcon from '@material-ui/icons/Functions';

import PriceInput from '@components/input/PriceInput';

import { Reclamation } from '@js/interfaces/reclamation';
import { Depreciation } from '@js/interfaces/depreciation';

const spySubscription = { values: true };

const OutgoingPriceInput: FC<NumberInputProps> = (props) => {
    const { source } = props;
    const translate = useTranslate();
    const { change } = useForm();
    const { values } = useFormState<Reclamation>({ subscription: spySubscription });

    const insurancePrice = values.insurancePrice;
    const depreciationId = values.depreciation;

    const { data: depreciation } = useGetOne<Depreciation>('depreciations', depreciationId!, {
        enabled: !!depreciationId,
    });

    const calcDisabled = !insurancePrice || !depreciation?.deprecation || depreciation.deprecation >= 100;

    const handleCalcClick = () => {
        if (calcDisabled) {
            return;
        }

        const price = Math.round((insurancePrice * (100 - depreciation.deprecation)) / 100);
        if (price) {
            change(source, price);
        }
    };

    return (
        <Box display="flex" alignItems="center">
            <PriceInput helperText={translate('app.form.helperText.outgoingPriceTip')} {...props} />
            <Box mb="20px">
                <Button onClick={handleCalcClick} label="app.action.calculate" disabled={calcDisabled}>
                    <FunctionsIcon />
                </Button>
            </Box>
        </Box>
    );
};

export default OutgoingPriceInput;
