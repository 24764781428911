import { FC, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { LinearProgress, useTranslate } from 'react-admin';

import { LogEntry } from '@js/interfaces/logentry';
import useFetchGet from '@js/hooks/useFetchGet';

type Props = {
    record: LogEntry;
};

type DataResponse = {
    data: Record<string, any>;
    statusId: number;
    message: string;
    response?: Record<string, any>;
};

const DataWithMessageField: FC<Props> = ({ record: logEntry }) => {
    const { data, loading } = useFetchGet<{ data: DataResponse }>(`${logEntry.id}/data`);
    const [expanded, setExpanded] = useState<boolean>(false);
    const translate = useTranslate();

    if (loading || !data) {
        return <LinearProgress />;
    }

    const {
        data: { message, ...rest },
    } = data;
    const hasData = Object.keys(rest).length > 0;

    return (
        <Box>
            <Typography variant="body2" gutterBottom>
                {message}
            </Typography>
            {hasData && (
                <>
                    <Typography
                        variant="caption"
                        color="primary"
                        onClick={() => setExpanded(!expanded)}
                        style={{ cursor: 'pointer' }}
                    >
                        {translate('ra.action.expand')}
                    </Typography>
                    {expanded && (
                        <pre>
                            <code>{JSON.stringify(rest, null, 4)}</code>
                        </pre>
                    )}
                </>
            )}
        </Box>
    );
};

export default DataWithMessageField;
