import { useMemo } from 'react';
import {
    getResources,
    ReduxState,
    ResourceDefinition as RaResourceDefinition,
    useResourceContext,
    UseResourceDefinitionOptions,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { defaults } from 'lodash';

import { ResourceOptions } from '@components/ResourceGuesser';

export interface ResourceDefinition extends RaResourceDefinition {
    options?: ResourceOptions;
}

const useResourceDefinition = (props: UseResourceDefinitionOptions): ResourceDefinition => {
    const resource = useResourceContext(props);
    const definitionFromRedux = useSelector<ReduxState, ResourceDefinition>((state) =>
        getResources(state).find((r) => r.name === resource),
    );
    const { hasCreate, hasEdit, hasList, hasShow } = props;

    return useMemo(() => {
        return defaults(
            {},
            {
                hasCreate,
                hasEdit,
                hasList,
                hasShow,
            },
            definitionFromRedux,
        );
    }, [definitionFromRedux, hasCreate, hasEdit, hasList, hasShow]);
};

export default useResourceDefinition;
