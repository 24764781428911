import { FC } from 'react';
import { ReferenceField as RaReferenceField, ReferenceFieldProps } from 'react-admin';

import useReferenceFieldLink from '@js/hooks/useReferenceFieldLink';

const ReferenceField: FC<ReferenceFieldProps> = ({ children, ...props }) => {
    const link = useReferenceFieldLink(props.reference);

    return (
        <RaReferenceField link={link} {...props}>
            {children}
        </RaReferenceField>
    );
};

ReferenceField.defaultProps = {
    addLabel: true,
};

export default ReferenceField;
