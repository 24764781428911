import { FC, useState } from 'react';
import {
    CRUD_UPDATE,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useRefresh,
    useResourceContext,
    useUpdate,
} from 'react-admin';

import ProcessingTimeField from './ProcessingTimeField';
import DialogInfo from '../DialogInfo';
import ProcessingTimeTable, { ProcessingTimeTableProps } from '../ProcessingTimeTable';
import ProcessingTimeForm, { ProcessingTimeFormData, SubmitHandler } from '../ProcessingTimeForm';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { secondsToMinutesSting } from '@js/utility/numberFormatUtility';
import { useProcessingTimeUtil } from '../input/ProcessingTimeInput';

import { Reclamation } from '@js/interfaces/reclamation';
import { ProcessingTimeEmbedded } from '@js/interfaces/processingtime';

interface Props {
    record?: Reclamation;
    resource?: string;
    addLabel?: boolean;
    source?: string;
    sortable?: boolean;
}

const DialogContent: FC<{ reclamation: Reclamation; resource?: string }> = ({ reclamation, ...props }) => {
    const resource = useResourceContext(props);
    const [editData, setEditData] = useState<ProcessingTimeFormData | undefined>();
    const [update] = useUpdate(resource, reclamation?.id, {}, reclamation);
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const { addItem, editItem } = useProcessingTimeUtil();
    const refresh = useRefresh();
    const { processingTimes = [] } = reclamation;

    const items = processingTimes.reduce<Record<string, ProcessingTimeEmbedded>>(
        (prev, item) => ({
            ...prev,
            [item['@id']]: item,
        }),
        {},
    );

    const handleDelete = (id: string) => {
        const data: {
            processingTimes: ProcessingTimeEmbedded[];
        } = { processingTimes: processingTimes.filter((item) => item['@id'] !== id) };

        return update(
            { payload: { data } },
            {
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify('ra.notification.deleted', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: false,
                    });
                    refresh();
                },
                onFailure: (error) => {
                    notifyFailure(error);
                    refresh();
                },
                mutationMode: 'pessimistic',
                returnPromise: true,
            },
        );
    };

    const handleEdit: ProcessingTimeTableProps['onEdit'] = (id, { time, ...record }) => {
        setEditData({ ...record, time: secondsToMinutesSting(time) });
    };

    const handleSubmit: SubmitHandler = (processingTimeData) => {
        const data: {
            processingTimes: PartialBy<ProcessingTimeEmbedded, '@id'>[];
        } = { processingTimes: [] };
        const editingId = processingTimeData['@id'];

        // If edit
        if (editingId) {
            data['processingTimes'].push(
                // Convert items object back to array and replaces submitted data
                ...Object.values({
                    ...items,
                    [editingId]: editItem(processingTimeData),
                }),
            );
        } else {
            // if new just add as new array item
            data['processingTimes'].push(addItem(processingTimeData), ...processingTimes);
        }

        return update(
            { payload: { data } },
            {
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify('ra.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: false,
                    });
                    setEditData(undefined);
                    refresh();
                },
                onFailure: (error) => {
                    notifyFailure(error);
                    setEditData(undefined);
                    refresh();
                },
                mutationMode: 'pessimistic',
                returnPromise: true,
            },
        );
    };

    return (
        <>
            <ProcessingTimeForm
                initialValues={editData}
                onSubmit={handleSubmit}
                onReset={() => setEditData(undefined)}
            />
            <ProcessingTimeTable items={items} onEdit={handleEdit} onDelete={handleDelete} />
        </>
    );
};

const ReclamationProcessingTimeField: FC<Props> = (props) => {
    const [open, setOpen] = useState(false);
    const reclamation = useRecordContext(props);
    const getResourceLabel = useGetResourceLabel();
    const totalSeconds = (reclamation?.processingTimes ?? []).reduce(
        (sum, processingTime) => sum + processingTime.time,
        0,
    );

    return (
        <>
            <ProcessingTimeField totalSeconds={totalSeconds} onClick={() => setOpen(true)} />
            <DialogInfo onClose={() => setOpen(false)} open={open} title={getResourceLabel('processing_times')}>
                <DialogContent reclamation={reclamation} resource={props.resource} />
            </DialogInfo>
        </>
    );
};

ReclamationProcessingTimeField.defaultProps = {
    addLabel: true,
    source: 'processingTimes',
    sortable: false,
};

export default ReclamationProcessingTimeField;
