import { FC, ReactNode, useState } from 'react';
import {
    Button,
    RecordContextProvider,
    ResourceContext,
    useCreate,
    useGetResourceLabel,
    useTranslate,
} from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, FormProps, useForm } from 'react-final-form';

import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import ContentSave from '@material-ui/icons/Save';

import LoadingButton from '@components/button/LoadingButton';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';

interface Props {
    onChange?: () => void;
    resource: string;
    field: string;
    disabled?: boolean;
    initialValues?: FormProps['initialValues'];
    children: ReactNode;
}

const QuickCreateReferenceButton: FC<Props> = ({ onChange, resource, field, disabled, initialValues, children }) => {
    const [showDialog, setShowDialog] = useState(false);
    const form = useForm();
    const [create] = useCreate();
    const notifyError = useNotifyHttpError();
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();

    const handleCloseDialog = () => {
        setShowDialog(false);
    };
    const handleOpenDialog = () => {
        setShowDialog(true);
    };

    const handleSubmit: FormProps['onSubmit'] = async (values) => {
        return create(resource, values, {
            onSuccess: ({ data }) => {
                setShowDialog(false);
                // Update the form to target the newly created post
                // Updating the ReferenceInput value will force it to reload the available posts
                form.change(field, data.id);
                if (onChange) {
                    onChange();
                }
            },
            onFailure: notifyError,
            returnPromise: true,
        });
    };

    const title = translate('ra.page.create', {
        name: getResourceLabel(resource),
    });

    return (
        <>
            <Button onClick={handleOpenDialog} label="ra.action.create" disabled={disabled}>
                <AddIcon />
            </Button>
            <Dialog fullWidth open={showDialog} onClose={handleCloseDialog} aria-label={title}>
                <DialogTitle>{title}</DialogTitle>
                <ResourceContext.Provider value={resource}>
                    <RecordContextProvider value={initialValues}>
                        <Form
                            initialValues={initialValues}
                            resource={resource}
                            onSubmit={handleSubmit}
                            render={({ submitting, pristine, handleSubmit }) => {
                                return (
                                    <>
                                        <DialogContent>{children}</DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={handleCloseDialog}
                                                label="ra.action.cancel"
                                                disabled={submitting}
                                            >
                                                <CancelIcon />
                                            </Button>
                                            <LoadingButton
                                                label="ra.action.save"
                                                loading={submitting}
                                                disabled={pristine}
                                                icon={<ContentSave />}
                                                onClick={handleSubmit}
                                            />
                                        </DialogActions>
                                    </>
                                );
                            }}
                        />
                    </RecordContextProvider>
                </ResourceContext.Provider>
            </Dialog>
        </>
    );
};

export default QuickCreateReferenceButton;
