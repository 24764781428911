import { Alert, AlertProps } from '@material-ui/lab';
import { Box, Snackbar } from '@material-ui/core';
import { useEffect, useState } from 'react';

import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';
import useGetIdentity from '@js/hooks/useGetIdentity';

type Notification = {
    severity: AlertProps['severity'];
    message: string;
};

const CustomNotification = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [open, setOpen] = useState(!!notifications.length);
    const { identity } = useGetIdentity();
    const subscribe = useCustomMercureSubscription();

    useEffect(() => {
        if (!identity) {
            return;
        }
        const topic = `${identity.id}/custom_notifications`;

        return subscribe(topic, (data) => {
            try {
                const notification = JSON.parse(data);

                setNotifications((notifications) => [...notifications, notification]);
                setOpen(true);
            } catch (error) {
                console.error(error);
            }
        });
    }, [identity, subscribe]);

    const handleCloseNotification = (notification: Notification) => {
        setNotifications((notifications) => {
            const newNotifications = notifications.filter((item) => item !== notification);

            if (newNotifications.length === 0) {
                setOpen(false);
            }

            return newNotifications;
        });
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={(e, reason) => {
                if (reason === 'clickaway') {
                    return;
                }

                setOpen(false);
                setNotifications([]);
            }}
        >
            <Box display="flex" flexDirection="column" gridRowGap={10}>
                {notifications.map((notification, index) => {
                    return (
                        <Alert
                            key={index}
                            elevation={6}
                            variant="filled"
                            severity={notification.severity}
                            onClose={() => handleCloseNotification(notification)}
                        >
                            {notification.message}
                        </Alert>
                    );
                })}
            </Box>
        </Snackbar>
    );
};

export default CustomNotification;
