import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Button, useResourceContext } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

import { ApiRecord } from '@js/interfaces/ApiRecord';
import useResourceDefinition from '@js/hooks/useResourceDefinition';

interface Props<RecordType extends ApiRecord> {
    initialValues: Partial<RecordType>;
    resource: string;
}

const CreateSubResourceButton = <RecordType extends ApiRecord = ApiRecord>({
    initialValues,
    ...props
}: Props<RecordType>) => {
    const resource = useResourceContext(props);
    const { pathname } = useLocation();
    const { hasCreate } = useResourceDefinition(props);

    if (!hasCreate) {
        return null;
    }

    const to = {
        pathname: `/${resource}/create`,
        search: `?source=${JSON.stringify(initialValues)}&returnUrl=${encodeURIComponent(pathname)}`,
    };

    return (
        <Box my={1}>
            <Button
                component={Link as unknown as ReactNode}
                variant="outlined"
                color="primary"
                to={to}
                label="ra.action.create"
            >
                <AddIcon />
            </Button>
        </Box>
    );
};

export default CreateSubResourceButton;
