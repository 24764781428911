import { useEffect } from 'react';
import { get } from 'lodash';
import {
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    LinearProgress as MuiLinearProgress,
    MenuItem,
    MenuProps as MenuPropsType,
    Select,
    SelectProps,
    Theme,
} from '@material-ui/core';
import {
    FieldTitle,
    FilterPayload,
    InputHelperText,
    Labeled,
    LinearProgress,
    SortPayload,
    useInput,
    useResourceContext,
    Validator,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import useRefCallback from '@js/hooks/useRefCallback';
import useScrollableSelectInputController, { Choice } from './useScrollableSelectInputController';
import { ApiRecord, Iri } from '@js/interfaces/ApiRecord';

export interface ScrollableSelectInputProps<T extends ApiRecord = ApiRecord> {
    source: string;
    reference: string;
    label?: string;
    helperText?: string | false;
    optionText?: keyof Choice<T> | string;
    disable?: string | ((choice: Choice<T>) => boolean);
    resource?: string;
    className?: string;
    sort?: SortPayload;
    filter?: FilterPayload;
    multiple?: boolean;
    subItems?: string;
    validate?: Validator | Validator[];
    allowEmpty?: boolean;
    emptyLabel?: string;
    fullWidth?: boolean;
    perPage?: number;
    alwaysOn?: boolean;
    variant?: SelectProps['variant'];
    shrink?: boolean;
}

const useStyles = makeStyles<Theme, { fullWidth?: boolean }>((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(1 / 4),
    },
    linear: {
        width: '100%',
        margin: '10px 0',
    },
    input: ({ fullWidth }) => ({
        minWidth: theme.spacing(20),
        ...(fullWidth && { width: '100%' }),
    }),
}));

const MenuProps: Pick<MenuPropsType, 'variant' | 'getContentAnchorEl'> & Partial<Pick<MenuPropsType, 'PaperProps'>> = {
    PaperProps: {
        // Strict max height of dropdown
        style: {
            maxHeight: 450,
        },
    },
    // Next options fix jumping menu when items are added on page change
    variant: 'menu',
    getContentAnchorEl: null,
};

const ScrollableSelectInput = <RecordType extends ApiRecord = ApiRecord>({
    source,
    label,
    className,
    helperText,
    disable,
    allowEmpty,
    emptyLabel,
    shrink,
    variant = 'filled',
    perPage = 25,
    optionText = 'name',
    multiple = false,
    ...props
}: ScrollableSelectInputProps<RecordType>) => {
    const resource = useResourceContext(props);
    const {
        input,
        meta: { error, submitError, touched },
        isRequired,
    } = useInput({ source, resource, ...props });
    const { loaded, page, loadNextPage, loading, choices, totalChoices, reachedLastPage } =
        useScrollableSelectInputController<RecordType>({
            input,
            perPage,
            ...props,
        });
    const [lastChoice, lastChoiceRef] = useRefCallback();
    const classes = useStyles(props);

    useEffect(() => {
        if (!lastChoice) {
            return;
        }

        const observer = new IntersectionObserver((entries) => {
            const { isIntersecting } = entries[0];

            if (isIntersecting) {
                observer.unobserve(lastChoice);

                if (!reachedLastPage && !loading) {
                    loadNextPage();
                }
            }
        });

        observer.observe(lastChoice);

        return () => {
            observer.disconnect();
        };
    }, [lastChoice, loadNextPage, reachedLastPage, loading]);

    if (!choices || (!loaded && page === 1)) {
        return (
            <Labeled
                label={label}
                source={source}
                resource={resource}
                className={className}
                isRequired={isRequired}
                margin="dense"
            >
                <LinearProgress />
            </Labeled>
        );
    }

    return (
        <FormControl
            variant={variant}
            margin="dense"
            error={touched && !!(error || submitError)}
            className={classNames(classes.input, className)}
        >
            <InputLabel id={`${source}-outlined-label`} error={touched && !!(error || submitError)} shrink={shrink}>
                <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            </InputLabel>
            <Select
                autoWidth
                labelId={`${source}-outlined-label`}
                error={!!(touched && (error || submitError))}
                {...input}
                value={input.value || (multiple ? [] : '')}
                MenuProps={MenuProps}
                multiple={multiple}
                displayEmpty={!!emptyLabel}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                renderValue={
                    multiple
                        ? (selected: Iri[]) => (
                              <div className={classes.chips}>
                                  {selected
                                      .map((id) => choices[id])
                                      .filter((item) => !!item)
                                      .map((item) => (
                                          <Chip
                                              key={item['@id']}
                                              label={get(item, optionText)}
                                              className={classes.chip}
                                          />
                                      ))}
                              </div>
                          )
                        : undefined
                }
            >
                {allowEmpty && !multiple && (
                    <MenuItem value="" key="null">
                        {emptyLabel ?? ' '}
                    </MenuItem>
                )}
                {Object.keys(choices).map((id, index) => {
                    const choice = choices[id];
                    const text = (choice._level ? `${'-'.repeat(choice._level)} ` : '') + get(choice, optionText);
                    const disabled = disable
                        ? typeof disable === 'function'
                            ? disable(choice)
                            : (get(choice, disable, false) as boolean)
                        : false;
                    const isLastChoice = index + 1 === totalChoices;

                    return (
                        <MenuItem
                            key={id}
                            value={id}
                            disabled={disabled}
                            ref={isLastChoice ? lastChoiceRef : undefined}
                        >
                            {text}
                        </MenuItem>
                    );
                })}
                {loading && (
                    <MenuItem disabled>
                        <MuiLinearProgress className={classes.linear} />
                    </MenuItem>
                )}
            </Select>
            <FormHelperText error={touched && !!(error || submitError)}>
                <InputHelperText touched={Boolean(touched)} error={error || submitError} helperText={helperText} />
            </FormHelperText>
        </FormControl>
    );
};

export default ScrollableSelectInput;
