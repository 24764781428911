import { FC } from 'react';
import { AppBar as RaAppBar, AppBarProps, useTranslate } from 'react-admin';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsn from 'classnames';

import UserMenu from './UserMenu';
import LanguageMenu from './LanguageMenu';
import CustomNotification from './CustomNotification';
import CheckForApplicationUpdate from './CheckForApplicationUpdate';

import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

const useStyles = makeStyles<Theme, { isAdmin: boolean }>({
    root: ({ isAdmin }) => ({
        '& #header-language-menu + button, #header-language-menu + .MuiCircularProgress-root': {
            display: isAdmin ? 'block' : 'none',
        },
    }),
    reactAdminTitle: {
        flex: 1,
    },
    appTitle: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    overflow: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
});

const AppBar: FC<AppBarProps> = (props) => {
    const { isAdmin } = useUserPermissions();
    const isSmallScreen = useIsSmallScreen();
    const trans = useTranslate();
    const classes = useStyles({ isAdmin });

    return (
        <>
            <CustomNotification />
            <RaAppBar {...props} userMenu={<UserMenu />} className={classes.root}>
                <Typography
                    id="react-admin-title"
                    className={clsn(classes.reactAdminTitle, classes.oveflow)}
                    variant="h6"
                    color="inherit"
                />
                {!isSmallScreen && <strong className={classes.appTitle}>{trans('app.title')}</strong>}
                <CheckForApplicationUpdate />
                <LanguageMenu />
            </RaAppBar>
        </>
    );
};

export default AppBar;
