/* eslint-disable react/jsx-key */
import { FC } from 'react';
import {
    linkToRecord,
    ListProps,
    ReferenceManyField,
    SearchInput,
    SingleFieldList,
    TextField,
    useListContext,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import FunctionField from '@components/field/FunctionField';
import BooleanFilterInput from '@components/input/BooleanFilterInput';

import PreviewField from './field/PreviewField';

import { Status } from '@js/interfaces/status';

const useStyles = makeStyles((theme) => ({
    statusFieldList: {
        marginTop: theme.spacing(0.5),
        marginBottom: 0,
    },
}));

const sort = { field: 'label', order: 'ASC' };

const filters = [
    <SearchInput source="q" alwaysOn />,
    <BooleanFilterInput
        label="resources.statuses.filters.triggerSelloSync"
        source="children.triggerSelloSync"
        alwaysOn
        checkbox
    />,
    <BooleanFilterInput label="resources.statuses.filters.sold" source="children.sold" alwaysOn checkbox />,
    <BooleanFilterInput label="resources.statuses.fields.canceled" source="children.canceled" alwaysOn checkbox />,
    <BooleanFilterInput label="resources.statuses.fields.onSale" source="children.onSale" alwaysOn checkbox />,
];

const StatusesField: FC<{ source: string; sortable: boolean }> = ({ source, ...props }) => {
    const classes = useStyles();
    const { push } = useHistory();
    const { filterValues } = useListContext();
    const childrenFilter = filterValues?.children as Record<string, any> | undefined;

    const handleClick = (record: Status) => () => push(linkToRecord('/statuses', record.id, 'edit'));

    return (
        <ReferenceManyField {...props} reference="statuses" target="parent" sort={sort} filter={childrenFilter}>
            <SingleFieldList className={classes.statusFieldList} linkType={false}>
                <FunctionField<Status>
                    source={source}
                    render={(record) => (
                        <Box mr={1} mb={0.5}>
                            <PreviewField record={record} size="small" onClick={handleClick(record)} />
                        </Box>
                    )}
                />
            </SingleFieldList>
        </ReferenceManyField>
    );
};

const StatusSetList: FC<ListProps> = (props) => {
    return (
        <ListGuesser
            {...props}
            filters={filters}
            filter={{ 'exists[parent]': false }}
            sort={sort}
            hideFields={[
                'parent',
                'backgroundColor',
                'textColor',
                'triggerSelloSync',
                'sold',
                'complete',
                'autocomplete',
                'canceled',
                'purchasedSeparately',
                'waste',
                'recycling',
            ]}
        >
            <DatagridGuesser>
                <TextField source="label" sortable />
                <StatusesField source="children" sortable={false} />
            </DatagridGuesser>
        </ListGuesser>
    );
};

export default StatusSetList;
