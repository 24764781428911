import { FC } from 'react';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { ChipField, ReferenceArrayField, SingleFieldList, useGetResourceLabel, useTranslate } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { toLocaleString } from '@js/utility/dateUtility';
import { StatusNotification } from '@js/interfaces/statusNotification';

interface Props {
    notifications: StatusNotification[];
    showActions?: boolean;
    onDelete?: (index: number) => void;
}

const StatusNotificationsTable: FC<Props> = ({ notifications, showActions, onDelete }) => {
    const translate = useTranslate();
    const translateField = useTranslateResourceField('status_notifications');
    const getResourceLabel = useGetResourceLabel();

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{translateField('createdAt')}</TableCell>
                        <TableCell>{translateField('notifyAt')}</TableCell>
                        <TableCell>{translateField('message')}</TableCell>
                        <TableCell>{getResourceLabel('users')}</TableCell>
                        <TableCell>{getResourceLabel('user_groups')}</TableCell>
                        <TableCell>{translateField('notifiedAt')}</TableCell>
                        {showActions && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notifications.map((notification, index) => {
                        const { createdAt, notifyAt, message, notifiedAt } = notification;

                        return (
                            <TableRow key={index}>
                                <TableCell>{toLocaleString(createdAt)}</TableCell>
                                <TableCell>{toLocaleString(notifyAt)}</TableCell>
                                <TableCell>{message}</TableCell>
                                <TableCell>
                                    <ReferenceArrayField
                                        reference="users"
                                        source="users"
                                        record={{ id: notification['@id'], users: notification['users'] }}
                                    >
                                        <SingleFieldList linkType={false}>
                                            <ChipField source="fullName" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                </TableCell>
                                <TableCell>
                                    <ReferenceArrayField
                                        reference="user_groups"
                                        source="userGroups"
                                        record={{ id: notification['@id'], userGroups: notification['userGroups'] }}
                                    >
                                        <SingleFieldList linkType={false}>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                </TableCell>
                                <TableCell>
                                    <Typography component="span" noWrap>
                                        {notifiedAt ? translate('app.label.sent') : translate('app.label.pending')}
                                    </Typography>
                                </TableCell>

                                {showActions && onDelete && (
                                    <TableCell>
                                        <IconButton aria-label="delete" size="medium" onClick={() => onDelete(index)}>
                                            <DeleteIcon color="error" fontSize="inherit" />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default StatusNotificationsTable;
