import GroupIcon from '@material-ui/icons/Group';

import UserGroupList from './UserGroupList';
import UserGroupShow from './UserGroupShow';
import UserGroupForm from './UserGroupForm';

export default {
    list: UserGroupList,
    show: UserGroupShow,
    icon: GroupIcon,
    options: {
        form: UserGroupForm,
        alwaysHideFields: ['permissions', 'theme'],
    },
};
