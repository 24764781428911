import { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import {
    CRUD_CREATE,
    SelectInput,
    SelectInputProps,
    TextInput,
    useCreate,
    useGetMany,
    useGetResourceLabel,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { FormApi } from 'final-form';
import SaveIcon from '@material-ui/icons/Save';

import ScrollableSelectInput from '@components/input/ScrollableSelectInput';
import LoadingButton from '@components/button/LoadingButton';
import CommentsTimeline from '../../CommentsTimeline';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

import { Errand } from '@js/interfaces/errand';
import { ExpandPanelProps } from './ExpandPanel';
import { Comment } from '@js/interfaces/comment';
import { User } from '@js/interfaces/user';

type CommentField = 'classificationComment' | 'classificationOutComment';

const commentFields: Array<CommentField> = ['classificationComment', 'classificationOutComment'];

const RelatedUsersInput: FC<SelectInputProps & { record: Errand }> = ({ record, ...props }) => {
    const translate = useTranslate();
    const { loaded, loading, data } = useGetMany('users', record?.relatedUsers ?? []) as unknown as {
        data: User[];
        loading: boolean;
        loaded: boolean;
    };

    return (
        <SelectInput
            choices={[
                ...[
                    { id: '', name: ' ' },
                    {
                        id: 'everyone',
                        name: translate('resources.comments.options.notify.everyone'),
                    },
                ],
                ...data.filter(Boolean).map((user) => ({
                    id: user.id,
                    name: user.fullName,
                })),
            ]}
            {...props}
            loaded={loaded}
            loading={loading}
            source="notify"
            variant="standard"
            fullWidth
        />
    );
};

const CreateCommentForm: FC<{ onCreate: () => void } & ExpandPanelProps> = ({ onCreate, ...props }) => {
    const record = useRecordContext(props) as Errand;
    const [create, { loading: submitting }] = useCreate('comments');
    const notify = useNotify();
    const notifyHttpError = useNotifyHttpError();
    const getResourceLabel = useGetResourceLabel();
    const translateField = useTranslateResourceField();

    const handleSubmit = (data: Partial<Comment>, form: FormApi) => {
        // If reclamation field IS set - it means that user want to post Reclamation comment, in this case reset 'errand' field.
        // If reclamation IS NOT set - it means that user is going to post Errand comment, and we need to give it 'errand' ID. Also reset 'field' which is related only to reclamations.
        const dataModify = data.reclamation ? { errand: null } : { errand: record.id, field: null };
        const finalData = {
            ...data,
            ...dataModify,
        };

        create(
            {
                payload: {
                    data: finalData,
                },
            },
            {
                action: CRUD_CREATE,
                onSuccess: () => {
                    notify('ra.notification.created', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                    });
                    onCreate();
                    form.restart();
                },
                onFailure: notifyHttpError,
            },
        );
    };

    return (
        <Form
            onSubmit={handleSubmit}
            render={({ values, handleSubmit, dirty }) => (
                <Box paddingBottom={3} paddingTop={3} paddingRight={2} minWidth={320}>
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <div>
                            <TextInput
                                label={getResourceLabel('comments')}
                                source="text"
                                multiline
                                fullWidth
                                variant="outlined"
                                minRows={2}
                            />
                        </div>
                        <Box marginBottom={1} display="flex" flexDirection="column">
                            <RelatedUsersInput label={translateField('notify', 'comments')} record={record} />
                            <ScrollableSelectInput
                                label={translateField('reclamation', 'comments')}
                                source="reclamation"
                                reference="reclamations"
                                filter={{ errand: record.id }}
                                optionText="label"
                                emptyLabel={getResourceLabel('errands')}
                                variant="standard"
                                shrink
                                allowEmpty
                                fullWidth
                            />
                            {values['reclamation'] && (
                                <div>
                                    <SelectInput
                                        label={translateField('field', 'comments')}
                                        source="field"
                                        choices={commentFields.map((field) => ({
                                            id: field,
                                            name: translateField(field, 'reclamations'),
                                        }))}
                                        required
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            )}
                        </Box>
                        <LoadingButton
                            onClick={handleSubmit}
                            loading={submitting}
                            disabled={!dirty || !values['text']}
                            label="ra.action.save"
                            icon={<SaveIcon />}
                        />
                    </form>
                </Box>
            )}
        />
    );
};

const CommentsForm: FC<ExpandPanelProps> = (props) => {
    const [version, setVersion] = useState(0);

    // Force re-render comments list
    const handleCreate = () => {
        setVersion((v) => v + 1);
    };

    return (
        <Box display="flex">
            <CreateCommentForm onCreate={handleCreate} {...props} />
            <div>
                <CommentsTimeline key={version} {...props} />
            </div>
        </Box>
    );
};

export default CommentsForm;
