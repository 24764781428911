import { FC } from 'react';
import { NumberInput, NumberInputProps } from 'react-admin';
import decimal from '@js/validator/decimal';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';

export type PriceInputProps = Omit<NumberInputProps, 'format' | 'parse' | 'step'> & { source: string };

const format = (value?: number) => {
    if (!value && value !== 0) {
        return '';
    }

    return value / 100;
};

const parse = (value?: string) => {
    if (!value && value !== '0') {
        return null;
    }

    return Math.round(parseFloat(value) * 100);
};

const PriceInput: FC<PriceInputProps> = (props) => {
    const validate = useInputAutoValidator(props) ?? [];
    validate.push(decimal());

    return (
        <NumberInput
            {...props}
            step="0.01"
            validate={validate}
            format={format}
            parse={parse}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
    );
};

export default PriceInput;
