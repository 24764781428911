import { required, useRecordContext, useTranslate } from 'react-admin';

import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';

import { Product } from '@js/interfaces/product';

interface Props {
    source?: string;
    resource?: string;
    record?: Product;
    fullWidth?: boolean;
}

const BundleItemInput = ({ source = 'bundleItems', resource = 'products', ...props }: Props) => {
    const translate = useTranslate();
    const record = useRecordContext(props);
    const disabled = !!record?.id && record.syncedWithSello;
    const filter = {
        'exists[bundle]': false,
        'exists[bundleItems]': false,
        'exists[selloSyncedAt]': false,
        'exists[reclamation]': true,
    };

    return (
        <AutoCompleteGuesserInput
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={100}
            source={source}
            resource={resource}
            filter={filter}
            validate={required()}
            helperText={translate(
                `resources.products.message.${disabled ? 'disable_bundle_items_tip' : 'bundle_items_tip'}`,
            )}
            disabled={disabled}
            {...props}
        />
    );
};

export default BundleItemInput;
