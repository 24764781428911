import { FC } from 'react';
import { ListProps } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import CommissionField from './CommissionField';

export const resellerListFilter = {
    'exists[overrideReseller]': false,
    'exists[reclamation]': false,
};

const ResellerList: FC<ListProps> = (props) => (
    <ListGuesser {...props} filter={resellerListFilter}>
        <DatagridGuesser>
            <CommissionField />
        </DatagridGuesser>
    </ListGuesser>
);

export default ResellerList;
