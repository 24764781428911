import { ChangeEvent, FC } from 'react';
import { FieldTitle } from 'react-admin';
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core';
import { useField } from 'react-final-form';

type Props = {
    source: string;
    inverse?: boolean;
    resource?: string;
    alwaysOn?: boolean;
    checkbox?: boolean;
    label?: string;
};

const BooleanFilterInput: FC<Props> = ({ source, inverse, checkbox, ...rest }) => {
    const { input } = useField(source);

    // If filter is checked, set value true or false depending on inverse,
    // otherwise set value to null to remove filter.
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = !inverse;
        input.onChange(event.target.checked ? value : null);
    };
    const Control = checkbox ? Checkbox : Switch;

    return (
        <FormControlLabel
            control={<Control color="primary" checked={typeof input.value === 'boolean'} onChange={handleChange} />}
            label={<FieldTitle source={source} {...rest} />}
        />
    );
};

export default BooleanFilterInput;
