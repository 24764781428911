import { Validator } from 'react-admin';

const decimal = (): Validator => (value) => {
    if (typeof value === 'number') {
        value = value.toString();
    }

    if (typeof value === 'string' && !/^\d+(?:[.,])?\d{0,2}$/.test(value)) {
        return 'app.validation.validDecimal';
    }
};

export default decimal;
