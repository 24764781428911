import { FC } from 'react';
import { ReferenceManyField, ReferenceManyFieldProps, SingleFieldList, ChipField } from 'react-admin';

const TypesField: FC<Omit<ReferenceManyFieldProps, 'reference' | 'target' | 'children'>> = ({
    source: _,
    ...props
}) => {
    return (
        <ReferenceManyField reference="types" target="reclamations.errand" {...props}>
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceManyField>
    );
};

TypesField.defaultProps = {
    source: 'types',
};

export default TypesField;
