import { useRecordContext, useTranslate } from 'react-admin';
import { get } from 'lodash';
import { Box, Typography } from '@material-ui/core';

import { Reclamation } from '@js/interfaces/reclamation';

type Props = {
    source: string;
    soldQuantitySource?: string;
    addLabel?: boolean;
    sortable?: boolean;
    record?: Reclamation;
    resource?: string;
};

const QuantityField = ({ source, soldQuantitySource = 'soldQuantity', ...props }: Props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) return null;

    const quantity = get(record, source, 0) as number;
    const soldQuantity = get(record, soldQuantitySource, 0) as number;

    return (
        <Typography component="span" variant="body2" noWrap>
            {quantity}
            <Box component="span" ml={1}>
                {`( - ${soldQuantity} ${translate('app.label.sold')}) = ${quantity - soldQuantity}`}
            </Box>
        </Typography>
    );
};

QuantityField.defaultProps = {
    source: 'quantity',
    sortable: false,
    addLabel: true,
};

export default QuantityField;
