import { FC, ReactElement } from 'react';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton } from '@material-ui/core';

import ClickToCopyTooltip from '@components/button/ClickToCopyTooltip';

interface Props {
    source?: string;
    input: ReactElement<{ source: string }>;
}

const useIconStyles = makeStyles({
    icon: {
        position: 'absolute',
        right: 0,
        top: -8,
    },
    div: {
        position: 'relative',
    },
});

const InputCopyableWrapper: FC<Props> = ({ input }) => {
    const classes = useIconStyles();
    const {
        input: { value: copyText },
    } = useField(input.props.source, { subscription: { value: true } });

    return (
        <div className={classes.div}>
            <ClickToCopyTooltip text={copyText} className={classes.icon}>
                <IconButton disabled={!copyText}>
                    <FileCopyIcon className={classes.icon} fontSize="small" />
                </IconButton>
            </ClickToCopyTooltip>
            {input}
        </div>
    );
};

export default InputCopyableWrapper;
