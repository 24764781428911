import { FC } from 'react';
import { ReferenceManyFieldProps } from 'react-admin';

import ReferenceManyGrid from '@components/field/ReferenceManyGrid';

import { PredefinedText } from '@js/interfaces/predefinedtext';
import { UserGroup } from '@js/interfaces/usergroup';

const PredefinedTextDatagrid: FC<Omit<ReferenceManyFieldProps, 'children' | 'target' | 'reference' | 'record'>> = (
    props,
) => {
    return (
        <ReferenceManyGrid<PredefinedText, UserGroup>
            {...props}
            reference="predefined_texts"
            target="userGroup"
            initialValues={(record) => ({ userGroup: record.id.toString() })}
        />
    );
};

export default PredefinedTextDatagrid;
