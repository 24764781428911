import useListFields from '@js/hooks/useListFields';

interface Props {
    resource?: string;
}

const useShowFields = (props?: Props, hideFields?: string[]) => {
    return useListFields(props, hideFields);
};

export default useShowFields;
