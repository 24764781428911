import { ReactNode, useState } from 'react';
import {
    FileField,
    Identifier,
    ImageField,
    LinearProgress,
    Record as RaRecord,
    useReference,
    useTranslate,
} from 'react-admin';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ShowIcon from '@material-ui/icons/Visibility';

import MediaFileViewDialog from '@components/mui/MediaFileViewDialog';
import VideoField from '@components/field/VideoField';

import { VichUploadable } from '@js/interfaces/vichUploadable';
import { ExternalMedia } from '@js/utility/ExternalMedia';

interface UploadFile extends RaRecord {
    rawFile: File;
}

type FileRecord = VichUploadable | UploadFile;

interface Props {
    reference: string;
    source?: string;
    title?: string;
    record?: Identifier | FileRecord | ExternalMedia;
    className?: string;
}

const FileInputPreview = ({ record, source, title, ...props }: Props) => {
    if (!record) return null;

    if (isIdentifier(record)) {
        return <ReferencePreview {...props} source={source!} title={title!} id={record} />;
    }
    if (record instanceof ExternalMedia) {
        const Field = record.type === 'video' ? VideoField : ImageField;
        return <Field record={record} source="url" title="id" />;
    }

    return <FilePreview {...props} source={source!} title={title!} record={record} />;
};

// !Import to keep default props, because it's used by parent component
FileInputPreview.defaultProps = {
    source: 'paths.publicPath',
    title: 'originalName',
};

interface ReferencePreviewProps extends Omit<Props, 'record'> {
    id: Identifier;
    source: string;
    title: string;
}

const ReferencePreview = ({ id, ...props }: ReferencePreviewProps) => {
    const { loaded, referenceRecord } = useReference({ reference: props.reference, id: id as string });
    const translate = useTranslate();

    if (!loaded) {
        return (
            <Box mx={1} display="inline-block">
                <LinearProgress />
            </Box>
        );
    }
    if (!referenceRecord) return <Alert severity="warning">{translate('ra.page.not_found')}</Alert>;

    return <FilePreview record={referenceRecord as VichUploadable} {...props} />;
};

interface FilePreviewProps extends Props {
    record: FileRecord;
    source: string;
    title: string;
}

const FilePreview = (props: FilePreviewProps) => {
    const { record } = props;
    const mimeType = record.mimeType || record.rawFile?.type;

    if (mimeType?.startsWith('image') || mimeType?.startsWith('video')) {
        const Field = mimeType.startsWith('image') ? ImageField : VideoField;
        // If this is an uploaded file, then it is already downloaded
        if ('rawFile' in props.record) return <Field {...props} />;

        const { record, source, title } = props;
        return (
            <FileDownloadControl record={record} source={source} title={title}>
                <Field {...props} />
            </FileDownloadControl>
        );
    }

    return <FileField target="_blank" {...props} />;
};

interface FileDownloadControlProps {
    children: ReactNode;
    record: VichUploadable;
    source: string;
    title: string;
}

const FileDownloadControl = ({ children, record: file, source, title }: FileDownloadControlProps) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <>
            <IconButton
                className={classes.showButton}
                onClick={() => setOpen(true)}
                title={translate('ra.action.show')}
            >
                <ShowIcon color="secondary" />
            </IconButton>
            {children}
            <MediaFileViewDialog open={open} onClose={() => setOpen(false)} file={file} path={source} title={title} />
        </>
    );
};

const useStyles = makeStyles({
    showButton: {
        right: '40px !important',
    },
});

const isIdentifier = (record: Props['record']): record is Identifier => {
    return ['string', 'number'].includes(typeof record);
};

export default FileInputPreview;
