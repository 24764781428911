/* eslint-disable react/jsx-key */
import { NullableBooleanInput, SearchInput, useTranslate } from 'react-admin';
import { useMemo } from 'react';

export default function useFilters(prefixProperties?: string) {
    const translate = useTranslate();
    prefixProperties = prefixProperties ? `${prefixProperties}.` : '';

    return useMemo(
        () => [
            <SearchInput source="q" alwaysOn autoComplete="off" />,
            <NullableBooleanInput
                label={translate('resources.products.fields.exists[selloOrderRows]')}
                source={`exists[${prefixProperties}selloOrderRows]`}
                alwaysOn
            />,
            <NullableBooleanInput
                label={translate('resources.products.fields.exists[selloSyncedAt]')}
                source={`exists[${prefixProperties}selloSyncedAt]`}
                alwaysOn
            />,
        ],
        [prefixProperties, translate],
    );
}
