import { Children, FC, forwardRef, MouseEventHandler, ReactNode, useRef } from 'react';
import { Box, MenuItem, Theme, useMediaQuery } from '@material-ui/core';
import { useExpanded, useRecordContext, useResourceContext } from 'react-admin';
import QueueIcon from '@material-ui/icons/Queue';
import ContentCreateIcon from '@material-ui/icons/Create';

import IconButton from '@components/button/IconButton';
import MenuButton, { MenuHandle } from '@components/button/MenuButton';
import LockRecordIcon from '@components/detail/LockRecordIcon';
import SyncSelloButton from '@components/button/SyncSelloButton';
import PrintItemLabelButton from '@components/button/PrintItemLabelButton';

import SplitSelloButton from '../../reclamation/button/SplitSelloButton';
import DeleteButton from '../../reclamation/button/DeleteButton';
import PurchaseSeparatelyButton from '../../reclamation/button/PurchaseSeparatelyButton';

import { Reclamation } from '@js/interfaces/reclamation';
import { useResourcePermissions } from '@js/context/UserPermissionsContext';

interface Props {
    resource?: string;
    record?: Reclamation;
    onClone: (reclamation: Reclamation) => void;
    disableClone?: boolean;
}

const ButtonsView = forwardRef<MenuHandle, { children: ReactNode }>(({ children }, ref) => {
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true });

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon />
            {isSmall ? (
                <MenuButton ref={ref} keepMounted>
                    {Children.map(children, (button) => (
                        <MenuItem>{button}</MenuItem>
                    ))}
                </MenuButton>
            ) : (
                children
            )}
        </Box>
    );
});
ButtonsView.displayName = 'ButtonsView';

const EditRowButtons: FC<Props> = ({ onClone, disableClone, ...props }) => {
    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const [expanded, toggleExpanded] = useExpanded(resource, record?.id);
    const ref = useRef<MenuHandle>(null);
    const { isListActionEnabled, isFieldEditable, isEditActionEnabled } = useResourcePermissions(props);

    const handleToggleExpand: MouseEventHandler<HTMLButtonElement> = (event) => {
        toggleExpanded();
        event.stopPropagation();
        ref.current?.close();
    };

    return (
        <ButtonsView ref={ref}>
            <IconButton
                label="ra.action.clone"
                onClick={() => {
                    onClone(record);
                    ref.current?.close();
                }}
                disabled={expanded || disableClone}
            >
                <QueueIcon />
            </IconButton>
            {isListActionEnabled('purchase_separately') && <PurchaseSeparatelyButton {...props} />}
            {isListActionEnabled('split') && <SplitSelloButton />}
            {isFieldEditable('status') && <SyncSelloButton onClick={ref.current?.close} />}
            <PrintItemLabelButton record={record} />
            <IconButton label="ra.action.edit" onClick={handleToggleExpand} disabled={expanded}>
                <ContentCreateIcon />
            </IconButton>
            {isEditActionEnabled('delete') && (
                <DeleteButton record={record} onClick={() => ref.current?.close()} onlyIcon />
            )}
        </ButtonsView>
    );
};

export default EditRowButtons;
