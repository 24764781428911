import { useState } from 'react';
import {
    Button,
    LinearProgress,
    useGetResourceLabel,
    useListContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Typography, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

import DownloadIcon from '@material-ui/icons/GetApp';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import SettingsIcon from '@material-ui/icons/Settings';

import { useEntrypoint } from '@js/context/AppConfigContext';
import { downloadCsvStream } from '@js/request/apiRequest';
import { createListURL } from '@js/utility/hydraRequestUtility';
import useResourceExportFields from '@js/hooks/useResourceExportFields';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

const ErrandsConfigurableExportButton = (props: { resource?: string }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const entrypoint = useEntrypoint();
    const { total, selectedIds, loading: listLoading, currentSort, filterValues } = useListContext();
    const resource = useResourceContext(props);

    const countExport = selectedIds.length || total;

    const handleConfirmExport: ConfirmExportHandler = (selectedErrandFields, selectedReclamationFields) => {
        const url = createListURL(new URL(`${entrypoint}/${resource}/export`), {
            sort: currentSort,
            filter: {
                'fields[errands]': selectedErrandFields.join(','),
                'fields[reclamations]': selectedReclamationFields.join(','),
                ...filterValues,
                ...(selectedIds.length && { id: selectedIds }),
            },
        });

        setOpenDialog(false);
        downloadCsvStream(url.toString());
    };

    return (
        <>
            <Button onClick={() => setOpenDialog(true)} label="ra.action.export" disabled={total === 0 || listLoading}>
                <DownloadIcon />
            </Button>
            {openDialog && (
                <ConfigureOptionsDialog
                    resource={resource}
                    countExport={countExport}
                    onClose={() => setOpenDialog(false)}
                    onConfirmExport={handleConfirmExport}
                />
            )}
        </>
    );
};

type ConfirmExportHandler = (selectedErrandFields: string[], selectedReclamationFields: string[]) => void;

const ConfigureOptionsDialog = ({
    resource,
    countExport,
    onClose,
    onConfirmExport,
}: {
    resource: string;
    onClose: () => void;
    countExport: number;
    onConfirmExport: ConfirmExportHandler;
}) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const { selectedFields: selectedErrandFields, loading: loadingErrandFields } = useResourceExportFields('errands');
    const { selectedFields: selectedReclamationFields, loading: loadingReclamationFields } =
        useResourceExportFields('reclamations');
    const getFieldName = useTranslateResourceField();

    const loading = loadingErrandFields || loadingReclamationFields;

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>{translate('ra.action.confirm')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate('app.message.bulk_export_warning', { total: countExport })}
                </DialogContentText>
                {!loading ? (
                    <>
                        <Typography variant="subtitle2">{getResourceLabel('errands')}:</Typography>
                        <Typography variant="body2" gutterBottom>
                            {selectedErrandFields.map((field) => getFieldName(field, 'errands')).join(', ')}
                        </Typography>
                        <Typography variant="subtitle2">{getResourceLabel('reclamations')}:</Typography>
                        <Typography variant="body2">
                            {selectedReclamationFields.map((field) => getFieldName(field, 'reclamations')).join(', ')}
                        </Typography>
                    </>
                ) : (
                    <LinearProgress />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} label="ra.action.cancel" color="default">
                    <AlertError />
                </Button>
                <Button
                    component={Link}
                    to={`/${resource}/configure_export`}
                    label="app.action.configure"
                    color="default"
                >
                    <SettingsIcon />
                </Button>
                <Button
                    onClick={() => onConfirmExport(selectedErrandFields, selectedReclamationFields)}
                    color="primary"
                    label="ra.action.confirm"
                    disabled={loading}
                >
                    <ActionCheck />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrandsConfigurableExportButton;
