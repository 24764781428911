import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import CheckboxChunkGroupInput from '@components/input/CheckboxChunkGroupInput';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useErrandFilters from '@components/resource/errand/ErrandList/useFilters';
import useReclamationFilters from '@components/resource/reclamation/useFilters';

type Props = {
    source: string;
    resource: string;
};

const FilterSettings = ({ source, resource }: Props) => {
    const renderSettings = () => {
        switch (resource) {
            case 'errands':
                return <ErrandFilterSettings source={source} resource={resource} />;
            case 'reclamations':
                return <ReclamationFilterSettings source={source} resource={resource} />;
            default:
                return <Alert severity="error">Not supported {resource}</Alert>;
        }
    };

    return (
        <Box ml={5} mt={3}>
            {renderSettings()}
        </Box>
    );
};

const ErrandFilterSettings = (props: Props) => {
    const filters = useErrandFilters(props);
    return <ErrandReclamationFilterSettings {...props} filters={filters} />;
};

const ReclamationFilterSettings = (props: Props) => {
    const filters = useReclamationFilters(props);
    return <ErrandReclamationFilterSettings {...props} filters={filters} />;
};

const ErrandReclamationFilterSettings = ({
    source,
    resource,
    filters,
}: {
    source: string;
    resource: string;
    filters: ReactElement[];
}) => {
    const translateField = useTranslateResourceField(resource);
    const translate = useTranslate();

    const choices = filtersToChoices({
        filters,
        getFieldLabel: translateField,
    });

    return (
        <CheckboxChunkGroupInput
            label={translate('app.label.always_on')}
            source={`${source}.${resource}.listFilters.alwaysOn`}
            choices={choices}
        />
    );
};

const filtersToChoices = ({
    filters,
    getFieldLabel,
}: {
    filters: ReactElement[];
    getFieldLabel: (field: string) => string;
}) => {
    return filters
        .filter((filter) => filter.props.source !== 'q') // Exclude search filter
        .map((filter) => ({
            id: filter.props.source,
            name: filter.props.label || getFieldLabel(filter.props.source),
        }));
};

export default FilterSettings;
