import { FC } from 'react';
import { ReferenceArrayField, SelectField, SingleFieldList } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import CopyableTextField from '@components/field/CopyableTextField';
import FileField from '@components/field/FileField';
import FieldGuesser from '@components/field/FieldGuesser';

import ErrandProcessingTimeField from './ErrandProcessingTimeField';
import StatusPreviewField from './StatusPreviewField';
import PriceErrandCurrencyField from './PriceErrandCurrencyField';
import LinksField from './LinksField';
import LocationField from './LocationField';
import TypesField from './TypesField';
import AutoProcessingTimeField from './AutoProcessingTimeField';
import AddressField from './AddressField';
import CustomerField from './CustomerField';

import { useCurrencyChoices } from '@js/context/AppConfigContext';

interface Props {
    field: Field;
    source: string;
    addLabel?: boolean;
    sortable?: boolean;
    fullWidth?: boolean;
}

const ErrandFieldGuesser: FC<Props> = ({ field, ...props }) => {
    const currencyChoices = useCurrencyChoices();

    switch (field.name) {
        case 'composedIdentifier':
            return <CopyableTextField {...props} />;
        case 'processingTimes':
            return <ErrandProcessingTimeField {...props} />;
        case 'totalAutoProcessingTimeInSeconds':
            return <AutoProcessingTimeField {...props} />;
        case 'lastStatus':
            return <StatusPreviewField {...props} />;
        case 'currency':
            return <SelectField choices={currencyChoices} {...props} />;
        case 'cost':
        case 'costRedeemedItem':
        case 'shippingCost':
            return <PriceErrandCurrencyField {...props} />;
        case 'images':
            return (
                <ReferenceArrayField reference="errand_upload_images" {...props}>
                    <SingleFieldList linkType={false}>
                        <FileField />
                    </SingleFieldList>
                </ReferenceArrayField>
            );
        case 'links':
            return <LinksField {...props} />;
        case 'location':
            return <LocationField {...props} />;
        case 'types':
            return <TypesField {...props} />;
        case 'address':
            return <AddressField {...props} field={field} />;
        case 'customer':
            return <CustomerField {...props} field={field} />;
        default:
            return <FieldGuesser {...props} field={field} />;
    }
};

ErrandFieldGuesser.defaultProps = {
    addLabel: true,
};

export default ErrandFieldGuesser;
