import { FC, useState } from 'react';
import { FormApi } from 'final-form';
import {
    useListContext,
    useUpdateMany,
    useNotify,
    useResourceContext,
    Button,
    Confirm,
    required,
    useRefresh,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import LabelIcon from '@material-ui/icons/Label';

import { StatusInput } from './input/StatusInput';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';

interface Props {
    source: string;
    resource?: string;
}

const BulkUpdateStatusButton: FC<Props> = ({ source, ...props }) => {
    const [open, setOpen] = useState(false);
    const { selectedIds, onUnselectItems } = useListContext(props);
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const translateField = useTranslateResourceField();
    const [updateMany, { loading }] = useUpdateMany();
    const resource = useResourceContext(props);
    const refresh = useRefresh();

    const handleSubmit = (data: Record<string, any>, { restart }: FormApi) => {
        updateMany(resource, selectedIds, data, {
            onSuccess: () => {
                notify('ra.notification.updated', {
                    type: 'info',
                    messageArgs: {
                        smart_count: selectedIds.length,
                    },
                    undoable: true,
                });
                onUnselectItems();
                restart();
                setOpen(false);
            },
            onFailure: (error) => {
                notifyFailure(error);
                restart();
                setOpen(false);
                refresh();
            },
            mutationMode: 'undoable',
        });
    };
    const label = translateField(source);

    return (
        <>
            <Button label={label} onClick={() => setOpen(true)}>
                <LabelIcon />
            </Button>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <Confirm
                        isOpen={open}
                        loading={loading}
                        title={label}
                        content={
                            <Box minWidth="450px">
                                <StatusInput source={source} label={label} fullWidth validate={required()} />
                            </Box>
                        }
                        onConfirm={handleSubmit}
                        onClose={() => setOpen(false)}
                    />
                )}
            />
        </>
    );
};

export default BulkUpdateStatusButton;
