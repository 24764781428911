import { FC } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { LinearProgress, useInput, useTranslate } from 'react-admin';

import { useZebraPrinterContext, ZebraPrinterContextValue } from '@js/context/ZebraPrinterContext';
import { useResourceFeatures } from '@js/context/UserPermissionsContext';

const PrintLabelCheckbox: FC<
    Pick<ZebraPrinterContextValue, 'errors'> & {
        source: string;
    }
> = ({ errors, ...props }) => {
    const features = useResourceFeatures('reclamations');
    const { input } = useInput({
        ...props,
        initialValue: !features.disableDefaultLabelPrint && !errors,
        type: 'checkbox',
    });
    const translate = useTranslate();

    return (
        <FormControlLabel
            disabled={!!errors}
            control={<Switch {...input} color="primary" />}
            label={
                <>
                    {translate('app.action.print_label')}
                    {errors && <span> ({errors})</span>}
                </>
            }
        />
    );
};

const PrintLabelInput: FC<{
    source: string;
}> = (props) => {
    const { loaded, errors } = useZebraPrinterContext();

    if (!loaded) {
        return <LinearProgress />;
    }

    return <PrintLabelCheckbox errors={errors} {...props} />;
};

export default PrintLabelInput;
