import { FC, PropsWithChildren, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Form, FormProps, useForm } from 'react-final-form';
import {
    Button as RaButton,
    LinearProgress,
    minValue,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    useGetManyReference,
    useGetResourceLabel,
    useTranslate,
} from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import LoadingButton from '@components/button/LoadingButton';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useDidMountEffect from '@js/hooks/useDidMountEffect';
import { minutes } from '@js/validator/minutes';

import { PredefinedText } from '@js/interfaces/predefinedtext';
import { GetManyReferenceResult } from '@components/types';
import { Iri } from '@js/interfaces/ApiRecord';
import useGetIdentity from '@js/hooks/useGetIdentity';

export type ProcessingTimeFormData = {
    '@id'?: Iri;
    time: string;
    userGroup?: Iri;
    description?: string;
};

export type SubmitHandler = FormProps<ProcessingTimeFormData>['onSubmit'];

interface Props {
    initialValues?: FormProps<ProcessingTimeFormData>['initialValues'];
    onSubmit: SubmitHandler;
    onReset: () => void;
}

const resource = 'processing_times';

const FormRenderer: FC<PropsWithChildren<Props>> = ({ onSubmit, initialValues, onReset, children }) => {
    const translate = useTranslate();
    const isEditing = !!initialValues;

    return (
        <Form<ProcessingTimeFormData>
            onSubmit={onSubmit}
            initialValues={initialValues ?? {}}
            render={({ handleSubmit, pristine, form: { restart }, submitting }) => (
                <>
                    {children}
                    <Box my={2} display="flex" gridGap="5px">
                        <LoadingButton
                            onClick={handleSubmit}
                            disabled={pristine}
                            label={translate(isEditing ? 'ra.action.edit' : 'ra.action.add')}
                            loading={submitting}
                            icon={isEditing ? <SaveIcon /> : <AddIcon />}
                        />
                        {isEditing && (
                            <RaButton
                                size="small"
                                color="default"
                                label="ra.action.cancel"
                                disabled={submitting}
                                onClick={() => {
                                    onReset();
                                    restart();
                                }}
                            >
                                <CancelIcon />
                            </RaButton>
                        )}
                    </Box>
                </>
            )}
        />
    );
};

const PredefinedSelectField = () => {
    const getResourceLabel = useGetResourceLabel();
    const { identity } = useGetIdentity();
    const { ids, loaded, data } = useGetManyReference(
        'predefined_texts',
        'userGroup.users',
        identity?.id ?? 0,
        { page: 1, perPage: 99 },
        { field: 'createdAt', order: 'DESC' },
        {},
        'userGroups',
        {
            enabled: Boolean(identity?.id),
        },
    ) as GetManyReferenceResult<PredefinedText>;
    const [value, setValue] = useState<Iri>('');
    const form = useForm();

    useDidMountEffect(() => {
        const selected = value && data[value];
        if (!selected) return;

        form.batch(() => {
            if (!form.getFieldState('description')?.dirty) {
                form.change('description', selected.text);
            }
            if (!form.getFieldState('time')?.dirty && selected.time) {
                form.change('time', selected.time);
            }
        });
    }, [value]);

    if (!loaded) {
        return <LinearProgress />;
    }

    return (
        <FormControl margin="dense" fullWidth>
            <InputLabel>{getResourceLabel('predefined_texts')}</InputLabel>
            <Select value={value} onChange={(event) => setValue(event.target.value as Iri)}>
                <MenuItem value="">{' '}</MenuItem>
                {ids
                    .map((id) => data[id])
                    .filter(Boolean)
                    .map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

const UserGroupSelectField = () => {
    const { identity } = useGetIdentity();
    const getResourceLabel = useGetResourceLabel();

    if (!identity || !identity.userGroups.length) return null;

    return (
        <Box minWidth="240px">
            <ReferenceInput
                reference="user_groups"
                source="userGroup"
                label={getResourceLabel('user_groups', 1)}
                resource={resource}
                filter={{ id: identity.userGroups }}
                validate={required()}
                allowEmpty
                initialValue={identity.userGroups.length === 1 ? identity.userGroups[0] : undefined}
            >
                <SelectInput variant="standard" optionText="name" fullWidth />
            </ReferenceInput>
        </Box>
    );
};

const ProcessingTimeForm: FC<Props> = ({ onSubmit, onReset, initialValues }) => {
    const translateField = useTranslateResourceField(resource);

    return (
        <FormRenderer initialValues={initialValues} onSubmit={onSubmit} onReset={onReset}>
            <Box display={{ xs: 'block', md: 'flex' }} gridGap={10}>
                <UserGroupSelectField />
                <PredefinedSelectField />
            </Box>
            <Box display={{ xs: 'block', md: 'flex' }} gridGap={10}>
                <Box minWidth="240px">
                    <TextInput
                        type="number"
                        source="time"
                        label={translateField('timePlaceholder')}
                        resource={resource}
                        fullWidth
                        autoComplete="off"
                        validate={[minutes(), minValue(1), required()]}
                        variant="standard"
                    />
                </Box>
                <TextInput source="description" resource={resource} fullWidth variant="standard" multiline />
            </Box>
        </FormRenderer>
    );
};

export default ProcessingTimeForm;
