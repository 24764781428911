import { FC } from 'react';
import { LinearProgress, useTranslate } from 'react-admin';
import { Box, Typography } from '@material-ui/core';

import DatagridLoading from '@components/list/DatagridLoading';
import List from '../List';

import useFetchGet from '@js/hooks/useFetchGet';
import useFormatNumber from '@js/hooks/useFormatNumber';
import { Saving } from '@js/interfaces/Saving';

import { FilterValues } from './Filters';

type Props = {
    filters?: FilterValues;
};

type SavingsDetailedPayload = {
    savings_count: Record<Saving, number>;
};

const SavingsCount: FC<Props> = ({ filters }) => {
    const { data, loading } = useFetchGet<SavingsDetailedPayload>('/api/dashboard/savings_count', filters);
    const translate = useTranslate();
    const formatNumber = useFormatNumber();

    if (loading && !data) return <DatagridLoading nbFakeLines={4} />;
    if (!data) return null;

    const savings = data.savings_count;

    const items = (Object.entries(savings) as Array<[Saving, number]>).map(([saving, value]) => ({
        label: translate(`app.co2.savings.${saving}`),
        value: formatNumber(value),
    }));

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{translate('app.action.co2')}</Typography>
                {loading && <LinearProgress />}
            </Box>
            <List items={items} />
        </>
    );
};

export default SavingsCount;
