import { useEffect } from 'react';
import { useNotify } from 'react-admin';

import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';
import { useZebraPrinterContext } from '@js/context/ZebraPrinterContext';
import { UserIdentity } from '@js/auth/types';

const useUserZebraPrintSubscription = (identity: UserIdentity | undefined) => {
    const subscribe = useCustomMercureSubscription();
    const zebraState = useZebraPrinterContext();
    const notify = useNotify();

    useEffect(() => {
        const { print, loaded, errors } = zebraState;

        if (!identity?.id || !loaded) {
            return;
        }

        const topic = `${identity.id}/zebra_print`;

        return subscribe(topic, (zplData) => {
            if (errors) {
                notify(errors, {
                    type: 'warning',
                });

                return;
            }

            // When it's ready we get print function
            print(zplData);
            notify('app.message.sent_to_printer', {
                type: 'info',
            });
        });
    }, [identity, subscribe, zebraState, notify]);
};

export default useUserZebraPrintSubscription;
