import { FC, useState } from 'react';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Button, useRecordContext } from 'react-admin';

import { ApiToken } from '@js/interfaces/apitoken';

const TokenField: FC = (props) => {
    const [visible, setVisible] = useState(false);
    const record = useRecordContext<ApiToken>(props);

    const handleClick = () => {
        setVisible(true);
    };

    if (!visible) {
        return (
            <Button label="ra.action.show" onClick={handleClick} size="small">
                <ImageEye />
            </Button>
        );
    }

    return (
        <code>
            <pre>{record.token}</pre>
        </code>
    );
};

TokenField.defaultProps = {
    addLabel: true,
    source: 'token',
};

export default TokenField;
