import { ReactNode, useEffect, useState } from 'react';
import {
    Button,
    MutationMode,
    Record as RaRecord,
    RecordContextProvider,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, FormProps } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import LoadingButton from '@components/button/LoadingButton';

interface Props<T extends RaRecord> {
    children: ReactNode;
    resource: string;
    record: T;
    onClose: () => void;
    title?: string;
    mutationMode?: MutationMode;
    initialValues?: Record<string, any>;
    validate?: FormProps<T>['validate'];
}

const UpdateDialogFormContent = <T extends RaRecord>({
    children,
    record,
    resource,
    onClose,
    title,
    validate,
    initialValues = {},
    mutationMode = 'undoable',
}: Props<T>) => {
    const [update] = useUpdate();
    const notifyHttpError = useNotifyHttpError();
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();

    const handleSubmit = (data: Record<string, any>) =>
        update(resource, record.id, data, record, {
            onSuccess: () => {
                onClose();
                notify('ra.notification.updated', {
                    type: 'info',
                    messageArgs: {
                        smart_count: 1,
                    },
                    undoable: 'undoable' === mutationMode,
                });
            },
            onFailure: (error) => {
                notifyHttpError(error);
                refresh();
            },
            mutationMode,
            returnPromise: true,
        });

    return (
        <>
            {title && <DialogTitle>{translate(title, { _: title })}</DialogTitle>}
            <RecordContextProvider value={initialValues}>
                <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validate}>
                    {({ handleSubmit, pristine, submitting }) => (
                        <>
                            <DialogContent>{children}</DialogContent>
                            <DialogActions>
                                <Button onClick={onClose} label="ra.action.cancel" disabled={submitting}>
                                    <CancelIcon />
                                </Button>
                                <LoadingButton
                                    label="ra.action.save"
                                    loading={submitting}
                                    icon={<SaveIcon />}
                                    onClick={handleSubmit}
                                    disabled={pristine}
                                />
                            </DialogActions>
                        </>
                    )}
                </Form>
            </RecordContextProvider>
        </>
    );
};

const UpdateDialogForm = <T extends RaRecord>({ open, children, onClose, ...props }: Props<T> & { open: boolean }) => {
    // This will delay the rendering of the dialog content until the dialog is exited
    const [renderContent, setRenderContent] = useState(open);

    useEffect(() => {
        if (open) setRenderContent(open);
    }, [open]);

    const handleExited = () => {
        setRenderContent(false);
    };

    return (
        <Dialog fullWidth open={open} onClose={onClose} TransitionProps={{ onExited: handleExited }}>
            {(renderContent || open) && (
                <UpdateDialogFormContent onClose={onClose} {...props}>
                    {children}
                </UpdateDialogFormContent>
            )}
        </Dialog>
    );
};

export default UpdateDialogForm;
