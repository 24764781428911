const SentryEntryTest = () => {
    return (
        <span>
            <h1>Sentry Entry Test</h1>
            <button
                onClick={() => {
                    throw new Error('Test sentry error');
                }}
            >
                Thrown error
            </button>
        </span>
    );
};

export default SentryEntryTest;
