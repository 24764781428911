import { ApiRecord, Iri } from '@js/interfaces/ApiRecord';

export enum EVENT {
    ERRAND_CREATE = 'errand_create',
    STATUS_CHANGE = 'status_change',
}

export interface NotificationEvent extends ApiRecord {
    event: EVENT;
    initiator?: Iri;
    reference?: Iri;
    userRecipients?: Iri[];
    userGroupRecipients?: Iri[];
    message?: string;
}
