import { FC, ReactNode, useState } from 'react';
import { Box } from '@material-ui/core';
import { MutationMode, useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import AnchorText from '@components/field/AnchorText';
import UpdateDialogForm from '@components/form/UpdateDialogForm';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { ApiRecord } from '@js/interfaces/ApiRecord';

export interface EditableFieldProps {
    record?: ApiRecord;
    label?: string;
    resource?: string;
    source: string;
    children: ReactNode;
    form: ReactNode;
    initialValues?: Record<string, any>;
    mutationMode?: MutationMode;
}

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
        },
        '& button:hover': {
            cursor: 'pointer',
        },
    },
}));

const EditableField: FC<EditableFieldProps> = ({
    label,
    source,
    children,
    form,
    initialValues,
    mutationMode = 'undoable',
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const translate = useTranslate();
    const translateField = useTranslateResourceField();
    const classes = useStyles();
    const value = get(record, source);
    const resource = useResourceContext(props);
    label ??= translateField(source);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box component="span" onClick={(e) => e.stopPropagation()}>
            {value ? (
                <Box className={classes.link} component="span" onClick={handleOpen}>
                    {children}
                </Box>
            ) : (
                <AnchorText onClick={handleOpen} noWrap>
                    {translate('app.action.set', { subject: label })}
                </AnchorText>
            )}
            <UpdateDialogForm
                open={open}
                onClose={handleClose}
                resource={resource}
                record={record}
                title={label}
                initialValues={initialValues ?? { [source]: value }}
                mutationMode={mutationMode}
            >
                {form}
            </UpdateDialogForm>
        </Box>
    );
};

export default EditableField;
