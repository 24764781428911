import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Button as MuiButton, makeStyles } from '@material-ui/core';

type ValidationError = {
    message: string;
    severity: 'error' | 'warning';
};

const ImportErrors = ({ errors: allError }: { errors: ValidationError[] }) => {
    const [showAllErrors, setShowAllErrors] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();

    const errors = [
        ...allError.filter((error) => error.severity === 'error'),
        ...allError.filter((error) => error.severity !== 'error'),
    ];
    const count = errors.length;

    if (count === 0) {
        return <Alert severity="info">{translate('app.label.no_errors')}</Alert>;
    }

    const renderError = (error: ValidationError, index: number) => {
        return (
            <Alert key={index} severity={error.severity} className={classes.error}>
                {error.message}
            </Alert>
        );
    };
    const showLimit = 10;

    return (
        <>
            {errors?.slice(0, showLimit).map(renderError)}
            {count > showLimit && !showAllErrors && (
                <MuiButton onClick={() => setShowAllErrors(true)} variant="contained" className={classes.button}>
                    {`${translate('app.action.show_more')} (+${count - showLimit}) `}
                </MuiButton>
            )}
            {showAllErrors && errors?.slice(showLimit).map(renderError)}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    error: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export default ImportErrors;
