import { useState } from 'react';
import { Button, useRefresh } from 'react-admin';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import ManualSaleDialogForm from '../../manual_sales/ManualSaleDialogForm';
import { useIsFeatureDisabled } from '@js/context/AppConfigContext';

const ManualSaleButtonView = () => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    return (
        <>
            <Button onClick={() => setOpen(true)} label="app.action.manual_sale">
                <MonetizationOnIcon />
            </Button>
            <ManualSaleDialogForm open={open} onClose={() => setOpen(false)} postCreate={refresh} />
        </>
    );
};

export default function ManualSaleButton() {
    if (useIsFeatureDisabled()('ManualSale')) return null;

    return <ManualSaleButtonView />;
}
