import { FC, useEffect, useState } from 'react';
import {
    ChipField as RaChipField,
    RecordContextProvider,
    ReferenceArrayField,
    ReferenceArrayFieldProps,
    ResourceContext,
    SingleFieldList,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';

import ResellerCostInfo from '@components/resource/resellers/ResellerCostInfo';
import ResellerShowLayout from '@components/resource/resellers/ResellerShowLayout';

import { Reclamation } from '@js/interfaces/reclamation';
import { Reseller } from '@js/interfaces/reseller';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';

const useStyles = makeStyles({
    chipField: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

interface ChipFieldProps {
    record?: Reseller;
    reclamation: Reclamation;
    onClick: (reseller: Reseller) => void;
}

interface ResellerDialogProps {
    onClose: () => void;
    reseller?: Reseller;
    reclamation: Reclamation;
}

const ChipField: FC<ChipFieldProps> = ({ onClick, reclamation, ...props }) => {
    const classes = useStyles();
    const reseller = useRecordContext(props);
    if (!reseller) {
        return null;
    }
    // Check if Reclamation has override reseller that overrides original reseller
    const overrideReseller = reclamation?.overrideResellers?.find(
        ({ overrideReseller }) => overrideReseller === reseller.id,
    );

    const handleClick = () => {
        // Use override reseller values or original reseller
        onClick(overrideReseller ? { ...overrideReseller, id: overrideReseller['@id'] } : reseller);
    };

    return <RaChipField {...props} source="name" onClick={handleClick} className={classes.chipField} />;
};

const ResellersField: FC<Omit<ReferenceArrayFieldProps, 'children' | 'reference'>> = (props) => {
    const [reseller, setReseller] = useState<Reseller>();
    const reclamation = useRecordContext(props) as Reclamation;

    return (
        <>
            <ReferenceArrayField reference="resellers" {...props}>
                <SingleFieldList linkType={false}>
                    <ChipField reclamation={reclamation} onClick={setReseller} />
                </SingleFieldList>
            </ReferenceArrayField>
            <ResellerDialog onClose={() => setReseller(undefined)} reseller={reseller} reclamation={reclamation} />
        </>
    );
};

ResellersField.defaultProps = {
    addLabel: true,
    source: 'resellers',
};

const ResellerDialog: FC<ResellerDialogProps> = ({ onClose, reseller, reclamation }) => {
    const translate = useTranslate();
    const fullScreen = useIsSmallScreen();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (reseller) {
            setOpen(true);
        }
    }, [reseller]);

    return (
        <Dialog
            onClose={() => setOpen(false)}
            open={open}
            fullScreen={fullScreen}
            TransitionProps={{ onExited: onClose }}
        >
            <DialogTitle>{reseller?.name}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    {reseller && (
                        <RecordContextProvider value={reseller}>
                            <ResourceContext.Provider value="resellers">
                                <ResellerShowLayout reclamationPreview />
                                {reclamation.insurancePrice && (
                                    <>
                                        <Divider />
                                        <Box paddingTop={1}>
                                            <ResellerCostInfo reseller={reseller} reclamation={reclamation} />
                                        </Box>
                                    </>
                                )}
                            </ResourceContext.Provider>
                        </RecordContextProvider>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setOpen(false)} color="primary">
                    {translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResellersField;
