import { FC } from 'react';
import { Identifier, useVersion } from 'react-admin';
import { Alert } from '@material-ui/lab';
import useLockRecordWarningMessage from '@js/hooks/useLockRecordWarningMessage';

interface Props {
    id: Identifier;
}

const AlertLockEditRecord: FC<Props> = ({ id }) => {
    const version = useVersion();
    const warningMessage = useLockRecordWarningMessage(id);
    if (!warningMessage) return null;

    return (
        <Alert severity="warning" key={version}>
            {warningMessage}
        </Alert>
    );
};

export default AlertLockEditRecord;
