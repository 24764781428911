import { FC } from 'react';
import { Box } from '@material-ui/core';
import {
    Datagrid as RaDatagrid,
    DatagridProps,
    EditButton,
    ListProps,
    Pagination,
    ShowButton,
    useResourceDefinition,
} from 'react-admin';

import ListGuesser, { useListGuesserControllerContext } from '@components/list/ListGuesser';
import SimpleListGuesser from '@components/list/SimpleListGuesser';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import ListStickyToolbar from '@components/list/ListStickyToolbar';
import LockRecordIcon from '@components/detail/LockRecordIcon';

import ErrandListActions from './ErrandListActions';
import ExpandPanel from './ExpandPanel';
import ErrandFieldGuesser from '../field/ErrandFieldGuesser';
import BulkActionButtons from './BulkActionButtons';
import ManualSaleButton from './ManualSaleButton';
import ReturnedItemButton from './ReturnedItemButton';

import useFilters from './useFilters';
import { Errand } from '@js/interfaces/errand';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { isFieldSortable } from '@js/utility/fieldsUtil';
import { useResourceFilterDefaultValues, useResourcePermissions } from '@js/context/UserPermissionsContext';

const ErrandList = (props: ListProps) => {
    const isSmall = useIsSmallScreen();
    const filters = useFilters(props);
    const filterDefaultValues = useResourceFilterDefaultValues(['lastStatus'], props);

    return (
        <ListGuesser
            {...props}
            list={ListStickyToolbar}
            hideFields={['statusNotifications']}
            actions={<ErrandListActions />}
            filters={filters}
            filterDefaultValues={filterDefaultValues}
            bulkActionButtons={<BulkActionButtons />}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} />}
        >
            {isSmall ? (
                <SimpleListGuesser<Errand> linkType="show" secondaryText={(record) => record.composedIdentifier} />
            ) : (
                <Datagrid />
            )}
        </ListGuesser>
    );
};

const Datagrid: FC<DatagridProps> = (props) => {
    const { selectedFields, schema } = useListGuesserControllerContext();

    return (
        <RaDatagrid
            {...props}
            expand={ExpandPanel}
            rowStyle={(record) => ({
                ...((record as Errand)?.reclamationsCount > 1 && { backgroundColor: '#efe' }),
            })}
            body={DatagridBodyWithMercure}
            hasBulkActions
        >
            {selectedFields.map((field) => (
                <ErrandFieldGuesser
                    key={field.name}
                    source={field.name}
                    field={field}
                    sortable={isFieldSortable(field, schema)}
                />
            ))}
            <RowActions />
        </RaDatagrid>
    );
};

const RowActions = (props: { record?: Errand; resource?: string }) => {
    const { isListActionEnabled } = useResourcePermissions(props);
    const { hasShow, hasEdit } = useResourceDefinition(props);

    return (
        <Box display="flex" justifyContent="end">
            <LockRecordIcon />
            {hasShow && <ShowButton {...props} />}
            {hasEdit && <EditButton {...props} />}
            {isListActionEnabled('manual_sale') && <ManualSaleButton {...props} />}
            {isListActionEnabled('returned_item') && <ReturnedItemButton {...props} />}
        </Box>
    );
};

export default ErrandList;
