import { Identifier, linkToRecord, useReference } from 'react-admin';
import { FC } from 'react';

import TimelineLink from '@js/components/mui/TimelineLink';

import useReferenceFieldLink from '@js/hooks/useReferenceFieldLink';
import { User } from '@js/interfaces/user';

const TimelineUserTitle: FC<{ id: Identifier }> = ({ id }) => {
    const resource = 'users';
    const { referenceRecord, loaded } = useReference({ reference: resource, id: id.toString() });
    const user = referenceRecord as User | undefined;
    const link = useReferenceFieldLink(resource);

    if (!loaded) return <span>...</span>;
    if (!user) return <span>[User not found]</span>;

    if (!link) return <span>{user.fullName}</span>;

    return <TimelineLink to={linkToRecord(`/${resource}`, id, link)}>{user.fullName}</TimelineLink>;
};

export default TimelineUserTitle;
