import { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import CopyableTextField from '@components/field/CopyableTextField';
import FlowDialog from './FlowDialog';

import { Reclamation } from '@js/interfaces/reclamation';

interface Props {
    source: string;
    record?: Reclamation;
    resource?: string;
    addLabel?: boolean;
    sortable?: boolean;
}

const ComposedIdentifierField = (props: Props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();
    const [open, setOpen] = useState(false);

    if (!record) return null;
    const hasFlow = record.parent || record.child;

    return (
        <>
            <Box display="flex" alignItems="center">
                <CopyableTextField {...props} />
                {hasFlow && (
                    <Tooltip title={translate('app.label.flow')}>
                        <IconButton size="small" onClick={() => setOpen(true)}>
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <FlowDialog onClose={() => setOpen(false)} open={open} record={record} resource={props.resource} />
        </>
    );
};

export default ComposedIdentifierField;
