import { FC, PropsWithChildren } from 'react';
import {
    Identifier,
    Labeled,
    LinearProgress,
    useGetList,
    useGetManyReference,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useFetchGet from '@js/hooks/useFetchGet';
import { useFormatCentsPrice } from '@js/hooks/useFormatPrice';

import { Errand } from '@js/interfaces/errand';
import { Reseller } from '@js/interfaces/reseller';
import { Reclamation } from '@js/interfaces/reclamation';
import { GetManyReferenceResult } from '@components/types';

interface CostResponsePayload {
    salesPrice: number;
    handlingCost: number;
}

const TextField: FC<PropsWithChildren<{ label: string }>> = ({ children, label }) => (
    <div>
        <Labeled label={label}>
            <Typography component="span" variant="body2">
                {children}
            </Typography>
        </Labeled>
    </div>
);

const ReclamationOverrideInfo: FC<{ errandId: Identifier; resellerId: Identifier }> = ({ errandId, resellerId }) => {
    const translate = useTranslate();

    // Load Reclamations that overrides this reseller
    const { loaded, ids, data } = useGetList<Reclamation>('reclamations', undefined, undefined, {
        errand: errandId,
        resellers: resellerId,
        'overrideResellers.overrideReseller': resellerId,
    });

    const label = translate('resources.resellers.labels.overrideBy');

    if (!loaded) {
        return (
            <Labeled label={label}>
                <LinearProgress />
            </Labeled>
        );
    }

    return (
        <TextField label={label}>
            {ids.length ? ids.map((id) => data[id].label).join(', ') : translate('ra.boolean.false')}
        </TextField>
    );
};

const ResellerDetails: FC<{ errand: Errand; reseller: Reseller }> = ({ errand, reseller }) => {
    const translate = useTranslate();
    const formatPrice = useFormatCentsPrice(errand.currency);
    const { data: cost } = useFetchGet<CostResponsePayload>(`${reseller.id}/cost`, { errand: errand.id.toString() });

    const renderCostInfo = (cost: CostResponsePayload) => {
        const { salesPrice, handlingCost } = cost;

        const formatSalesPrice = formatPrice(salesPrice);
        const formatHandlingCost = formatPrice(handlingCost);
        const formatProfit = formatPrice(salesPrice - handlingCost);

        return (
            <>
                <TextField label={translate('resources.resellers.labels.salesPrice')}>{formatSalesPrice}</TextField>
                <TextField label={translate('resources.resellers.labels.handlingCost')}>{formatHandlingCost}</TextField>
                <TextField label={translate('app.label.profit')}>
                    {`${formatSalesPrice} - ${formatHandlingCost} = ${formatProfit}`}
                </TextField>
            </>
        );
    };

    return (
        <div>
            <ReclamationOverrideInfo errandId={errand.id} resellerId={reseller.id} />
            {cost ? renderCostInfo(cost) : <LinearProgress />}
        </div>
    );
};

const ResellersShowTab: FC<{
    record?: Errand;
}> = (props) => {
    const translate = useTranslate();
    const translateResource = useGetResourceLabel();
    const errand = useRecordContext(props);

    const { data, loaded, ids } = useGetManyReference(
        'resellers',
        'reclamations.errand',
        errand.id,
        { page: 1, perPage: 25 },
        { field: 'name', order: 'asc' },
        {},
        'reclamations',
    ) as GetManyReferenceResult<Reseller>;

    if (!loaded) {
        return (
            <Box my={3}>
                <LinearProgress />
            </Box>
        );
    }

    if (ids.length === 0) {
        return (
            <Box my={3}>
                <Alert severity="info">{translate('ra.page.empty', { name: translateResource('resellers') })}</Alert>
            </Box>
        );
    }

    return (
        <Box my={2}>
            {ids
                .map((id) => data[id])
                .map((reseller) => {
                    return (
                        <Accordion key={reseller.id} TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography component="span" noWrap>
                                    {reseller.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ResellerDetails errand={errand} reseller={reseller} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </Box>
    );
};

export default ResellersShowTab;
