import { ReactNode, useState } from 'react';
import { LinearProgress, useLocale, useRecordContext, useTranslate } from 'react-admin';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton as MuiIconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpacityIcon from '@material-ui/icons/Opacity';
import CloseIcon from '@material-ui/icons/Close';
import NavigationRefresh from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/Info';

import LoadingButton from '@components/button/LoadingButton';
import IconButton from '@components/button/IconButton';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import { Locale, useIsFeatureDisabled } from '@js/context/AppConfigContext';
import useFetchGet from '@js/hooks/useFetchGet';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useFormatPrice } from '@js/hooks/useFormatPrice';
import { useResourceFeatures } from '@js/context/UserPermissionsContext';

import { post } from '@js/request/apiRequest';
import { Reclamation } from '@js/interfaces/reclamation';
import { SavingRecord } from '@js/interfaces/Saving';

type Props = {
    record?: Reclamation;
};

const Co2CalculatorButton = (props: Props) => {
    const isFeatureDisabled = useIsFeatureDisabled();

    if (isFeatureDisabled('Co2')) return null;

    return <Co2CalculatorButtonView {...props} />;
};

const Co2CalculatorButtonView = (props: Props) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const disabled = !record?.category;

    const renderButton = () => (
        <IconButton label="app.action.co2" disabled={disabled} onClick={() => setOpen(true)}>
            <OpacityIcon />
        </IconButton>
    );

    return (
        <>
            {disabled ? (
                <Tooltip title="Category is required">
                    <span>{renderButton()}</span>
                </Tooltip>
            ) : (
                renderButton()
            )}
            {!disabled && <DialogInfo open={open} record={record} onClose={() => setOpen(false)} />}
        </>
    );
};

const useDialogInfoStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        zIndex: 2,
    },
}));

const DialogInfo = ({ record, open, onClose }: { record: Reclamation; open: boolean; onClose: () => void }) => {
    const isSmallScreen = useIsSmallScreen();
    const { data, refresh, loading } = useFetchGet<{ savings: SavingRecord[] }>(`${record.id}/savings`, undefined, {
        enabled: open && !!record?.id,
    });
    const translate = useTranslate();
    const notifyError = useNotifyHttpError();
    const [refreshing, setRefreshing] = useState(false);

    const classes = useDialogInfoStyles();

    const handleRefresh = async () => {
        setRefreshing(true);

        try {
            await post<void>(`${record.id}/clear_savings`);
            refresh();
        } catch (error) {
            notifyError(error);
        } finally {
            setRefreshing(false);
        }
    };

    const errors = data?.savings.filter((saving) => !!saving.error);
    const savings = data?.savings.filter((saving) => !saving.error);

    return (
        <Dialog fullWidth fullScreen={isSmallScreen} open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                {translate('app.action.co2')}
                <MuiIconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
                    <CloseIcon />
                </MuiIconButton>
            </DialogTitle>
            <DialogContent>
                {(loading || refreshing) && <LinearProgress />}
                {errors?.map((saving) => (
                    <Box key={saving.error} my={1}>
                        <Alert severity="warning">
                            {saving.error}
                            {!!saving.recipe && ` | ${saving.recipe}`}
                        </Alert>
                    </Box>
                ))}
                {savings?.map((saving, index) => (
                    <SavingsAccordion key={index} savingRecord={saving} />
                ))}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={refreshing}
                    disabled={loading}
                    onClick={handleRefresh}
                    label="ra.action.refresh"
                    icon={<NavigationRefresh />}
                />
            </DialogActions>
        </Dialog>
    );
};

const SavingsAccordion = ({ savingRecord }: { savingRecord: SavingRecord }) => {
    const translate = useTranslate();
    const getFieldLabel = useTranslateResourceField();
    const formatPrice = useFormatPrice();
    const features = useResourceFeatures('reclamations');
    const [tabValue, setTabValue] = useState(0);

    const salesLabel = `${getFieldLabel('salesPrice', 'sello_sales')}: ${formatPrice(savingRecord.salesPrice)}`;
    const quantityLabel = `${getFieldLabel('quantity', 'reclamations')}: ${savingRecord.soldQuantity}`;

    const showRawValues = !features.disableSavingsRawValues;
    const showAnalogies = !features.disableSavingsAnalogies;

    const renderAsTabs = showRawValues && showAnalogies;

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{`${savingRecord.recipe} | ${salesLabel} | ${quantityLabel}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {renderAsTabs ? (
                    <Box flexGrow={1}>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(_, newValue) => setTabValue(newValue)}
                        >
                            <Tab label={translate('app.label.values')} />
                            <Tab label={translate('app.label.analogies')} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <SavingsRawValues savingRecord={savingRecord} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Analogies savingRecord={savingRecord} />
                        </TabPanel>
                    </Box>
                ) : (
                    <>
                        {showRawValues && <SavingsRawValues savingRecord={savingRecord} />}
                        {showAnalogies && <Analogies savingRecord={savingRecord} />}
                    </>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

const TabPanel = ({ children, value, index }: { children: ReactNode; value: number; index: number }) => {
    const isActive = value === index;

    return (
        <div role="tabpanel" hidden={!isActive}>
            {isActive && children}
        </div>
    );
};

const FormulaInfoIcon = ({ formula }: { formula: string | null }) => {
    const [show, setShow] = useState(false);
    const translate = useTranslate();

    if (!formula) return null;

    return (
        <>
            <Tooltip title={translate('ra.message.details')} placement="top">
                <MuiIconButton onClick={() => setShow(!show)} size="small" disableRipple disableFocusRipple>
                    <InfoIcon fontSize="inherit" />
                </MuiIconButton>
            </Tooltip>
            {show && (
                <>
                    <br />
                    {formula}
                </>
            )}
        </>
    );
};

const SavingsRawValues = ({ savingRecord }: { savingRecord: SavingRecord }) => {
    const translate = useTranslate();

    return (
        <List>
            {Object.entries(savingRecord.savings).map(([saving, result]) => {
                return (
                    <ListItem key={saving} disableGutters>
                        <ListItemText
                            primary={translate(`app.co2.savings.${saving}`)}
                            secondary={
                                result?.value ? (
                                    <>
                                        {result.value}
                                        <FormulaInfoIcon formula={result.formula} />
                                    </>
                                ) : (
                                    translate('app.label.no_result')
                                )
                            }
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

const useAnalogiesStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}));

const Analogies = ({ savingRecord }: { savingRecord: SavingRecord }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useAnalogiesStyles();

    const analogies = savingRecord.analogies;
    const atLeastOneAnalogy = Object.values(analogies).some((value) => value.length > 0);

    if (!atLeastOneAnalogy) {
        return (
            <Box my={2}>
                <Alert severity="info">{translate('ra.page.not_found')}</Alert>
            </Box>
        );
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            {Object.entries(analogies).map(([saving, analogyList]) => {
                return analogyList.map((analogy) => {
                    const randomImage =
                        analogy.logos.length === 0
                            ? null
                            : analogy.logos.length > 1
                            ? analogy.logos[Math.floor(Math.random() * analogy.logos.length)]
                            : analogy.logos[0];
                    const description = analogy.description[locale as Locale] || analogy.description.en;

                    return (
                        <Grid key={analogy.id} item xs={12} sm={6} md={4} lg={3}>
                            <Card key={`${saving}-${analogy.id}`} className={classes.card}>
                                {randomImage && (
                                    <CardMedia
                                        image={randomImage.url}
                                        title={randomImage.title}
                                        className={classes.media}
                                    />
                                )}
                                <CardContent>
                                    <Typography variant="subtitle2" component="p">
                                        {translate(`app.co2.savings.${saving}`)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                });
            })}
        </Grid>
    );
};

export default Co2CalculatorButton;
