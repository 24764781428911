import { ComponentType, FC } from 'react';
import { Resource, ResourceProps, ResourceOptions as RaResourceOptions } from 'react-admin';
import UserPermissionsService from '@js/auth/UserPermissionsService';

import ListGuesser from '@components/list/ListGuesser';
import ShowGuesser from '@components/detail/ShowGuesser';
import CreateGuesser from '@components/detail/CreateGuesser';
import EditGuesser from '@components/detail/EditGuesser';
import { useIsResourceDisabled } from '@js/context/AppConfigContext';

export interface RegisterResource<T extends object = Record<string, never>>
    extends Pick<ResourceProps, 'list' | 'show' | 'create' | 'edit' | 'icon'> {
    options?: ResourceOptions<T>;
}

export interface ResourceOptions<T extends object = Record<string, never>> extends RaResourceOptions {
    form?: ComponentType;
    alwaysHideFields?: Array<keyof T>;
}

interface Props extends Omit<ResourceProps, 'list' | 'show' | 'create' | 'edit'> {
    list?: ResourceProps['list'] | null;
    create?: ResourceProps['create'] | null;
    edit?: ResourceProps['edit'] | null;
    show?: ResourceProps['show'] | null;
    permissions: UserPermissionsService;
    options?: ResourceOptions;
}

const ResourceGuesser: FC<Props> = ({ permissions, list, show, create, edit, ...props }) => {
    const isResourceDisabledBySettings = useIsResourceDisabled();

    if (!permissions?.isResourceEnabled(props.name) || isResourceDisabledBySettings(props.name)) {
        return <Resource {...props} />;
    }

    return (
        <Resource
            {...props}
            // View can be disabled by settings it to null, if not view provided use guesser or use given component
            list={list === null ? undefined : list ?? ListGuesser}
            show={show === null ? undefined : show ?? ShowGuesser}
            create={create === null ? undefined : create ?? CreateGuesser}
            edit={edit === null ? undefined : edit ?? EditGuesser}
        />
    );
};

export default ResourceGuesser;
