import { FC, useState } from 'react';
import { Identifier, useGetResourceLabel, useTranslate } from 'react-admin';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SettingsIcon from '@material-ui/icons/Settings';

import OverrideResellerModal from './OverrideResellerModal';
import { Reseller } from '@js/interfaces/reseller';

interface Props {
    selectedIds: Identifier[];
    choices: Array<Reseller>;
    loaded?: boolean;
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
}));

const SettingsButton: FC<Props> = ({ choices, selectedIds = [], loaded }) => {
    const resellers = choices.filter(({ id }) => id && selectedIds.includes(id));

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();
    const translateResource = useGetResourceLabel();

    const overrideLabel = translate('app.action.override');
    const disabledTooltip = () =>
        loaded ? translate('app.label.choose_at_least_one', { option: translateResource('resellers') }) : '';
    const isDisabled = !loaded || selectedIds.length < 1;

    return (
        <>
            <Tooltip title={isDisabled ? disabledTooltip() : overrideLabel}>
                <span>
                    <IconButton
                        className={classes.button}
                        aria-label={overrideLabel.toLowerCase()}
                        disabled={isDisabled}
                        onClick={() => setOpen(true)}
                    >
                        <SettingsIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <OverrideResellerModal
                open={open}
                source="overrideResellers"
                resellers={resellers}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

export default SettingsButton;
