import { FC } from 'react';
import {
    ArrayInput,
    required,
    SelectInput,
    SelectInputProps,
    SimpleFormIterator,
    useGetResourceLabel,
    useTranslate,
} from 'react-admin';

import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';

import { UserGroup } from '@js/interfaces/usergroup';
import { User } from '@js/interfaces/user';
import { Rule } from '@js/interfaces/StatusChangeRule';

interface Props {
    source?: string;
    record?: UserGroup | User;
    resource?: string;
    fullWidth?: boolean;
}

const RuleSelectInput: FC<SelectInputProps> = (props) => {
    return (
        <SelectInput
            {...props}
            choices={Object.values(Rule).map((rule) => ({
                id: rule,
                name: `resources.status_change_rules.rule.${rule}`,
            }))}
        />
    );
};

const StatusChangeRulesFormTab: FC<Props> = (props) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    const getLabel = (key: string, args?: Record<string, string>) => {
        return translate(`resources.status_change_rules.fields.${key}`, args);
    };

    return (
        <ArrayInput source="statusChangeRules" {...props}>
            <SimpleFormIterator disableReordering TransitionProps={{ timeout: 0 }}>
                <ReferenceRecursiveSelectInput
                    source="sourceStatus"
                    label={getLabel('sourceStatus', { resource: getResourceLabel('reclamations') })}
                    validate={required()}
                    reference="statuses"
                />
                <RuleSelectInput source="rule" label={getLabel('rule')} validate={required()} />
                <ReferenceRecursiveSelectInput
                    source="targetStatus"
                    label={getLabel('targetStatus', { resource: getResourceLabel('errands') })}
                    validate={required()}
                    reference="statuses"
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

StatusChangeRulesFormTab.defaultProps = {
    source: 'statusChangeRules',
};

export default StatusChangeRulesFormTab;
