import { Label } from '@material-ui/icons';

import StatusList from './StatusList';
import StatusForm from './StatusForm';

export default {
    list: StatusList,
    show: null,
    icon: Label,
    options: {
        form: StatusForm,
        alwaysHideFields: ['breadcrumbs'],
    },
};
