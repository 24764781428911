import { FC, useEffect, useState } from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { Field } from '@api-platform/api-doc-parser';
import { useField, useForm } from 'react-final-form';

import InputGuesser from '@components/form/InputGuesser';
import QuickCreateReferenceButton from '@js/components/button/QuickCreateReferenceButton';
import AddressTypeInput from '@components/resource/address/AddressTypeInput';

import { Iri } from '@js/interfaces/ApiRecord';
import useFormFields from '@js/hooks/useFormFields';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

const CreateButton: FC<{ onCreate: () => void; customer: Iri }> = ({ onCreate, customer }) => {
    const fields = useFormFields({ resource: 'addresses' });

    const getInputElement = (field: Field) => {
        switch (field.name) {
            case 'type':
                return <AddressTypeInput fullWidth />;
            default:
                return <InputGuesser source={field.name} field={field} fullWidth />;
        }
    };

    return (
        <Box mb="20px">
            <QuickCreateReferenceButton
                field="address"
                resource="addresses"
                disabled={!customer}
                initialValues={{ customer }}
                onChange={onCreate}
            >
                {fields.map((field) => (
                    <div key={field.name}>{getInputElement(field)}</div>
                ))}
            </QuickCreateReferenceButton>
        </Box>
    );
};

interface Props {
    source: string;
    resource?: string;
    reference?: string;
    fullWidth?: boolean;
    helperText?: string | false;
}

const AddressInput = ({ reference = 'addresses', helperText, ...props }: Props) => {
    const { change } = useForm();
    const {
        input: { value: customer },
        meta: { dirty: customerDirty },
    } = useField<Iri>('customer', { subscription: { value: true, dirty: true } });

    const fieldName = useResourceFieldName(reference);
    const [version, setVersion] = useState(0);

    useEffect(() => {
        if (customerDirty) {
            change(props.source, null);
        }
    }, [customerDirty, customer, change, props.source]);

    return (
        <Box display="flex" alignItems="center">
            {customer ? (
                <ReferenceInput key={version} reference={reference} filter={{ customer }} {...props}>
                    <SelectInput optionText={fieldName} allowEmpty resettable fullWidth helperText={helperText} />
                </ReferenceInput>
            ) : (
                <SelectInput {...props} allowEmpty resettable fullWidth choices={[]} disabled helperText={helperText} />
            )}
            <CreateButton
                onCreate={() => {
                    setVersion((v) => v + 1);
                }}
                customer={customer}
            />
        </Box>
    );
};

export default AddressInput;
