import { Validator } from 'react-admin';

const repeat =
    (compareToField: string): Validator =>
    (value, allValues) => {
        if (value !== allValues[compareToField] || '') {
            return { message: 'app.validation.repeat', args: { compareToField } };
        }
    };

export default repeat;
