import { useMemo } from 'react';
import { useResourceContext } from 'react-admin';

import { useListColumnContext } from '@js/context/ListColumnsContext';
import useListFields from '@js/hooks/useListFields';
import useResourceSchema from './useResourceSchema';

interface Props {
    resource?: string;
}

const SHOW_DEFAULT_MAX_COLUMNS = 5;

const useListGuesserController = (props: Props, hideFields?: string[]) => {
    const resource = useResourceContext(props);
    const { columns } = useListColumnContext();
    const schema = useResourceSchema(props);
    const fields = useListFields({ resource: props.resource, schema }, hideFields);

    return useMemo(() => {
        const selectedColumns =
            columns[resource] ||
            fields
                .filter(({ name }) => 'updatedAt' !== name)
                .slice(0, SHOW_DEFAULT_MAX_COLUMNS)
                .map((field) => field.name);

        return {
            schema,
            fields,
            selectedFields: fields.filter((field) => selectedColumns.includes(field.name)),
            selectedColumns,
        };
    }, [columns, fields, resource, schema]);
};

export default useListGuesserController;
