import { cacheDataProviderProxy, Identifier } from 'react-admin';
import {
    ApiDocumentationParserResponse,
    ApiPlatformAdminDataProvider,
    fetchHydra,
    hydraDataProvider as baseHydraDataProvider,
} from '@api-platform/admin';
import { Field, parseHydraDocumentation, Resource } from '@api-platform/api-doc-parser';

import { createFakeIdParameters } from './parameterHelper';
import { normalizeMediaObjectField } from './dataProviderNormalizers';
import { ExtendedResourceFields } from '@js/global';
import { getOrFetchCurrentUser } from '@js/auth/authProvider';

interface NormalizableDataField extends Field {
    reference: Resource;
    normalizeData: (data: any) => Promise<Identifier | Identifier[] | null>;
}

const isNormalizableDataField = (field: Field | undefined): field is NormalizableDataField => {
    return typeof field === 'object' && typeof field.reference === 'object' && field.reference !== null;
};

const apiDocumentationParser = async (entrypoint: string): Promise<ApiDocumentationParserResponse> => {
    const currentUser = await getOrFetchCurrentUser();

    // If failed to get refresh token redirect to login
    if (!currentUser) {
        // Simulate unauthorized response
        return {
            api: {
                entrypoint,
                resources: [],
            },
            status: 401,
            response: new Response(),
        };
    }

    const [hydraDocumentation, extendDocs] = await Promise.all([
        parseHydraDocumentation(entrypoint),
        fetch(new URL(`${entrypoint}/extended-documentation`)).then(
            (response) => response.json() as Promise<Record<string, ExtendedResourceFields>>,
        ),
    ]);

    const simpleFileUploadFields: Record<string, string[]> = {
        errands: ['images'],
        user_groups: ['logo'],
        reclamations: ['images'],
        stores: ['images', 'files'],
        products: ['images'],
    };

    // Manually set parameters/normalizers of some resources
    hydraDocumentation.api.resources?.forEach((resource) => {
        // Add ID filter parameters to all resources.
        // All resources by default have ID filter \Corerely\ApiPlatformHelperBundle\Doctrine\Extension\IdentifierCollectionFilterExtension
        resource.parameters = createFakeIdParameters();
        resource.sortableFields = extendDocs[resource.name]?.sortableFields || [];
        resource.validationRules = extendDocs[resource.name]?.validationRules || {};

        // Normalize File Upload fields
        simpleFileUploadFields[resource.name]?.forEach((fieldName) => {
            const field = resource.fields?.find(({ name }) => name === fieldName);

            if (!isNormalizableDataField(field)) {
                throw new Error(
                    `Invalid upload-able field normalization configuration for resource "${resource.name}" and field "${fieldName}"`,
                );
            }

            field.normalizeData = async (data) => normalizeMediaObjectField(field.reference.url, data);
        });
    });

    return hydraDocumentation;
};

// https://api-platform.com/docs/admin/handling-relations/#embedded-relations
const useEmbedded = true;

const getDataProvider = (entrypoint: string, mercure: string) => {
    const hydraDataProvider = baseHydraDataProvider({
        entrypoint,
        httpClient: fetchHydra,
        apiDocumentationParser,
        useEmbedded,
        mercure: {
            hub: mercure,
        },
    });
    // Cache responses for 1 minute.
    const oneMinute = 60 * 1000;

    return cacheDataProviderProxy(hydraDataProvider, oneMinute) as ApiPlatformAdminDataProvider;
};

export default getDataProvider;
