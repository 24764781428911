import { BulkActionProps, BulkDeleteButton, useResourceContext } from 'react-admin';

import { useUserPermissions } from '@js/context/UserPermissionsContext';

const BulkActionButtons = (props: BulkActionProps) => {
    const permissions = useUserPermissions();
    const resource = useResourceContext(props);

    if (!permissions.isEditActionEnabled(resource, 'delete')) return null;

    return <BulkDeleteButton {...props} />;
};

export default BulkActionButtons;
