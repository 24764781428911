import { BooleanInput, BooleanInputProps } from 'react-admin';

const InverseBooleanInput = ({ parse, format, ...props }: BooleanInputProps) => {
    const finalParse = (value: any) => (!parse ? !value : parse(value));
    const finalFormat = (value: any) => (!format ? !value : format(value));

    return <BooleanInput {...props} parse={finalParse} format={finalFormat} />;
};

export default InverseBooleanInput;
