import { useTranslate } from 'react-admin';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import List from '@components/Dashboard/List';
import DatagridLoading from '@components/list/DatagridLoading';

import useFetchGet from '@js/hooks/useFetchGet';
import useFormatNumber from '@js/hooks/useFormatNumber';

import { FilterValues } from './Filters';

type Props = {
    filter?: FilterValues;
};

type Payload = {
    savings_error_count: Array<{
        error: string;
        count: number;
    }>;
    savings_usage_default_value_count: number;
};

const SavingsErrorCount = ({ filter }: Props) => {
    const translate = useTranslate();
    const { data, loading } = useFetchGet<Payload>('/api/dashboard/savings_error_count', filter);
    const formatNumber = useFormatNumber();

    if (loading && !data) return <DatagridLoading nbFakeLines={4} />;
    if (!data) return null;

    const items = [...data.savings_error_count]
        .sort((e1, e2) => e2.count - e1.count)
        .map((error) => ({
            label: error.error,
            value: formatNumber(error.count),
        }));

    return (
        <>
            <Box display="flex" gridGap="8px" alignItems="center">
                <Typography variant="h6">{translate('app.label.error_count')}</Typography>
                {loading && <CircularProgress size={18} />}
            </Box>
            <List
                items={[
                    ...items,
                    {
                        label: translate('app.co2.calculated_using_default_values'),
                        value: formatNumber(data.savings_usage_default_value_count),
                    },
                ]}
                disablePadding
            />
        </>
    );
};

export default SavingsErrorCount;
