import { Link, LinkProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },
}));

const TimelineLink = (props: LinkProps) => {
    const classes = useStyles();

    return <Link className={classes.link} {...props} />;
};

export default TimelineLink;
