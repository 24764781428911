import { FC } from 'react';
import { LinearProgress, Link, linkToRecord, useGetResourceLabel, useReference, useTranslate } from 'react-admin';
import Alert from '@material-ui/lab/Alert';

import { Iri } from '@js/interfaces/ApiRecord';
import { Errand } from '@js/interfaces/errand';
import { Comment } from '@js/interfaces/comment';
import { StatusNotification } from '@js/interfaces/statusNotification';
import { Reclamation } from '@js/interfaces/reclamation';

interface Props {
    reference: Iri;
}

const ReferenceInfo: FC<Props> = ({ reference: id }) => {
    const reference = id.split('/')[2];
    const getResourceLabel = useGetResourceLabel();
    const { loaded, referenceRecord: record } = useReference({ reference, id });
    const translate = useTranslate();

    // Can not parse IRI reference
    if (!reference) {
        return null;
    }

    const renderInfo = () => {
        if (!record) return null;

        switch (reference) {
            case 'errands':
                const errand = record as Errand;
                return (
                    <Link to={linkToRecord('/errands', errand.id, 'show')}>
                        {errand.name ? `${errand.name} [${errand.composedIdentifier}]` : errand.composedIdentifier}
                    </Link>
                );
            case 'comments':
                const comment = record as Comment;
                return (
                    <>
                        {comment.text}{' '}
                        <Link to={`${linkToRecord('/errands', comment.errandOrReclamationErrand, 'show')}/comments`}>
                            {translate('ra.action.show')}
                        </Link>
                    </>
                );
            case 'status_notifications':
                const statusNotification = record as unknown as StatusNotification;
                let link = linkToRecord('/errands', statusNotification.errandOrReclamationErrand, 'show');
                if (statusNotification.reclamation) {
                    link += '/reclamations';
                }
                return <Link to={link}>{translate('ra.action.show')}</Link>;
            case 'reclamations':
                const reclamation = record as Reclamation;
                return (
                    <Link to={`${linkToRecord('/errands', reclamation.errand, 'show')}/reclamations`}>
                        {reclamation.label}
                    </Link>
                );
            default:
                return <Alert severity="info">No information about reference.</Alert>;
        }
    };

    return (
        <tr>
            <td>
                <b>{getResourceLabel(reference)}:</b>
            </td>
            <td>{loaded ? renderInfo() : <LinearProgress />}</td>
        </tr>
    );
};

export default ReferenceInfo;
