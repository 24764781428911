import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useResourceContext, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import DialogErrorContent from '@components/form/LockedFormSafeguard/DialogErrorContent';

import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import useCustomMercureSubscription from '@js/hooks/useCustomMercureSubscription';
import useSecondsTimer from '@components/form/LockedFormSafeguard/useSecondsTimer';
import { post } from '@js/request/apiRequest';
import { useParseHttpErrorAsString } from '@js/hooks/useNotifyHttpError';
import useGetIdentity from '@js/hooks/useGetIdentity';

import { Iri } from '@js/interfaces/ApiRecord';
import { RequestBody as TakeOverRequestBody } from './TakeOverEditing';

type Props = {
    id: Iri;
    resource?: string;
    onAccept?: () => void;
};

type TakeOverMessage = {
    action: TakeOverRequestBody['action'];
    sender: Iri;
    senderName: string;
};

type HandOverRequestBody = {
    action: 'accept' | 'reject';
    record: Iri;
};

const HandOverEditing = ({ id, onAccept, ...props }: Props) => {
    const [open, setOpen] = useState(false);
    const isSmallScreen = useIsSmallScreen();
    const [takeOverMessage, setTakeOverMessage] = useState<TakeOverMessage>();
    const { identity } = useGetIdentity();
    const subscription = useCustomMercureSubscription();
    const history = useHistory();
    const resource = useResourceContext(props);
    const parseError = useParseHttpErrorAsString();
    const [error, setError] = useState('');
    const translate = useTranslate();

    const handleClose = () => setOpen(false);
    const handleError = async (error: unknown) => setError(await parseError(error));
    const handleAccept = useMemo(() => onAccept ?? (() => history.push(`/${resource}`)), [history, onAccept, resource]);

    useEffect(() => {
        if (!identity?.id || !id) return;

        return subscription(`${identity.id}/take_over_editing?topic=${encodeURIComponent(id)}`, (message) => {
            const data = JSON.parse(message) as TakeOverMessage;

            // If take over was forced, redirect to list
            if (data.action === 'force') {
                handleAccept();
            } else {
                setTakeOverMessage(data);
                setOpen(true);
            }
        });
    }, [handleAccept, id, identity?.id, subscription]);

    return (
        <Dialog fullWidth fullScreen={isSmallScreen} maxWidth="md" open={open}>
            <DialogTitle>{translate('app.action.hand_over')}</DialogTitle>
            {error ? (
                <DialogErrorContent error={error} onClose={handleClose} />
            ) : (
                <DialogHandOverResponse
                    id={id}
                    onAccept={handleAccept}
                    onError={handleError}
                    onClose={handleClose}
                    takeOverMessage={takeOverMessage}
                />
            )}
        </Dialog>
    );
};

type DialogHandOverResponseProps = {
    onAccept: () => void;
    onClose: () => void;
    onError: (error: unknown) => void;
    takeOverMessage?: TakeOverMessage;
    id: Iri;
};

const DialogHandOverResponse = ({ onClose, onError, onAccept, takeOverMessage, id }: DialogHandOverResponseProps) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const { seconds, expired } = useSecondsTimer(60);

    const handOverRequest = (onSuccess: () => void, action: HandOverRequestBody['action']) => {
        if (!takeOverMessage) throw new Error('Take over message is required');

        setLoading(true);
        const body: HandOverRequestBody = { action, record: id };
        const receiver = takeOverMessage.sender;

        post(`${receiver}/hand_over_editing`, { body: JSON.stringify(body) })
            .then(onSuccess)
            .catch(onError);
    };

    const handleCancel = () => {
        handOverRequest(onClose, 'reject');
    };
    const handleAccept = () => {
        handOverRequest(onAccept, 'accept');
    };

    return (
        <>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {expired
                        ? translate('app.message.hand_over_editing_expired', {
                              username: takeOverMessage?.senderName,
                          })
                        : translate('app.message.hand_over_editing', {
                              username: takeOverMessage?.senderName,
                              time: seconds,
                          })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={expired ? onClose : handleCancel} color="primary" disabled={loading}>
                    {translate('ra.action.cancel')}
                </Button>
                <Button onClick={handleAccept} color="primary" variant="contained" disabled={loading || expired}>
                    {translate('app.action.hand_over')}
                </Button>
            </DialogActions>
        </>
    );
};

export default HandOverEditing;
