import { FC } from 'react';
import { FormTab, TabbedForm, TabbedFormProps, useGetResourceLabel } from 'react-admin';

import Toolbar from '@components/form/Toolbar';
import InputGuesser from '@components/form/InputGuesser';
import AddressDataGrid from './AddressDataGrid';

import useFormFields from '@js/hooks/useFormFields';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

const CustomerForm: FC<Omit<TabbedFormProps, 'children' | 'onSubmit'>> = (props) => {
    const fields = useFormFields(props);
    const getResourceLabel = useGetResourceLabel();
    const permissions = useUserPermissions();

    return (
        <TabbedForm {...props} toolbar={<Toolbar />}>
            <FormTab label="summary">
                {fields.map((field) => (
                    <InputGuesser key={field.name} source={field.name} field={field} />
                ))}
            </FormTab>
            {permissions.isResourceEnabled('addresses') && (
                <FormTab label={getResourceLabel('addresses')} path="addresses">
                    <AddressDataGrid />
                </FormTab>
            )}
        </TabbedForm>
    );
};

export default CustomerForm;
