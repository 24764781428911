import { Create, CreateProps } from 'react-admin';
import ReportForm from './ReportForm';

const ReportCreate = (props: CreateProps) => {
    return (
        <Create {...props}>
            <ReportForm />
        </Create>
    );
};

export default ReportCreate;
