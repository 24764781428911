import { FC, MouseEventHandler, useState } from 'react';
import { Button, Confirm } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';

interface Props {
    to: string;
    onCancel?: () => void;
    label?: string;
    requireConfirm?: boolean;
    state?: Record<string, unknown>;
}

const CancelButton: FC<Props> = ({ to, onCancel, requireConfirm, state, label = 'ra.action.cancel' }) => {
    const [open, setOpen] = useState(false);
    const { push } = useHistory();

    const doCancel = () => {
        if (onCancel) {
            onCancel();
        }

        push({
            pathname: to,
            state,
        });
    };

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        if (!requireConfirm) {
            doCancel();

            return;
        }

        setOpen(true);
    };

    const handleConfirm: MouseEventHandler = () => {
        setOpen(false);
        doCancel();
    };
    const handleClose: MouseEventHandler = () => {
        setOpen(false);
    };

    return (
        <>
            <Button label={label} color="default" size="medium" onClick={handleClick}>
                <CancelIcon />
            </Button>
            <Confirm
                isOpen={open}
                title="ra.action.cancel"
                content="ra.message.are_you_sure"
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default CancelButton;
