import { ReactNode } from 'react';
import { Tab, TabProps } from 'react-admin';

import FieldGuesser from '@components/field/FieldGuesser';

import useShowFields from '@js/hooks/useShowFields';
import { getOverrideFields } from '@js/utility/fieldsUtil';

interface Props extends Omit<TabProps, 'children'> {
    children?: ReactNode;
    hideFields?: string[];
}

const TabbedTabGuesser = ({ children, hideFields, ...props }: Props) => {
    const fields = useShowFields(props, hideFields);
    const overrideFields = getOverrideFields(children);

    return (
        <Tab {...props}>
            {fields.map((field) => {
                return (
                    overrideFields[field.name] ?? <FieldGuesser key={field.name} source={field.name} field={field} />
                );
            })}
        </Tab>
    );
};

export default TabbedTabGuesser;
