import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SettingsList from './SettingsList';
import SettingsEdit from './SettingsEdit';

export default {
    icon: SettingsApplicationsIcon,
    list: SettingsList,
    show: null,
    create: null,
    edit: SettingsEdit,
};
