import { Record as RaRecord, SimpleList, SimpleListProps } from 'react-admin';
import { get } from 'lodash';

import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import useResourceSchema from '@js/hooks/useResourceSchema';

const SimpleListGuesser = <RecordType extends RaRecord>(props: SimpleListProps<RecordType>) => {
    const { schemaAnalyzer } = useHydraSchemaContext();
    const schema = useResourceSchema(props);

    const primaryText = (record: RaRecord) => get(record, schemaAnalyzer.getFieldNameFromSchema(schema));
    const tertiaryText = ({ createdAt }: RaRecord) => createdAt && new Date(createdAt).toLocaleDateString();

    return (
        <SimpleList
            primaryText={props.primaryText || primaryText}
            tertiaryText={props.tertiaryText || tertiaryText}
            {...props}
        />
    );
};

export default SimpleListGuesser;
