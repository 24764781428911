import { FC } from 'react';
import { Box } from '@material-ui/core';
import { LogEntry } from '@js/interfaces/logentry';

import DataField from './field/DataField';

interface Props {
    record?: LogEntry;
}

const LogEntryExpandPanel: FC<Props> = (props) => {
    return (
        <Box display="flex" my={2}>
            <DataField {...props} />
        </Box>
    );
};

export default LogEntryExpandPanel;
