import { CreateButton, FilterButton, ListActionsProps, ListProps, SearchInput, TopToolbar } from 'react-admin';

import ListGuesser from '@components/list/ListGuesser';
import VisibleColumnsButton from '@components/list/VisibleColumnsButton';
import ExportButton from '@components/button/ExportButton';

const filters = [<SearchInput key="q" source="q" alwaysOn />];

const ListActions = (props: ListActionsProps) => {
    return (
        <TopToolbar>
            <VisibleColumnsButton {...props} />
            <FilterButton />
            {props.hasCreate && <CreateButton />}
            <ExportButton />
        </TopToolbar>
    );
};

const StoreList = (props: ListProps) => {
    return <ListGuesser {...props} filters={filters} actions={<ListActions />} />;
};

export default StoreList;
