import { useCallback, useMemo } from 'react';
import { Record as RaRecord, Validator } from 'react-admin';
import get from 'lodash/get';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Field } from '@api-platform/api-doc-parser';

import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import useInputReferenceField from '@js/hooks/useInputReferenceField';

type Props = {
    source: string;
    label?: string;
    multiple?: boolean;
    reference?: string;
    field?: Field;
    resource?: string;
    filter?: Record<string, any>;
    hasParent?: (record: RaRecord | null | undefined) => boolean;
    variant?: 'standard' | 'outlined' | 'filled';
    alwaysOn?: boolean;
    fullWidth?: boolean;
    validate?: Validator | Validator[];
};

const defaultHasParent: Props['hasParent'] = (record) => !!record?.['parent'];

const HierarchicalAutocompleteSelectInput = ({ hasParent = defaultHasParent, filter, ...props }: Props) => {
    const { fieldName } = useInputReferenceField(props);

    const inputText = useCallback((record?: RaRecord) => get(record, fieldName, ''), [fieldName]);
    const optionText = useMemo(
        () => <OptionText inputText={inputText} hasParent={hasParent} />,
        [inputText, hasParent],
    );

    return (
        <AutoCompleteGuesserInput
            {...props}
            filter={{
                ...filter,
                groupByParent: true,
            }}
            perPage={200}
            optionText={optionText}
            inputText={inputText}
        />
    );
};

const OptionText = ({
    inputText,
    record,
    filterValue,
    hasParent,
}: {
    inputText: (record?: RaRecord) => string;
    hasParent: (record?: RaRecord) => boolean;
    record?: RaRecord;
    filterValue?: string;
}) => {
    const text = inputText(record);

    const matches = match(text, filterValue ?? '');
    const parts = parse(text, matches);

    return (
        <div>
            {hasParent(record) && <span> -- </span>}
            {parts.map((part, index) => {
                return part.highlight ? <strong key={index}>{part.text}</strong> : <span key={index}>{part.text}</span>;
            })}
        </div>
    );
};

export default HierarchicalAutocompleteSelectInput;
