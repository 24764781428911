import { useEffect, useState, createContext, useContext, FC, ReactNode } from 'react';
import { useTranslate } from 'react-admin';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

export interface ZebraPrinterContextValue {
    print: ZebraBrowserPrintWrapper['print'];
    loaded: boolean;
    errors: string | null;
}

const ZebraPrinterContext = createContext<ZebraPrinterContextValue>(undefined!);

const initialState = {
    print: () => Promise.resolve(),
    loaded: false,
    errors: null,
};

export const ZebraPrinterContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<ZebraPrinterContextValue>(initialState);
    const translate = useTranslate();

    useEffect(() => {
        const init = async () => {
            try {
                // Create a new instance of the object
                const browserPrint = new ZebraBrowserPrintWrapper();
                // Select default printer
                const defaultPrinter = await browserPrint.getDefaultPrinter();

                // Set the printer
                browserPrint.setPrinter(defaultPrinter);

                // Check printer status
                const printerStatus = await browserPrint.checkPrinterStatus();

                // Check if the printer is ready
                if (printerStatus.isReadyToPrint) {
                    setState({
                        print: (text: string) => browserPrint.print(text),
                        loaded: true,
                        errors: null,
                    });
                } else {
                    setState({
                        ...initialState,
                        errors: printerStatus.errors,
                        loaded: true,
                    });
                }
            } catch (error) {
                if (error instanceof Error && error.message !== 'TypeError: Failed to fetch') {
                    console.error(error);
                }

                setState({
                    ...initialState,
                    errors: translate('app.error.printer_detect_error'),
                    loaded: true,
                });
            }
        };

        init();
    }, [translate]);

    return <ZebraPrinterContext.Provider value={state}>{children}</ZebraPrinterContext.Provider>;
};

export const useZebraPrinterContext = () => {
    return useContext(ZebraPrinterContext);
};
