import PriceCurrencySpyInput from './PriceCurrencySpyInput';
import { useField } from 'react-final-form';

interface Props {
    source: string;
    resource?: string;
    fullWidth?: boolean;
}

const ShippingCostInput = (props: Props) => {
    const { input } = useField('shippingPaid', { subscription: { value: true } });

    return <PriceCurrencySpyInput {...props} disabled={!!input.value} />;
};

export default ShippingCostInput;
