import { AuthProvider } from 'react-admin';
import { UserIdentity } from '@js/auth/types';
import UserPermissionsService from '@js/auth/UserPermissionsService';
import { LoginFormData } from '@components/routes/LoginPage';

let CURRENT_USER: UserIdentity | null = null;
let PERMISSIONS: UserPermissionsService | null = null;
let LOGGING_OUT: Promise<void> | null = null;

export const getOrFetchCurrentUser = async () => {
    if (CURRENT_USER) {
        return CURRENT_USER;
    }

    const response = await fetch('/api/users/current_user', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        return null;
    }

    CURRENT_USER = await response.json();

    return CURRENT_USER;
};

export const isUserLoggedIn = () => CURRENT_USER !== null;

const resetCurrentUser = () => {
    CURRENT_USER = null;
    PERMISSIONS = null;
};

const authProvider: AuthProvider = {
    login: async (loginData: LoginFormData) => {
        resetCurrentUser();

        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
            body: JSON.stringify(loginData),
        });

        if (response.status === 401) {
            return Promise.reject((await response.json()).error);
        }
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        CURRENT_USER = await response.json();

        return Promise.resolve();
    },

    logout: () => {
        if (!CURRENT_USER) {
            return Promise.resolve();
        }

        if (!LOGGING_OUT) {
            LOGGING_OUT = fetch('/api/logout', {
                credentials: 'same-origin',
                redirect: 'manual',
            }).then(() => {
                resetCurrentUser();
                LOGGING_OUT = null;
            });
        }

        return LOGGING_OUT;
    },

    checkAuth: () => {
        return CURRENT_USER ? Promise.resolve() : Promise.reject();
    },

    checkError: (error) => {
        const status = error?.status || error?.response?.status;

        if (status === 401) {
            resetCurrentUser();

            return Promise.reject();
        }

        // other error code (404, 500, etc.): no need to log out
        return Promise.resolve();
    },

    getIdentity: async () => {
        return CURRENT_USER || { id: 0 };
    },

    getPermissions: async (): Promise<UserPermissionsService | undefined> => {
        if (!CURRENT_USER) {
            return undefined;
        }
        return (PERMISSIONS ??= new UserPermissionsService(CURRENT_USER));
    },
};

export default authProvider;
