/* eslint-disable react/jsx-key */
import {
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceInput,
    SearchInput,
    SelectArrayInput,
    SelectInput,
} from 'react-admin';

import QrCodeScannerFilter from '@components/filter/QrCodeScannerFilter';
import BarcodeScannerFilter from '@components/filter/BarcodeScannerFilter';
import DateRangeFilter from '@components/filter/DateRangeFilter';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import BooleanFilterInput from '@components/input/BooleanFilterInput';

export default [
    <SearchInput source="q" alwaysOn autoComplete="off" />,
    <QrCodeScannerFilter source="qrcode" alwaysOn />,
    <BarcodeScannerFilter source="barcode" alwaysOn />,
    <ReferenceRecursiveSelectInput source="status" alwaysOn />,
    <BooleanFilterInput source="exists[status]" alwaysOn inverse />,
    <DateRangeFilter source="createdAt" />,
    <ReferenceInput reference="models" source="model" sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput optionText="name" />
    </ReferenceInput>,
    <ReferenceArrayInput reference="types" source="type" sort={{ field: 'name', order: 'ASC' }}>
        <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>,
    <ReferenceInput
        reference="resellers"
        source="resellers"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ 'exists[overrideReseller]': false }}
        allowEmpty
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
];
