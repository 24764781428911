import { useResourceContext, useTranslate } from 'react-admin';

const useInputInfoHelperText = ({
    source,
    helperText: helperTextProp,
    ...props
}: {
    source: string;
    resource?: string;
    helperText?: string | false;
}) => {
    const resource = useResourceContext(props);
    const translate = useTranslate();

    return translate(`resources.${resource}.fields_info.${source}`, { _: '' }) || helperTextProp;
};

export default useInputInfoHelperText;
