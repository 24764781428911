import { cloneElement, forwardRef, ReactNode } from 'react';
import { TableBody } from '@material-ui/core';
import { DatagridBodyProps, DatagridRow } from 'react-admin';
import classnames from 'classnames';

interface Props extends DatagridBodyProps {
    rows: ReactNode;
}

const DatagridBodyAdditionalRows = forwardRef<HTMLTableSectionElement, Props>(
    (
        {
            basePath,
            children,
            classes,
            className,
            data,
            expand,
            hasBulkActions,
            hover,
            ids,
            onToggleItem,
            row,
            rowClick,
            rowStyle,
            selectedIds,
            isRowSelectable,
            resource,
            rows,
            ...rest
        },
        ref,
    ) => {
        if (!ids || !data || !row) return null;

        return (
            <TableBody ref={ref} className={classnames('datagrid-body', className)} {...rest}>
                {ids?.map((id, rowIndex) =>
                    cloneElement(
                        row,
                        {
                            basePath,
                            classes,
                            className: classes
                                ? classnames(classes.row, {
                                      [classes.rowEven]: rowIndex % 2 === 0,
                                      [classes.rowOdd]: rowIndex % 2 !== 0,
                                      [classes.clickableRow]: rowClick,
                                  })
                                : undefined,
                            expand,
                            hasBulkActions: hasBulkActions && !!selectedIds,
                            hover,
                            id,
                            key: id,
                            onToggleItem,
                            record: data?.[id],
                            resource,
                            rowClick,
                            selectable: !isRowSelectable || isRowSelectable(data[id]),
                            selected: selectedIds?.includes(id),
                            style: rowStyle && data ? rowStyle(data[id], rowIndex) : null,
                        },
                        children,
                    ),
                )}
                {rows}
            </TableBody>
        );
    },
);

DatagridBodyAdditionalRows.displayName = 'DatagridBodyAdditionalRows';

DatagridBodyAdditionalRows.defaultProps = {
    data: {},
    hasBulkActions: false,
    ids: [],
    row: <DatagridRow />,
};

export default DatagridBodyAdditionalRows;
