import { useEffect, useState } from 'react';
import { Button, CardContentInner } from 'react-admin';
import { Box, LinearProgress, Toolbar as MuiToolbar } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ContentSave from '@material-ui/icons/Save';

import LoadingButton from '@components/button/LoadingButton';
import ImportErrors from '@components/routes/ImportErrors';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { ImportState, stateToFormData } from './ErrandImport';

type Props = {
    onGoBack: () => void;
    onSubmit: () => void;
    state: ImportState;
};

type ValidationError = {
    message: string;
    severity: 'error' | 'warning';
};

type ValidationErrors = {
    errors: {
        errand?: ValidationError;
        reclamations?: Array<ValidationError>;
    };
};

const ConfirmationForm = ({ onGoBack, onSubmit, state }: Props) => {
    const [errors, setErrors] = useState<ValidationErrors['errors']>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notifyFailure = useNotifyHttpError();

    useEffect(() => {
        if (errors) return;

        const formData = stateToFormData(state);

        post<ValidationErrors>('/api/errands/validate', {
            body: formData,
        })
            .then((response) => {
                setErrors(response.errors);
            })
            .catch((error) => {
                notifyFailure(error);
            });
    }, [errors, notifyFailure, state]);

    const isLoading = errors === undefined;
    const errandHasError = !!errors?.errand;
    const finalErrors = [...(errors?.errand ? [errors.errand] : []), ...(errors?.reclamations || [])];

    const handleConfirm = () => {
        setIsSubmitting(true);
        onSubmit();
    };

    return (
        <>
            <CardContentInner>
                {isLoading ? (
                    <Box my={2}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <ImportErrors errors={finalErrors} />
                )}
            </CardContentInner>
            <MuiToolbar>
                <Box display="flex" gridGap={10}>
                    <Button size="medium" color="default" label="ra.action.cancel" onClick={onGoBack}>
                        <CancelIcon />
                    </Button>
                    <LoadingButton
                        icon={<ContentSave />}
                        onClick={handleConfirm}
                        loading={isSubmitting}
                        disabled={isLoading || errandHasError}
                        label="app.action.import"
                        size="medium"
                    />
                </Box>
            </MuiToolbar>
        </>
    );
};

export default ConfirmationForm;
