import { FC } from 'react';
import { LinearProgress, Record, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import useIdsBatchRequest from '@js/hooks/useIdsBatchRequest';
import { useFormatCentsPrice } from '@js/hooks/useFormatPrice';

type Props = {
    source?: string;
    addLabel?: boolean;
    sortable?: boolean;
    record?: Record;
};

const TotalSpendCostField: FC<Props> = (props) => {
    const record = useRecordContext(props);
    const {
        loading,
        data: total,
        error,
    } = useIdsBatchRequest<number>('/api/reclamations/total_spend_cost', record?.id);
    const formatPrice = useFormatCentsPrice();

    if (!record) return null;

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <ErrorIcon role="presentation" color="error" fontSize="small" />;
    }

    return (
        <Typography component="span" variant="body2">
            {formatPrice(total!)}
        </Typography>
    );
};

TotalSpendCostField.defaultProps = {
    source: 'totalSpendCost',
    addLabel: true,
    sortable: false,
};

export default TotalSpendCostField;
