import { ComponentType, createElement, FC, useCallback } from 'react';
import { MenuItemLink as RaMenuItemLink, useGetResourceLabel, useNotify } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';

import useFetchGet from '@js/hooks/useFetchGet';
import useOnNewNotificationSubscription from '@components/resource/user_notifications/useOnNewNotificationSubscription';
import useGetIdentity from '@js/hooks/useGetIdentity';

interface MenuItemProps {
    resource: string;
    label: string;
    icon?: ComponentType;
}

const MenuItem: FC<{ resource: { name: string; icon?: ComponentType } }> = ({ resource: { name, icon } }) => {
    const getResourceLabel = useGetResourceLabel();
    const commonProps = {
        label: getResourceLabel(name),
        resource: name,
        icon,
    };

    switch (name) {
        case 'user_notifications':
            return <UserNotificationsMenuItem {...commonProps} />;
        default:
            return <MenuItemLink {...commonProps} />;
    }
};

const MenuItemLink: FC<MenuItemProps> = ({ label, resource, icon }) => {
    return <RaMenuItemLink to={`/${resource}`} primaryText={label} leftIcon={createElement(icon ?? DefaultIcon)} />;
};

const UserNotificationsMenuItem: FC<MenuItemProps> = ({ label, ...props }) => {
    const { identity } = useGetIdentity();
    const { data, refresh } = useFetchGet<{ count: number }>(`${identity?.id}/count_unread_notifications`, undefined, {
        enabled: !!identity,
    });
    const notify = useNotify();

    const onNewNotification = useCallback(() => {
        refresh();
        notify('app.message.received_new_message');
    }, [refresh, notify]);

    useOnNewNotificationSubscription(onNewNotification);

    return <MenuItemLink {...props} label={`${label} (${data?.count ?? '--'})`} />;
};

export default MenuItem;
