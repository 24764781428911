import ErrandList from './ErrandList';
import ErrandCreate from './ErrandCreate';
import ErrandEdit from './ErrandEdit';
import ErrandShow from './ErrandShow';

export default {
    list: ErrandList,
    show: ErrandShow,
    create: ErrandCreate,
    edit: ErrandEdit,
    options: {
        alwaysHideFields: ['draft'],
    },
};
