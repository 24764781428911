import { ComponentType, createElement, FC, MouseEventHandler, ReactNode } from 'react';
import { Collapse, List, ListItemIcon, MenuItem, Theme, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles<Theme, Pick<Props, 'sidebarIsOpen'>>((theme) => ({
    icon: { minWidth: theme.spacing(5) },
    sidebar: ({ sidebarIsOpen }) => ({
        '& a': {
            paddingLeft: theme.spacing(sidebarIsOpen ? 4 : 2),
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    }),
}));

interface Props {
    onToggleExpand: MouseEventHandler<HTMLElement>;
    sidebarIsOpen: boolean;
    isExpanded: boolean;
    label: string;
    icon: ComponentType;
    children: ReactNode;
}

const SubMenu: FC<Props> = ({ onToggleExpand, sidebarIsOpen, isExpanded, label, icon, children }) => {
    const classes = useStyles({ sidebarIsOpen });

    const header = (
        <MenuItem button onClick={onToggleExpand}>
            <ListItemIcon className={classes.icon}>{createElement(isExpanded ? ExpandMore : icon)}</ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {label}
            </Typography>
        </MenuItem>
    );

    return (
        <>
            {sidebarIsOpen ? (
                header
            ) : (
                <Tooltip title={label} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.sidebar}>
                    {children}
                </List>
            </Collapse>
        </>
    );
};

export default SubMenu;
