import DescriptionIcon from '@material-ui/icons/Description';

import LogEntryList from './LogEntryList';

export default {
    list: LogEntryList,
    show: null,
    create: null,
    edit: null,
    icon: DescriptionIcon,
};
