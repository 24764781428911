import { FileField, FileInput, required, SimpleForm, SimpleFormProps, useGetResourceLabel } from 'react-admin';

import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

const ReportForm = (props: Omit<SimpleFormProps, 'children'>) => {
    const getResourceLabel = useGetResourceLabel();

    return (
        <SimpleForm {...props}>
            <FileInput source="file" validate={required()} accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.pdf">
                <FileField source="src" title="title" />
            </FileInput>
            <HierarchicalAutocompleteSelectInput
                label={getResourceLabel('stores')}
                source="stores"
                reference="stores"
                multiple
            />
        </SimpleForm>
    );
};

export default ReportForm;
