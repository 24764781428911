import DescriptionIcon from '@material-ui/icons/Description';

import ReportList from './ReportList';
import ReportCreate from './ReportCreate';

export default {
    icon: DescriptionIcon,
    list: ReportList,
    create: ReportCreate,
    edit: null,
    show: null,
};
