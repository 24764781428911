import { useCallback } from 'react';
import { useSetLocale } from 'react-admin';
import { saveLocale } from '@js/locale/i18nProvider';

const useChangeLocale = () => {
    const setLocale = useSetLocale();

    return useCallback(
        (locale: string) => {
            setLocale(locale); // Change app state
            saveLocale(locale); // Save for reload
        },
        [setLocale],
    );
};

export default useChangeLocale;
