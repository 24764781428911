import { FC } from 'react';
import { minLength, PasswordInput, required, Validator } from 'react-admin';

import repeat from '../../validator/repeat';

const inputProps = { autoComplete: 'new-password', 'data-lpignore': true };

interface Props {
    className?: string;
    required?: boolean;
    source: string;
}

const PasswordAndRepeatPasswordInput: FC<Props> = ({ source, required: isRequired, className }) => {
    const isValidator = (validator: Validator | boolean | undefined): validator is Validator => !!validator;

    return (
        <>
            <PasswordInput
                source={source}
                validate={[minLength(6) as Validator, isRequired && required()].filter(isValidator)}
                style={{ marginRight: '1rem' }}
                className={className}
                inputProps={inputProps}
            />
            <PasswordInput
                source={`${source}Repeat`}
                validate={[repeat(source), isRequired && required()].filter(isValidator)}
                className={className}
                inputProps={inputProps}
            />
        </>
    );
};

export default PasswordAndRepeatPasswordInput;
