import LanguageIcon from '@material-ui/icons/Language';

import LocalizationLabelList from './LocalizationLabelList';

export default {
    list: LocalizationLabelList,
    show: null,
    create: null,
    edit: null,
    icon: LanguageIcon,
};
