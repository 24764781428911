import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import { RegisterResource } from '@components/ResourceGuesser';
import { Product } from '@js/interfaces/product';

import ProductList from './ProductList';
import ProductCreate from './ProductCreate';
import ProductEdit from './ProductEdit';
import ProductShow from './ProductShow';

export default {
    icon: FormatLineSpacingIcon,
    list: ProductList,
    show: ProductShow,
    create: ProductCreate,
    edit: ProductEdit,
    options: {
        alwaysHideFields: [
            'bundleItems',
            'syncedWithSello',
            'deleteDisableReason',
            'label',
            'sellOnPhysicalStore',
            'splitType',
            'selloProductId',
        ],
    },
} satisfies RegisterResource<Product>;
