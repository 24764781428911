import { Field as ApiField, Resource } from '@api-platform/api-doc-parser';

import { usePossibleNotDefinedResourceSchema } from '@js/hooks/useResourceSchema';
import useResourceFieldName from '@js/hooks/useResourceFieldName';

interface Field extends ApiField {
    reference: Resource;
}

function isApiFieldReferenceField(field: ApiField | undefined): field is Field {
    return typeof field?.reference === 'object' && field.reference !== null;
}

const useInputReferenceField = ({
    source,
    field: fieldOverride,
    reference: referenceOverride,
    ...props
}: {
    source: string;
    resource?: string;
    reference?: string;
    multiple?: boolean;
    field?: ApiField;
}) => {
    // If we are inside form of resource context, we will get a schema for that resource
    const schema = usePossibleNotDefinedResourceSchema(props);

    // Use field passed as prop or find it in schema by source name,
    // it'll not found anything if we are not inside resource context
    const field = fieldOverride ?? schema?.fields?.find((field) => field.name === source);

    /**
     * We either use the reference passed as a prop,
     * or we use the reference from the field passed from guesser,
     * or we find field by source and resource and use its reference.
     */
    let reference = referenceOverride;
    if (!reference) {
        if (!isApiFieldReferenceField(field)) {
            throw new Error(
                `Could not get reference for field "${source}", either provide reference as prop or use inside resource context or pass field reference as prop`,
            );
        }

        reference = field.reference.name;
    }

    const fieldName = useResourceFieldName(reference);

    // Field will be defined is used inside resource context or passed as prop
    return {
        reference,
        fieldName,
        multiple: props.multiple ?? (isApiFieldReferenceField(field) && field.maxCardinality !== 1),
    };
};

export default useInputReferenceField;
