import { Redirect, Route, Switch } from 'react-router-dom';

import SavingsDetailPage from './SavingsDetailPage';
import EconomicalDetailedPage from './EconomicalDetailed';
import UsersDetailed from './UsersDetailed';

import { useUserPermissions } from '@js/context/UserPermissionsContext';

const Router = () => {
    const permissions = useUserPermissions();
    if (!permissions.dashboard.enabled) return <Redirect to="/errands" />;

    return (
        <Switch>
            <Route
                path="/dashboard/savings"
                render={() => {
                    if (!permissions.dashboard.isSectionEnabled('savings_detailed')) return <Redirect to="/" />;
                    return <SavingsDetailPage />;
                }}
            />
            <Route
                path="/dashboard/economical_detailed"
                render={() => {
                    if (!permissions.dashboard.isSectionEnabled('economical_detailed')) return <Redirect to="/" />;
                    return <EconomicalDetailedPage />;
                }}
            />
            <Route
                path="/dashboard/users_detailed"
                render={() => {
                    if (!permissions.dashboard.isSectionEnabled('users_detailed')) return <Redirect to="/" />;
                    return <UsersDetailed />;
                }}
            />
        </Switch>
    );
};

export default Router;
