/* eslint-disable react/jsx-key */
import { FC, useMemo } from 'react';
import { ListProps, SearchInput, TextInput, useRecordContext, useTranslate } from 'react-admin';

import ListGuesser, { DatagridGuesser } from '@components/list/ListGuesser';
import DatagridBodyWithMercure from '@components/list/DatagridBodyWithMercure';
import TranslatableTextField from '@components/field/TranslatableTextField';
import RowActions from '@components/list/RowActions';
import BooleanFilterInput from '@components/input/BooleanFilterInput';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useResourceFieldName from '@js/hooks/useResourceFieldName';
import { Category } from '@js/interfaces/category';

const CategoryList: FC<ListProps> = (props) => {
    const getFieldLabel = useTranslateResourceField(props.resource);
    const translate = useTranslate();
    const fieldName = useResourceFieldName(props);

    const filters = useMemo(
        () => [
            <SearchInput source={fieldName} alwaysOn />,
            <BooleanFilterInput source="entrypoint" alwaysOn />,
            <TextInput
                source="q"
                label={translate('app.label.search_in_translations')}
                style={{ minWidth: '256px' }}
            />,
        ],
        [fieldName, translate],
    );

    return (
        <ListGuesser {...props} filters={filters}>
            <DatagridGuesser body={DatagridBodyWithMercure} rowActions={<ConditionalRowActions />}>
                <TranslatableTextField source="translatableTitle" label={getFieldLabel('title')} sortable={false} />
            </DatagridGuesser>
        </ListGuesser>
    );
};

const ConditionalRowActions = (props: { record?: Category }) => {
    const record = useRecordContext(props);

    if (!record || !record.entrypoint) {
        return null;
    }

    return <RowActions {...props} />;
};

export default CategoryList;
