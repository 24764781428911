import { FC } from 'react';
import { DashboardMenuItem, getResources, useTranslate, MenuProps, ReduxState, ResourceDefinition } from 'react-admin';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import SubMenu from './SubMenu';
import MenuItem from './MenuItem';
import MenuLogo from './MenuLogo';

import useExpandedState from './useExpandedState';
import useIsSmallScreen from '@js/hooks/useIsSmallScreen';
import menu, { isMenuItemGroup, MenuItem as MenuItemType } from '@js/menu/menuBuilder';
import { useUserPermissions } from '@js/context/UserPermissionsContext';

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles<Theme, { isSidebarOpen: boolean }>(
    (theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '0.5em',
            [theme.breakpoints.only('xs')]: {
                marginTop: 0,
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '1.5em',
            },
            width: ({ isSidebarOpen }) => (isSidebarOpen ? MENU_WIDTH : CLOSED_MENU_WIDTH),
        },
    }),
    { name: 'Menu' },
);

const Menu: FC<MenuProps> = ({ hasDashboard, logout }) => {
    const [expanded, toggleExpanded] = useExpandedState(menu);
    const resources = useSelector<ReduxState, ResourceDefinition[]>(getResources);
    const isSidebarOpen = useSelector<ReduxState, boolean>((state) => state.admin.ui.sidebarOpen);
    const isSmall = useIsSmallScreen();
    const translate = useTranslate();
    const permissions = useUserPermissions();
    const classes = useStyles({ isSidebarOpen });
    // Used to force redraw on navigation
    useSelector<ReduxState>((state) => state.router.location.pathname);

    const mappedResources = keyBy(resources, 'name');

    // MenuItem is visible if this is resource item and corresponding resource "hasList".
    // If this is a group of items, check if at least one resource from group is visible
    const isMenuItemVisible = (menuItem: MenuItemType) => {
        if (!isMenuItemGroup(menuItem)) {
            return !!mappedResources[menuItem]?.hasList;
        }

        return menuItem.items.some((resource) => mappedResources[resource]?.hasList);
    };

    const handleToggleExpand = (group: string) => () => {
        toggleExpanded(group);
    };

    return (
        <div className={classes.main}>
            <MenuLogo sidebarOpen={isSidebarOpen} />
            {hasDashboard && permissions.dashboard.enabled && <DashboardMenuItem />}
            {menu.filter(isMenuItemVisible).map((menuItem) => {
                if (isMenuItemGroup(menuItem)) {
                    const { name, icon, items } = menuItem;

                    return (
                        <SubMenu
                            key={name}
                            onToggleExpand={handleToggleExpand(name)}
                            sidebarIsOpen={isSidebarOpen}
                            label={translate(`app.menu.${name}`)}
                            isExpanded={expanded[name]}
                            icon={icon}
                        >
                            {items
                                .filter((item) => mappedResources[item]?.hasList)
                                .map((item) => (
                                    <MenuItem key={item} resource={mappedResources[item]} />
                                ))}
                        </SubMenu>
                    );
                }

                return <MenuItem key={menuItem} resource={mappedResources[menuItem]} />;
            })}
            {isSmall && logout}
        </div>
    );
};

export default Menu;
