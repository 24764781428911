import { useState } from 'react';
import { Record as RaRecord, useRecordContext } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

import IconButton from '@components/button/IconButton';

import { post } from '@js/request/apiRequest';
import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import { useZebraPrinterContext } from '@js/context/ZebraPrinterContext';

type Props = {
    record?: RaRecord;
};

const PrintItemLabelButton = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const record = useRecordContext(props);
    const notifyError = useNotifyHttpError();
    const { loaded: printerLoaded, errors: printErrors } = useZebraPrinterContext();

    if (!record) return null;

    const handleClick = async () => {
        setLoading(true);

        try {
            await post(`${record.id}/print_label`, {
                body: JSON.stringify({}),
            });
        } catch (error) {
            notifyError(error);
        }

        setLoading(false);
    };

    return (
        <IconButton
            label="app.action.print_label"
            onClick={handleClick}
            disabled={loading || !printerLoaded || !!printErrors}
            disabledReason={printErrors}
        >
            {loading ? <CircularProgress size={20} thickness={2} /> : <PrintIcon />}
        </IconButton>
    );
};

export default PrintItemLabelButton;
