import { FC, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { Form, FormProps, useFormState } from 'react-final-form';
import { Button, Confirm, Identifier, RecordContextProvider, ResourceContext, useNotify } from 'react-admin';
import arrayMutators from 'final-form-arrays';
import { Box } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import LoadingButton from '@components/button/LoadingButton';

import { Reclamation } from '@js/interfaces/reclamation';
import { ReclamationFormCommonProps } from './ReclamationsEditTab';

export type FormControllerProps = {
    onSubmit: FormProps<Reclamation>['onSubmit'];
    onCancel: () => void;
    initialValues?: Partial<Reclamation>;
    id?: Identifier;
    validate?: FormProps<Reclamation>['validate'];
    disablePristine?: boolean;
} & Pick<ReclamationFormCommonProps, 'registerForm' | 'unregisterForm'>;

const CancelButton: FC<{ pristine: boolean; onCancel: () => void }> = ({ pristine, onCancel }) => {
    const [open, setOpen] = useState(false);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        if (pristine) {
            onCancel();
            return;
        }

        setOpen(true);
    };

    return (
        <>
            <Button label="ra.action.cancel" color="default" size="medium" onClick={handleClick}>
                <CancelIcon />
            </Button>
            <Confirm
                isOpen={open}
                title="ra.action.cancel"
                content="ra.message.are_you_sure"
                onConfirm={onCancel}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const RegisterForm: FC<Pick<FormControllerProps, 'id' | 'registerForm' | 'unregisterForm'>> = ({
    registerForm,
    unregisterForm,
    id = 'NEW_RECLAMATION',
}) => {
    const { pristine } = useFormState({ subscription: { pristine: true } });

    useEffect(() => {
        if (!id) {
            throw new Error('"id" is required in order to register a form');
        }
        if (pristine) {
            return;
        }

        registerForm(id);

        return () => {
            unregisterForm(id);
        };
    }, [pristine, registerForm, unregisterForm, id]);

    return null;
};

const FormController = ({
    onSubmit,
    onCancel,
    initialValues,
    children,
    id,
    registerForm,
    unregisterForm,
    validate,
    disablePristine,
}: FormControllerProps & { children: ReactNode }) => {
    const notify = useNotify();

    return (
        <ResourceContext.Provider value="reclamations">
            <RecordContextProvider value={initialValues}>
                <Form<Reclamation>
                    onSubmit={(values, form, callback) => {
                        onSubmit(sanitizeEmptyValues(initialValues, values), form, callback);
                    }}
                    initialValues={initialValues}
                    mutators={{ ...arrayMutators }}
                    validate={validate}
                    render={({ handleSubmit, pristine, submitting, errors }) => {
                        return (
                            <>
                                {children}
                                <Box display="flex" mb={2} gridGap={5}>
                                    <LoadingButton
                                        onClick={() => {
                                            if (errors && Object.keys(errors).length > 0) {
                                                notify('ra.message.invalid_form', { type: 'warning' });
                                            }

                                            handleSubmit();
                                        }}
                                        loading={submitting}
                                        disabled={pristine && !disablePristine}
                                        label="ra.action.save"
                                        icon={<SaveIcon />}
                                    />
                                    <CancelButton onCancel={onCancel} pristine={pristine} />
                                </Box>
                                <RegisterForm id={id} registerForm={registerForm} unregisterForm={unregisterForm} />
                            </>
                        );
                    }}
                />
            </RecordContextProvider>
        </ResourceContext.Provider>
    );
};

const sanitizeEmptyValues = (initialValues: Partial<Reclamation> | undefined, values: Reclamation) => {
    if (!initialValues) return values;

    const initialValuesWithEmptyFields = (Object.keys(initialValues) as Array<keyof Reclamation>).reduce<
        Partial<Reclamation>
    >((acc, key) => {
        if (
            key === 'composedIdentifier' ||
            key === 'id' ||
            key === 'createdAt' ||
            key === 'updatedAt' ||
            key === 'label'
        ) {
            return acc;
        }

        if (typeof initialValues[key] === 'string' && typeof values[key] === 'undefined') {
            acc[key] = null;
        }

        return acc;
    }, {});

    return {
        ...values,
        ...initialValuesWithEmptyFields,
    };
};

export default FormController;
