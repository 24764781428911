import { FC } from 'react';
import { LinearProgress, useGetResourceLabel, useRecordContext, useReference } from 'react-admin';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

import useResourceFieldName from '@js/hooks/useResourceFieldName';
import { LogEntry } from '@js/interfaces/logentry';

interface Props {
    source?: string;
    record?: LogEntry;
    addLabel?: boolean;
}

const ResourceField: FC<Props> = (props) => {
    const logEntry = useRecordContext(props);

    if (!logEntry) return null;

    return <ObjectRepresentation logEntry={logEntry} />;
};

const ObjectRepresentation = ({ logEntry }: { logEntry: LogEntry }) => {
    const { referenceRecord, loaded } = useReference({ reference: logEntry.resource, id: logEntry.resourceIri });
    const getResourceLabel = useGetResourceLabel();
    const fieldName = useResourceFieldName(logEntry.resource);

    if (!loaded) {
        return <LinearProgress />;
    }

    let referenceLabel = '';

    if (fieldName !== 'id' && referenceRecord) {
        referenceLabel = `- ${get(referenceRecord, fieldName) || '[No label]'}`;
    }

    return (
        <Typography component="span" variant="body2">
            {`${getResourceLabel(logEntry.resource, 1)} ${referenceLabel} (${logEntry.objectId})`}
        </Typography>
    );
};

ResourceField.defaultProps = {
    addLabel: true,
    source: 'resource',
};

export default ResourceField;
