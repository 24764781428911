import { useState, MouseEvent, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import BarcodeScannerInput from '@components/input/BarcodeScannerInput';
import IceCatDialogIconButton, { IceCatDialogIconButtonHandle } from '../button/IceCatDialogIconButton';
import SuggestCloneIconButton, { SuggestCloneIconButtonHandle } from '../button/SuggestCloneIconButton';

import { useIsFeatureDisabled } from '@js/context/AppConfigContext';

type Props = {
    source: string;
    fullWidth?: boolean;
    resource?: string;
};

const EanInput = (props: Props) => {
    const isFeatureDisabled = useIsFeatureDisabled();

    return (
        <Box display="flex">
            <BarcodeScannerInput {...props} />
            <Box pt={1}>
                {isFeatureDisabled('Icecat') ? <SuggestCloneIconButton suggestSource="ean" /> : <MenuItems />}
            </Box>
        </Box>
    );
};

const MenuItems = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const translate = useTranslate();
    const iceCatButtonRef = useRef<IceCatDialogIconButtonHandle>(null);
    const suggestCloneButtonRef = useRef<SuggestCloneIconButtonHandle>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} keepMounted>
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        if (anchorEl) iceCatButtonRef.current?.open();
                    }}
                >
                    <IceCatDialogIconButton gtinSource="ean" ref={iceCatButtonRef} />
                    {translate('app.action.icecat')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        if (anchorEl) suggestCloneButtonRef.current?.open();
                    }}
                >
                    <SuggestCloneIconButton suggestSource="ean" ref={suggestCloneButtonRef} />
                    {translate('app.action.copy')}
                </MenuItem>
            </Menu>
        </>
    );
};
export default EanInput;
