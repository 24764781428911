import { FC } from 'react';
import {
    ChipField,
    Datagrid,
    DateField,
    FunctionField,
    Identifier,
    LinearProgress,
    ReferenceManyField,
    ReferenceManyFieldProps,
    useGetOne,
    useGetResourceLabel,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { AutoProcessingTime } from '@js/interfaces/autoprocessingtime';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { get } from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';

import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import ReferenceField from '@components/field/ReferenceField';

import useResourceSchema from '@js/hooks/useResourceSchema';
import { useHydraSchemaContext } from '@js/context/HydraSchemaContext';
import { secondsToHumanTimeWithSeconds } from '@js/utility/numberFormatUtility';

import { Errand } from '@js/interfaces/errand';
import useIdsBatchRequest from '@js/hooks/useIdsBatchRequest';

const ReclamationReference: FC<{ id: Identifier }> = ({ id }) => {
    const { loading, data: reclamation } = useGetOne('reclamations', id);
    const { schemaAnalyzer } = useHydraSchemaContext();
    const schema = useResourceSchema({ resource: 'reclamations' });

    if (loading) {
        return <LinearProgress />;
    }

    const fieldName = schemaAnalyzer.getFieldNameFromSchema(schema);

    return <span>{get(reclamation, fieldName)}</span>;
};

const ReclamationOrErrandReference: FC<{ record?: AutoProcessingTime; label: string }> = (props) => {
    const record = useRecordContext(props);
    const getResourceLabel = useGetResourceLabel();

    if (record.reclamation) {
        return <ReclamationReference id={record.reclamation} />;
    }

    return <span>{getResourceLabel('errands', 1)}</span>;
};

const AutoTrackingTimeShowTab: FC<
    Omit<ReferenceManyFieldProps, 'record' | 'reference' | 'target' | 'children' | 'filter'> & { record?: Errand }
> = (props) => {
    const translate = useTranslate();
    const errand = useRecordContext<Errand>(props);
    const {
        loading,
        data: total,
        error,
    } = useIdsBatchRequest<number>('/api/errands/auto_processing_time', errand?.id, {
        enabled: !!errand,
    });
    const getResourceLabel = useGetResourceLabel();

    return (
        <Box mt={1} mb={2}>
            <Box my={2} ml={1}>
                <Typography variant="subtitle2">
                    <b>{translate('app.label.total')}</b>:{' '}
                    {loading ? (
                        <CircularProgress color="secondary" size={15} />
                    ) : error ? (
                        <ErrorIcon />
                    ) : (
                        secondsToHumanTimeWithSeconds(total!)
                    )}
                </Typography>
            </Box>
            <ReferenceManyField
                {...props}
                reference="auto_processing_times"
                target="errand"
                sort={{ field: 'createdAt', order: 'DESC' }}
                pagination={<AutoHidePagination />}
            >
                <Datagrid empty={<AlertEmptyResource />}>
                    <DateField source="createdAt" showTime sortable={false} />
                    <ReferenceField source="createdBy" reference="users" sortable={false}>
                        <ChipField source="fullName" />
                    </ReferenceField>
                    <ReclamationOrErrandReference
                        label={`${getResourceLabel('errands', 1)} & ${getResourceLabel('reclamations', 1)}`}
                    />
                    <FunctionField<AutoProcessingTime>
                        source="time"
                        render={(record) => secondsToHumanTimeWithSeconds(record?.time ?? 0)}
                        sortable={false}
                    />
                    <FunctionField<AutoProcessingTime>
                        source="type"
                        render={(record) => record && translate(`resources.auto_processing_times.type.${record.type}`)}
                        sortable={false}
                    />
                </Datagrid>
            </ReferenceManyField>
        </Box>
    );
};

export default AutoTrackingTimeShowTab;
