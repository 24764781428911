import { FC } from 'react';
import { Identifier, LinearProgress, useGetMany } from 'react-admin';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';

import useResourceFieldName from '@js/hooks/useResourceFieldName';

type Props = {
    resource: string;
    id: Identifier | undefined;
    emptyText?: string;
};

const ReferenceOneRecord: FC<Props> = ({ resource, id, emptyText }) => {
    const { data, error, loaded } = useGetMany(resource, [id ?? 0], { enabled: !!id });
    const fieldName = useResourceFieldName(resource);

    if (!loaded && id) {
        return <LinearProgress />;
    }
    if (error) {
        return (
            <ErrorIcon
                aria-errormessage={error.message ? error.message : error}
                role="presentation"
                color="error"
                fontSize="small"
            />
        );
    }

    const record = data[0];

    if (!record) {
        return emptyText ? (
            <Typography variant="body2" component="span">
                {emptyText}
            </Typography>
        ) : null;
    }

    return (
        <Typography variant="body2" component="span">
            {get(record, fieldName)}
        </Typography>
    );
};

export default ReferenceOneRecord;
