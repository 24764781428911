import { Datagrid, DateField, Record as RaRecord, ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import { Box } from '@material-ui/core';

import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import RecordPriceField from '@components/field/RecordPriceField';
import DeleteIconButton from '@components/button/DeleteIconButton';
import PassthroughProps from '@components/PassthroughProps';

import { PurchaseSeparatelySale } from '@js/interfaces/PurchaseSeparatelySale';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useSalesContextFilter from '@components/resource/reclamation/useSalesContextFilter';

const PurchaseSeparatelySales = (props: { record?: RaRecord; resource?: string }) => {
    // Use field translations from the manual_sales resource
    const getFieldLabel = useTranslateResourceField('manual_sales');
    const { warning, filter } = useSalesContextFilter();

    return (
        <ReferenceManyField
            reference="purchase_separately_sales"
            sort={{ field: 'soldAt', order: 'DESC' }}
            pagination={<AutoHidePagination />}
            target="reclamation"
            filter={filter}
            {...props}
        >
            <PassthroughProps>
                {(props) => (
                    <>
                        {warning}
                        <Datagrid {...props} empty={<AlertEmptyResource mb={2} />}>
                            <DateField source="soldAt" label={getFieldLabel('soldAt')} showTime />
                            <RecordPriceField
                                source="salesPrice"
                                currencyField="currency"
                                label={getFieldLabel('salesPrice')}
                                sortable={false}
                            />
                            <TextField source="quantity" label={getFieldLabel('quantity')} sortable={false} />
                            <RowActions />
                        </Datagrid>
                    </>
                )}
            </PassthroughProps>
        </ReferenceManyField>
    );
};

const RowActions = (props: { record?: PurchaseSeparatelySale; resource?: string }) => {
    const record = useRecordContext(props);
    if (!record) return null;

    return (
        <Box display="flex" justifyContent="end">
            <DeleteIconButton
                {...props}
                disabledReason={
                    record.canDelete ? undefined : 'resources.purchase_separately_sales.disableDeleteReason'
                }
            />
        </Box>
    );
};

export default PurchaseSeparatelySales;
