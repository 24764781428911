import { Children, FC, isValidElement, PropsWithChildren, ReactElement } from 'react';
import { chunk } from 'lodash';
import { Labeled, Record } from 'react-admin';
import { Grid } from '@material-ui/core';

type Field = ReactElement<{ source: string; label?: string; fullWidth?: boolean }>;

type Props = { chunkSize?: number; basePath?: string; record?: Record; resource?: string };

const GridShowLayout: FC<PropsWithChildren<Props>> = ({ chunkSize = 10, children, basePath, record, resource }) => {
    const fields = Children.toArray(children).filter((field) => {
        if (field && isValidElement(field)) {
            if (!field.props.source) {
                throw new Error('Each field should have "source" prop set');
            }

            return true;
        }

        return false;
    }) as Array<Field>;
    const createFieldsKey = (fields: Array<Field>) => fields.map((field) => field.props.source).join('_');

    return (
        <Grid container>
            {chunk(fields, chunkSize).map((fields) => (
                <Grid key={createFieldsKey(fields)} item xs={12} md={6} lg={4} xl={3}>
                    {fields.map((field) => (
                        <div key={field.props.source} className={`ra-field ra-field-${field.props.source}`}>
                            <Labeled
                                basePath={basePath}
                                record={record}
                                resource={resource}
                                label={field.props.label}
                                source={field.props.source}
                                disabled={false}
                                fullWidth={field.props.fullWidth}
                            >
                                {field}
                            </Labeled>
                        </div>
                    ))}
                </Grid>
            ))}
        </Grid>
    );
};

export default GridShowLayout;
