import { SimpleForm, SimpleFormProps } from 'react-admin';

import Toolbar from '@components/form/Toolbar';
import ResellerInputGuesser from './ResellerInputGuesser';

import useFormFields from '@js/hooks/useFormFields';
import { useAppConfigContext } from '@js/context/AppConfigContext';

const ResellerForm = (props: Omit<SimpleFormProps, 'children' | 'onSubmit'>) => {
    const fields = useFormFields(props);
    const { defaultCurrency } = useAppConfigContext();

    return (
        <SimpleForm toolbar={<Toolbar />} initialValues={{ currency: defaultCurrency }} {...props}>
            {fields.map((field) => {
                return <ResellerInputGuesser key={field.name} source={field.name} field={field} />;
            })}
        </SimpleForm>
    );
};

export default ResellerForm;
