import { ReactElement } from 'react';
import {
    Datagrid,
    DateField,
    FunctionField as RaFunctionField,
    Identifier,
    LinearProgress,
    Record as RaRecord,
    ReferenceManyField,
    Tab,
    TabProps,
    TextField,
    useGetResourceLabel,
    useReference,
    useTranslate,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

import SelloSalesPriceField from '@components/resource/sello_sales/field/SelloSalesPriceField';
import ClickToCopyTooltip from '@components/button/ClickToCopyTooltip';
import AutoHidePagination from '@components/list/AutoHidePagination';
import FunctionField from '@components/field/FunctionField';
import RecordPriceField from '@components/field/RecordPriceField';

import QuantityField from './field/QuantityField';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { Product } from '@js/interfaces/product';
import { Reclamation } from '@js/interfaces/reclamation';

interface Props extends Omit<TabProps, 'children' | 'record'> {
    record?: Product;
}

const BundleItemsTab = (props: Props) => (
    <Tab {...props}>
        <TabContent />
    </Tab>
);

const TabContent = (props: { record?: Product; resource?: string }) => {
    const getFieldLabel = useTranslateResourceField();
    const getResourceLabel = useGetResourceLabel();

    return (
        <Box my={2}>
            <ReferenceManyField reference="products" target="bundle" pagination={<AutoHidePagination />} {...props}>
                <Datagrid expand={Expand}>
                    <TextField source="title" label={getFieldLabel('title', 'reclamations')} />
                    <QuantityField source="quantity" label={getFieldLabel('quantity', 'reclamations')} />
                    <TextField
                        source="composedIdentifier"
                        label={getFieldLabel('composedIdentifier', 'reclamations')}
                    />
                    <FunctionField<Product>
                        label={getResourceLabel('reclamations', 1)}
                        render={(record) => {
                            if (!record?.reclamation) return null;
                            return <ReclamationField reclamationId={record.reclamation} />;
                        }}
                    />
                </Datagrid>
            </ReferenceManyField>
        </Box>
    );
};

const useExpandStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTableCell-head:last-child': {
            textAlign: 'right',
        },
        '& .MuiTableCell-body:last-child': {
            textAlign: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '40%',
        },
    },
}));

const Expand = (props: { record?: RaRecord; resource?: string }) => {
    const classes = useExpandStyles();

    return (
        <div className={classes.root}>
            <ReferenceSales reference="sello_sales" priceField={<SelloSalesPriceField />} {...props} />
            <ReferenceSales
                reference="external_sales"
                priceField={<RecordPriceField source="salesPrice" currencyField="currency" />}
                {...props}
            />
        </div>
    );
};

const ReferenceSales = ({
    reference,
    priceField,
    ...props
}: {
    record?: RaRecord;
    resource?: string;
    reference: 'sello_sales' | 'external_sales';
    priceField: ReactElement;
}) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    return (
        <ReferenceManyField
            reference={reference}
            target="product"
            {...props}
            perPage={20}
            pagination={<AutoHidePagination />}
            sort={{ field: 'soldAt', order: 'desc' }}
        >
            <Datagrid>
                <DateField source="soldAt" showTime />
                <RaFunctionField label={translate('app.label.type')} render={() => getResourceLabel(reference, 1)} />
                {priceField}
            </Datagrid>
        </ReferenceManyField>
    );
};

const ReclamationField = ({ reclamationId }: { reclamationId: Identifier }) => {
    const { referenceRecord } = useReference({ reference: 'reclamations', id: reclamationId.toString() });

    if (!referenceRecord) return <LinearProgress />;
    const reclamation = referenceRecord as Reclamation;

    return (
        <ClickToCopyTooltip text={reclamation.composedIdentifier}>{reclamation.composedIdentifier}</ClickToCopyTooltip>
    );
};

export default BundleItemsTab;
