/* eslint-disable react/jsx-key */
import { useMemo } from 'react';
import { NullableBooleanInput, SearchInput, SelectInput } from 'react-admin';

import QrCodeScannerFilter from '@components/filter/QrCodeScannerFilter';
import BarcodeScannerFilter from '@components/filter/BarcodeScannerFilter';
import DateRangeFilter from '@components/filter/DateRangeFilter';
import { ScrollableSelectInputGuesser } from '@components/form/InputGuesser';
import ReferenceRecursiveSelectInput from '@components/input/ReferenceRecursiveSelectInput';
import BooleanFilterInput from '@components/input/BooleanFilterInput';
import HierarchicalAutocompleteSelectInput from '@components/form/HierarchicalAutocompleteSelectInput';

import { App, useAppConfigContext } from '@js/context/AppConfigContext';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import { useResourcePermissions } from '@js/context/UserPermissionsContext';

/*
 * !!!
 * By changing filters,
 * remember to update filters in assets/js/components/resource/user_groups/input/PermissionsInput/FilterSettings.tsx => ErrandFilterSettings.tsx !!!
 */

export default function useFilters(props: { resource?: string }) {
    const { currentApp } = useAppConfigContext();
    const translateField = useTranslateResourceField();
    const { isFieldListable, isFilterAlwaysOn } = useResourcePermissions(props);

    return useMemo(() => {
        const filters = [
            <SearchInput source="q" alwaysOn autoComplete="off" />,
            <QrCodeScannerFilter source="qrcode" alwaysOn />,
            <BarcodeScannerFilter source="barcode" alwaysOn />,
            <ReferenceRecursiveSelectInput source="lastStatus" alwaysOn multiple />,
            <BooleanFilterInput
                source="exists[reclamations.status]"
                label={translateField('exists[status]', 'reclamations')}
                alwaysOn
                inverse
            />,
            <DateRangeFilter source="createdAt" alwaysOn={isFilterAlwaysOn('createdAt')} />,
            <DateRangeFilter source="updatedAt" alwaysOn={isFilterAlwaysOn('updatedAt')} />,
            <ScrollableSelectInputGuesser
                label={translateField('model', 'reclamations')}
                reference="models"
                source="reclamations.model"
                filter={{
                    'exists[reclamations]': true,
                }}
                alwaysOn={isFilterAlwaysOn('reclamations.model')}
            />,
            <ScrollableSelectInputGuesser
                reference="users"
                source="assignedUser"
                filter={{
                    'exists[errands]': true,
                }}
                sort={{
                    field: 'firstName',
                    order: 'ASC',
                }}
                alwaysOn={isFilterAlwaysOn('assignedUser')}
            />,
            <ScrollableSelectInputGuesser
                label={translateField('type', 'reclamations')}
                reference="types"
                source="reclamations.type"
                filter={{
                    'exists[reclamations]': true,
                }}
                alwaysOn={isFilterAlwaysOn('reclamations.type')}
            />,
            <ScrollableSelectInputGuesser
                label={translateField('resellers', 'reclamations')}
                reference="resellers"
                source="reclamations.resellers"
                alwaysOn={isFilterAlwaysOn('reclamations.resellers')}
            />,
            <HierarchicalAutocompleteSelectInput
                reference="stores"
                source="store"
                alwaysOn={isFilterAlwaysOn('store')}
                multiple
            />,
            <NullableBooleanInput source="credited" alwaysOn={isFilterAlwaysOn('credited')} />,
        ];

        if (isFieldListable('externalApp')) {
            const appChoices = Object.values(App)
                .filter((app) => ![App.Localhost, currentApp].includes(app))
                .map((app) => ({ id: app, name: app }));

            filters.push(<SelectInput source="externalApp" choices={appChoices} />);
        }

        return filters;
    }, [currentApp, isFieldListable, isFilterAlwaysOn, translateField]);
}
