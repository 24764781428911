import { FC, memo } from 'react';
import { DatagridBody, DatagridBodyProps, useResourceContext } from 'react-admin';
import { shallowEqual } from 'react-redux';

import useMercureResourceSubscription from '@js/hooks/useMercureResourceSubscription';

const DatagridBodyWithMercure: FC<DatagridBodyProps> = (props) => {
    const { ids } = props;
    const resource = useResourceContext(props);

    useMercureResourceSubscription(resource, ids);

    return <DatagridBody {...props} />;
};

export const PureDatagridBodyWithMercure = memo(DatagridBodyWithMercure, (prevProps, nextProps) => {
    const { children: _1, expand: _2, row: _3, ...prevPropsWithoutChildren } = prevProps;
    const { children: _4, expand: _5, row: _6, ...nextPropsWithoutChildren } = nextProps;

    return shallowEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
});

export default DatagridBodyWithMercure;
