import { FC, useEffect } from 'react';
import {
    Labeled,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    useGetResourceLabel,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import RecordTitle from '@components/detail/RecordTitle';
import ShowLayoutTabs from '@components/detail/ShowLayoutTabs';
import FunctionField from '@components/field/FunctionField';
import GridShowLayout from '@components/detail/GridShowLayout';

import ResellersShowTab from './ResellersShowTab';
import CommentsTimeline from './CommentsTimeline';
import ReclamationEditableDatagrid from './ReclamationEditableDatagrid';
import ErrandFieldGuesser from './field/ErrandFieldGuesser';
import AutoTrackingTimeShowTab from './AutoTrackingTimeShowTab';

import useMercureResourceSubscription from '@js/hooks/useMercureResourceSubscription';
import useShowFields from '@js/hooks/useShowFields';
import { useUserPermissions } from '@js/context/UserPermissionsContext';
import { post } from '@js/request/apiRequest';
import { extractFields } from './util';

const useStyles = makeStyles((theme) => ({
    reclamationsBox: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
        },
    },
}));

const ErrandShow: FC<ShowProps> = (props) => {
    const translate = useTranslate();
    const translateResourceName = useGetResourceLabel();
    const resource = useResourceContext(props);
    const showFields = useShowFields(props, ['statusNotifications']);
    const { extracted: excludeFromGridFields, rest: fields } = extractFields(showFields, ['images']);
    const permissions = useUserPermissions();
    const classes = useStyles();
    const { id } = props;

    useEffect(() => {
        if (id) {
            post(`${id}/view-event`);
        }
    }, [id]);

    useMercureResourceSubscription(resource, id);

    return (
        <Show {...props} title={<RecordTitle />}>
            <TabbedShowLayout tabs={<ShowLayoutTabs />}>
                <Tab label={translate('app.resources.errands.form.general')}>
                    <FunctionField
                        addLabel={false}
                        render={(record, props) => (
                            <>
                                <GridShowLayout record={record} {...props}>
                                    {fields.map((field) => (
                                        <ErrandFieldGuesser key={field.name} source={field.name} field={field} />
                                    ))}
                                </GridShowLayout>
                                {excludeFromGridFields.map((field) => (
                                    <Labeled key={field.name} {...props} source={field.name}>
                                        <ErrandFieldGuesser source={field.name} field={field} {...props} />
                                    </Labeled>
                                ))}
                            </>
                        )}
                    />
                </Tab>
                {permissions.isResourceEnabled('reclamations') && (
                    <Tab label={translateResourceName('reclamations')} path="reclamations">
                        <FunctionField
                            addLabel={false}
                            render={(record, props) => (
                                <div className={classes.reclamationsBox}>
                                    <ReclamationEditableDatagrid record={record} {...props} />
                                </div>
                            )}
                        />
                    </Tab>
                )}
                {permissions.isResourceEnabled('resellers') && (
                    <Tab label={translateResourceName('resellers')} path="resellers">
                        <ResellersShowTab />
                    </Tab>
                )}
                {permissions.isResourceEnabled('comments') && (
                    <Tab label={translateResourceName('comments')} path="comments">
                        <CommentsTimeline />
                    </Tab>
                )}
                {permissions.isAdmin && (
                    <Tab label={translateResourceName('auto_processing_times')} path="auto_processing_times">
                        <AutoTrackingTimeShowTab />
                    </Tab>
                )}
            </TabbedShowLayout>
        </Show>
    );
};

export default ErrandShow;
