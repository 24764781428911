import polyglotI18nProvider from 'ra-i18n-polyglot';
import { TranslationMessages } from 'react-admin';

import { get } from '@js/request/apiRequest';
import { Locale } from '@js/context/AppConfigContext';

export const COOKIE_NAME = 'locale';

export const saveLocale = (locale: Locale) => {
    const expireInOneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    document.cookie = `${COOKIE_NAME}=${locale}; expires=${expireInOneYear.toUTCString()}; path=/`;
};

const loadTranslations = (locale: Locale) => {
    // If loaded already return
    const i18n = window.ADMIN_APP.i18n;

    if (locale in i18n.translations) return i18n.translations[locale];

    // Load locale
    return get<Record<Locale, TranslationMessages>>(`/api/locale/${locale}`).then((translations) => {
        i18n.translations = {
            ...i18n.translations,
            ...translations,
        };

        return translations[locale];
    });
};

export const i18nProvider = polyglotI18nProvider(
    (locale: string) => loadTranslations(locale as Locale),
    window.ADMIN_APP.i18n.activeLocale,
);
