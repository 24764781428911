import { FC } from 'react';
import { Labeled, LinearProgress, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useFormatCentsPrice } from '@js/hooks/useFormatPrice';
import { Reseller } from '@js/interfaces/reseller';
import { Reclamation } from '@js/interfaces/reclamation';
import useFetchGet from '@js/hooks/useFetchGet';

const useStyles = makeStyles({
    inline: {
        flexDirection: 'unset',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',

        '& div[class^="RaLabeled-"]': {
            width: 'auto',
            paddingTop: 0,
        },
    },
});

interface ResellerCostInfoProps {
    reseller: Reseller;
    reclamation: Reclamation;
}

const ResellerCostInfo: FC<ResellerCostInfoProps> = ({ reseller, reclamation }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const formatPrice = useFormatCentsPrice(reseller.currency);

    const { data, loading } = useFetchGet<{ handlingCost: number }>(`${reseller.id}/reclamation_handling_cost`, {
        reclamation: reclamation.id.toString(),
    });

    if (loading) {
        return <LinearProgress />;
    }

    const { insurancePrice = 0 } = reclamation;
    const handlingCost = data?.handlingCost || 0;
    const profit = insurancePrice - handlingCost;

    return (
        <Labeled label={translate('app.label.profit')} className={classes.inline}>
            <Typography variant="body2" component="span">
                {`${formatPrice(insurancePrice)} - ${formatPrice(handlingCost)} = ${formatPrice(profit)}`}
            </Typography>
        </Labeled>
    );
};

export default ResellerCostInfo;
