import { NumberInput, useRecordContext, maxValue } from 'react-admin';
import { FC } from 'react';
import { useField } from 'react-final-form';

import { Product } from '@js/interfaces/product';

type Props = {
    source?: string;
    resource?: string;
    record?: Product;
};

const PhysicalStoreSoldQuantityInput: FC<Props> = ({ source, ...props }) => {
    const {
        input: { value: syncToSelloFlag },
    } = useField<boolean | undefined>('syncToSello', { subscription: { value: true } });
    const record = useRecordContext(props);

    if (!record) return null;
    const { selloSyncedAt, quantity } = record;

    const isSelloProduct = Boolean(syncToSelloFlag || selloSyncedAt);
    const helperText = isSelloProduct ? 'resources.products.helper_text.disable_physical_store_sello' : undefined;

    return (
        <NumberInput
            source={source!}
            {...props}
            helperText={helperText}
            disabled={isSelloProduct}
            min={0}
            max={quantity}
            validate={maxValue(quantity)}
        />
    );
};

PhysicalStoreSoldQuantityInput.defaultProps = {
    source: 'physicalStoreSoldQuantity',
    resource: 'products',
};

export default PhysicalStoreSoldQuantityInput;
