import { FC } from 'react';

import ReferenceManyGrid from '@components/field/ReferenceManyGrid';
import AddressTypeField from '../address/AddressTypeField';

import { Customer } from '@js/interfaces/customer';
import { Address } from '@js/interfaces/address';

const AddressDataGrid: FC = (props) => {
    return (
        <ReferenceManyGrid<Address, Customer>
            {...props}
            reference="addresses"
            target="customer"
            initialValues={(customer) => ({ customer: customer.id.toString() })}
        >
            <AddressTypeField />
        </ReferenceManyGrid>
    );
};

export default AddressDataGrid;
