import { FC, ReactNode, MouseEvent } from 'react';
import { useResourceContext, Button } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';
import ContentCreate from '@material-ui/icons/Create';
import { ApiRecord } from '@js/interfaces/ApiRecord';

interface Props {
    record?: ApiRecord;
    basePath?: string;
    label?: string;
}

const EditButtonReturnUrl: FC<Props> = ({ basePath = '', record, label = 'ra.action.edit' }) => {
    const resource = useResourceContext();
    const { pathname } = useLocation();
    basePath = basePath || `/${resource}`;

    return (
        <Button
            component={Link as unknown as ReactNode}
            to={{
                pathname: record ? `${basePath}/${encodeURIComponent(record.id)}` : '',
                search: `?returnUrl=${encodeURIComponent(pathname)}`,
                state: { _scrollToTop: true },
            }}
            label={label}
            onClick={stopPropagation}
        >
            <ContentCreate />
        </Button>
    );
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: MouseEvent<HTMLButtonElement>) => e.stopPropagation();

export default EditButtonReturnUrl;
