import { FC } from 'react';
import { useRecordContext } from 'react-admin';

import DiffHtmlField from './DiffHtmlField';
import DataWithMessageField from './DataWithMessageField';

import { ACTION, LogEntry } from '@js/interfaces/logentry';

const DataField: FC<{ record?: LogEntry }> = (props) => {
    const record = useRecordContext(props);

    const renderContent = () => {
        switch (record?.action) {
            case ACTION.SELLO_SYNC:
            case ACTION.WEB_HOOK:
                return <DataWithMessageField record={record} />;
            case ACTION.CREATE:
            case ACTION.UPDATE:
            case ACTION.REMOVE:
            case ACTION.PURCHASE_SEPARATELY:
                return <DiffHtmlField record={record} />;
            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
};

export default DataField;
