import { useLocale } from 'react-admin';
import { useCallback } from 'react';

const useFormatNumber = () => {
    const locale = useLocale();

    return useCallback(
        (value: number | string | null | undefined) => {
            if (value === null || value === undefined) return '';
            return value.toLocaleString(locale);
        },
        [locale],
    );
};

export default useFormatNumber;
