import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import {
    CRUD_UPDATE,
    Identifier,
    useExpanded,
    useNotify,
    useRecordContext,
    useResourceContext,
    useUpdate,
} from 'react-admin';
import ReclamationForm from '@components/resource/reclamation/ReclamationForm';
import FormController, { FormControllerProps } from './FormController';
import LockedFormSafeguard from '@components/form/LockedFormSafeguard';

import useNotifyHttpError from '@js/hooks/useNotifyHttpError';
import useAutoTrackTimeController from '@js/hooks/useAutoTrackTimeController';

import { ReclamationFormCommonProps } from './ReclamationsEditTab';
import { Reclamation } from '@js/interfaces/reclamation';

type Props = ReclamationFormCommonProps & {
    resource?: string;
    id?: Identifier;
};

const EditFormWithRecord = ({ record, ...props }: Props & { record: Reclamation }) => {
    const { id } = props;
    const resource = useResourceContext(props);
    const [update] = useUpdate(resource, record.id, undefined, record);
    const notify = useNotify();
    const notifyFailure = useNotifyHttpError();
    const [, toggleExpanded] = useExpanded(resource, record.id);
    const initialValues = useMemo(() => cloneDeep(record), [record]);
    const { saveEditSession, saveCancelSession } = useAutoTrackTimeController();

    const handleCancel = () => {
        saveCancelSession({ reclamation: id });
        toggleExpanded();
    };

    const handleSubmit: FormControllerProps['onSubmit'] = async (data) =>
        update(
            { payload: { data } },
            {
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify('ra.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                        undoable: false,
                    });

                    saveEditSession({
                        reclamation: id,
                    });

                    toggleExpanded();
                },
                onFailure: notifyFailure,
                mutationMode: 'optimistic',
                returnPromise: true,
            },
        );

    return (
        <LockedFormSafeguard
            id={id}
            resource={resource}
            onCancelTakeOver={toggleExpanded}
            onAcceptHandOver={toggleExpanded}
        >
            <FormController {...props} onSubmit={handleSubmit} onCancel={handleCancel} initialValues={initialValues}>
                <ReclamationForm resource={resource} record={record} id={id} />
            </FormController>
        </LockedFormSafeguard>
    );
};

export default function EditForm(props: Props & { record?: Reclamation }) {
    const record = useRecordContext(props);

    // Make sure the record is loaded before rendering the form
    if (!record) return null;

    return <EditFormWithRecord {...props} record={record} />;
}
