import { Box, CardActions, Checkbox, FormControlLabel, Link, TextField } from '@material-ui/core';
import { Field } from 'react-final-form';
import { useLogin, useNotify, useSafeSetState, useTranslate } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';

import LoginFormLayout, { ActionButton } from './LoginFormLayout';

export interface LoginFormData {
    username: string;
    password: string;
    _remember_me?: boolean;
}

const LoginPage = () => {
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();

    const validate = (values: LoginFormData) => {
        const errors: Partial<LoginFormData> = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const handleSubmit = (values: LoginFormData) => {
        setLoading(true);
        login(values)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    {
                        type: 'warning',
                        messageArgs: {
                            _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
                        },
                    },
                );
            });
    };

    return (
        <LoginFormLayout onSubmit={handleSubmit} validate={validate}>
            <Box paddingBottom={2} paddingX={2}>
                <Box mt={1}>
                    <Field
                        autoFocus
                        id="username"
                        name="username"
                        label={translate('ra.auth.username')}
                        disabled={loading}
                    >
                        {({ meta: { touched, error }, input, ...props }) => (
                            <TextField
                                error={!!(touched && error)}
                                helperText={touched && error}
                                {...input}
                                {...props}
                                fullWidth
                            />
                        )}
                    </Field>
                </Box>
                <Box mt={1}>
                    <Field
                        id="password"
                        name="password"
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading}
                        autoComplete="current-password"
                    >
                        {({ meta: { touched, error }, input, ...props }) => (
                            <TextField
                                error={!!(touched && error)}
                                helperText={touched && error}
                                {...input}
                                {...props}
                                fullWidth
                            />
                        )}
                    </Field>
                </Box>
                <Box mt={1}>
                    <Field id="remember_me" name="_remember_me" type="checkbox" disabled={loading}>
                        {({ input }) => (
                            <FormControlLabel
                                control={<Checkbox {...input} />}
                                label={translate('app.label.remember_me')}
                            />
                        )}
                    </Field>
                </Box>
            </Box>
            <CardActions>
                <ActionButton loading={loading} label="ra.auth.sign_in" />
            </CardActions>
            <Box display="flex" justifyContent="center">
                <Link component={RouterLink} to="/reset-password" variant="overline">
                    {translate('app.label.reset_password')}
                </Link>
            </Box>
        </LoginFormLayout>
    );
};

export default LoginPage;
