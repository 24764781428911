import { FC } from 'react';
import { Form } from 'react-final-form';
import {
    AutocompleteInput,
    CardContentInner,
    NumberInput,
    required,
    SelectInput,
    useTranslate,
    Validator,
} from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field } from '@api-platform/api-doc-parser';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Toolbar from '../Toolbar';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useFormFields from '@js/hooks/useFormFields';
import { ImportState } from './ErrandImport';

interface Props {
    state: ImportState;
    onGoBack: () => void;
    onSubmit: (values: ImportState['mapping']) => void;
}

const RESOURCE = 'reclamations';

const validateUnique = (): Validator => (value, allValues: ImportState['mapping']) => {
    if (value) {
        const repeated = Object.values(allValues?.mapping ?? {})?.filter((column) => column === value).length;

        if (repeated > 1) {
            return 'app.validation.unique';
        }
    }

    return undefined;
};

const MappingForm: FC<Props> = ({ state, onGoBack, onSubmit }) => {
    const fields = useFormFields({ resource: RESOURCE });
    const translate = useTranslate();

    return (
        <Form<ImportState['mapping']>
            onSubmit={onSubmit}
            initialValues={{
                mapping: { ...state.mapping?.mapping },
                weightMeasure: state.mapping?.weightMeasure ?? 'kg',
                exchangeRate: state.mapping?.exchangeRate,
            }}
        >
            {({ handleSubmit, submitting, valid, values }) => (
                <>
                    <CardContentInner>
                        {state.upload?.import?.firstLine ? (
                            <FieldsMapping firstLine={state.upload.import.firstLine} fields={fields} />
                        ) : (
                            <Alert severity="error">{translate('app.error.error_parsing_file')}</Alert>
                        )}
                    </CardContentInner>
                    <Toolbar
                        cancel={{ onClick: onGoBack, label: 'ra.action.back' }}
                        submit={{
                            onSubmit: handleSubmit,
                            disabled: !valid || Object.values(values?.mapping ?? {}).every((column) => !column),
                            saving: submitting,
                            icon: NavigateNextIcon,
                        }}
                    />
                </>
            )}
        </Form>
    );
};

const FieldsMapping = ({ firstLine, fields }: { firstLine: Record<string, string>; fields: Field[] }) => {
    const translateField = useTranslateResourceField(RESOURCE);
    const translate = useTranslate();

    const choices = fields
        .map((field) => ({ id: field.name, name: translateField(field.name, RESOURCE) }))
        .sort((f1, f2) => (f1.name > f2.name ? 1 : -1));

    return (
        <>
            <Grid container spacing={1}>
                {Object.entries(firstLine).map(([column, label]) => (
                    <Grid key={column} item>
                        <AutocompleteInput
                            label={label}
                            source={`mapping[${column}]`}
                            choices={choices}
                            validate={validateUnique()}
                            emptyValue={null}
                            emptyText="resources.errands.import.skip_field_import"
                            allowEmpty
                            fullWidth
                        />
                    </Grid>
                ))}
            </Grid>
            <Typography variant="h6" gutterBottom>
                {translate('app.label.conversation')}
            </Typography>
            <Box display="flex" gridGap={8}>
                <SelectInput
                    source="weightMeasure"
                    label={translate('app.input.weightMeasure')}
                    choices={[
                        { id: 'kg', name: 'app.label.kg' },
                        { id: 'g', name: 'app.label.g' },
                    ]}
                    validate={required()}
                    helperText={translate('app.form.helperText.weightMeasure')}
                />
                <NumberInput
                    source="exchangeRate"
                    label={translate('app.input.exchangeRate')}
                    helperText={translate('app.form.helperText.exchangeRate')}
                />
            </Box>
        </>
    );
};

export default MappingForm;
