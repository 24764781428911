import { Record as RaRecord, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
    source: string;
    record?: RaRecord;
    title?: string;
};

const useStyles = makeStyles({
    root: {
        margin: '0.5rem',
        maxHeight: '10rem',
    },
});

const VideoField = ({ source, title, ...props }: Props) => {
    const record = useRecordContext(props);
    const value = get(record, source) as string | undefined;
    const classes = useStyles();

    if (!value) return null;

    const titleValue = title && get(record, title);
    return <video className={classes.root} title={titleValue} src={value} controls preload="metadata" />;
};

export default VideoField;
