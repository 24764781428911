import { forwardRef } from 'react';
import { Radio, RadioProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        padding: 0,
    },
});

const TooltipRadio = forwardRef<HTMLButtonElement, RadioProps>(function TooltipRadio(props, ref) {
    const classes = useStyles();

    return <Radio ref={ref} classes={classes} {...props} />;
});

export default TooltipRadio;
