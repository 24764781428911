import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SelectField, SimpleShowLayoutProps } from 'react-admin';
import classNames from 'classnames';

import { SimpleShowLayoutGuesser } from '@components/detail/ShowGuesser';
import CommissionField from './CommissionField';

import { Reseller } from '@js/interfaces/reseller';
import { useCurrencyChoices } from '@js/context/AppConfigContext';

interface Props extends Omit<SimpleShowLayoutProps, 'children'> {
    reclamationPreview?: boolean;
    record?: Reseller;
}

const useStyles = makeStyles({
    inline: {
        padding: 0,
        paddingTop: '0 !important',
        '& .ra-field > div': {
            flexDirection: 'unset',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',

            '& div[class^="RaLabeled-"]': {
                width: 'auto',
                paddingTop: 0,
            },
        },
    },
});

const excludeOnReclamationPreview = ['name', 'currency', 'createdAt', 'updatedAt'];

const ResellerShowLayout: FC<Props> = ({ reclamationPreview = false, ...props }) => {
    const classes = useStyles();
    const classNameLayout = classNames({
        [classes.inline]: reclamationPreview,
    });
    const currencyChoices = useCurrencyChoices();

    return (
        <SimpleShowLayoutGuesser
            className={classNameLayout}
            hideFields={reclamationPreview ? excludeOnReclamationPreview : undefined}
            {...props}
        >
            <SelectField source="currency" choices={currencyChoices} addLabel />
            <CommissionField source="commission" />
        </SimpleShowLayoutGuesser>
    );
};

export default ResellerShowLayout;
