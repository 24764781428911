import { FC, useState } from 'react';
import {
    FilterContext,
    FilterForm,
    LinearProgress,
    ReferenceManyField,
    useGetResourceLabel,
    useListContext,
    useRecordContext,
} from 'react-admin';
import ErrorIcon from '@material-ui/icons/Error';

import AutoHidePagination from '@components/list/AutoHidePagination';
import DateRangeFilter from '@components/filter/DateRangeFilter';
import { ScrollableSelectInputGuesser } from '@js/components/form/InputGuesser';

import ProcessingTimeField from './ProcessingTimeField';
import DialogInfo from '../DialogInfo';
import ProcessingTimeTable from '../ProcessingTimeTable';

import useIdsBatchRequest from '@js/hooks/useIdsBatchRequest';

import { Errand } from '@js/interfaces/errand';
import { ProcessingTime } from '@js/interfaces/processingtime';

interface Props {
    record?: Errand;
    addLabel?: boolean;
    source?: string;
    sortable?: boolean;
}

const DialogContent = () => {
    const { data } = useListContext<ProcessingTime>();
    const record = useRecordContext<Errand>();
    const getResourceLabel = useGetResourceLabel();

    if (!record) return null;

    // Filters values are set to list context provided by ReferenceManyField
    const filters = [
        <DateRangeFilter key="createdAt" source="createdAt" alwaysOn />,
        <ScrollableSelectInputGuesser
            key="userGroup"
            label={getResourceLabel('user_groups')}
            reference="user_groups"
            source="userGroup"
            sort={{ field: 'name', order: 'ASC' }}
            filter={{
                'processingTimes.errand': record.id,
            }}
            allowEmpty
            alwaysOn
            multiple
        />,
    ];

    return (
        <>
            <FilterContext.Provider value={filters}>
                <FilterForm />
            </FilterContext.Provider>
            <ProcessingTimeTable items={data} />
            <AutoHidePagination />
        </>
    );
};

const ErrandProcessingTimeField: FC<Props> = ({ source: _, ...props }) => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext(props);
    const getResourceLabel = useGetResourceLabel();
    const {
        error,
        loading,
        data: totalSeconds,
    } = useIdsBatchRequest<number>('/api/errands/processing_time', record?.id);

    if (!record?.id) return null;

    if (loading && totalSeconds === null) {
        return <LinearProgress />;
    }
    if (error) {
        return <ErrorIcon role="presentation" color="error" fontSize="small" />;
    }

    return (
        <>
            <ProcessingTimeField totalSeconds={totalSeconds!} onClick={() => setOpen(true)} />
            <DialogInfo onClose={() => setOpen(false)} open={open} title={getResourceLabel('processing_times')}>
                <ReferenceManyField
                    {...props}
                    reference="processing_times"
                    target="errand"
                    sort={{ field: 'createdAt', order: 'DESC' }}
                    perPage={10}
                >
                    <DialogContent />
                </ReferenceManyField>
            </DialogInfo>
        </>
    );
};

ErrandProcessingTimeField.defaultProps = {
    addLabel: true,
    source: 'processingTimes',
    sortable: false,
};

export default ErrandProcessingTimeField;
