import { FC } from 'react';
import { Record, SelectInput } from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import InputGuesser from '@components/form/InputGuesser';
import CommissionInput from './CommissionInput';
import { useCurrencyChoices } from '@js/context/AppConfigContext';

interface Props {
    field: Field;
    source: string;
    label?: string;
    record?: Record;
    resource?: string;
    fullWidth?: boolean;
}

const ResellerInputGuesser: FC<Props> = ({ field, ...props }) => {
    const currencyChoices = useCurrencyChoices();

    switch (field.name) {
        case 'currency':
            return <SelectInput choices={currencyChoices} {...props} />;
        case 'commission':
            return <CommissionInput {...props} />;
        default:
            return <InputGuesser field={field} {...props} />;
    }
};

export default ResellerInputGuesser;
