import { FC, memo } from 'react';
import {
    FormTab,
    required,
    SelectInput,
    TabbedForm,
    TabbedFormProps,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Field } from '@api-platform/api-doc-parser';

import PasswordAndRepeatPasswordInput from '@components/input/PasswordAndRepeatPasswordInput';
import InputGuesser from '@components/form/InputGuesser';
import Toolbar from '@components/form/Toolbar';

import UserGroupsInput from './input/UserGroupsInput';
import RolesInput from './input/RolesInput';
import ArchivedAtInput from './input/ArchivedAtInput';
import DefaultProcessingTimeUserGroupInput from './input/DefaultProcessingTimeUserGroupInput';
import StatusChangeRulesFormTab from '../user_groups/StatusChangeRulesFormTab';

import { useUserPermissions } from '@js/context/UserPermissionsContext';
import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useFormFields from '@js/hooks/useFormFields';
import { useCountryChoices, useLanguageChoices } from '@js/context/AppConfigContext';

const UserInputGuesser: FC<{ field: Field; isEditing: boolean; resource?: string; source?: string }> = ({
    field,
    isEditing,
    ...props
}) => {
    const countryChoices = useCountryChoices();
    const languageChoices = useLanguageChoices();

    switch (field.name) {
        case 'userGroups':
            return <UserGroupsInput {...props} />;
        case 'defaultProcessingTimeUserGroup':
            return <DefaultProcessingTimeUserGroupInput {...props} />;
        case 'locale':
            return <SelectInput source={field.name} choices={languageChoices} {...props} validate={required()} />;
        case 'country':
            return <SelectInput source={field.name} choices={countryChoices} {...props} validate={required()} />;
        case 'password':
            return <PasswordAndRepeatPasswordInput source="password" required={!isEditing} {...props} />;
        case 'roles':
            return <RolesInput {...props} />;
        case 'archivedAt':
            return <ArchivedAtInput source={field.name} {...props} />;
        default:
            return <InputGuesser source={field.name} field={field} {...props} />;
    }
};

const PureUserInputGuesser = memo(UserInputGuesser, (prevProps, nextProps) => {
    return (
        prevProps.isEditing === nextProps.isEditing &&
        prevProps.resource === nextProps.resource &&
        prevProps.source === nextProps.source &&
        prevProps.field === nextProps.field
    );
});

const UserForm = (props: Omit<TabbedFormProps, 'children'>) => {
    const record = useRecordContext(props);
    const permissions = useUserPermissions();
    const translateField = useTranslateResourceField();
    const translate = useTranslate();
    const fields = useFormFields(props, ['statusChangeRules']);

    return (
        <TabbedForm {...props} toolbar={<Toolbar />} autoComplete="off">
            <FormTab label={translate('resources.user_groups.tab.general')}>
                {fields.map((field) => (
                    <PureUserInputGuesser key={field.name} field={field} isEditing={!!record?.id} />
                ))}
            </FormTab>
            {permissions.isFieldEditable('users', 'statusChangeRules') && (
                <FormTab label={translateField('statusChangeRules', 'user_groups')} path="status_change_rules">
                    <StatusChangeRulesFormTab fullWidth />
                </FormTab>
            )}
        </TabbedForm>
    );
};

export default UserForm;
