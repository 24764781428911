import { EmbeddedRecord, Iri } from '@js/interfaces/ApiRecord';

export enum Type {
    QUARANTINE = 'quarantine',
}

export interface StatusNotification extends EmbeddedRecord {
    type: Type;
    notifyAt?: string;
    users: Iri[];
    userGroups: Iri[];
    notifiedAt?: string;
    errand?: Iri;
    reclamation?: Iri;
    message?: string;
    readonly errandOrReclamationErrand: Iri;
}
