import { Box } from '@material-ui/core';

import CheckboxChunkGroupInput from '@components/input/CheckboxChunkGroupInput';
import useTranslateListAction from './useTranslateListAction';

import { EditActions, ListActions, ResourcePermissions } from '@js/auth/UserPermissionsService';

type ResourceActions = Partial<ResourcePermissions['actions']>;

type Props = {
    source: string;
    resource: string;
};

const RESOURCE_ACTIONS: Record<string, ResourceActions> = {
    errands: {
        list: [
            'add_filter',
            'create',
            'import',
            'import_orders',
            'import_co2',
            'export',
            'manual_sale',
            'returned_item',
        ],
        edit: ['delete'],
    },
    reclamations: { list: ['split', 'co2', 'export', 'purchase_separately', 'manual_sale'], edit: ['delete'] },
};

const ActionCheckboxes = ({ source, resource }: Props) => {
    const translateListAction = useTranslateListAction();

    if (!(resource in RESOURCE_ACTIONS)) {
        throw new Error(`Actions not found for "${resource}" resource`);
    }
    const actions = RESOURCE_ACTIONS[resource];

    return (
        <>
            {(Object.entries(actions) as Array<[keyof ResourceActions, ListActions | EditActions]>).map(
                ([view, actions]) => {
                    const choices = actions.map((action) => ({
                        id: action,
                        name: translateListAction(action),
                    }));

                    return (
                        <Box m={3} ml={5} key={view}>
                            <CheckboxChunkGroupInput
                                label={translateListAction(view)}
                                source={`${source}.${resource}.actions.${view}`}
                                choices={choices}
                                chunkSize={5}
                            />
                        </Box>
                    );
                },
            )}
        </>
    );
};

export default ActionCheckboxes;
