import { TextInput, Validator } from 'react-admin';
import { Reclamation } from '@js/interfaces/reclamation';
import { Box } from '@material-ui/core';

import SuggestCloneIconButton from '../button/SuggestCloneIconButton';
import useInputAutoValidator from '@js/hooks/useInputAutoValidator';

type Props = {
    source: string;
    fullWidth?: boolean;
    resource?: string;
    record?: Reclamation;
    validate?: Validator | Validator[];
};

const TitleInput = (props: Props) => {
    const validate = useInputAutoValidator(props);

    return (
        <Box display="flex">
            <TextInput {...props} validate={validate} />
            <Box mt={1}>
                <SuggestCloneIconButton suggestSource={props.source} />
            </Box>
        </Box>
    );
};

export default TitleInput;
