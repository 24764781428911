import { FC } from 'react';
import { DatagridLoading as RaDatagridLoading, DatagridLoadingProps, useDatagridStyles } from 'react-admin';

type Props = Pick<DatagridLoadingProps, 'nbFakeLines'> & { nbChildren?: number };

const DatagridLoading: FC<Props> = ({ nbFakeLines, nbChildren = 4 }) => {
    const classes = useDatagridStyles();

    return <RaDatagridLoading nbChildren={nbChildren} nbFakeLines={nbFakeLines} classes={classes} />;
};

export default DatagridLoading;
